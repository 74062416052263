import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '~/pages/SignIn';
import SignUp from '../pages/SignUp';
import Dashboard from '../pages/Dashboard';
import ProfileScreen from '../pages/ProfileScreen';
import Calendar from '../pages/Calendar';
import Training from '../pages/Training';
import TrainingActivity from '../pages/TrainingActivity';
import Contracts from '../pages/Contracts';
import TrainingCategory from '../pages/TrainingCategory';
import Discount from '../pages/Discount';
import Promotions from '../pages/Promotions';
import GroupFolder from '../pages/GroupFolder';
import ExchangeToken from '../pages/ExchangeToken';
import ExtraTelegram from '../pages/ExtraTelegram';
import CourseScreen from '../pages/CourseScreen';
import CourseExecScreen from '../pages/CourseExecScreen';
import TrainingExecScreen from '../pages/TrainingExecScreen';
import ExtraGiftsScreen from '../pages/ExtraGiftsScreen';
import ExtraNutritionScreen from '../pages/ExtraNutritionScreen';
import VideoAoVivoScreen from '../pages/VideoAoVivoScreen';
import VideoCalendarScreen from '../pages/VideoCalendarScreen';
import WeightTraining from '../pages/TrainingListScreen/WeightTraining';
import DrillTraining from '../pages/TrainingListScreen/DrillTraining';
import CardioTraining from '../pages/TrainingListScreen/CardioTraining';
import OthersTraining from '../pages/TrainingListScreen/OthersTraining';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SuccessResetPasswordScreen from '../pages/ResetPassword/SuccessResetPasswordScreen';
import WhatsappContactScreen from '../pages/WhatsappContactScreen';
import EmailContactScreen from '../pages/EmailContactScreen';
import ShowLiveLecture from '../pages/ShowLiveLecture'
import RecordedLives from '../pages/RecordedLives'
import NewCalendar from '../pages/TrainingPlan/NewCalendar'
import ConfirmEmail from '../pages/ConfirmEmail';
import ConfirmEmailSuccess from '../pages/ConfirmEmail/ConfirmEmailSuccess';

export default function routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/register" component={SignUp} />

      <Route path="/exchange-token/:userID/:platform" externalLink exact component={ExchangeToken} />

      <Route path="/solicitar-recuperar-senha" exact component={ForgotPassword} />
      <Route path="/recuperar-senha" exact component={ResetPassword} />
      <Route path="/sucesso-recuperar-senha" exact component={SuccessResetPasswordScreen} />

      <Route path="/confirmar-email" exact component={ConfirmEmail} />
      <Route path="/confirmar-email-sucesso" exact component={ConfirmEmailSuccess} />


      <Route path="/novo-treino" component={Calendar} isPrivete />
      <Route path="/meu-treino" component={NewCalendar} isPrivete />
      <Route path="/calendario" component={NewCalendar} isPrivete />
      <Route path="/perfil" component={ProfileScreen} isPrivete />
      <Route path="/dashboard" component={Dashboard} isPrivete />
      <Route path="/treinos" component={Training} isPrivete />
      <Route path="/atividades-treino" component={TrainingActivity} isPrivete />

      <Route path="/contatos" component={Contracts} isPrivete />

      <Route path="/categorias-treino" component={TrainingCategory} isPrivete />

      <Route path="/descontos" component={Discount} isPrivete />
      <Route path="/promocoes" component={Promotions} isPrivete />
      <Route path="/grupos-pastas" component={GroupFolder} isPrivete />

      <Route path="/grupos-telegram" component={ExtraTelegram} isPrivete />
      <Route path="/video-aulas" component={CourseScreen} isPrivete />
      <Route path="/curso/:id" component={CourseExecScreen} isPrivete />
      <Route path="/beneficios-descontos" component={ExtraGiftsScreen} isPrivete />
      <Route path="/dicas-nutricao" component={ExtraNutritionScreen} isPrivete />
      <Route path="/gravacoes-anteriores" component={VideoAoVivoScreen} isPrivete />
      <Route path="/agenda-lives" component={VideoCalendarScreen} isPrivete />

      <Route path="/treinos-forca" component={WeightTraining} isPrivete />
      <Route path="/treinos-educativos" component={DrillTraining} isPrivete />
      <Route path="/treinos-cardio" component={CardioTraining} isPrivete />
      <Route path="/treinos-outros" component={OthersTraining} isPrivete />

      <Route path="/exercicio-execucao/:id" component={TrainingExecScreen} isPrivete />
      <Route path="/aula-coletiva/:lectureId" component={ShowLiveLecture} isPrivete />
      <Route path="/aulas-extras" component={RecordedLives} isPrivete />

      <Route path="/whatsapp-treinadores" component={WhatsappContactScreen} isPrivete />
      <Route path="/enviar-email" component={EmailContactScreen} isPrivete />

      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
