import React, { useState } from 'react';
import { Row, Col, Modal, Input, ModalBody, ModalFooter, Button, FormGroup, Form, Label } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import api from '~/services/api';
import InputMask from 'react-input-mask'

const PaceInput = (props) => (
  <InputMask mask="99:99" defaultValue={props.defaultValue} onChange={props.onChange} placeholder="--:--">
    {(inputProps) => <Input {...inputProps} type="text" disableunderline="true" />}
  </InputMask>
);

export default function NewZoneModal(props) {
  const [show, setShow] = useState(props.open);
  const [userID, setUserID] = useState(props.userID);
  const [executionDate, setExecutionDate] = useState();
  const [zoneBpm, setZoneBpm] = useState();
  const [zonePace, setZonePace] = useState();

  const handleSubmit = async () => {

    if (!zoneBpm && !zonePace) {
      toast.error('Ops! Preecha o BPM Médio ou Ritmo')
      return;
    }

    if (!executionDate) {
      toast.error('Ops! Preecha a data que foi executado esse teste.')
      return;
    }

    const currDate = moment(executionDate).toDate()

    currDate.setHours(12);

    const zoneToSave = {
      user_id: userID,
      type: '20min',
      execution_date: moment(currDate).format('YYYY-MM-DD HH:mm:ss')
    }

    if (zoneBpm)
      zoneToSave.bpm_avg = zoneBpm;

    if (zonePace)
      zoneToSave.pace = zonePace;

    try {
      const response = await api.post('user_zones', zoneToSave)

      if (response && response.status === 200) {
        toast.success("Zonas registrada com sucesso!")
        setShow(!show)
        if (props.refreshUser) {
          props.refreshUser()
        }
      } else {
        toast.warn('Erro ao registrar nova zona.')
      }
    } catch (error) {
      toast.error('Aconteceu um erro ao salvar nova zona.')
    }
  }

  return (
    <Modal style={{ maxWidth: '600px', width: '600px', maxHeight: '400px', height: '400px' }} isOpen={show} toggle={() => props.toggle(!show)}>
      <Form>
        <ModalBody>
          <Row>
            <Col lg={12}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="bpm_avg">BPM Médio</Label>
                    <Input type="number" name="bpm_avg" id="bpm_avg" onChange={(e) => setZoneBpm(e.target.value)} placeholder="BPM" />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label htmlFor="pace">Ritmo (min/km)</Label>
                    <PaceInput defaultValue={''} onChange={e => setZonePace(e.target.value)} name="pace" id="pace" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="execution_date">Data</Label>
                    <Input type="date" name="execution_date" id="execution_date" onChange={(e) => setExecutionDate(e.target.value)} placeholder="Data" />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => props.toggle(!show)}>Cancelar</Button>
          <FormGroup>
            <Button onClick={handleSubmit} color="success" >Salvar e Fechar</Button>
          </FormGroup>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
