import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';

import api from '~/services/api';
import { formatTrainingCategory } from '~/services/util'

import TainingNewModal from '../Training/new/TainingNewModal';
import TrainingShowModal from '../Training/show/TrainingShowModal';

import Loader from '~/components/Loader/Loader';

export default class Training extends Component {
    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);

        this.state = {
            pageData: [],
            loading: true,
            trainingNewModal: false,
            trainingShowModal: false,
            trainingToSave: {trainingGroups: []},
            isTrainingEdit: false
        }

        this.loadData();
    }

    loadData = async () => {
        const { data } = await api.get('trainings');

        this.setState({...this.state, pageData: data, loading: false });
    }

    formatTrainingVisibility = (visible, type) => {
        return visible.split(',').filter((item) => item === type);
    }

    formatTrainingIntensity = (intensity) => {
        switch (intensity) {
            case 1:
                return 'Iniciante'

            case 2:
                return 'Intermediário'

            case 3:
                return 'Avançado'
            default:
                return '-'
        }
    }

    handleEditTraining = (training) => {

        this.setState({...this.state, trainingToSave: training, isTrainingEdit: true, trainingNewModal: true })
    }

    render() {

        return (
            <React.Fragment>
                {this.state.loading && <Loader />}

                <Row>
                    <Col>
                        <div className="page-title-box">
                            <Row>
                                <Col lg={7}>
                                    <h4 className="page-title">Treinos</h4>
                                </Col>
                                <Col lg={5} className="mt-lg-3 mt-md-0">
                                    
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>Lista de Treinos</p>
                                <Button style={{marginRight: 10}} color="success" onClick={() => this.setState({...this.state, trainingNewModal: true })}>Novo</Button>
                                <>
                                <table className="table table-centered mb-0" id="btn-editable">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Tipo</th>
                                            <th>Visibilidade</th>
                                            <th>Intensidade</th>
                                            <th></th>
                                        <th className="tabledit-toolbar-column"></th></tr>
                                    </thead>

                                    <tbody>
                                        { this.state.pageData.map( data => (
                                            <tr id="1">
                                                <td><span className="tabledit-span tabledit-identifier">{data.id}</span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">
                                                    <button style={{marginRight: 10}} className="btn btn-link" color="primary" onClick={() => this.setState({...this.state, trainingToShow: data, trainingShowModal: true })}>{data.name}</button>
                                                </span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">{formatTrainingCategory(data.type)}</span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">
                                                    {this.formatTrainingVisibility(data.visible_type, 1) && <span className="badge badge-primary">ADM</span>}&nbsp;
                                                    {this.formatTrainingVisibility(data.visible_type, 2) && <span className="badge badge-success">Explorar</span>}
                                                </span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">{this.formatTrainingIntensity(data.intensity)}</span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">{data.created_at}</span></td>
                                                <td className="tabledit-view-mode"><Button color="primary" onClick={() => this.handleEditTraining(data)}>Editar</Button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {this.state.trainingNewModal && <TainingNewModal open={this.state.trainingNewModal} training={this.state.trainingToSave} isTrainingEdit={this.state.isTrainingEdit} toggle={() => this.setState({...this.state, trainingNewModal: false, isTrainingEdit: false })} /> }
                {this.state.trainingShowModal && <TrainingShowModal open={this.state.trainingShowModal} training={this.state.trainingToShow} toggle={() => this.setState({...this.state, trainingShowModal: false })} /> }
        </React.Fragment>

        );
        
    };
}
