import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom'
import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { signInRequest, signInSuccess, setLoading } from '~/store/modules/auth/actions';
import { loadUserActivitiesRequest } from '~/store/modules/userActivity/actions';
import { loadUserTrainingPlanRequest } from '~/store/modules/userTrainingPlan/actions';
import Loader from '~/components/Loader/Loader';
import logo from '~/assets/img/logo_corridaperfeita_azul.png';
import queryString from 'query-string';
import api from '~/services/api';

class Login extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    const urlParsed = queryString.parse(this.props.location.search);

    this.handleValidSubmit = this.handleValidSubmit.bind(this);

    this.state = {
      email: '',
      password: '',
      redirectPath: urlParsed?.rpath,
      token: urlParsed?.token
    }
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.state.token) {
      this.props.setLoading(true)

      api.get('user_by_token', { headers: { Authorization: `Bearer ${this.state.token}` } })
        .then(response => {
          localStorage.setItem('cp-showAppPopup', true)

          api.defaults.headers.Authorization = `Bearer ${this.state.token}`;

          this.props.signInSuccess(this.state.token, response.data.user)
          this.props.loadUserActivitiesRequest(response.data.user.id)
          this.props.loadUserTrainingPlanRequest(response.data.user.id)
        })
        .catch(() => {
          this.props.setLoading(false)
        })
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Handles the submit
   */
  handleValidSubmit = (event, values) => {
    this.props.signInRequest(values.email, values.password, this.state.redirectPath);
  }


  /**
   * Redirect to root
   */
  renderRedirectToRoot = () => {
    const isAuthTokenValid = this.props.signed;
    if (isAuthTokenValid) {
      return <Redirect to='/' />
    }
  }

  render() {
    const isAuthTokenValid = this.props.signed;
    return (
      <React.Fragment>
        {this.renderRedirectToRoot()}

        {(this._isMounted || !isAuthTokenValid) && <div className="account-pages mt-5 mb-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={5}>
                <Card>
                  <CardBody className="p-4 position-relative">
                    <div className="text-center w-75 m-auto">
                      <a href="/">
                        <span><img src={logo} alt="" height="48" /></span>
                      </a>
                      <p className="text-muted mb-4 mt-3">Área para membros do Corrida Perfeita</p>
                    </div>


                    { /* preloader */}
                    {this.props.loading && <Loader />}

                    {this.props.error && <Alert color="danger" isOpen={this.props.error ? true : false}>
                      <div>{this.props.error}</div>
                    </Alert>}

                    <AvForm onValidSubmit={this.handleValidSubmit}>

                      <AvGroup>
                        <Label for="password">Email</Label>
                        <AvField type="email" name="email" id="email" placeholder="Seu e-mail" value={this.state.email} required />
                        <AvFeedback>Campo inválido</AvFeedback>
                      </AvGroup>

                      <AvGroup>
                        <Label for="password">Password</Label>
                        <AvInput type="password" name="password" id="password" placeholder="Sua senha" value={this.state.password} required />
                        <AvFeedback>This field is invalid</AvFeedback>
                      </AvGroup>

                      <FormGroup>
                        <Button style={{ background: '#081C39' }} className="btn-block">Entrar</Button>
                      </FormGroup>

                      {/* <p><strong>email:</strong> test &nbsp;&nbsp; <strong>Password:</strong> test</p> */}
                    </AvForm>

                    <div className="text-center w-75 m-auto">
                      <p><Link to="/solicitar-recuperar-senha">Esqueceu sua senha?</Link></p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col className="col-12 text-center">
                <a
                  href="https://corridaperfeita.com/clube/?utm_campaign=link-tela-login-web&utm_medium=link-pagina&utm_source=site-corrida-perfeita"
                  target="_blank" rel="noreferrer">
                  Ainda não tem cadastro?
                </a>
              </Col>
            </Row>

          </Container>
        </div>}
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state) => {
  const { signed, loading } = state.auth;

  return { signed, loading }
};

export default connect(mapStateToProps, { signInRequest, signInSuccess, setLoading, loadUserActivitiesRequest, loadUserTrainingPlanRequest })(Login);
