import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { updateProfileRequest } from '~/store/modules/user/actions'
import api from '~/services/api'
import InputMask from 'react-input-mask'

const CepInput = (props) => (
  <InputMask mask="99999-999" defaultValue={props.defaultValue} onChange={props.onChange} placeholder="Seu Código Postal">
    {(inputProps) => <input {...inputProps} className="form-control" type="text" disableunderline="true" />}
  </InputMask>
);

const isBrazilianCheck = (value) => {
  const word = String(value).toLowerCase()

  if (word === 'brasil' || word === 'brasíl' || word === 'brazil') return true

  return false
}

function AccountData(props) {
  const dispatch = useDispatch()

  const profile = props.profile

  const [name, setName] = useState(profile.name)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [address, setAddress] = useState(profile.address || {})

  const [states, setStates] = useState([])
  const [countries, setCountries] = useState([])
  const [isBrazilian, setIsBrazilian] = useState(profile.address && isBrazilianCheck(profile.address.country) ? true : false)

  function handleUpdateAccount(event) {
    event.preventDefault()

    const data = {
      id: profile.id,
      name
    }

    if (address && address.street) {
      if (address.zipcode) {
        let value = address.zipcode

        value = value.split('-')

        if (value.length > 1) {
          value = value[0] + value[1]

          address.zipcode = value
        }
      }

      data.address = address
    }

    if (password && password.length < 6) {
      toast.error('A senha deve possuir no mínimo 6 caracteres.')
      return
    }

    if (passwordConfirmation && passwordConfirmation !== password) {
      toast.error('As senhas devem ser iguais, verifique-as.')
      return
    }

    if ((password && !passwordConfirmation) || (passwordConfirmation && !password)) {
      toast.error('Preencha todos os campos referentes às senhas.')
      return
    }

    if (password && passwordConfirmation) {
      data.password = password
      data.password_confirmation = passwordConfirmation
    }

    dispatch(updateProfileRequest(data))

    if (password && passwordConfirmation) {
      setPassword('')
      setPasswordConfirmation('')
    }
  }

  function handleChangeCountry(e) {
    const value = e.target.value

    if (isBrazilianCheck(value)) {
      setIsBrazilian(true)
    } else {
      setIsBrazilian(false)
    }

    setAddress({ ...address, country: value })
  }

  useEffect(() => {
    api.get('countries')
      .then((response) => {
        setCountries(response.data)
      })
  }, [])

  useEffect(() => {
    api.get('states')
      .then(response => {
        setStates(response.data["estados"])
      })
  }, [])

  const handleSetState = (value) => {
    setAddress({ ...address, state: value, city: states.find((item) => item.nome === value)?.cidades[0] })
  }

  return (
    <Row>
      <Col>
        <div className="p-2">
          <form onSubmit={handleUpdateAccount} className="form-horizontal">
            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="name">Nome</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  value={name}
                  onChange={e => setName(e.target.value)} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="email">Email</label>
              <div className="col-sm-6">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  disabled
                  value={profile.email}
                  onChange={() => { }} />
                <small>Para alterar seu email, entre em contato conosco.</small>
              </div>

            </div>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="password">Nova senha</label>
              <div className="col-sm-6">
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Sua nova senha"
                  value={password}
                  onChange={e => setPassword(e.target.value)} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="password_confirmation">Repetir nova senha</label>
              <div className="col-sm-6">
                <input
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  className="form-control"
                  placeholder="Confirmação da nova senha"
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)} />
              </div>
            </div>

            <button type="submit" className="btn btn-bordered-primary waves-effect width-md waves-light mt-4 btn-xs-block">Salvar</button>

            <h4 className="mt-5 mb-3">Endereço</h4>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="country">País</label>
              <div className="col-sm-6">
                <select
                  id="country"
                  name="country"
                  className="form-control"
                  value={address.country}
                  onChange={handleChangeCountry}>
                  <option value="0">Selecione...</option>
                  {
                    countries.map((country) => (
                      <option key={country.nome_pais} value={country.nome_pais}>{country.nome_pais}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="state">Estado/Província</label>
              <div className="col-sm-6">
                {isBrazilian ?
                  <select
                    id="state"
                    name="state"
                    className="form-control"
                    value={address.state}
                    onChange={e => handleSetState(e.target.value)}>
                    <option value="0">Selecione...</option>
                    {
                      states.map(state => (
                        <option key={state.nome} value={state.nome}>{state.nome}</option>
                      ))
                    }
                  </select>
                  :
                  <input
                    type="text"
                    id="state"
                    name="state"
                    className="form-control"
                    value={address.state}
                    placeholder="Seu estado"
                    onChange={e => setAddress({ ...address, state: e.target.value })} />
                }
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="city">Cidade</label>
              <div className="col-sm-6">
                {isBrazilian ?
                  <select
                    id="city"
                    name="city"
                    className="form-control"
                    value={address.city}
                    onChange={e => setAddress({ ...address, city: e.target.value })}>
                    <option value="0">Selecione...</option>
                    {
                      states.find((item) => item.nome === address.state)?.cidades?.map(city => (
                        <option key={city} value={city}>{city}</option>
                      ))
                    }
                  </select>
                  :
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className="form-control"
                    value={address.city}
                    placeholder="Sua cidade"
                    onChange={e => setAddress({ ...address, city: e.target.value })} />
                }
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="street">Rua/Avenida</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  id="street"
                  name="street"
                  className="form-control"
                  placeholder="Sua rua/avenida"
                  value={address.street}
                  onChange={e => setAddress({ ...address, street: e.target.value })} />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="number">Número</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  id="number"
                  name="number"
                  className="form-control"
                  placeholder="Número da casa"
                  value={address.number}
                  onChange={e => setAddress({ ...address, number: e.target.value })} />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="complement">Complemento</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  id="complement"
                  name="complement"
                  className="form-control"
                  placeholder="Complemento do seu endereço"
                  value={address.complement}
                  onChange={e => setAddress({ ...address, complement: e.target.value })} />
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="district">Bairro</label>
              <div className="col-sm-6">
                <input
                  type="text"
                  id="district"
                  name="district"
                  className="form-control"
                  placeholder="Seu bairro"
                  value={address.district}
                  onChange={e => setAddress({ ...address, district: e.target.value })} />
              </div>
            </div>



            <div className="form-group row">
              <label className="col-sm-12 col-form-label" htmlFor="zipcode">CEP/Código Postal</label>
              <div className="col-sm-6">
                {isBrazilian ?
                  <CepInput
                    defaultValue={address.zipcode}
                    onChange={e => setAddress({ ...address, zipcode: e.target.value })}
                    name="zipcode"
                    id="zipcode" />
                  :
                  <input
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    className="form-control"
                    value={address.zipcode}
                    placeholder="Seu Código Postal"
                    onChange={e => setAddress({ ...address, zipcode: e.target.value })} />
                }
              </div>
            </div>

            <button type="submit" className="btn btn-bordered-primary waves-effect width-md waves-light mt-4 btn-xs-block">Salvar</button>
          </form>
        </div>
      </Col>
    </Row>
  );
}

export default AccountData;
