import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import {
  updateProfileRequest,
} from '~/store/modules/user/actions'
import { BASE_FRONT_URL } from '~/services/api'
import { Container } from './styles'
import ModalConfirmation from './Modal'
import api from '~/services/api'
import { toast } from 'react-toastify'

function Integrations(props) {
  const dispatch = useDispatch()

  const profile = props.profile
  const stravaIntegrated = useSelector(state => state.user.stravaIntegrated)
  const garminIntegrated = useSelector(state => state.user.garminIntegrated)

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);
  const toggle3 = () => setModal3(!modal3);
  const toggle4 = () => setModal4(!modal4);

  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;
  const closeBtn2 = <button className="close" onClick={toggle2}>&times;</button>;
  const closeBtn3 = <button className="close" onClick={toggle3}>&times;</button>;
  const closeBtn4 = <button className="close" onClick={toggle4}>&times;</button>;

  function handleStravaIntegrate() {
    const hostName = window.location.hostname
    const domainAlias = 'app.corridaperfeita.com'

    const stravaURL = hostName === domainAlias ?
      `${process.env.REACT_APP_STRAVA_API_URL}oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&approval_prompt=force&scope=activity:read_all&redirect_uri=${process.env.REACT_APP_BASE_FRONT_URL_PRODUCTION_NEW}/perfil`
      :
      `${process.env.REACT_APP_STRAVA_API_URL}oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&approval_prompt=force&scope=activity:read_all&redirect_uri=${BASE_FRONT_URL}/perfil`

    window.location.href = stravaURL;
  }

  async function handleGarminIntegrate() {
    try {
      const hostName = window.location.hostname

      const response = await api.get(`integrations/garmin/getGarminRequestToken?hostName=${hostName}`)

      if (response.data && response.data.url) {
        window.location.href = response.data.url
      } else {
        toast.error('Ocorreu um erro ao integrar com a Garmin, tente novamente.')
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao integrar com a Garmin, tente novamente.')
    }
  }

  function removeStravaIntegration() {
    const userToUpdate = { id: profile.id, s_id: '', s_username: '', s_access_token: '', s_refresh_token: '' };

    dispatch(updateProfileRequest(userToUpdate))

    toggle()
  };

  function removeGarminIntegration() {
    const userToUpdate = { id: profile.id, garmin_access_token: '', garmin_token_secret: '' };

    dispatch(updateProfileRequest(userToUpdate))

    toggle2()
  };

  return (
    <Container>
      <ModalConfirmation
        closeBtn={closeBtn}
        handle={removeStravaIntegration}
        modal={modal}
        toggle={toggle}
        body="Tem certeza que deseja remover a integração com Strava?" />

      <ModalConfirmation
        closeBtn={closeBtn2}
        handle={removeGarminIntegration}
        modal={modal2}
        toggle={toggle2}
        body="Tem certeza que deseja remover a integração com Garmin?" />

      <div>
        <Modal isOpen={modal3} toggle={toggle3}>
          <ModalHeader toggle={toggle3} close={closeBtn3}>Aviso</ModalHeader>
          <ModalBody>
            <p>
              A nossa integração com a Garmin permite:
            </p>
            <p>
              1) Transferir para o Corrida Perfeita os treinos realizados em seu Garmin.
            </p>
            <p>
              2) Enviar os treinos de corrida planejados no Corrida Perfeita, direto para seu Garmin Connect.
            </p>
            <p>
              Para ativar as duas possibilidades acima, marque todas as opções na tela de integração no site da Garmin.
            </p>
            <p>
              <b>Atenção:</b> Caso receba os treinos direto da Garmin, evite deixar ativada a integração com o Strava, para não haver duplicidade de registros aqui no Corrida Perfeita.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle3}>Cancelar</Button>&nbsp;
            <Button color="primary" onClick={handleGarminIntegrate}>Continuar</Button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal isOpen={modal4} toggle={toggle4}>
          <ModalHeader toggle={toggle4} close={closeBtn4}>Aviso</ModalHeader>
          <ModalBody>
            <p>
              A integração com o Strava permite armazenar no Corrida Perfeita qualquer atividade que seja registrada lá, inclusive originadas de relógios como Polar, Suunto, Tomtom, Amazfit, etc. Para saber como integrar seu relógio ao Strava, <a href="https://ajuda.corridaperfeita.com/support/solutions/folders/47000768394" target="_blank" rel="noopener noreferrer">clique aqui.</a>
            </p>
            <p>
              Se você possui um relógio Garmin, recomenda-se usar apenas nossa integração direta com a Garmin.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle4}>Cancelar</Button>&nbsp;
            <Button color="primary" onClick={handleStravaIntegrate}>Continuar</Button>
          </ModalFooter>
        </Modal>
      </div>

      <Row>
        <Col>
          <div className="p-2">
            <Row className="mt-2">
              <Col lg={3}>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Strava</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">Status: {stravaIntegrated || profile.s_id ? 'Conectado' : 'Desconectado'}</CardSubtitle>

                    {stravaIntegrated || profile.s_id ?
                      <button
                        onClick={toggle}
                        className="btn btn-bordered-danger waves-effect width-md waves-light">
                        Desconectar
                      </button>
                      :
                      <button
                        onClick={toggle4}
                        className="btn btn-bordered-success waves-effect width-md waves-light">
                        Conectar
                      </button>}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={3}>
                <Card>
                  <CardBody>
                    <CardTitle tag="h5">Garmin</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted">Status: {garminIntegrated || profile.garmin_access_token ? 'Conectado' : 'Desconectado'}</CardSubtitle>

                    {garminIntegrated || profile.garmin_access_token ?
                      <button
                        onClick={toggle2}
                        className="btn btn-bordered-danger waves-effect width-md waves-light">
                        Desconectar
                      </button>
                      :
                      <button
                        onClick={toggle3}
                        className="btn btn-bordered-success waves-effect width-md waves-light">
                        Conectar
                      </button>}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Integrations;
