import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F2F2F2;
  padding: 20px;
  border-radius: 5px;
  margin-top: 30px;

  h5 {
    color:#2C5483;
    font-weight: bold;
  }

  a {
    width: 200px;
  }
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #F2F2F2;
  padding: 20px;
  border-radius: 5px;
  margin-top: 30px;

  h5 {
    color: #2C5483;
    font-weight: bold;
  }

  p {
    font-weight: bold;
  }

  button {
    width: 200px;
  }
`

export const HeaderDivider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`
