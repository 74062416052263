import styled from 'styled-components'

export const ModalTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: #081B39;
`

export const StepTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 20;
`

export const GenericButton = styled.button`
  background-color: ${props => props.backgroundColor};
  height: 40px;
  width: 130px;
  color: ${props => props.fontColor};
  font-weight: bold;
  font-size: 12pt;
  border: 1px solid ${props => props.borderColor};
  margin-top: 15px;
`

export const ButtonsFooter = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StripedStepsIndicator = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StripedIndicator = styled.div`
  background-color: ${props => props.backgroundColor};
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 5px;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  img {
    margin-right: 20px;
  }

  @media (max-width: 667px) {
    flex-direction: column;

    img {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`
