import React from 'react';

import { Row, Col, Card, CardBody } from 'reactstrap';

function WhatsappContactScreen() {
  return (
    <>
      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={7}>
                <h4 className="page-title">Atendimento WhatsApp</h4>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col lg={7}>
                  <p>
                    Nossos treinadores estão disponíveis via WhatsApp, para lhe ajudar em dúvidas diversas sobre seus treinos e sobre corrida.
                  </p>

                  <a
                    className="btn btn-primary"
                    href="https://corridaperfeita.com/whatsapp-clube-essencial"
                    target="_blank" rel="noreferrer">
                    Clique aqui para falar no WhatsApp
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default WhatsappContactScreen;
