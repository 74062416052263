export function saveTrainingPlanRequestRequest(data, userProfileToUpdate = null) {
    return {
        type: '@trainingPlanRequests/SAVE_TRAINING_PLAN_REQUEST_REQUEST',
        payload: { data, userProfileToUpdate }
    };
}

export function saveTrainingPlanRequestSuccess() {
    return {
        type: '@trainingPlanRequests/SAVE_TRAINING_PLAN_REQUEST_SUCCESS'
    };
}

export function saveTrainingPlanRequestFailure() {
    return {
        type: '@trainingPlanRequests/SAVE_TRAINING_PLAN_REQUEST_FAILURE'
    };
}

export function toggleShowModalTrainingPlanRequest() {
    return {
        type: '@trainingPlanRequests/TOGGLE_SHOW_MODAL_TRAINING_PLAN_REQUEST'
    };
}

export function toggleShowModalTrainingPlanRequestTmp() {
    return {
        type: '@trainingPlanRequests/TOGGLE_SHOW_MODAL_TRAINING_PLAN_REQUEST_TMP'
    };
}