import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  profile: {},
  stravaIntegrated: false,
  garminIntegrated: false,
  onboardStatuses: {
    weightTraining: false,
    drills: false,
    cardio: false,
  }
};

export default function user(state = INITIAL_STATE, action) {

  return produce(state, draft => {

    switch (action.type) {

      case '@user/UPDATE_STRAVA_INTEGRATED': {
        draft.stravaIntegrated = !draft.stravaIntegrated;
        break;
      }

      case '@user/UPDATE_GARMIN_INTEGRATED': {
        draft.garminIntegrated = !draft.garminIntegrated
        break
      }

      case '@user/SET_STRAVA_INTEGRATED': {
        draft.stravaIntegrated = action.payload.flag
        break
      }

      case '@user/SET_GARMIN_INTEGRATED': {
        draft.garminIntegrated = action.payload.flag
        break
      }

      case '@user/UPDATE_STRAVA_INTEGRATION': {
        draft.loading = true;
        break;
      }

      case '@user/UPDATE_GARMIN_INTEGRATION': {
        draft.loading = true;
        break;
      }

      case '@user/UPDATE_STRAVA_INTEGRATION_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@user/UPDATE_GARMIN_INTEGRATION_SUCCESS': {
        draft.loading = false;
        break;
      }

      case '@user/UPDATE_STRAVA_INTEGRATION_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@user/UPDATE_GARMIN_INTEGRATION_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@user/UPDATE_PROFILE_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@user/UPDATE_PROFILE_SUCCESS': {
        draft.profile = action.payload.profile;
        draft.loading = false;
        break;
      }

      case '@user/UPDATE_ONBOARD_STATUS': {
        draft.onboardStatuses = { ...draft.onboardStatuses, ...action.payload.onboardStatuses }
        break;
      }

      case '@user/UPDATE_PROFILE_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        break;
      }

      default:
    }
  })
}