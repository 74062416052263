import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarHeader, SidebarFooter } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { setCourseFlow } from '~/store/modules/auth/actions';
import history from '~/services/history';
import { Row, Col, Card, CardBody, Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import moment from 'moment'

import Loader from '~/components/Loader/Loader';

import Highcharts from 'highcharts/highstock'
import { FaHeart, FaBars, FaArrowLeft, FaArrowRight, FaClock, FaWindowClose } from 'react-icons/fa';
import { MdHelp, MdClose } from 'react-icons/md'

import TrainingExecMain from './components/TrainingExecMain'
import TrainingSidebar from './components/TrainingSidebar'
import queryString from 'query-string';


import api from '~/services/api';

const ROUTES_TRAINING = {
    'WeightTraining': 'treinos-forca',
    'Drills': 'treinos-educativos',
    'Cardio': 'treinos-cardio',
    'Others': 'treinos-outros',
}

class TrainingExecScreen extends Component {
    constructor(props) {
        super(props);
        const trainingID = props.match.params.id

        const urlParsed = queryString.parse(this.props.location?.search);

        this.state = {
            loading: false,
            currTraining: {},
            currentTrainingGroup: null,
            currentTrainingGroupIdx: null,
            currentTrainingActivity: null,
            currentTrainingActivityIdx: 0,
            timer: 0,
            interval: null,
            initActivityModal: true,
            showModalStart: true, // true
            showModalTimer: false, // false
            currInitInterval: null,
            currInitTimer: 0,
            trainingToSave: {},
            currInteral: null,
            lastIntervalTimer: 0,
            isStart: false,
            currTimer: 0,
            isGiveUp: false,
            lastActivityGiveUp: null,
            currTrainingIndex: 0,
            trainingToChangeID: urlParsed?.rt,
            trainingStarted: false,
            modal: false
        }

        this._loadTraining(props.match.params.id)
    }

    toggle = () => this.setState({ ...this.state, modal: !this.state.modal })

    _loadTraining = async (trainingID) => {
        await Promise.all([ api.get(`/trainings/${trainingID}`) ]).then(async ([coursesResp]) => {
            if (coursesResp.status === 200) {
             const training = coursesResp.data;
                let trainingGroup = training.trainingGroups[0];

                if (trainingGroup) {
                    const newActs = [...trainingGroup.trainingActivities, {id: 66666, name: 'Finalizar Série', activity_type: 'FINISH'}]
                    trainingGroup = { ...trainingGroup, trainingActivities: newActs }

                    const trainingToSave = this.state.trainingToSave;
                    moment(new Date()).format('YYYY-MM-DD')
                    trainingToSave.due_date = moment(new Date());

                    let tActivity = trainingGroup.trainingActivities[0]

                    const stt = {
                        ...this.state,
                        currTraining: training,
                        currentTrainingGroup: trainingGroup,
                        currentTrainingGroupIdx: 0,
                        currentTrainingActivity: tActivity,
                        currentTrainingActivityIdx: 0,
                        trainingToSave: trainingToSave,
                        interval: null,
                        currInteral: null,
                        lastIntervalTimer: 0,
                        isStart: false,
                        currTimer: 0,
                        isGiveUp: false,
                        lastActivityGiveUp: null,
                        currTrainingIndex: 0 };

                    this.setState(stt)
                }
            } else {
                alert('Problema ao carregar curso, tente novamente mais tarde.')
            }
        });
    }
    
    componentWillUnmount() {
        this.props.setCourseFlow(false);
    }

    _exitTraining = async () => {
        await this.props.setCourseFlow(false);

        history.push(`/${ROUTES_TRAINING[this.state.currTraining.type]}?type=${this.state.currTraining.type}`)
    }

    _handleCollapsedChange = (collapsed) => {
        this.setState({ ...this.state, collapsed })
    };

    _handleRtlChange = (rtl) => {
        this.setState({ ...this.state, rtl, locale: (rtl ? 'ar' : 'en') })
    };
    _handleImageChange = (image) => {
        this.setState({ ...this.state, image })
    };

    _handleToggleSidebar = (toggled) => {
        this.setState({ ...this.state, toggled })
    };

    _handleInitCourse = () => {
    }

    _updatecurrTraining = (newTraining) => {
        this.setState({ ...this.state, currTraining: newTraining })
    }

    _goToActivity = (newModule, newModuleIdx, newAct, newActIdx) => {
        this.setState({...this.state, 
            currentTrainingActivity: newAct,
            currentTrainingActivityIdx: newActIdx,
            currentTrainingGroupIdx: newModuleIdx,
            currentTrainingGroup: newModule })
    }

    _startTraining = (status) => {
        this.setState({ ...this.state, trainingStarted: status })
    }

    render() {
        const { currTraining, currentTrainingGroup, currentTrainingActivity, currentTrainingActivityIdx, currentTrainingGroupIdx } = this.state;

        const executedPercent = !!currTraining ? `${currTraining.executed_percent}% concluído` : ''
        const courseStatus = !!currTraining && (currTraining.executed_status === 'EXECUTED_LOW' || currTraining.executed_status === 'EXECUTED_MEDIUM') ? executedPercent : (currTraining.executed_status === 'EXECUTED_HIGH' ? 'Concluído' : 'Não iniciado' )
        const courseButtonLabel = !!currTraining && (currTraining.executed_status === 'EXECUTED_LOW' || currTraining.executed_status === 'EXECUTED_MEDIUM') ? 'Continuar' : (currTraining.executed_status === 'EXECUTED_HIGH' ? 'Abrir' : 'Iniciar' )

        const estimatedDuration = currTraining.estimated_init ? `${currTraining.estimated_init} a ${currTraining.estimated_end} minutos` : '--'

        const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>;
        return (
          <React.Fragment>
                  { /* preloader */}
                  {false && <Loader />}

                  <div>
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle} close={closeBtn}>Confirmação</ModalHeader>
                        <ModalBody>
                            Deseja mesmo terminar o treino aqui?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.toggle}>Cancelar</Button>&nbsp;
                            <Button color="danger" onClick={this._exitTraining}>OK</Button>
                        </ModalFooter>
                    </Modal>
                </div>

                <div style={{backgroundColor: 'white', margin: 0, padding: 10, height: 100, width: '100%'}}>
                    {currTraining && (
                        <Row>
                            <Col lg={4}>
                                <h4 style={{   }}>{currTraining.name}</h4>
                                <p>{currTraining.description}</p>
                            </Col>
                            <Col lg={7}>
                                <p style={{ marginTop: 10 }}><FaHeart style={{ marginRight: 5 }} />{currTraining.equipments}</p>
                                <p><FaClock style={{ marginRight: 5 }} />Duração estimada: {estimatedDuration}</p>
                            </Col>
                            <Col lg={1} style={{display: 'flex', justifyContent: 'center'}}>
                                <Button  onClick={this.toggle} style={{width: 44, height: 44, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <MdClose size={25} style={{fontWeight: '600', fontSize: 25}} />
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>

                <div className={`app ${this.state.rtl ? 'rtl' : ''} ${this.state.toggled ? 'toggled' : ''}`} style={{display: 'flex', flex: 1, height: '100%'}}>
                    <TrainingSidebar
                        trainingStarted={this.state.trainingStarted}
                        image={this.state.image}
                        training={currTraining}
                        currTrainingGroup={currentTrainingGroup}
                        currTrainingGroupIdx={currentTrainingGroupIdx}
                        currTrainingActivity={currentTrainingActivity}
                        currTrainingActivityIdx={currentTrainingActivityIdx}
                        
                        updateTraining={this._updatecurrTraining}

                        collapsed={this.state.collapsed}
                        toggled={this.state.toggled}
                        handleToggleSidebar={this._handleToggleSidebar}/>

                    {currTraining && currTraining.id &&
                    <TrainingExecMain
                        startTrainingFunc={this._startTraining}
                        image={this.state.image}
                        test={{id: 666, name: 'testeData', type: "fdp"}}
                        training={currTraining}
                        currTraininGroup={currentTrainingGroup}
                        currTraininActivity={currentTrainingActivity}
                        currTrainingActivityIdx={currentTrainingActivityIdx}
                        currTrainingGroupIdx={currentTrainingGroupIdx}
                        profile={this.props.profile}
                        goToActivity={this._goToActivity}
                        updateTraining={this._updatecurrTraining}
                        exitTraining={this.toggle}
                        toggled={this.state.toggled}
                        collapsed={this.state.collapsed}
                        handleToggleSidebar={this._handleToggleSidebar}
                        handleCollapsedChange={this._handleCollapsedChange}
                        handleRtlChange={this._handleRtlChange}
                        handleImageChange={this._handleImageChange}
                        trainingToChangeID={this.state.trainingToChangeID}
                        />}
                    </div>
      
          </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.user.profile
    }
};
export default connect(mapStateToProps, { setCourseFlow })(TrainingExecScreen);