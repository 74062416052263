import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, Input, InputGroup, Modal, ModalFooter, ModalBody, ModalHeader } from 'reactstrap';
import moment from 'moment'
import api from '~/services/api'
import Loader from '~/components/Loader/Loader';
import Pagination from "react-reactstrap-pagination";
import { FaVideo, FaSearch } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Container } from './styles'

function RecordedLives() {
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [lives, setLives] = useState([])
    const [selectedPage, setSelectedPage] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [lastPage, setLastPage] = useState(1)
    const [live, setLive] = useState({})
    const [searchText, setSearchText] = useState('')

    const toggle = () => setShowModal(!showModal)

    const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

    const loadLives = useCallback(async (page, search = null) => {
        try {
            setLoading(true)

            const url = search ? `live_lectures?page=${page}&limit=${perPage}&clone_type=SCHEMA&order=DESC&isRecord=true&obj_type=LIVE&title=${search}`
                : `live_lectures?page=${page}&limit=${perPage}&clone_type=SCHEMA&order=DESC&isRecord=true&obj_type=LIVE`

            const { data } = await api.get(url)

            setLives(data.data)
            setTotalItems(data.total)
            setPerPage(data.perPage)
            setLastPage(data.lastPage)
            setSelectedPage(data.page)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error('Erro ao carregar gravações das lives!')
        }
    }, [perPage])

    function handleShowLiveModal(liveToShow) {
        setLive(liveToShow)
        setShowModal(true)
    }

    function handleSearch(e) {
        e.preventDefault()

        loadLives(1, searchText)
    }

    useEffect(() => {
        loadLives(1)
    }, [loadLives])

    return (
        <Container>
            <div>
                <Modal isOpen={showModal} toggle={toggle} size="xl">
                    <ModalHeader toggle={toggle} close={closeBtn}>{live.title}</ModalHeader>
                    <ModalBody>
                        <div className="embed-responsive embed-responsive-16by9 mt-3">
                            <iframe
                                title="video"
                                className="embed-responsive-item"
                                src={live.record_link}
                                allowFullScreen></iframe>
                        </div>

                        {live.description && <div className="mt-2"><h5>{live.description}</h5></div>}

                        {live.audio_link &&
                            <div className="mt-3">
                                <h5>Escute em áudio</h5>
                                <audio src={live.audio_link} controls className="w-100" />
                            </div>}
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-bordered-primary" onClick={toggle}>Fechar</button>
                    </ModalFooter>
                </Modal>
            </div>

            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Aulas extras (lives)</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            {loading && <Loader />}
                            <Row>
                                <Col sm={12} lg={12}>
                                    <form onSubmit={handleSearch} className="d-block d-sm-none">
                                        <InputGroup>
                                            <Input
                                                value={searchText}
                                                onChange={e => setSearchText(e.target.value)}
                                                type="text"
                                                name="search"
                                                id="search"
                                                placeholder="Pesquisar vídeos" />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit">
                                                    <FaSearch color="white" size={18} />
                                                </button>
                                            </div>
                                        </InputGroup>
                                    </form>
                                    <div className="table-responsive">
                                        <table className="table-borderless table-hover table">
                                            <thead>
                                                <tr>
                                                    <th><h4>Tema</h4></th>
                                                    <th><h4>Data</h4></th>
                                                    <th className="d-none d-sm-block">
                                                        <form onSubmit={handleSearch}>
                                                            <InputGroup>
                                                                <Input
                                                                    value={searchText}
                                                                    onChange={e => setSearchText(e.target.value)}
                                                                    type="text"
                                                                    name="search"
                                                                    id="search"
                                                                    placeholder="Pesquisar vídeos" />
                                                                <div className="input-group-append">
                                                                    <button
                                                                        className="btn btn-primary"
                                                                        type="submit">
                                                                        <FaSearch color="white" size={18} />
                                                                    </button>
                                                                </div>
                                                            </InputGroup>
                                                        </form>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lives.length > 0 &&
                                                    lives.map(lec => (
                                                        <tr key={lec.id}>
                                                            <th scope="row">{lec.title}</th>
                                                            <td>{moment(lec.date).format('DD/MM/YYYY')}</td>
                                                            <td>
                                                                <button
                                                                    onClick={() => handleShowLiveModal(lec)}
                                                                    className="btn btn-bordered-primary btn-block">
                                                                    Assistir&nbsp;&nbsp;
                                                                <FaVideo color="white" size={20} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ overflow: 'auto' }}>
                                    <Pagination
                                        firstPageText="Início"
                                        lastPageText="Fim"
                                        previousPageText="Anterior"
                                        nextPageText="Próximo"
                                        totalItems={totalItems}
                                        pageSize={perPage}
                                        maxPaginationNumbers={lastPage}
                                        defaultActivePage={selectedPage}
                                        onSelect={loadLives}
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container >
    );
}

export default RecordedLives;
