import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import userActivities from './userActivity/reducer'
import userTrainingPlan from './userTrainingPlan/reducer'
import liveLectures from './liveLectures/reducer'
import trainingPlanRequests from './trainingPlanRequests/reducer'

export default combineReducers({
  auth,
  user,
  userActivities,
  userTrainingPlan,
  liveLectures,
  trainingPlanRequests
})