import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import DatePicker from 'react-datepicker'

export default function Alternative({
  selectedSchema,
  step,
  startAt,
  setValueForEnableNextButton,
  handleStartAt,
  minStartAt,
  maxStartAt,
  alternativeType,
  setAlternativeType,
  setCurrentPractice,
  currentPractice,
  setTrainingSessionTime,
  trainingSessionTime,
  setMHistoryNotes,
  mHistoryNotes,
  setGeneralNotes,
  generalNotes
}) {
  return (
    <>
      {selectedSchema.type === 'ALTERNATIVE' && step.order === 1 &&
        <div className="mt-5">
          <h4>{step.title}</h4>

          <h5>{step.description}</h5>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {step.data.length > 0 &&
              step.data.map(opt => (
                <div
                  className="mt-2"
                  key={opt.label}
                  onClick={() => setValueForEnableNextButton(() => setAlternativeType(opt.value))}
                  style={{ border: `${alternativeType === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                  <input
                    type="radio"
                    name={opt.label}
                    value={opt.value}
                    checked={alternativeType === opt.value}
                    onChange={e => setAlternativeType(e.target.value)} />

                  <div className="ml-2" style={{ display: 'flex', flexDirection: 'column' }}>
                    <h5>{opt.label}</h5>
                  </div>
                </div>
              ))}
          </div>
        </div>
      }

      {selectedSchema.type === 'ALTERNATIVE' && step.order === 2 &&
        <div className="mt-5">
          <h4>{step.title}</h4>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="mt-2" style={{ maxWidth: 400 }}>
              <FormGroup>
                <Label for="startAt">{step.selectText}</Label>

                <DatePicker
                  selected={startAt ? new Date(startAt) : null}
                  onChange={date => setValueForEnableNextButton(() => handleStartAt(date))}
                  minDate={new Date(minStartAt)}
                  maxDate={new Date(maxStartAt)}
                  placeholderText="Data de início"
                  locale="pt-BR"
                  dateFormat="dd/MM/yyyy"
                  className="form-control"
                  withPortal
                />
              </FormGroup>
            </div>
          </div>

          <h5>{step.description}</h5>
        </div>
      }

      {selectedSchema.type === 'ALTERNATIVE' && step.order === 3 &&
        <div className="mt-5">
          <h4>{step.title}</h4>

          <h5>{step.description}</h5>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {step.data.length > 0 &&
              step.data.map(opt => {
                return (
                  <div
                    className="mt-2"
                    key={opt.label}
                    onClick={() => setValueForEnableNextButton(() => setTrainingSessionTime(opt.value))}
                    style={{ border: `${trainingSessionTime === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                    <input
                      type="radio"
                      name={opt.label}
                      value={opt.value}
                      checked={trainingSessionTime === opt.value}
                      onChange={e => setTrainingSessionTime(e.target.value)} />

                    <h5 className="ml-2">{opt.label}</h5>
                  </div>
                )
              })}
          </div>
        </div>
      }

      {selectedSchema.type === 'ALTERNATIVE' && step.order === 4 &&
        <div className="mt-5">
          <h4>{step.title}</h4>

          <h5>{step.description}</h5>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {step.data.length > 0 &&
              step.data.map(opt => {
                return (
                  <div
                    className="mt-2"
                    key={opt.label}
                    onClick={() => setValueForEnableNextButton(() => setCurrentPractice(opt.value))}
                    style={{ border: `${currentPractice === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                    <input
                      type="radio"
                      name={opt.label}
                      value={opt.value}
                      checked={currentPractice === opt.value}
                      onChange={e => setCurrentPractice(e.target.value)} />

                    <h5 className="ml-2">{opt.label}</h5>
                  </div>
                )
              })}
          </div>
        </div>
      }

      {selectedSchema.type === 'ALTERNATIVE' && step.order === 5 &&
        <div className="mt-5">
          <h4>{step.title}</h4>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="mt-2" style={{ maxWidth: 400 }}>
              <FormGroup>
                <Label for="mHistoryNotes">{step.description}</Label>
                <Input
                  onChange={e => setValueForEnableNextButton(() => setMHistoryNotes(e.target.value))}
                  type="textarea"
                  maxLength="300"
                  name="mHistoryNotes"
                  id="mHistoryNotes"
                  placeholder="Opcional" />
                {mHistoryNotes && mHistoryNotes.length === 300 && <span style={{ color: 'red' }}>Limite máximo de 300 caracteres atingido</span>}
              </FormGroup>
              <FormGroup>
                <Label for="generalNotes">{step.description2}</Label>
                <Input
                  onChange={e => setValueForEnableNextButton(() => setGeneralNotes(e.target.value))}
                  type="textarea"
                  maxLength="300"
                  name="generalNotes"
                  id="generalNotes"
                  placeholder="Opcional" />
                {generalNotes && generalNotes.length === 300 && <span style={{ color: 'red' }}>Limite máximo de 300 caracteres atingido</span>}
              </FormGroup>
            </div>
          </div>
        </div>
      }
    </>
  )
}
