import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Nav, NavLink, NavItem, TabPane, TabContent, FormGroup, Button, Input,  Card, CardTitle, CardText } from 'reactstrap';
import Loader from '~/components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowModalTrainingPlanRequest } from '~/store/modules/trainingPlanRequests/actions'
import api from '~/services/api'
import { FaHeart, FaBars, FaArrowLeft, FaArrowRight, FaWindowClose, FaWindows, FaMale, FaTv, FaUser, FaCalendarAlt, FaLifeRing, FaQuestion } from 'react-icons/fa';
import { toast } from 'react-toastify'
import distanceImg from '~/assets/img/training_requests/distancia.png'
import classnames from 'classnames'
import InputMask from 'react-input-mask'

import { MdHelp, MdClose } from 'react-icons/md'

import { updateProfileRequest, updateOnboardStatus } from '~/store/modules/user/actions'

import moment from 'moment'

const TimeInput = (props) => (
  <InputMask mask="99:99:99" defaultValue={props.defaultValue} onChange={props.onChange} placeholder="--:--:--">
    {(inputProps) => <Input {...inputProps} type="text" disableunderline="true" bsSize="sm" />}
  </InputMask>
);

const newUserOnboardOptions = [
  {order: 1, title: 'Desejamos boas vindas à plataforma do Corrida Perfeita', subtitle: '', description: 'Solicite aqui um plano se você deseja se preparar para uma prova de corrida ou distância em específico, dos 5km aos 42km. Seja completar pela primeira vez ou melhorar seu desempenho. Em tempos de pandemia, use esta opção para vencer “provas pessoais”.', type: 'DISTANCE', steps: [
    {order: 1, complete_desc: '', melhore_desc: '', complete_label: 'Completar os 5km',  melhore_label: 'Melhorar nos 5km', title: 'Treinos para 5km', description: 'Qual o seu principal objetivo na preparação para 5km?', complete_link: 'https://corridaperfeita.typeform.com/to/Xbv5tW', melhora_link: 'https://corridaperfeita.typeform.com/to/KJqgwO'},
    {order: 2, complete_desc: 'Pré-requisito: Já completar 5km correndo sem parar. Se não consegue, comece pelos planos de 5km ou iniciação.', melhore_desc: 'Pré-requisito: Já correr 10km sem parar. Se não consegue, comece pelos planos para completar 10km.', complete_label: 'Completar os 10km',  melhore_label: 'Melhorar nos 10km', title: 'Treinos para 10km', description: 'Qual o seu principal objetivo na preparação para 10km?', complete_link: 'https://corridaperfeita.typeform.com/to/bakRkh', melhora_link: 'https://corridaperfeita.typeform.com/to/TZguK6'},
    {order: 3, complete_desc: 'Pré-requisito para esta opção: Já conseguir completar 10km correndo.', melhore_desc: 'Pré-requisito para esta opção: Já conseguir completar 15km correndo.', complete_label: 'Completar os 15/16km',  melhore_label: 'Melhorar nos 15/16km', title: 'Treinos para 15 e 18 km', description: 'Qual o seu principal objetivo na preparação para 15 ou 16km?', complete_link: 'https://corridaperfeita.typeform.com/to/c2OCpx', melhora_link: 'https://corridaperfeita.typeform.com/to/aCuo05'},
    {order: 4, complete_desc: 'Pré-requisito: Já completar 10km correndo sem parar. Se não consegue, comece pelos planos de 10km ou iniciação 8 semanas.', melhore_desc: 'Pré-requisito: Já correr 21km sem parar. Se não consegue, comece pelos planos para completar 21km.', complete_label: 'Completar os 21km',  melhore_label: 'Melhorar nos 21km', title: 'Treinos para 21km', description: 'Qual o seu principal objetivo na preparação para 21km – Meia Maratona?', complete_link: 'https://corridaperfeita.typeform.com/to/oYIqxU', melhora_link: 'https://corridaperfeita.typeform.com/to/biWCvO'},
    {order: 5, complete_desc: 'Pré-requisitos recomendados para esta opção: Prática média recente de corrida de pelo menos 3h semanais  e já ter completado 21km.', melhore_desc: 'Pré-requisitos recomendados para esta opção: Prática média recente de corrida de pelo menos 3h semanais  e já ter completado 42km.', complete_label: 'Completar os 42km',  melhore_label: 'Melhorar nos 42km', title: 'Treinos para 42km', description: 'Qual o seu principal objetivo na preparação para 42km – Maratona?', complete_link: 'https://corridaperfeita.typeform.com/to/A8EEaR', melhora_link: 'https://corridaperfeita.typeform.com/to/uBWB7t'},
    {order: 6, title: 'Treinos para ultramaratona', description: '', complete_link: 'https://corridaperfeita.typeform.com/to/NXn0Ha' },
  ]},
  { order: 2, title: 'Treinos de base e manutenção', subtitle: '', description: 'Solicite aqui um plano se você deseja manter seu condicionamento na corrida seja porque não tem provas planejadas no momento ou está longe de sua prova almejada. Ou ainda, se você deseja um plano para manter o condicionamento para provas que estejam muito próximas de acontecer, e que não há tempo para um plano de treino completo e específico.', type: 'KEEP_FIT' },
  { order: 3, title: 'Iniciação ou retorno à corrida', subtitle: '', description: 'Solicite aqui um plano se você deseja apenas começar a correr neste momento, ou retomar o ritmo, depois de bastante tempo de afastamento. O objetivo destes planos é preparar você para correr com conforto de 20 a 30 minutos sem parar (4 semanas de treino), ou para que você consiga correr de 40 a 50 minutos sem parar (8 semanas de treino). Se você já tem objetivos maiores do que apenas a iniciação, use as outras opções.', type: 'RETURN_INITIATION' },
  { order: 4, title: 'Treinos para emagrecimento', subtitle: '', description: 'Solicite um plano aqui, se o seu objetivo no momento é usar os treinos de corrida 100% focados para redução de peso corporal. Os demais treinos acima também contribuem com esse processo, mas nesta opção você terá um planejamento todo focado no objetivo de emagrecimento. Montamos planos de 4 ou 8 semanas.', type: 'SLIMMING' }
]

const trainingOptions = {
  "WeightTraining": {
        title: "FORTALECIMENTO",
        link: "https://ajuda.corridaperfeita.com/support/solutions/articles/47001152879-como-fazer-as-s%C3%A9ries-de-fortalecimento-do-corrida-perfeita",
        buttons: true,
        page1: ["Aqui você acessa as séries de exercícios de fortalecimento específicos para a corrida do nosso método.",
            "Você incluirá em sua rotina exercícios simples de serem realizados e que são voltados para: a melhora dos movimentos e postura na corrida, para mais desempenho, para menos desgaste e menos lesões e dores.",
            "Antes de conhecer ou executar as séries de fortalecimento, é muito importante que você confira nossas orientações de execução, para que você não venha a ter problemas."],
        page2: ["Importante: Antes de iniciar alguma das séries de fortalecimento que serão abertas a seguir, certifique-se de que conferiu as importantes orientações de execução. ",
            "Caso já tenha conferido, apenas finalize"]},
  "Drills": {
        title: "EXERCÍCIOS EDUCATIVOS",
        link: "https://ajuda.corridaperfeita.com/support/solutions/articles/47001152887-como-fazer-as-s%C3%A9ries-de-exerc%C3%ADcios-educativos-do-corrida-perfeita",
        buttons: true,
        page1: ["Aqui você acessa as séries de exercícios educativos de corrida do nosso método.",
            "Eles vão atuar diretamente na melhora natural de sua técnica e postura durante a corrida. Inclua-os em sua rotina antes de começar seus treinos de corrida. Se possível, faça antes de todo treino de corrida.",
            "Antes de conhecer ou executar as séries de educativos, é muito importante que você confira nossas orientações de execução, para que você não venha a ter problemas."],
        page2: ["Importante: Antes de iniciar alguma das séries de educatios que serão abertas a seguir, certifique-se de que conferiu as importantes orientações de execução. ",
            "Caso já tenha conferido, apenas finalize"]},
  "Cardio": {
        title: "TREINOS FUNCIONAIS CARDIO",
        link: "https://app.corridaperfeita.com/funcionais-cardio/",
        buttons: false,
        page1: ["Aqui oferecemos sessões de treinos funcionais com mais intensidade, que podem ser usados para substituir uma corrida, quando você não puder correr.",
            "Nos passos seguintes, temos rápidas orientações sobre a execução desses treinos."],
        page2: ["Recomendamos começar pelas sessões de nível inicial, e conforme sua percepção de esforço, ou necessidade, avance ou varie os níveis.",
            "Numa substituição eventual de um treino de corrida por nós prescrito, recomendamos a seguinte estratégia:",
            "- TREINOS LONGOS: Substituir por 2 (duas) sessões seguidas de um nível inicial.",
            "- TREINOS INTERVALADOS: Substituir por 1 (uma) ou 2 (duas) sessões seguidas de nível intermediário ou avançado.",
            "- TREINOS CONTÍNUOS MAIS CURTOS: Substituir por 1(uma) sessão de qualquer nível.",
            "Em caso de dúvidas, entre em contato conosco nas opções de atendimento e ajuda."]},
}

const formatTrainingRequestType = (type) => {
  switch (type) {
    case 'DISTANCE':
      return distanceImg
    case 'KEEP_FIT':
      return distanceImg
    case 'RETURN_INITIATION':
      return distanceImg
    case 'SLIMMING':
      return distanceImg
    case 'TAF':
      return distanceImg
    default:
      return ''
  }
}

const style = {
  padding: 20,
  marginLeft: 10,
  marginTop: 10,
  borderRadius: 8,
  background: '#EEEEEE',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  cursor: 'pointer'
}

function TrainingsOnboardModal({ toggle, open, trainingType }) {
  const dispatch = useDispatch()

  const [schemas, setSchemas] = useState([])
  const [stepSelected, setStepSelected] = useState([])
  const [currTrainingOnboard, setCurrTrainingOnboard] = useState(trainingOptions[trainingType])
  const [currOnboardPage, setCurrOnboardPage] = useState(trainingOptions[trainingType].page1)

  const [selectedSchema, setSelectedSchema] = useState(null)

  const [typeformIframeModal, setTypeformIframeModal] = useState(true)
  const [typeformIframeURL, setTypeformIframeURL] = useState('')

  const profile = useSelector(state => state.user.profile)
  const onboardStatuses = useSelector(state => state.user.onboardStatuses)
  const showModal = useSelector(state => state.trainingPlanRequests.showModal)
  
  const [activeTab, setActiveTab] = useState(1);


  const navFlow = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const changePage = tab => {
    const newPage = tab === 1 ? trainingOptions[trainingType].page1 : trainingOptions[trainingType].page2
    setCurrOnboardPage(newPage)

    if(activeTab !== tab) setActiveTab(tab);
  }

  const finishOnboard = tab => {
    if (trainingType === 'WeightTraining') {
      dispatch(updateOnboardStatus({ weightTraining: true }));
    } else if (trainingType === 'Drills') {
      dispatch(updateOnboardStatus({ drills: true }));
    } else if (trainingType === 'Cardio') {
      dispatch(updateOnboardStatus({ cardio: true }));
    }
  }

  const closeModal = () => {
    finishOnboard()
    toggle()
  }

  return (
    <div>
      <Modal isOpen={open} toggle={closeModal} className="modal-dialog modal-lg" style={{}}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', backgroundColor: '#003399', borderTopLeftRadius: 4, borderTopRightRadius: 4}}>
          <button className="btn link" onClick={closeModal}><MdClose size={30} /></button>
        </div>
        <Row style={{backgroundColor: '#003399', minHeight: 500, margin: 0, borderBottomLeftRadius: 4, borderBottomRightRadius: 4}}>
          <Col lg={12} style={{display: 'flex', flexDirection: 'column' }}>
            <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
                <Row>
                  <Col sm="12">
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', flex: 1, padding: 20}}>
                      <h3 style={{ color: 'white', textAlign: 'center' }}>{currTrainingOnboard?.title}</h3>
                      
                      {currOnboardPage?.map( (txt, tIdx) => {
                        return (
                          <p key={`tap1_${tIdx}`} style={{ color: 'white', marginTop: 13, textAlign: 'center', fontSize: 18, fontWeight: '300' }}>{txt}</p>
                        )
                      })}

                      {currTrainingOnboard.buttons && 
                        <Button outline color="warning" style={{ marginTop: 20 }} onClick={() => {
                          window.open(currTrainingOnboard.link, '_blank');
                        }}>
                          Ver orientações
                          {/* <FaArrowRight style={{ marginLeft: 10 }} /> */}
                        </Button>}


                        {activeTab === 1 &&
                          <Button outline color="secondery" style={{ marginTop: 20 }} onClick={() => changePage(2)}>
                            CONTINUAR
                            <FaArrowRight style={{ marginLeft: 10 }} />
                          </Button>}
                    </div>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId={2}>
                <Row>
                  <Col sm="12">
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', flex: 1, padding: 20}}>
                    
                      <h3 style={{ color: 'white', textAlign: 'center' }}>{currTrainingOnboard?.title}</h3>
                      
                      {currOnboardPage?.map( (txt, tIdx) => {
                        return (
                          <p key={`tap2_${tIdx}`} style={{ color: 'white', marginTop: 13, textAlign: 'center', fontSize: 18, fontWeight: '300' }}>{txt}</p>
                        )
                      })}

                      {currTrainingOnboard.buttons && 
                        <Button outline color="warning" style={{ marginTop: 20 }} onClick={() => {
                          window.open(currTrainingOnboard.link, '_blank');
                          finishOnboard()
                        }}>
                          Ver orientações
                        </Button>}
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default TrainingsOnboardModal;