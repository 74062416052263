import styled from 'styled-components';

export const Container = styled.div`
    .subscription {
        h5 {
            margin-top: 25px;
        }
        
        .table-title {
            margin-top: 70px;
        }

        .table-responsive {
            margin-bottom: 70px;
        }
    }
`;