import produce from 'immer';

const INITIAL_STATE = {
    loading: false,
    showModal: false,
    trainingPlanRequestShowModalTMPButton: false,
    requestSucceded: false
};

export default function trainingPlanRequests(state = INITIAL_STATE, action) {

    return produce(state, draft => {

        switch (action.type) {

            case '@trainingPlanRequests/SAVE_TRAINING_PLAN_REQUEST_REQUEST': {
                draft.loading = true
                break;
            }

            case '@trainingPlanRequests/SAVE_TRAINING_PLAN_REQUEST_SUCCESS': {
                draft.loading = false;
                draft.requestSucceded = true;
                break;
            }

            case '@trainingPlanRequests/SAVE_TRAINING_PLAN_REQUEST_FAILURE': {
                draft.loading = false;
                break;
            }

            case '@trainingPlanRequests/TOGGLE_SHOW_MODAL_TRAINING_PLAN_REQUEST': {
                draft.showModal = !draft.showModal
                draft.requestSucceded = false;
                break
            }

            case '@trainingPlanRequests/TOGGLE_SHOW_MODAL_TRAINING_PLAN_REQUEST_TMP': {
                draft.trainingPlanRequestShowModalTMPButton = !draft.trainingPlanRequestShowModalTMPButton
                draft.requestSucceded = false;
                break
            }

            default:
        }
    })
}