import produce from 'immer';

import moment from 'moment'

const INITIAL_STATE = {
    loading: false,
    expirationDate: null,
    data: {},
    modalActivity: false,
    activityToUpdate: null,
    modalLoading: false
};

export default function userActivities(state = INITIAL_STATE, action) {

    return produce(state, draft => {

        switch (action.type) {

            case '@userActivity/LOAD_USER_ACTIVITIES_REQUEST': {
                draft.loading = true
                break;
            }

            case '@userActivity/LOAD_USER_ACTIVITIES_SUCCESS': {
                draft.data = action.payload.data;
                draft.loading = false;
                draft.expirationDate = moment().add(30, 'seconds').format('YYYY-MM-DD HH:mm');
                break;
            }

            case '@userActivity/LOAD_USER_ACTIVITIES_FAILURE': {
                draft.loading = false;
                break;
            }

            case '@userActivity/SAVE_USER_ACTIVITY_REQUEST': {
                draft.modalLoading = true
                break;
            }

            case '@userActivity/SAVE_USER_ACTIVITY_SUCCESS': {
                const newActivity = action.payload.data

                draft.data.data = [newActivity, ...draft.data.data];
                draft.modalLoading = false;
                break;
            }

            case '@userActivity/SAVE_USER_ACTIVITY_FAILURE': {
                draft.modalLoading = false;
                break;
            }

            case '@userActivity/UPDATE_USER_ACTIVITY_REQUEST': {
                draft.modalLoading = true
                break;
            }

            case '@userActivity/UPDATE_USER_ACTIVITY_SUCCESS': {
                const updatedActivity = action.payload.data

                draft.data.data.forEach(function (item, i) { if (item.id === updatedActivity.id) draft.data.data[i] = updatedActivity; });
                draft.modalLoading = false;
                break;
            }

            case '@userActivity/UPDATE_USER_ACTIVITY_FAILURE': {
                draft.modalLoading = false;
                break;
            }

            case '@userActivity/TOGGLE_MODAL_ACTIVITY': {
                draft.modalActivity = !draft.modalActivity
                break
            }

            case '@userActivity/SET_ACTIVITY_TO_UPDATE': {
                draft.activityToUpdate = action.payload.data
                break
            }

            default:
        }
    })
}
