export function updateProfileRequest(data, showToast = true) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { data, showToast },
  };
}

export function updateProfileSuccess(profile) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}

export function updateOnboardStatus(onboardStatuses) {
  return {
    type: '@user/UPDATE_ONBOARD_STATUS',
    payload: { onboardStatuses },
  };
}

export function updateProfileFailure() {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE',
  };
}

export function updateStravaIntegrated() {
  return {
    type: '@user/UPDATE_STRAVA_INTEGRATED',
  };
}

export function updateGarminIntegrated() {
  return {
    type: '@user/UPDATE_GARMIN_INTEGRATED',
  };
}

export function setStravaIntegrated(flag) {
  return {
    type: '@user/SET_STRAVA_INTEGRATED',
    payload: { flag }
  }
}

export function setGarminIntegrated(flag) {
  return {
    type: '@user/SET_GARMIN_INTEGRATED',
    payload: { flag }
  }
}

export function updateStravaIntegration(profileId) {
  return {
    type: '@user/UPDATE_STRAVA_INTEGRATION',
    payload: { profileId }
  };
}

export function updateStravaIntegrationSuccess() {
  return {
    type: '@user/UPDATE_STRAVA_INTEGRATION_SUCCESS',
  };
}

export function updateStravaIntegrationFailure() {
  return {
    type: '@user/UPDATE_STRAVA_INTEGRATION_FAILURE',
  };
}

export function integrateStrava(data) {
  return {
    type: '@user/INTEGRATE_STRAVA',
    payload: { data }
  };
}

export function integrateGarmin(data) {
  return {
    type: '@user/INTEGRATE_GARMIN',
    payload: { data }
  };
}
