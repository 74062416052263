import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Loader from '~/components/Loader/Loader'
import { Row, Col, Card, CardBody, Modal, ModalBody, ModalFooter } from 'reactstrap'
import api from '~/services/api'
import { toast } from 'react-toastify'
import { FaPlay, FaPause, FaCheck, FaStar, FaRegStar } from 'react-icons/fa'
import Timer from 'react-compound-timer'
import { startLiveLectureRequest, finishLiveLectureRequest } from '~/store/modules/liveLectures/actions'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import moment from 'moment'
import { formatDuration, formatActivityTypeIcon } from '~/services/util'
import ModalActivity from '../Dashboard/components/Modal'
import { toggleModalActivity } from '~/store/modules/userActivity/actions'
import './style.css'

function ShowLiveLecture(props) {
  const lectureId = props.match.params.lectureId

  const startedLectureId = useSelector(state => state.liveLectures.startedLectureId)

  const location = qs.parse(useLocation().search)

  const dispatch = useDispatch()

  const modalLoading = useSelector(state => state.liveLectures.modalLoading)
  const modalActivity = useSelector(state => state.userActivities.modalActivity)

  const [loading, setLoading] = useState(false)
  const [lecture, setLecture] = useState({})
  const [timerStarted, setTimerStarted] = useState(false)
  const [timerPaused, setTimerPaused] = useState(false)
  const [runtime, setRuntime] = useState(0)
  const [modal, setModal] = useState(false)

  const [favorited, setFavorited] = useState(false)

  const toggle = () => setModal(!modal)

  useEffect(() => {
    async function loadLecture() {
      try {
        setLoading(true)

        const response = await api.get(`live_lectures/${lectureId}`)

        setLecture(response.data)
        setFavorited(response.data.favorited)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error('Ocorreu um problema ao carregar aula!')
      }
    }

    loadLecture()
  }, [lectureId])

  function handleModalFinishLecture(millis) {
    const minutes = Number(millis / 60000).toFixed(2);
    setRuntime(minutes)

    toggle()
  }

  function handleStartLecture() {
    dispatch(startLiveLectureRequest(lectureId))
  }

  function handleFinishLecture() {
    dispatch(finishLiveLectureRequest({ lectureId: startedLectureId, runtime }))
    toggle()
    setTimerStarted(false)
  }

  function handleFinishLectureForRecord() {
    dispatch(finishLiveLectureRequest({ lectureId: startedLectureId, runtime }))
    setTimerStarted(false)
  }

  function handleSaveActivity() {
    dispatch(toggleModalActivity())
    toggle()
  }

  async function handleFavorite() {
    try {
      if (favorited) {
        await api.delete(`live_lectures/${lecture.id}/removeFavorite`)

        setFavorited(false)
      } else {
        await api.post(`live_lectures/${lecture.id}/favorite`)

        setFavorited(true)
      }
    } catch (error) {
      toast.error('Erro ao favoritar, tente novamente')
    }
  }

  return (
    <>
      {modalActivity && <ModalActivity prevDuration={runtime} prevTitle={lecture.title} prevHandleFinish={handleFinishLectureForRecord} />}

      <div>
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
          <ModalBody className="text-center">
            {modalLoading && <Loader />}

            <h3>Parabéns!</h3>
            <h2>{lecture.name}</h2>
            <h3>Finalizada</h3>
            <h2>{formatDuration(runtime, 'time')}</h2>
            <Card
              style={{ padding: 10, cursor: 'pointer' }}
              onClick={handleSaveActivity}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ fontSize: 18, color: '#003399', textAlign: 'center', height: 'auto' }}>Salvar Atividade</p>
              </div>

              <div style={{ display: 'flex', marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ fontSize: 15, color: '#010101' }}>{lecture.title}</p>
                <img alt="type" style={{ height: 22 }} src={formatActivityTypeIcon('Cardio')} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p style={{ fontSize: 15, marginTop: 10, color: '#a9a9a9' }}>FC Média</p>
                  <p style={{ fontSize: 15, marginTop: 5, color: '#010101' }}>--</p>
                </div>

                <div style={{ height: 60, width: 1, backgroundColor: '#a9a9a9', borderRadius: 1 }}></div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p style={{ fontSize: 15, marginTop: 10, color: '#a9a9a9' }}>FC Máxima</p>
                  <p style={{ fontSize: 15, marginTop: 5, color: '#010101' }}>--</p>
                </div>

                <div style={{ height: 60, width: 1, backgroundColor: '#a9a9a9', borderRadius: 1 }}></div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <p style={{ fontSize: 15, marginTop: 10, color: '#a9a9a9' }}>Calorias</p>
                  <p style={{ fontSize: 15, marginTop: 5, color: '#010101' }}>--</p>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: 18, color: '#003399', textAlign: 'center', height: 'auto' }}>Preencher métricas</p>
              </div>
            </Card>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-bordered-danger btn-block" onClick={handleFinishLecture}>
              Sair
            </button>
          </ModalFooter>
        </Modal>
      </div>

      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={12}>
                <div className="lecture-title">
                  <h4 className="page-title">
                    {lecture.title}
                    {location.referrer &&
                      location.referrer === 'gravacoes' ?
                      ` ${moment(lecture.date).format('DD/MM/YYYY')}`
                      :
                      ''
                    }
                  </h4>
                  <button
                    style={{ border: 'none', padding: 5, backgroundColor: '#fff' }}
                    onClick={handleFavorite}>
                    {favorited ? <FaStar className="mr-1" size={25} color="#DEC610" /> : <FaRegStar className="mr-1" size={25} color="#081B39" />}
                    {favorited ? 'Favoritada' : 'Favoritar'}
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {loading && <Loader />}

              <Row style={{ alignItems: 'center' }}>
                <Col lg={6}>
                  <h3>Execução da aula {lecture.name}</h3>
                  <h5>{lecture.description}</h5>
                </Col>
                <Col lg={2}>
                  <h5>Equipamentos: {lecture.equipments}</h5>
                </Col>
                <Col>
                  <Timer
                    startImmediately={false}
                    onStart={() => setTimerStarted(true)}
                    onResume={() => setTimerPaused(false)}
                    onPause={() => setTimerPaused(true)}
                  >
                    {({ start, resume, pause, stop, reset, getTime }) => (
                      <>
                        <Row>
                          <Col lg={4}>
                            <h3>
                              <Timer.Hours formatValue={
                                value =>
                                  `${value < 10 ? `0${value}` : value}`} />:
                              <Timer.Minutes formatValue={
                                value =>
                                  `${value < 10 ? `0${value}` : value}`} />:
                              <Timer.Seconds formatValue={
                                value =>
                                  `${value < 10 ? `0${value}` : value}`} />
                            </h3>
                          </Col>
                          <Col>
                            {timerStarted ?
                              <Row>
                                <Col>
                                  {timerPaused ?
                                    <button onClick={resume} className="btn btn-bordered-primary">
                                      Continuar&nbsp;
                                      <FaPlay color="white" size={16} />
                                    </button>
                                    :
                                    <button onClick={pause} className="btn btn-bordered-primary">
                                      Pausar&nbsp;
                                      <FaPause color="white" size={16} />
                                    </button>
                                  }
                                </Col>
                                <Col>
                                  <button
                                    onClick={() => {
                                      stop()
                                      handleModalFinishLecture(getTime())
                                    }}
                                    className="btn btn-bordered-success">
                                    Finalizar&nbsp;
                                    <FaCheck color="white" size={16} />
                                  </button>
                                </Col>
                              </Row>
                              :
                              <button
                                onClick={() => {
                                  reset()
                                  start()
                                  handleStartLecture()
                                }}
                                className="btn btn-bordered-success">
                                Iniciar&nbsp;
                                <FaPlay color="white" size={16} />
                              </button>
                            }
                          </Col>
                        </Row>
                      </>
                    )}
                  </Timer>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  {location.referrer &&
                    location.referrer === 'gravacoes' ?
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        title="video"
                        className="embed-responsive-item"
                        src={lecture.record_link}
                        allowFullScreen></iframe>
                    </div>
                    :
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe
                        title="video"
                        className="embed-responsive-item"
                        src={lecture.live_link}
                        allowFullScreen></iframe>
                    </div>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default ShowLiveLecture;
