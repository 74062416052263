import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

function ModalInfo({ toggle, modal, link }) {
  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle} close={closeBtn}>Atenção</ModalHeader>
        <ModalBody>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Antes de entrar no grupo, confira as importantes informações a seguir. Não deixe de ler tudo:
          </p>

          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            NÃO USE PARA ATENDIMENTO
          </p>
          <p style={{ textAlign: 'center' }}>
            Não use seu grupo para buscar atendimento ou tirar dúvidas em que você espera resposta da equipe Corrida Perfeita.
            Para isso use apenas os canais de atendimento, disponíveis no respectivo menu ATENDIMENTO aqui na plataforma.
          </p>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            O QUE NÃO POSTAR
          </p>
          <p style={{ textAlign: 'center' }}>
            Não use seu grupo para emitir opiniões políticas, religiosas ou de assuntos totalmente alheios ao universo da corrida e dos esportes.
            Use-o para perguntar ou compartilhar algo de real valor dentro do nosso universo, para você e para os demais membros.
          </p>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            SPAM
          </p>
          <p style={{ textAlign: 'center' }}>
            Não use o grupo para fins comerciais, ou seja, para divulgar a venda de seus produtos ou serviços de forma comercial. De todo o modo, você pode
            divulgar a venda de um item pessoal, como um relógio que não usa mais, por exemplo.
          </p>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            BOM SENSO
          </p>
          <p style={{ textAlign: 'center' }}>
            Recomendamos que use do bom senso. O grupo é uma ferramenta a mais para você interagir com outros corredores, fazer novas descobertas e amizades no mundo
            da corrida. Se alguma das recomendações acima não for seguida, a equipe Corrida Perfeita pode se reservar o direito de remover o membro do grupo.
          </p>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            SE APRESENTE
          </p>
          <p style={{ textAlign: 'center' }}>
            Ao ingressar num grupo, aproveite para se apresentar. Diga de onde é e quais seus próximos objetivos na corrida.
          </p>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            LEMBRETE FINAL
          </p>
          <p style={{ textAlign: 'center' }}>
            E por fim, lembramos que para sanar dúvidas ou falar diretamente conosco, NÃO use o grupo. Use os canais oficiais do Menu Atendimento
            aqui na plataforma.
          </p>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <a href={link} target="_blank" rel="noreferrer" style={{ paddingLeft: 50, paddingRight: 50 }} className="btn btn-outline-secondary">
              CONCORDAR E ENTRAR
            </a>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalInfo;
