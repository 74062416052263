import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import logo from '~/assets/img/logo_corridaperfeita_azul.png';


const ConfirmEmailSuccess = (props) => {

  return (
    <>
      <div className="account-pages mt-5 mb-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={4}>
              <Card>
                <CardBody className="p-4 justify-content-center">
                  <div className="text-center w-90 m-auto">
                    <a href="/">
                      <span><img src={logo} alt="" height="48" /></span>
                    </a>
                     <p className="text-muted mb-4 mt-3">O seu email foi confirmado com sucesso!</p>
                  </div>

                  <Link to='/' ><Button style={{ background: '#081C39' }} className="btn-block" >Voltar ao início</Button></Link> 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ConfirmEmailSuccess;