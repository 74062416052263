import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvFeedback } from 'availity-reactstrap-validation';

import { forgotPasswordRequest } from '~/store/modules/auth/actions';
import Loader from '~/components/Loader/Loader';
import logo from '~/assets/img/logo_corridaperfeita_azul.png';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            email: ''
        }
    }

    /**
     * Handles the submit
     */
    handleValidSubmit = (event, values) => {
        this.props.forgotPasswordRequest(
            values.email,
            `${process.env.REACT_APP_FORGOT_PASSWORD_REDIRECT_URL}`);
    }

    render() {
        return (
            <>
                <div className="account-pages mt-5 mb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Card>
                                    <CardBody className="p-4 position-relative">
                                        <div className="text-center w-75 m-auto">
                                            <a href="/">
                                                <span><img src={logo} alt="" height="48" /></span>
                                            </a>
                                            <p className="text-muted mb-4 mt-3"><Link to="/">Voltar para login</Link></p>
                                            <p className="text-muted mb-4 mt-3">Recuperar senha</p>
                                        </div>


                                        { /* preloader */}
                                        {this.props.loading && <Loader />}

                                        {this.props.error && <Alert color="danger" isOpen={this.props.error ? true : false}>
                                            <div>{this.props.error}</div>
                                        </Alert>}

                                        <AvForm onValidSubmit={this.handleValidSubmit}>

                                            <AvGroup>
                                                <Label for="email">Email</Label>
                                                <AvField type="email" name="email" id="email" placeholder="Seu e-mail" value={this.state.email} required />
                                                <AvFeedback>Campo inválido</AvFeedback>
                                            </AvGroup>

                                            <FormGroup>
                                                <Button style={{ background: '#081C39' }} className="btn-block">Receber E-mail de Recuperação</Button>
                                            </FormGroup>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { signed, loading } = state.auth;

    return { signed, loading }
};

export default connect(mapStateToProps, { forgotPasswordRequest })(ForgotPassword);
