import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Container, GroupContainer, HeaderDivider } from './styles'
import ModalInfo from './ModalInfo'

class ExtraTelegram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropDownOpen: false,
      showModalInfo: false,
      modalLink: null
    }
  }

  handleOpenModalInfo = (link) => {
    this.setState({ ...this.state, modalLink: link }, () => {
      this.setState({ ...this.state, showModalInfo: true })
    });
  }

  render() {
    return (
      <React.Fragment>
        <ModalInfo
          link={this.state.modalLink}
          modal={this.state.showModalInfo}
          toggle={() => this.setState({ ...this.state, showModalInfo: !this.state.showModalInfo })}
        />

        <Row>
          <Col>
            <div className="page-title-box">
              <Row>
                <Col lg={7}>
                  <h4 className="page-title">Grupos no Telegram</h4>
                </Col>
                <Col lg={5} className="mt-lg-3 mt-md-0">

                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={6}>
                    <h5 className="text-center">Temos nossos grupos exclusivos no aplicativo Telegram.
                      Eles são uma cortesia e se destinam exclusivamente à
                      interação saudável, agregadora e amistosa entre os
                      atletas do Clube Corrida Perfeita.</h5>

                    <Container>
                      <h5>Canal de Avisos</h5>
                      <p>Além dos grupos abaixo, recomenda-se que você também entre no canal
                        de avisos do Telegram, onde emitimos comunicados de interesse de todos
                        os alunos, como novidades, aulas novas, etc...
                      </p>
                      <a
                        className="btn btn-primary"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/joinchat/AAAAAEsN_l3PsNQqnLD5Dg"
                      >
                        Entrar
                      </a>
                    </Container>

                    <GroupContainer>
                      <HeaderDivider>
                        <h5>Grupos</h5>
                      </HeaderDivider>
                      <br />
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/CfueNVBmmE-xxnSgCylY9A")}
                      >
                        Geral
                      </button>
                      <br />
                      <p>Temáticos</p>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/6NPVW3aDB2k3ZTRh")}
                      >
                        Iniciantes
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/IZugLUzh0RxGul1aLSSpBQ")}
                      >
                        Tênis e vestuário
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/IZugLUjAxweIAQbMDSMmvA")}
                      >
                        Relógios e acessórios
                      </button>
                      <br />
                      <p>Por região</p>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/MCmTFRNhmzJjcDTtEsBIAQ")}
                      >
                        Centro Oeste
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/MCmTFRYaAxccHBfCJjMvaQ")}
                      >
                        Nordeste
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/MCmTFRbKe3n6PgSa2d8OBw")}
                      >
                        Norte
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/MCmTFQ8IpvfbNwAlybj4Iw")}
                      >
                        Sudeste
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/MCmTFRWchvzGsKhfRKeY-w")}
                      >
                        Sul
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/+zijHscFOe4E5MmMx")}
                      >
                        Brasília (Distrito Federal)
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/MCmTFRVJnaC1dFtX42FkLA")}
                      >
                        RJ (estado)
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/MCmTFRSTNv74ObjekM4hMA")}
                      >
                        SP (estado)
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/IZugLUlBZ_I4FpRLQE_nPA")}
                      >
                        Portugal
                      </button>
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => this.handleOpenModalInfo("https://t.me/joinchat/IZugLVLGRU4-fAPi3FJdhg")}
                      >
                        Residentes no exterior
                      </button>
                    </GroupContainer>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ExtraTelegram;
