import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCourseFlow } from '~/store/modules/auth/actions';
import history from '~/services/history';
import { Row } from 'reactstrap'

import { FaClock, FaPeopleCarry } from 'react-icons/fa'

const TAX_CHILD = 0.38;

export default function TrainingListItem({ training, navigation, profile, trainingToChange }) {
  const dispatch = useDispatch();

  const [screenWidth, setScreenWidth] = useState(650)

  async function handleTrainingDetail() {
    const cloneT = JSON.parse(JSON.stringify(training))

    await dispatch(setCourseFlow(true));

    let redirectURL = `/exercicio-execucao/${training.id}`


    if (trainingToChange) {
      redirectURL += `?rt=${trainingToChange}`
    }

    history.push(redirectURL)
  }

  const marginTopChid = (screenWidth * TAX_CHILD);

  const formatTrainingIntensity = (intensity) => {

    switch (intensity) {
      case 1:
        return 'green';
      case 2:
        return 'yellow';
      case 3:
        return 'red';
      default:
        return 'gray';
    }
  }

  return (
    <div style={{ cursor: 'pointer', display: 'flex', zIndex: 2, marginTop: 0, marginBottom: 10, width: 650, flexDirection: 'row', backgroundColor: 'gray'}}
      onClick={handleTrainingDetail}>

      <div  style={{display: 'flex', flexDirection: 'column', height: marginTopChid }}>
        <div style={{width: 8, height: marginTopChid, position: 'absolute', zIndex: 10, backgroundColor: formatTrainingIntensity(training.intensity)}} />

        {training.cover_link &&
          <img src={training.cover_link} style={{ margin: 0, width: screenWidth, height: marginTopChid, position: 'absolute' }} alt="" />}

        <Row style={{margin: 20, zIndex: 10, display: 'flex', flexDirection: 'column' }}>
          <p style={{ fontWeight: '500', fontSize: 20,  color: 'white', width: '90%' }}>{training.name}</p>

          <div style={{height: 0.5, width: '80%', backgroundColor: 'white', marginVertical: 7}} />

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <div style={{display: 'flex', width: 15, height: 15, flexDirection: 'row', alignItems: 'flex-end'}}>
              <div style={{borderWidth: 1, width: 4, height: 4, borderRadius: 1, borderColor: 'white', marginRight: 1, backgroundColor: training.intensity >= 1 ? 'white' : 'gray'}} />
              <div style={{borderWidth: 1, width: 4, height: 9, borderRadius: 1, borderColor: 'white', marginRight: 1, backgroundColor: training.intensity >= 2 ? 'white' : 'gray'}} />
              <div style={{borderWidth: 1, width: 4, height: 14, borderRadius: 1, borderColor: 'white', backgroundColor: training.intensity >= 3 ? 'white' : 'gray'}} />
            </div>
            <p style={{ fontSize: 13, marginTop: 10, marginLeft: 5, color: '#e1e1e1' }}> {training.intensity === 1 ? 'Iniciante' : (training.intensity === 2 ? 'Intermediário' : 'Avançado')} </p>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <FaClock name="clock-outline" size={15} color={'#a3a3a3'} />
            <p style={{ fontSize: 13, marginTop: 15, marginLeft: 5, color: '#e1e1e1' }}> {training.estimated_init} a {training.estimated_end} minutos </p>
          </div>

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
            <FaPeopleCarry name="human" size={15} color={'#a3a3a3'} />
            <p style={{ fontSize: 13, marginTop: 15, marginLeft: 5, color: '#e1e1e1' }}> {training.equipments} </p>
          </div>
        </Row>
      </div>
    </div>
  );
}
