import React, { useState } from "react"
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import PauseModal from "../PauseModal"
import Loader from "~/components/Loader/Loader"
import api from "~/services/api"
import { ButtonsContainer } from "./styles"
import { signOut } from '~/store/modules/auth/actions';
import { useDispatch } from 'react-redux';

function RefundModalForm({ show, toggle, paymentMethod, subscriptionId, billAmount }) {
  const [reason, setReason] = useState("")
  const [optionPix, setOptionPix] = useState("")
  const [comments, setComments] = useState("")
  const [step, setStep] = useState(1)
  const [valuePix, setValuePix] = useState("")
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();

  const resetModal = () => {
    setStep(1)
    setReason("Financeiro")
    setOptionPix("")
    setComments("")
    setValuePix("")

    if (step === "CREDIT_CARD_SUCCESS" || step === "PIX_SUCCESS" || step === "CREDIT_CARD_WAITING" || step === 'PIX_WAITING') {
      dispatch(signOut())
    }

    toggle()
  }

  const sendPixRequest = () => {
    setLoading(true)

    api.post(
      `subscription_cancellations/refund_charge?typeOfPayment=${paymentMethod}`,
      {
        status: "CREATED",
        cancellation_reason: reason,
        comments: comments,
        subscription_id: subscriptionId,
        pix_key: valuePix,
        pix_key_type: optionPix,
      }
    )
      .then((response) => {
        if (response.data.message === 'refund_success') {
          setStep("PIX_SUCCESS")
          setLoading(false)
        }
        else if (response.data.message === 'refund_waiting') {
          setStep("PIX_WAITING")
          setLoading(false)
        }
      })
      .catch(() => {
        setStep("PIX_FAILURE")
        setLoading(false)
      })
  }

  const sendCreditCardRequest = async () => {
    setLoading(true)

    api.post(
      `subscription_cancellations/refund_charge?typeOfPayment=${paymentMethod}`,
      {
        status: "CREATED",
        cancellation_reason: reason,
        comments: comments,
        subscription_id: subscriptionId,
      }
    )
      .then((response) => {

        if (response.data.message === 'refund_success') {
          setStep("CREDIT_CARD_SUCCESS")
          setLoading(false)
        } else if (response.data.message === 'refund_waiting') {
          setStep("CREDIT_CARD_WAITING")
          setLoading(false)
        }

      })
      .catch(() => {
        setStep("CREDIT_CARD_FAILURE")
        setLoading(false)
      })
  }

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
    >
      <ModalBody className="d-flex flex-column justify-content-between p-3">
        <div>
          {step === 1 ? (
            <div>
              <Form>
                <h5>
                  Você poderia informar o motivo do seu pedido de reembolso?
                  <br />A resposta é opcional.
                </h5>
                <br />
                <FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Financeiro"}
                        type="radio"
                        name="reason"
                        value="Financeiro"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Financeiro
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Falta de tempo para treinar"}
                        type="radio"
                        name="reason"
                        value="Falta de tempo para treinar"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Falta de tempo para treinar
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Falta de disciplina/motivação"}
                        type="radio"
                        name="reason"
                        value="Falta de disciplina/motivação"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Falta de disciplina/motivação
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Lesão"}
                        type="radio"
                        name="reason"
                        value="Lesão"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Lesão
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Outros problemas de saúde"}
                        type="radio"
                        name="reason"
                        value="Outros problemas de saúde"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Outros problemas de saúde
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Contratei outro serviço"}
                        type="radio"
                        name="reason"
                        value="Contratei outro serviço"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Contratei outro serviço
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Mudei de atividade física"}
                        type="radio"
                        name="reason"
                        value="Mudei de atividade física"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Mudei de atividade física
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Não gostei do serviço"}
                        type="radio"
                        name="reason"
                        value="Não gostei do serviço"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Não gostei do serviço
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Motivos pessoais"}
                        type="radio"
                        name="reason"
                        value="Motivos pessoais"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Motivos pessoais
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={reason === "Outros"}
                        type="radio"
                        name="reason"
                        value="Outros"
                        onChange={e => setReason(e.target.value)}
                      />{" "}
                      Outros
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Form>
              <ButtonsContainer className="mt-4">
                <Button color="secondary" style={{ width: "48%" }} onClick={toggle}>
                  VOLTAR
                </Button>
                <Button color="primary" style={{ width: "48%" }} onClick={() => setStep(2)}>
                  CONTINUAR
                </Button>
              </ButtonsContainer>
            </div>
          ) : step === 2 ? (
            <div>
              <Form>
                <h5>
                  Gostaria de deixar algum comentário adicional sobre seu pedido de reembolso? A
                  resposta é opcional.
                </h5>
                <br />
                <textarea
                  value={comments}
                  style={{
                    height: 170,
                    width: "100%",
                  }}
                  onChange={e => setComments(e.target.value)}
                />
              </Form>

              <ButtonsContainer className="mt-4">
                <Button color="secondary" style={{ width: "48%" }} onClick={() => setStep(1)}>
                  VOLTAR
                </Button>
                <Button
                  color="primary"
                  style={{ width: "48%" }}
                  onClick={() => {
                    if (paymentMethod === "credit_card") {
                      setStep(3)
                    } else {
                      setStep(4)
                    }
                  }}
                >
                  CONTINUAR
                </Button>
              </ButtonsContainer>
            </div>
          ) : step === 3 ? (
            <div>
              <div className="content">
                <h5>
                  Podemos confirmar o pedido de reembolso?
                  <br />
                  Essa ação não poderá ser desfeita.
                </h5>
              </div>

              <ButtonsContainer className="mt-4">
                <Button color="secondary" style={{ width: "48%" }} onClick={() => setStep(2)}>
                  VOLTAR
                </Button>
                <Button color="primary" style={{ width: "48%" }} onClick={sendCreditCardRequest}>
                  CONFIRMAR
                </Button>
              </ButtonsContainer>
            </div>
          ) : step === 4 ? (
            <div>
              <Form>
                <h5>
                  Informe uma chave PIX de sua titularidade para recebimento do reembolso da sua
                  garantia:
                </h5>
                <br />
                <FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={optionPix === "CPF"}
                        type="radio"
                        name="cpf"
                        value="CPF"
                        onChange={e => setOptionPix(e.target.value)}
                      />
                      CPF
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={optionPix === "E-mail"}
                        type="radio"
                        name="email"
                        value="E-mail"
                        onChange={e => setOptionPix(e.target.value)}
                      />{" "}
                      E-mail
                    </Label>
                  </FormGroup>
                  <FormGroup check className="mb-2">
                    <Label check>
                      <Input
                        checked={optionPix === "Telefone"}
                        type="radio"
                        name="telefone"
                        value="Telefone"
                        onChange={e => setOptionPix(e.target.value)}
                      />{" "}
                      Telefone
                    </Label>
                  </FormGroup>
                  <Input
                    type="text"
                    value={valuePix}
                    onChange={({ target: { value } }) => setValuePix(value)}
                  />
                  {valuePix === "" && (
                    <div style={{ color: "red", fontSize: "0.75rem" }}>Não pode ser vazio</div>
                  )}
                </FormGroup>
              </Form>

              <ButtonsContainer className="mt-4">
                <Button color="secondary" style={{ width: "48%" }} onClick={() => setStep(2)}>
                  VOLTAR
                </Button>
                <Button color="primary" style={{ width: "48%" }} disabled={valuePix === ""} onClick={sendPixRequest} >
                  CONTINUAR
                </Button>
              </ButtonsContainer>
            </div>
          ) : step === "CREDIT_CARD_SUCCESS" ? (
            <div className="content">
              <h5>PEDIDO DE REEMBOLSO CONFIRMADO!</h5>
              <>
                <p>
                  Conforme solicitado, seu cancelamento foi efetuado e o pedido de estorno do valor
                  pago de {billAmount || ''} foi enviado para seu cartão.
                </p>
                <p>
                  <b>Prazo:</b> O prazo estimado para o estorno constar em sua fatura varia de acordo
                  com a política da entidade emissora do seu cartão. Ele costuma ocorrer de 2 a 30
                  dias úteis.{" "}
                </p>
                <p>
                  <b>Importante:</b> Mesmo que tenha sido um pagamento parcelado no cartão de crédito,
                  o estorno costuma ser feito pelos bancos no <b>valor total da compra ({billAmount || ''}).</b>{" "}
                  Isso significa que seu banco pode manter as parcelas e devolver o valor total delas
                  somado, todo de uma vez.
                </p>
                <p>Essas informações também foram enviadas para seu E-mail.</p>
              </>
            </div>
          ) : step === "PIX_SUCCESS" ? (
            <div>
              <h5>PEDIDO DE REEMBOLSO CONFIRMADO!</h5>
              <>
                <p>
                  Conforme solicitado, seu cancelamento foi efetuado e o pedido de estorno dos valores
                  foi encaminhado para nossa equipe.
                </p>
                <p>
                  <b>Prazo:</b> O prazo para depósito na chave Pix informada é de até 7 (sete) dias
                  úteis.
                </p>
                <p>Enviamos por e-mail o protocolo da sua solicitação.</p>
              </>
            </div>
          ) : step === "CREDIT_CARD_FAILURE" ? (
            <div>
              <h5>FALHA!</h5>
              <br></br>
              <p>
                Nosso sistema não conseguiu se comunicar com o emissor do seu cartão para envio
                automático do pedido de estorno.
              </p>
              <p>
                Nossa equipe já foi comunicada do fato e lhe retornará no e-mail cadastrado conosco em
                até <b>1(um) dia útil.</b>
              </p>
            </div>
          ) : step === "PIX_FAILURE" ? (
            <div>
              <h5>FALHA!</h5>
              <br></br>
              <p>
                Nossa equipe já foi comunicada do fato e lhe retornará no e-mail cadastrado conosco em
                até <b>1(um) dia útil.</b>
              </p>
            </div>
          ) : step === "CREDIT_CARD_WAITING" ? (
            <div>
              <h5>PEDIDO DE REEMBOLSO EM PROCESSAMENTO!</h5>
              <br></br>
              <p>
                Seu pedido de reembolso está em processamento junto à sua instituição emissora do cartão. Enviamos um protocolo para seu e-mail e assim que for processado, lhe avisaremos por lá.
              </p>
            </div>
          ) : step === 'PIX_WAITING' ? (
            <div>
              <h5>PEDIDO DE REEMBOLSO EM PROCESSAMENTO!</h5>
              <br></br>
              <p>
                Seu pedido de reembolso está em processamento. Enviamos um protocolo para seu e-mail e assim que for processado, lhe avisaremos por lá.
              </p>
            </div>
          )
            : (
              <></>
            )}

          {loading && <Loader />}
        </div>

        <ButtonsContainer>
          <Button block color="danger" onClick={resetModal}>
            SAIR
          </Button>
        </ButtonsContainer>
      </ModalBody>
    </Modal>
  )
}

export default function RefundModal({ show, toggle, paymentMethod, subscriptionId, billAmount }) {
  const [showModalRefundForm, setShowModalRefundForm] = useState(false)
  const toggleModalRefundForm = () => setShowModalRefundForm(!showModalRefundForm)

  const [showPauseModal, setShowPauseModal] = useState(false)
  const togglePauseModal = () => setShowPauseModal(!showPauseModal)

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      style={{ display: showModalRefundForm || showPauseModal ? "none" : "block" }}
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader>Você deseja mesmo cancelar sua adesão e ter seu dinheiro de volta?</ModalHeader>
      <ModalBody>
        <p>
          Se a pausa no uso dos nossos serviços for temporária, você pode solicitar apenas uma suspensão do
          seu tempo de acesso, para assim não perder o valor que pagou por sua adesão, em caso de reajuste
          futuro.
        </p>
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto ",
          }}
        >
          <Button
            color="primary"
            style={{ marginTop: 20, marginBottom: 10, height: 45 }}
            onClick={toggleModalRefundForm}
          >
            QUERO O REEMBOLSO
          </Button>
          <Button color="primary" style={{ marginBottom: 10, height: 45 }} onClick={togglePauseModal}>
            QUERO A PAUSA TEMPORÁRIA
          </Button>
          <Button color="danger" style={{ marginBottom: 20, height: 45 }} onClick={toggle}>
            SAIR
          </Button>
        </div>
      </ModalFooter>

      <RefundModalForm
        show={showModalRefundForm}
        toggle={toggleModalRefundForm}
        paymentMethod={paymentMethod}
        subscriptionId={subscriptionId}
        billAmount={billAmount}
      />
      <PauseModal show={showPauseModal} toggle={togglePauseModal} />
    </Modal>
  )
}
