import styled from 'styled-components';

export const Container = styled.div`
    .subscription {
        h5 {
            margin-top: 25px;
        }
        
        .table-title {
            margin-top: 70px;
        }

        .table-responsive {
            margin-bottom: 70px;
        }
    }

    @media all and (min-width: 992px) and (max-width: 1199px) {
        .btn-lg-block {
            width: 100% !important;
            display: block !important;
        }
    }

    @media all and (min-width: 768px) and (max-width: 991px) {
        .btn-md-block {
            width: 100% !important;
            display: block !important;
        }
    }

    @media all and (min-width: 576px) and (max-width: 767px) {
        .btn-sm-block {
            width: 100% !important;
            display: block !important;
        }
    }

    @media all and (max-width: 575px) {
        .btn-xs-block {
            width: 100% !important;
            display: block !important;
        }
    }
`;