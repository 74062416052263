import { takeLatest, call, put, all } from 'redux-saga/effects';

import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import {
  signInSuccess,
  signFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure
} from './actions';

import { loadUserActivitiesRequest } from '../userActivity/actions'
import { loadUserTrainingPlanRequest } from '../userTrainingPlan/actions'


export function* signIn({ payload }) {

  try {
    const { email, password, redirectPath } = payload;

    const response = yield call(api.post, 'sessions', {
      email, password
    });

    const { auth, user } = response.data;

    localStorage.setItem('cp-showAppPopup', true)

    api.defaults.headers.Authorization = `Bearer ${auth.token}`;

    yield put(signInSuccess(auth.token, user));
    yield put(loadUserActivitiesRequest(user.id))
    yield put(loadUserTrainingPlanRequest(user.id))

    if (redirectPath) {
      history.push(redirectPath)
    } else {
      history.push('/dashboard')
    }
  } catch (error) {
    if (error.response.data.error.message === 'assinatura_inativa') {
      toast.warn('Sua assinatura não está ativa. Em caso de dúvidas, escreva para assinatura@corridaperfeita.com')
    } else {
      toast.error('Falha na autenticação, verifique seus dados.')
    }

    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { username, email, password } = payload;

    yield call(api.post, 'users', {
      username, email, password, password_confirmation: password
    });

    history.push('/')
  } catch (error) {
    toast.error('Falha no cadastro, verifique seus dados.')
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/')
}

export function* forgotPassword({ payload }) {
  try {
    const { email, redirect_url } = payload;

    const response = yield call(api.post, 'passwords', {
      email,
      redirect_url
    });

    if (response.data.error) {
      toast.error(response.data.error.msg)
      yield put(forgotPasswordFailure())
      return
    }

    toast.success(response.data.message.msg)
    yield put(forgotPasswordSuccess())
  } catch (error) {
    toast.error('Ocorreu um erro, contate o suporte.')
    yield put(forgotPasswordFailure())
  }
}

export function* resetPassword({ payload }) {
  try {
    const { token, password, password_confirmation } = payload;

    const response = yield call(api.put, 'passwords', {
      token,
      password,
      password_confirmation
    });

    if (response.data.error) {
      toast.error(response.data.error.msg)
      yield put(resetPasswordFailure())
      return
    }

    toast.success(`${response.data.message.msg} Faça o login agora`)

    yield put(resetPasswordSuccess())

    history.push('/sucesso-recuperar-senha')
  } catch (error) {
    toast.error('Ocorreu um erro, contate o suporte.')
    yield put(resetPasswordFailure())
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/FORGOT_PASSWORD_REQUEST', forgotPassword),
  takeLatest('@auth/RESET_PASSWORD_REQUEST', resetPassword)
]);
