import React from 'react';

import { Modal, ModalBody } from 'reactstrap'

function ModalInfo({ toggle, modal }) {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalBody>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Como funciona</p>

          <p style={{ textAlign: 'center' }}>
            As nossas aulas funcionais, coletivas e ao vivo, são uma oportunidade de você
            complementar seus treinos conosco de uma maneira diferente, com mais motivação e
            participando de um momento único com os membros do Clube e com o professor.
          </p>

          <p style={{ textAlign: 'center' }}>
            Você pode fazer o treino ao vivo, ou depois, assistindo à gravação, que fica
            disponível aos membros do Clube Corrida Perfeita por pelo menos 30 dias depois
            da realização.
          </p>

          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Como acessar a aula ao vivo</p>

          <p style={{ textAlign: 'center' }}>
            Na sala virtual do sistema Zoom, onde você pode ter o bônus da execução de seu treino
            avaliada ao vivo pelo professor, desde que ligue sua câmera. O sistema Zoom pode ser
            acessado tanto por celular quanto por computador.
          </p>

          <p style={{ textAlign: 'center' }}>
            O acesso a sala com professor irá pedir a instalação do aplicativo do Zoom.
            Não há senha para entrar na sala. Se essa senha for pedida, feche o Zoom e clique
            novamente no link.
          </p>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <button style={{ paddingLeft: 50, paddingRight: 50 }} onClick={toggle} className="btn btn-outline-secondary">
              OK
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalInfo;
