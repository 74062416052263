import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Card, CardBody } from 'reactstrap';

import { setCourseFlow } from '~/store/modules/auth/actions';
import Loader from '~/components/Loader/Loader';

import Highcharts from 'highcharts/highstock'

import Chart from '../../components/charts/Chart'

import api from '~/services/api';

import CourseItem from './CourseItem/CourseItem'

class CourseScreen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            coursesList: null,
            userCourses: null
        }
    }

    async componentDidMount() {
        const coursesToShow = []

        try {
            await Promise.all([
                api.get(`/courses`), api.get(`/courses?user_id=${this.props.profile.id}`)
                 ]).then(async ([exploreCourses, userCourses]) => {
                   if (userCourses.status === 200) {
      
                    const { data } = userCourses
            
                    exploreCourses.data.forEach( crse => {
            
                      const hasMy = data.find( ({ cloned_id }) => { return cloned_id === crse.id } );
            
                      if (hasMy) {
                        coursesToShow.push(hasMy);
                      } else {
                        coursesToShow.push(crse);
                      }
                    })
      
                    this.setState({...this.state, loading: false, userCourses: data, coursesList: coursesToShow })
                  } else {
                    this.setState({...this.state, loading: false, })
                  }
                   
                 });
          } catch (error) {
            this.setState({...this.state, loading: false, })
          }
    }

    _handleInitCourse = () => {
        //
    }

    render() {
        return (
          <React.Fragment>
                  {false && <Loader />}
      
                  <Row>
                      <Col>
                          <div className="page-title-box">
                              <Row>
                                  <Col lg={7}>
                                      <h4 className="page-title">Orientações em vídeo aulas</h4>
                                  </Col>
                                  <Col lg={5} className="mt-lg-3 mt-md-0">
                                      
                                  </Col>
                              </Row>
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={12}>
                          <Card>
                                <CardBody>
                                    <div style={{width: '100%', height: 'auto', paddingBottom: 20 }}>
                                        <p style={{ fontWeight: '400', color: 'gray'}}>Confira a seguir todas as nossas séries de vídeos aulas que vão orientar sua jornada de sucesso na corrida.</p>
                                    </div>
                                    {this.state.loading && <Loader size="large" color={'gray'} style={{marginTop: 20}} />}
                                    {!this.state.loading && (!this.state.coursesList || this.state.coursesList.length <= 0) &&
                                        (<div style={{ backgroundColor: '#f9f9f9', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)', paddingBottom: 10, marginTop: 10 }}>
                                        <p style={{ margin: 10, alignSelf: 'center', fontWeight: '400', fontSize: 15, color: 'blue', }} >
                                            Nenhum curso encontrado.
                                        </p>
                                        <p style={{ marginHorizontal: 10, marginTop: 5, alignSelf: 'center', fontSize: 12, color: 'blue', }} >
                                            Aguarde, em breve novos cursos disponíveis aqui!
                                        </p>
                                        </div>)
                                    }

                                    {!this.state.loading && this.state.coursesList && this.state.coursesList.length > 0 &&
                                    this.state.coursesList.map(course => {
                                        return (
                                            <CourseItem key={`ckey_${course.id}`} course={course} initCourse={this._handleInitCourse} profile={this.props.profile} />
                                        )
                                    })}
                                </CardBody>
                          </Card>
                      </Col>
                  </Row>
          </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.user.profile
    }
};
export default connect(mapStateToProps, { setCourseFlow })(CourseScreen);