import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, ListGroup, ListGroupItem, Media, Form, FormGroup, Input, Label, Button } from 'reactstrap';
import Loader from '~/components/Loader/Loader'
import moment from 'moment';
import api from '~/services/api';
import { formatDistance, formatDuration, formatActivityTypeIcon, formatZone } from '~/services/util'
import iconRide from '~/assets/img/training_types/icone-bike.png';
import iconRun from '~/assets/img/training_types/icone-corrida.png';
import iconSwin from '~/assets/img/training_types/icone-natacao.png';
import iconWeight from '~/assets/img/training_types/icone-exercicio.png';
import iconDrill from '~/assets/img/training_types/icone-educativo.png';
import iconOther from '~/assets/img/training_types/icone-outros.png';
import { FaCheckCircle, FaTrash } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { saveUserActivityRequest, updateUserActivityRequest } from '~/store/modules/userActivity/actions'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'

const ACTIVITY_TYPE_OPTIONS = {
  DURATION: { id: 'DURATION', name: "Duração", labelType: 'Min', fieldName: 'duration' },
  DISTANCE: { id: 'DISTANCE', name: "Distância", labelType: 'Km', fieldName: 'distance' },
}

const TimeInput = (props) => (
  <InputMask mask="99:99:99" defaultValue={props.defaultValue} onChange={props.onChange} placeholder="--:--:--">
    {(inputProps) => <Input {...inputProps} type="text" disableunderline="true" />}
  </InputMask>
);

const PaceInput = (props) => (
  <InputMask mask="99:99" defaultValue={props.defaultValue} onChange={props.onChange} placeholder="--:--">
    {(inputProps) => <Input {...inputProps} type="text" disableunderline="true" />}
  </InputMask>
);

const chooseTotalTime = (item) => {
  let { durationHour, durationMin, durationSec } = item

  durationHour = parseFloat(durationHour)
  durationMin = parseFloat(durationMin)
  durationSec = parseFloat(durationSec)

  const duration = ((durationHour * 60) + durationMin + (durationSec ? durationSec / 60 : 0)).toFixed(2);

  return duration
}

const choosePace = (item) => {
  let { pace1, pace2 } = item

  pace1 = parseFloat(pace1)
  pace2 = parseFloat(pace2)

  const pace = ((pace1) + (pace2 / 60)).toFixed(2);

  return pace
}

function toNumberString(num) {
  if (Number.isInteger(num)) {
    return num + ".0"
  } else {
    return num.toString();
  }
}

function buildTypes() {
  const types = {
    label: 'Tipo',
    values: [
      { id: 'Run', name: 'Corrida' },
      { id: 'Ride', name: 'Bicicleta' },
      { id: 'Swim', name: 'Natação' },
      { id: 'WeightTraining', name: 'Força' },
      { id: 'Drills', name: 'Educativo' },
      { id: 'Workout', name: 'Outros Treinos' },
      { id: 'Others', name: 'Outros (avulso)' },
      { id: 'Cardio', name: 'Cardio' },
      { id: 'Canoeing', name: 'Canoagem' },
      { id: 'AlpineSki', name: 'AlpineSki' },
      { id: 'BackcountrySki', name: 'BackcountrySki' },
      { id: 'Crossfit', name: 'Crossfit' },
      { id: 'EBikeRide', name: 'Pedalada' },
      { id: 'Elliptical', name: 'Eliptico' },
      { id: 'Golf', name: 'Golf' },
      { id: 'Handcycle', name: 'Handcycle' },
      { id: 'Hike', name: 'Hike' },
      { id: 'IceSkate', name: 'IceSkate' },
      { id: 'InlineSkate', name: 'InlineSkate' },
      { id: 'Kayaking', name: 'Caiaque' },
      { id: 'Kitesurf', name: 'Kitesurf' },
      { id: 'NordicSki', name: 'NordicSki' },
      { id: 'RockClimbing', name: 'Escalada' },
      { id: 'RollerSki', name: 'RollerSki' },
      { id: 'Rowing', name: 'Rowing' },
      { id: 'Sail', name: 'Sail' },
      { id: 'Skateboard', name: 'Skateboard' },
      { id: 'Snowboard', name: 'Snowboard' },
      { id: 'Snowshoe', name: 'Snowshoe' },
      { id: 'Soccer', name: 'Futebol' },
      { id: 'StairStepper', name: 'StairStepper' },
      { id: 'StandUpPaddling', name: 'SUP' },
      { id: 'Surfing', name: 'Surfing' },
      { id: 'Velomobile', name: 'Velomobile' },
      { id: 'VirtualRide', name: 'Pedalada' },
      { id: 'VirtualRun', name: 'Corrida' },
      { id: 'Walk', name: 'Caminhada' },
      { id: 'Wheelchair', name: 'Wheelchair' },
      { id: 'Windsurf', name: 'Windsurf' },
      { id: 'Yoga', name: 'Yoga' }
    ]
  }

  return types;
}

function orderTrainingGroupsAndTrainingActivities(training) {
  let ordered = training

  if (ordered.trainingGroups && ordered.trainingGroups.length > 0) {
    ordered.trainingGroups = ordered.trainingGroups.sort((a, b) => {
      if (a.group_order && b.group_order && a.group_order < b.group_order) return -1

      return 0
    })

    for (let index = 0; index < ordered.trainingGroups.length; index++) {
      if (ordered.trainingGroups[index].trainingActivities && ordered.trainingGroups[index].trainingActivities.length > 0) {
        ordered.trainingGroups[index].trainingActivities = ordered.trainingGroups[index].trainingActivities.sort((a, b) => {
          if (a.group_order && b.group_order && a.group_order < b.group_order) return -1

          return 0
        })
      }
    }
  }

  return ordered
}

export default function TrainingShowModal(props) {
  const dispatch = useDispatch()

  const userId = useSelector(state => state.user.profile.id)

  const profile = useSelector(state => state.user.profile);

  const defaultTypes = buildTypes();

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(props.open);
  const [training, setTraining] = useState(null);
  const [userActivity, setUserActivity] = useState(null);
  const [currentFolder, setCurrentFolder] = useState({ name: 'Selecionar', id: -1 });
  const [currentActivityType, setCurrentActivityType] = useState({ name: 'Selecionar', id: -1 });

  const [trainingType, setTrainingType] = useState('');

  const [type, setType] = useState('')
  const [title, setTitle] = useState('')
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [hour, setHour] = useState(moment(new Date()).format('HH:mm'))
  const [duration, setDuration] = useState('')
  const [distance, setDistance] = useState(0)
  const [pace, setPace] = useState('')
  const [averageSpeed, setAverageSpeed] = useState(0)
  const [maxSpeed, setMaxSpeed] = useState(0)
  const [averageHeartrate, setAverageHeartrate] = useState(0)
  const [maxHeartrate, setMaxHeartrate] = useState(0)
  const [altimetry, setAltimetry] = useState(0)
  const [averageCadence, setAverageCadence] = useState(0)
  const [calorie, setCalorie] = useState(0)
  const [comments, setComments] = useState('')
  const [modalDeleteActivity, setModalDeleteActivity] = useState(false)

  const toggleModalDeleteActivity = () => setModalDeleteActivity(prev => !prev)

  const closeBtn = <button className="close" onClick={toggleModalDeleteActivity}>&times;</button>;

  async function loadUsrAct(trainId) {
    const response = await api.get(`user_activities?user_id=${userId}&training_id=${trainId}`)

    if (response.data.length > 0) {
      const tAct = response.data[0]

      const formatTimeValue = (value, type) => new Promise(resolve => {
        const val = formatDuration(value, type)
        resolve(val)
      })

      let pac = await formatTimeValue(tAct.pace, 'pace')
      let dur = await formatTimeValue(tAct.duration, 'time')

      setUserActivity({
        ...tAct,
        pace: pac,
        duration: dur
      });
    }
  }

  useEffect(() => {
    let trainingID = props.training.id
    let trainingType = null;

    if (props.training && props.training.obj_type === 'USR_ACT') {
      setTrainingType('USR_ACT')
      trainingType = 'USR_ACT'

      setUserActivity({
        ...props.training,
        pace: formatDuration(props.training.pace, 'pace'),
        duration: formatDuration(props.training.duration, 'time')
      });

      if (props.training.training_id) {
        trainingID = props.training.training_id
      } else {
        setLoading(false);
      }
    }

    if (trainingID && props.training.training_id && trainingType && trainingType === 'USR_ACT') {
      Promise.all([api.get(`trainings/${trainingID}`)])
        .then(([trainingsResp]) => {
          const train = orderTrainingGroupsAndTrainingActivities(trainingsResp.data)

          setTraining(train);

          if (train) {
            if (train.folder) {
              setCurrentFolder(train.folder);
            }

            if (train.activity_type === ACTIVITY_TYPE_OPTIONS.DURATION.id) {
              setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DURATION);
            } else {
              setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DISTANCE);
            }

            formatActivityType(train.type)
          }

          setLoading(false);
        });
    }

    if (trainingID && !trainingType) {
      Promise.all([api.get(`trainings/${trainingID}`)])
        .then(([trainingsResp]) => {
          const train = orderTrainingGroupsAndTrainingActivities(trainingsResp.data)
          setTraining(train);

          if (train) {
            if (train.executed_status === 'EXECUTED_HIGH') {
              loadUsrAct(train.id)
            }

            if (train.folder) {
              setCurrentFolder(train.folder);
            }

            if (train.activity_type === ACTIVITY_TYPE_OPTIONS.DURATION.id) {
              setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DURATION);
            } else {
              setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DISTANCE);
            }

            formatActivityType(train.type, trainingType)
          }

          setLoading(false);
        });
    }

  }, [props.training, userId])


  const formatActivityType = (actType, trainingType = null) => {

    if (trainingType) {
      setTrainingType(trainingType)
      return;
    }

    if (actType === 'WeightTraining' || actType === 'Drills' || actType === 'Cardio' || actType === 'Others') {
      setTrainingType('EXPLORE')
    } else if (actType === 'Run') {
      setTrainingType('RUN')
    }
  }

  function handleActivityUpdate() {
    let pace1 = userActivity.pace.split(':')[0]
    let pace2 = userActivity.pace.split(':')[1]

    let durationHour = userActivity.duration.split(':')[0]
    let durationMin = userActivity.duration.split(':')[1]
    let durationSec = userActivity.duration.split(':')[2]

    const data = {
      id: userActivity.id,
      title: userActivity.title,
      pace: parseFloat(choosePace({ pace1, pace2 })),
      duration: parseFloat(chooseTotalTime({ durationHour, durationMin, durationSec })),
      altimetry: !userActivity.altimetry ? 0 : parseFloat(toNumberString(userActivity.altimetry).split('.')[0]),
      average_cadence: !userActivity.average_cadence ? 0 : parseFloat(toNumberString(userActivity.average_cadence).split('.')[0]),
      average_heartrate: !userActivity.average_heartrate ? 0 : parseFloat(toNumberString(userActivity.average_heartrate).split('.')[0]),
      average_speed: !userActivity.average_speed ? 0 : parseFloat(userActivity.average_speed),
      calorie: !userActivity.calorie ? 0 : parseFloat(toNumberString(userActivity.calorie).split('.')[0]),
      distance: !userActivity.distance ? 0 : parseFloat(userActivity.distance),
      max_heartrate: !userActivity.max_heartrate ? 0 : parseFloat(toNumberString(userActivity.max_heartrate).split('.')[0]),
      heart_rate: !userActivity.heart_rate ? 0 : parseFloat(toNumberString(userActivity.average_heartrate).split('.')[0]),
      comments: userActivity.comments
    }

    dispatch(updateUserActivityRequest(data, true))
    props.toggle()
  }

  function handleSaveNewActivity() {
    let durationToSave = parseFloat(chooseTotalTime({
      durationHour: duration.split(':')[0],
      durationMin: duration.split(':')[1],
      durationSec: duration.split(':')[2]
    }))

    let paceToSave = parseFloat(choosePace({
      pace1: pace.split(':')[0],
      pace2: pace.split(':')[1]
    }))

    if (!date || !hour) {
      toast.error('Preencha a data e hora que realizou a atividade!')
      return
    }

    let planedTrainingType = type

    if (!userActivity && training.type) {
      planedTrainingType = training.type
    }

    if (!planedTrainingType || planedTrainingType.length === 0) {
      toast.error('Selecione o tipo da atividade!')
      return
    }

    const data = {
      user_id: userId,
      type: planedTrainingType,
      title,
      pace: paceToSave,
      duration: durationToSave,
      start_date: `${date} ${hour}`,
      altimetry: parseFloat(toNumberString(altimetry).split('.')[0]),
      average_cadence: parseFloat(toNumberString(averageCadence).split('.')[0]),
      average_heartrate: parseFloat(toNumberString(averageHeartrate).split('.')[0]),
      average_speed: parseFloat(averageSpeed),
      max_speed: maxSpeed,
      calorie: parseFloat(toNumberString(calorie).split('.')[0]),
      distance: parseFloat(distance),
      max_heartrate: parseFloat(toNumberString(maxHeartrate).split('.')[0]),
      heart_rate: parseFloat(toNumberString(averageHeartrate).split('.')[0]),
      training_id: training.id,
      comments
    }

    dispatch(saveUserActivityRequest(data, true))
    props.toggle()
  }

  const formatTrainingActSpeed = (intensity) => {
    switch (intensity) {
      case 1:
        return 'Lento'

      case 2:
        return 'Moderado'

      case 3:
        return 'Acelerado'
      default:
        return '-'
    }
  }

  const zones = {
    'ZONA-1': 1,
    'ZONA-2': 2,
    'ZONA-3': 3,
    'ZONA-4': 4,
    'ZONA-5a': 5,
    'ZONA-5b': 6,
    'ZONA-5c': 7
  }

  const formatUserZone = (zone) => {
    if (profile && profile.userZone && zone) {
      const zoneId = zones[zone];

      let currZone = profile.userZone.zones.filter(item => item.order === zoneId)

      if (currZone && currZone[0]) {
        currZone = currZone[0]

        const isBpm = currZone.start_pace || currZone.end_pace
        if (currZone.order === 1) {
          return !isBpm ? `(Abaixo de ${currZone.end_bpm} bpm)` : `(Acima de ${currZone.end_pace} min/km)`
        }

        if (currZone.order === 7) {
          return !isBpm ? `(Acima de ${currZone.start_bpm} bpm)` : `(Abaixo de ${currZone.start_pace} min/km)`
        }

        return !isBpm ? `(${currZone.start_bpm} a ${currZone.end_bpm} bpm)` : `(${currZone.start_pace} a ${currZone.end_pace} min/km)`
      }
    }

    return ''
  }


  function drawActivityInfos(act, groupItem, actIdx) {
    const zoneFormated = formatZone(act.zone);

    let description = ''

    if (groupItem.repetition && groupItem.repetition > 1) {

      if (currentActivityType.id === 'DISTANCE') {
        description = `${act.distance} km @ ${zoneFormated}`
      } if (currentActivityType.id === 'DURATION') {
        description = `${act.duration} min @ ${zoneFormated}`
      }
    } else {
      if (currentActivityType.id === 'DISTANCE') {
        description = `${act.distance} km @ ${zoneFormated}`
      } if (currentActivityType.id === 'DURATION') {
        description = `${act.duration} min @ ${zoneFormated}`
      }
    }

    return (
      <div key={actIdx}>
        {groupItem.repetition && groupItem.repetition > 1 ?
          <div>
            <div style={{ marginLeft: 20, marginTop: 5, alignSelf: 'flex-start', flexDirection: 'row', }} >
              <span style={{ fontWeight: '500' }}>{actIdx + 1}. </span>
              <span style={{ fontWeight: '500' }}>{act.name}</span>
            </div>
            <div>
              <span style={{ color: 'black', marginLeft: 40 }}>{description}</span>
              <div>
                <span style={{ color: 'gray', fontSize: 12, marginLeft: 40 }}>{`${formatUserZone(act.zone)}`}</span>
              </div>
            </div>
          </div>
          :
          <div>
            <div style={{ marginLeft: 20, marginTop: 5, alignSelf: 'flex-start', flexDirection: 'row' }} >
              <span style={{ fontWeight: '500' }}>{actIdx + 1}. </span>
              <span style={{ color: 'black', marginLeft: 5 }}>{description}</span>
              <div>
                <span style={{ color: 'gray', fontSize: 12, marginLeft: 5 }}>{`${formatUserZone(act.zone)}`}</span>
              </div>
            </div>
          </div>}
      </div>
    )
  }

  const handleDeleteUserActivity = () => {
    api.put(`user_activities/${userActivity.id}`, { visible: false })
      .then(resp => {
        window.location.reload()
      })
  }

  return (
    <>
      <div>
        <Modal isOpen={modalDeleteActivity} toggle={toggleModalDeleteActivity}>
          <ModalHeader toggle={toggleModalDeleteActivity} close={closeBtn}>Confirmação</ModalHeader>
          <ModalBody>
            Tem certeza que deseja remover essa atividade?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleModalDeleteActivity}>Cancelar</Button>&nbsp;
            <Button color="danger" onClick={handleDeleteUserActivity}>Sim</Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={show} toggle={props.toggle} size={!userActivity || (userActivity && userActivity.training_id > 0) ? 'xl' : ''}>
          <ModalHeader toggle={props.toggle} close={<button className="close" onClick={props.toggle}>&times;</button>}>
            <span style={{ zIndex: 5, fontWeight: '600', fontSize: 20, margin: 10, flexDirection: 'row' }}>
              <img src={formatActivityTypeIcon(training?.type || userActivity?.type)} alt="icon" />&nbsp;&nbsp;

          {training?.name}&nbsp;&nbsp;

          {
                training?.executed_status === 'EXECUTED_HIGH' ?
                  <FaCheckCircle color="green" size={20} />
                  : ''
              }

              {
                training?.executed_status === 'EXECUTED_LOW' || training?.executed_status === 'EXECUTED_MEDIUM' ?
                  <FaCheckCircle color="#ebb734" size={20} />
                  : ''
              }
            </span>
          </ModalHeader>

          {loading && <Loader />}

          {!loading && <>
            <ModalBody>
              <Row>
                {(!userActivity || (userActivity && userActivity.training_id > 0)) &&
                  <Col lg={6} style={{ backgroundColor: 'white', padding: 5 }}>

                    <h4>Descrição</h4>
                    <p>{training.description}</p>
                    <br />
                    <h4>Etapas do treino</h4>

                    <div style={{ overflowY: 'scroll', paddingLeft: '10px', paddingRight: '5px', width: '100%', maxHeight: '500px', float: 'left', position: 'relative' }}>
                      {trainingType === 'RUN' && training && training.trainingGroups &&
                        <Col lg={12}>
                          {training && training.trainingGroups.map((item, tgIDX) => {
                            return (
                              <div style={{ alignItems: 'center', marginTop: 10 }} key={item.id}>
                                <div style={{ alignItems: 'flex-start', width: '80%' }}>
                                  <div>
                                    <div style={{ flexDirection: 'row' }}>
                                      <span>{tgIDX + 1}. </span>
                                      <span style={{ fontWeight: '500', fontSize: 15 }}>
                                        {item.repetition > 1 ? `${item.name} | Repetir ${item.repetition} vezes` : `${item.name}`}
                                      </span>
                                    </div>
                                    {item.trainingActivities.map((act, aIdx) => {
                                      return drawActivityInfos(act, item, aIdx);
                                    })}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </Col>}

                      {(trainingType !== 'RUN') && training && training.trainingGroups && training.trainingGroups.map((tgroup, gidx) => {
                        return (
                          <Col lg={12}>
                            <Media list key={`key_${gidx}`}>
                              <Media tag="li">
                                <Media body>
                                  <Media heading>
                                    <p>{tgroup.name}</p>
                                  </Media>


                                  <ListGroup>
                                    {tgroup.trainingActivities.map((tact, aidx) => {

                                      const otherDesc = training && training.id && training.type === 'Run' ? tact.zone : formatTrainingActSpeed(tact.execution_speed)

                                      return (
                                        <ListGroupItem key={`id_${aidx}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                            {tact.thumb_link ?
                                              <img src={tact.thumb_link} alt="" height="40" />
                                              :
                                              <div style={{ height: 40, width: 40, backgroundColor: 'gray' }} />}
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                              <span style={{ marginLeft: 10 }}>{tact.name}</span>
                                              <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                {tact.activity_type === 'DISTANCE' && <span style={{ marginLeft: 10 }}>{formatDistance(tact.distance)}</span>}
                                                {tact.activity_type === 'DURATION' && <span style={{ marginLeft: 10 }}>{formatDuration(tact.duration)}</span>}
                                                {tact.activity_type === 'REPETITION' && <span style={{ marginLeft: 10 }}>{tact.repetition}x</span>}
                                          &nbsp;&nbsp;|&nbsp;&nbsp;
                                          <span>{otherDesc}</span>
                                              </div>
                                            </div>
                                          </div>
                                          <img src={tact.media_link} alt="" height="40" />
                                        </ListGroupItem>
                                      )
                                    })}
                                  </ListGroup>
                                </Media>
                              </Media>
                            </Media>
                          </Col>
                        )
                      })}
                    </div>
                  </Col>}

                <Col lg={(!userActivity || (training && (training.executed_status === 'EXECUTED_HIGH' || training.executed_status === 'EXECUTED_LOW' || training.executed_status === 'EXECUTED_MEDIUM'))) ? 6 : 12}>
                  <div style={{ float: 'right' }}>
                    <h4>{userActivity ? 'Editar Atividade' : 'Registrar Atividade'}</h4>

                    <Form>
                      <FormGroup>
                        <Label for="type">Tipo</Label>
                        <Input
                          disabled={userActivity || training ? true : false}
                          defaultValue={userActivity ? userActivity.type : training.type}
                          onChange={
                            userActivity ? e => setUserActivity({ ...userActivity, type: e.target.value })
                              : e => setType(e.target.value)
                          }
                          type="select"
                          name="type"
                          id="type"
                          placeholder={defaultTypes.label}>
                          <option value="0">Selecione...</option>
                          {defaultTypes.values.map(type => (
                            <option key={type.id} value={type.id}>{type.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="title">Título</Label>
                        <Input
                          defaultValue={userActivity ? userActivity.title : ''}
                          onChange={
                            userActivity ? e => setUserActivity({ ...userActivity, title: e.target.value })
                              : e => setTitle(e.target.value)
                          }
                          type="text" name="title" id="title" placeholder="Dê um título para a atividade" />
                      </FormGroup>
                      {userActivity ? '' :
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="date">Data</Label>
                              <Input defaultValue={date} onChange={e => setDate(e.target.value)} type="date" name="date" id="date" />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="hour">Hora</Label>
                              <Input defaultValue={hour} onChange={e => setHour(e.target.value)} type="time" name="hour" id="hour" />
                            </FormGroup>
                          </Col>
                        </Row>
                      }
                      <Row form>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="duration">Tempo</Label>
                            {userActivity && userActivity.duration && <TimeInput
                              defaultValue={userActivity ? userActivity.duration : ''}
                              onChange={
                                userActivity ? e => setUserActivity({ ...userActivity, duration: e.target.value })
                                  : e => setDuration(e.target.value)
                              }
                              name="duration"
                              id="duration" />}

                            {!userActivity && <TimeInput
                              defaultValue={''}
                              onChange={e => setDuration(e.target.value)}
                              name="duration"
                              id="duration" />}
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="distance">Distância</Label>
                            <Input
                              defaultValue={userActivity ? userActivity.distance : ''}
                              onChange={
                                userActivity ? e => setUserActivity({ ...userActivity, distance: e.target.value })
                                  : e => setDistance(e.target.value)
                              }
                              type="number"
                              name="distance"
                              id="distance"
                              placeholder="km" />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="pace">Ritmo</Label>
                            {userActivity && userActivity.pace && <PaceInput
                              defaultValue={userActivity.pace}
                              onChange={e => setUserActivity({ ...userActivity, pace: e.target.value })}
                              name="pace"
                              id="pace" />}

                            {!userActivity && <PaceInput
                              defaultValue={''}
                              onChange={e => setPace(e.target.value)}
                              name="pace"
                              id="pace" />}
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="average_speed">Vel. Média</Label>
                        <Input
                          defaultValue={userActivity ? userActivity.average_speed : ''}
                          onChange={
                            userActivity ? e => setUserActivity({ ...userActivity, average_speed: e.target.value })
                              : e => setAverageSpeed(e.target.value)
                          }
                          type="number"
                          name="average_speed"
                          id="average_speed"
                          placeholder="km/h" />
                      </FormGroup>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="average_heartrate">FC Média</Label>
                            <Input
                              defaultValue={userActivity ? userActivity.average_heartrate : ''}
                              onChange={
                                userActivity ? e => setUserActivity({ ...userActivity, average_heartrate: e.target.value })
                                  : e => setAverageHeartrate(e.target.value)
                              }
                              type="number" name="average_heartrate" id="average_heartrate" placeholder="bpm" />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="max_heartrate">FC Máxima</Label>
                            <Input
                              defaultValue={userActivity ? userActivity.max_heartrate : ''}
                              onChange={
                                userActivity ? e => setUserActivity({ ...userActivity, max_heartrate: e.target.value })
                                  : e => setMaxHeartrate(e.target.value)
                              }
                              type="number" name="max_heartrate" id="max_heartrate" placeholder="bpm" />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="altimetry">Ganho de Elevação</Label>
                        <Input
                          defaultValue={userActivity ? userActivity.altimetry : ''}
                          onChange={
                            userActivity ? e => setUserActivity({ ...userActivity, altimetry: e.target.value })
                              : e => setAltimetry(e.target.value)
                          }
                          type="number" name="altimetry" id="altimetry" placeholder="metros" />
                      </FormGroup>
                      <FormGroup>
                        <Label for="average_cadence">Cadência Média</Label>
                        <Input
                          defaultValue={userActivity ? userActivity.average_cadence : ''}
                          onChange={
                            userActivity ? e => setUserActivity({ ...userActivity, average_cadence: e.target.value })
                              : e => setAverageCadence(e.target.value)
                          }
                          type="number" name="average_cadence" id="average_cadence" placeholder="ppm" />
                      </FormGroup>
                      <FormGroup>
                        <Label for="calorie">Calorias</Label>
                        <Input
                          defaultValue={userActivity ? userActivity.calorie : ''}
                          onChange={
                            userActivity ? e => setUserActivity({ ...userActivity, calorie: e.target.value })
                              : e => setCalorie(e.target.value)
                          }
                          type="number" name="calorie" id="calorie" placeholder="kcal" />
                      </FormGroup>
                      <FormGroup>
                        <Label for="comments">Observações</Label>
                        <Input
                          defaultValue={userActivity ? userActivity.comments : ''}
                          onChange={
                            userActivity ? e => setUserActivity({ ...userActivity, comments: e.target.value })
                              : e => setComments(e.target.value)
                          }
                          type="textarea" name="comments" id="comments" />
                      </FormGroup>
                    </Form>
                  </div>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter>
              <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                  {userActivity &&
                    <button className="btn btn-bordered-danger" onClick={toggleModalDeleteActivity}>
                      <FaTrash />
                    </button>}
                </div>
                <div>
                  <button className="btn btn-bordered-primary" onClick={() => props.toggle()}>Cancelar</button>&nbsp;
                  <button className="btn btn-bordered-success" onClick={userActivity ? handleActivityUpdate : handleSaveNewActivity}>Salvar</button>
                </div>
              </div>
            </ModalFooter>
          </>}
        </Modal>
      </div>
    </>
  );
}
