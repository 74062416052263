import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap'
import DatePicker from 'react-datepicker'

export default function ReturnInitiation({
    selectedSchema,
    step,
    setValueForEnableNextButton,
    setDaysWeeklyWorkout,
    daysWeeklyWorkout,
    selectedWeek,
    handleSetSelectedWeek,
    minStartAt,
    startAt,
    handleStartAt,
    distanceHistory,
    setDistanceHistory,
    height,
    setHeight,
    weight,
    setWeight,
    setMHistoryNotes,
    setGeneralNotes,
    mHistoryNotes,
    generalNotes,
    maxStartAt
}) {
    return (
        <>
            {selectedSchema.type === 'RETURN_INITIATION' && step.order === 1 &&
                <div className="mt-5">
                    <h4>{step.title}:</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {step.data.length > 0 &&
                            step.data.map(opt => (
                                <div
                                    className="mt-2"
                                    key={opt.label}
                                    onClick={() => setValueForEnableNextButton(() => handleSetSelectedWeek(opt.value))}
                                    style={{ border: `${selectedWeek === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                                    <input
                                        type="radio"
                                        name={opt.label}
                                        value={opt.value}
                                        checked={selectedWeek === opt.value}
                                        onChange={e => handleSetSelectedWeek(parseInt(e.target.value))} />

                                    <div className="ml-2" style={{ display: 'flex', flexDirection: 'column' }}>
                                        <h5>{opt.label}</h5>
                                        <p>{opt.description}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            }

            {selectedSchema.type === 'RETURN_INITIATION' &&
                step.order === 2 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="mt-2" style={{ maxWidth: 400 }}>
                            <FormGroup>
                                <Label for="startAt">{step.selectText}</Label>

                                <DatePicker
                                    selected={startAt ? new Date(startAt) : null}
                                    onChange={date => setValueForEnableNextButton(() => handleStartAt(date))}
                                    minDate={new Date(minStartAt)}
                                    maxDate={new Date(maxStartAt)}
                                    placeholderText="Data de início"
                                    locale="pt-BR"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    withPortal
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <h5>{step.description}</h5>
                </div>
            }

            {selectedSchema.type === 'RETURN_INITIATION' && step.order === 3 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {step.data.length > 0 &&
                            step.data.map(opt => (
                                <div
                                    className="mt-2"
                                    key={opt.label}
                                    onClick={() => setValueForEnableNextButton(() => setDistanceHistory(opt.value))}
                                    style={{ border: `${distanceHistory === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                                    <input
                                        type="radio"
                                        name={opt.label}
                                        value={opt.value}
                                        checked={distanceHistory === opt.value}
                                        onChange={e => setDistanceHistory(e.target.value)} />

                                    <h5 className="ml-2">{opt.label}</h5>
                                </div>
                            ))}
                    </div>
                </div>
            }

            {selectedSchema.type === 'RETURN_INITIATION' && step.order === 4 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {step.data.length > 0 &&
                            step.data.map(opt => (
                                <div
                                    className="mt-2"
                                    key={opt.label}
                                    onClick={() => setValueForEnableNextButton(() => setDaysWeeklyWorkout(opt.value))}
                                    style={{ border: `${daysWeeklyWorkout === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                                    <input
                                        type="radio"
                                        name={opt.label}
                                        value={opt.value}
                                        checked={daysWeeklyWorkout === opt.value}
                                        onChange={e => setDaysWeeklyWorkout(e.target.value)} />

                                    <h5 className="ml-2">{opt.label}</h5>
                                </div>
                            ))}
                    </div>
                </div>
            }

            {selectedSchema.type === 'RETURN_INITIATION' && step.order === 5 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="mt-2" style={{ maxWidth: 400 }}>
                            <FormGroup>
                                <Label for="height">Altura(cm)</Label>
                                <Input
                                    value={height}
                                    onChange={e => setValueForEnableNextButton(() => setHeight(e.target.value))}
                                    type="number"
                                    name="height"
                                    id="height"
                                    placeholder="Altura" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="weight">Peso(kg)</Label>
                                <Input
                                    value={weight}
                                    onChange={e => setValueForEnableNextButton(() => setWeight(e.target.value))}
                                    type="number"
                                    name="weight"
                                    id="weight"
                                    placeholder="Peso" />
                            </FormGroup>
                        </div>
                    </div>
                </div>
            }

            {selectedSchema.type === 'RETURN_INITIATION' && step.order === 6 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="mt-2" style={{ maxWidth: 400 }}>
                            <FormGroup>
                                <Label for="mHistoryNotes">{step.description}</Label>
                                <Input
                                    onChange={e => setValueForEnableNextButton(() => setMHistoryNotes(e.target.value))}
                                    type="textarea"
                                    maxLength="300"
                                    name="mHistoryNotes"
                                    id="mHistoryNotes"
                                    placeholder="Opcional" />
                                {mHistoryNotes && mHistoryNotes.length === 300 && <span style={{ color: 'red' }}>Limite máximo de 300 caracteres atingido</span>}
                            </FormGroup>
                            <FormGroup>
                                <Label for="generalNotes">{step.description2}</Label>
                                <Input
                                    onChange={e => setValueForEnableNextButton(() => setGeneralNotes(e.target.value))}
                                    type="textarea"
                                    maxLength="300"
                                    name="generalNotes"
                                    id="generalNotes"
                                    placeholder="Opcional" />
                                {generalNotes && generalNotes.length === 300 && <span style={{ color: 'red' }}>Limite máximo de 300 caracteres atingido</span>}
                            </FormGroup>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
