import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { store } from '~/store';
import api from '~/services/api'
import { setLastSubscriptionCheckedDate } from '~/store/modules/auth/actions'
import { updateProfileSuccess } from '~/store/modules/user/actions'
import moment from 'moment'
import { isAdm } from '~/services/util'

const loading = () => <div></div>

const AuthLayout = Loadable({
  loader: () => import('../pages/_layouts/adm/index'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading
});

const NonAuthLayout = Loadable({
  loader: () => import('../pages/_layouts/nonAuth/index'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading
});

const CourseLayout = Loadable({
  loader: () => import('../pages/_layouts/course/index'),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading
});

export default function RouteWrapper({
  component: Component,
  isPrivete,
  path,
  externalLink,
  ...rest
}) {
  const currUrl = window.location.href;

  if (process.env.NODE_ENV === "production") window.location.href = `https://app${currUrl.split('https://app2').pop()}`

  const { profile } = store.getState().user;

  const { signed, lastSubscriptionCheckedDate } = store.getState().auth;

  if (
    signed &&
    profile &&
    !currUrl.includes('exchange-token') &&
    !currUrl.includes('solicitar-recuperar-senha') &&
    !currUrl.includes('recuperar-senha') &&
    !currUrl.includes('sucesso-recuperar-senha')) {
    if (lastSubscriptionCheckedDate) {
      if (moment().isAfter(lastSubscriptionCheckedDate)) {
        api.get(`users/${profile.id}`)
          .then(response => {
            store.dispatch(updateProfileSuccess(response.data))
            store.dispatch(setLastSubscriptionCheckedDate(moment().add(10, 'minutes')))
          })
      }
    } else {
      store.dispatch(setLastSubscriptionCheckedDate(moment().add(10, 'minutes')))
    }
  }

  if (!signed && isPrivete) {
    return <Redirect to={`/?rpath=${path}`} />
  }

  if (
    signed &&
    !isPrivete &&
    !currUrl.includes('exchange-token') &&
    !currUrl.includes('solicitar-recuperar-senha') &&
    !currUrl.includes('recuperar-senha') &&
    !currUrl.includes('sucesso-recuperar-senha')) {
    return <Redirect to="/dashboard" />
  }

  if (
    profile?.subscription_type !== 'ESSENTIAL' &&
    !isAdm(profile?.id) &&
    (currUrl.includes('meu-treino') || currUrl.includes('calendario'))
  ) {
    return <Redirect to="/dashboard" />
  }

  const isCourseLayout = currUrl.includes('curso') || currUrl.includes('exercicio-execucao')

  let Layout = signed ? (isCourseLayout ? CourseLayout : AuthLayout) : NonAuthLayout;

  if (isPrivete && externalLink) {
    return <Route {...rest} render={props => (
      <Component {...props} />
    )} />
  }

  return <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
}

RouteWrapper.protoTypes = {
  isPrivete: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivete: false,
};
