import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

export default function ModalConfirmation({ modal, toggle, closeBtn, handle, body }) {
    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} close={closeBtn}>Confirmação</ModalHeader>
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>Cancelar</Button>&nbsp;
                    <Button color="danger" onClick={handle}>Confirmar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}