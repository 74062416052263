import styled from 'styled-components';

export const Container = styled.section`
    table {
        padding: 10px;

        th {
            padding: 10px;
        }

        td {
            padding: 10px;
        }
    }
`;
