import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  loadLiveLecturesSuccess,
  loadLiveLecturesFailure,
  startLiveLectureSuccess,
  startLiveLectureFailure,
  finishLiveLectureSuccess,
  finishLiveLectureFailure
} from './actions';

import moment from 'moment'

export function* loadLiveLectures() {
  try {
    const today = moment(new Date()).format('YYYY-MM-DD')
    const url = `live_lectures?clone_type=SCHEMA&date=${today}&obj_type=LECTURE&isRecord=false`

    const response = yield call(api.get, url);

    yield put(loadLiveLecturesSuccess(response.data))
  } catch (error) {
    toast.error('Erro ao carregar agenda de lives!');
    yield put(loadLiveLecturesFailure())
  }
}

export function* startLiveLecture({ payload }) {
  try {
    const response = yield call(api.post, `live_lectures/${payload.lectureId}/clone`, {});

    yield put(startLiveLectureSuccess(response.data))
    toast.success('Aula iniciada!')
  } catch (error) {
    yield put(startLiveLectureFailure())
    toast.error('Erro ao iniciar aula!')
  }
}

export function* finishLiveLecture({ payload }) {
  try {
    const { lectureId, runtime } = payload.data

    yield call(api.put, `live_lectures/${lectureId}/finish`, { runtime });

    yield put(finishLiveLectureSuccess())
    toast.success('Aula finalizada!')
  } catch (error) {
    yield put(finishLiveLectureFailure())
    toast.error('Erro ao finalizar aula!')
  }
}

export default all([
  takeLatest('@liveLecture/LOAD_LIVE_LECTURES_REQUEST', loadLiveLectures),
  takeLatest('@liveLecture/START_LIVE_LECTURE_REQUEST', startLiveLecture),
  takeLatest('@liveLecture/FINISH_LIVE_LECTURE_REQUEST', finishLiveLecture)
]);
