export function signInRequest(email, password, redirectPath) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password, redirectPath },
  };
}

export function signInSuccess(token, user) {

  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signUpRequest(username, email, password) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { username, email, password, password_confirmation: password },
  };
}

export function setLoading(loading) {
  return {
    type: '@auth/SET_LOADING',
    payload: { loading },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function forgotPasswordRequest(email, redirect_url) {
  return {
    type: '@auth/FORGOT_PASSWORD_REQUEST',
    payload: { email, redirect_url },
  };
}

export function forgotPasswordSuccess() {
  return {
    type: '@auth/FORGOT_PASSWORD_SUCCESS'
  };
}

export function forgotPasswordFailure() {
  return {
    type: '@auth/FORGOT_PASSWORD_FAILURE'
  };
}

export function resetPasswordRequest(token, password, password_confirmation) {
  return {
    type: '@auth/RESET_PASSWORD_REQUEST',
    payload: { token, password, password_confirmation },
  };
}

export function resetPasswordSuccess() {
  return {
    type: '@auth/RESET_PASSWORD_SUCCESS'
  };
}

export function resetPasswordFailure() {
  return {
    type: '@auth/RESET_PASSWORD_FAILURE'
  };
}

export function setCourseFlow(courseFlow) {

  return {
    type: '@auth/SET_COURSE_FLOW',
    payload: { courseFlow },
  };
}

export function setLastSubscriptionCheckedDate(value) {
  return {
    type: '@auth/SET_LAST_SUBSCRIPTION_CHECKED_DATE',
    payload: { value },
  };
}
