import React, { useState, useEffect } from 'react';
import {
  Modal, ModalHeader, ModalBody, Form, FormGroup,
  Label, Input, Row, Col, ModalFooter, Button
} from 'reactstrap'
import api from '~/services/api';
import Loader from '~/components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux'
import { toggleModalActivity, updateUserActivityRequest, saveUserActivityRequest, setActivityToUpdate as setActivityToUpdateAction } from '~/store/modules/userActivity/actions'
import moment from 'moment'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'
import { formatDuration } from '~/services/util'
import { FaTrash } from 'react-icons/fa'

const TimeInput = (props) => (
  <InputMask mask="99:99:99" defaultValue={props.defaultValue} onChange={props.onChange} placeholder="--:--:--">
    {(inputProps) => <Input {...inputProps} type="text" disableunderline="true" />}
  </InputMask>
);

const PaceInput = (props) => (
  <InputMask mask="99:99" defaultValue={props.defaultValue} onChange={props.onChange} placeholder="--:--">
    {(inputProps) => <Input {...inputProps} type="text" disableunderline="true" />}
  </InputMask>
);

const chooseTotalTime = (item) => {
  let { durationHour, durationMin, durationSec } = item

  durationHour = parseFloat(durationHour)
  durationMin = parseFloat(durationMin)
  durationSec = parseFloat(durationSec)

  const duration = ((durationHour * 60) + durationMin + (durationSec ? durationSec / 60 : 0)).toFixed(2);

  return duration
}

const choosePace = (item) => {
  let { pace1, pace2 } = item

  pace1 = parseFloat(pace1)
  pace2 = parseFloat(pace2)

  const pace = ((pace1) + (pace2 / 60)).toFixed(2);

  return pace
}

function toNumberString(num) {
  if (Number.isInteger(num)) {
    return num + ".0"
  } else {
    return num.toString();
  }
}

function buildTypes() {
  const types = {
    label: 'Tipo',
    values: [
      { id: 'Run', name: 'Corrida' },
      { id: 'Ride', name: 'Bicicleta' },
      { id: 'Swim', name: 'Natação' },
      { id: 'WeightTraining', name: 'Força' },
      { id: 'Drills', name: 'Educativo' },
      { id: 'Workout', name: 'Outros Treinos' },
      { id: 'Others', name: 'Outros (avulso)' },
      { id: 'Cardio', name: 'Cardio' },
      { id: 'Canoeing', name: 'Canoagem' },
      { id: 'AlpineSki', name: 'AlpineSki' },
      { id: 'BackcountrySki', name: 'BackcountrySki' },
      { id: 'Crossfit', name: 'Crossfit' },
      { id: 'EBikeRide', name: 'Pedalada' },
      { id: 'Elliptical', name: 'Eliptico' },
      { id: 'Golf', name: 'Golf' },
      { id: 'Handcycle', name: 'Handcycle' },
      { id: 'Hike', name: 'Hike' },
      { id: 'IceSkate', name: 'IceSkate' },
      { id: 'InlineSkate', name: 'InlineSkate' },
      { id: 'Kayaking', name: 'Caiaque' },
      { id: 'Kitesurf', name: 'Kitesurf' },
      { id: 'NordicSki', name: 'NordicSki' },
      { id: 'RockClimbing', name: 'Escalada' },
      { id: 'RollerSki', name: 'RollerSki' },
      { id: 'Rowing', name: 'Rowing' },
      { id: 'Sail', name: 'Sail' },
      { id: 'Skateboard', name: 'Skateboard' },
      { id: 'Snowboard', name: 'Snowboard' },
      { id: 'Snowshoe', name: 'Snowshoe' },
      { id: 'Soccer', name: 'Futebol' },
      { id: 'StairStepper', name: 'StairStepper' },
      { id: 'StandUpPaddling', name: 'SUP' },
      { id: 'Surfing', name: 'Surfing' },
      { id: 'Velomobile', name: 'Velomobile' },
      { id: 'VirtualRide', name: 'Pedalada' },
      { id: 'VirtualRun', name: 'Corrida' },
      { id: 'Walk', name: 'Caminhada' },
      { id: 'Wheelchair', name: 'Wheelchair' },
      { id: 'Windsurf', name: 'Windsurf' },
      { id: 'Yoga', name: 'Yoga' }
    ]
  }

  return types;
}

function ModalActivity({ prevDuration = null, prevTitle = null, refreshActivities = null, prevHandleFinish }) {
  const dispatch = useDispatch()
  const userId = useSelector(state => state.user.profile.id)
  const loading = useSelector(state => state.userActivities.modalLoading)
  const modal = useSelector(state => state.userActivities.modalActivity)
  const activity = useSelector(state => state.userActivities.activityToUpdate)
  const [activityToUpdate, setActivityToUpdate] = useState(activity)
  const defaultTypes = buildTypes();
  const [type, setType] = useState(prevDuration ? 'Cardio' : '')
  const [title, setTitle] = useState(prevTitle ? prevTitle : '')
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'))
  const [hour, setHour] = useState(moment(new Date()).format('HH:mm'))
  const [duration, setDuration] = useState(prevDuration ? formatDuration(prevDuration, 'time') : '')
  const [distance, setDistance] = useState(0)
  const [pace, setPace] = useState('')
  const [averageSpeed, setAverageSpeed] = useState(0)
  const [maxSpeed, setMaxSpeed] = useState(0)
  const [averageHeartrate, setAverageHeartrate] = useState(0)
  const [maxHeartrate, setMaxHeartrate] = useState(0)
  const [altimetry, setAltimetry] = useState(0)
  const [averageCadence, setAverageCadence] = useState(0)
  const [calorie, setCalorie] = useState(0)
  const [comments, setComments] = useState('')
  const [modalDeleteActivity, setModalDeleteActivity] = useState(false)

  useEffect(() => {
    if (prevTitle) {
      const prevTitleLowerCase = prevTitle.toLowerCase()

      if (
        prevTitleLowerCase.includes('fortalecimento') ||
        prevTitleLowerCase.includes('forca') ||
        prevTitleLowerCase.includes('força')
      ) {
        setType('WeightTraining')
      } else if (prevTitleLowerCase.includes('cardio')) {
        setType('Cardio')
      } else {
        setType('Others')
      }
    }
  }, [prevTitle])

  const toggle = () => {
    dispatch(toggleModalActivity())
    dispatch(setActivityToUpdateAction(null))
  }

  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

  const toggleModalDeleteActivity = () => setModalDeleteActivity(!modalDeleteActivity)

  function handleActivityUpdate() {
    let pace1 = activityToUpdate.pace.split(':')[0]
    let pace2 = activityToUpdate.pace.split(':')[1]

    let durationHour = activityToUpdate.duration.split(':')[0]
    let durationMin = activityToUpdate.duration.split(':')[1]
    let durationSec = activityToUpdate.duration.split(':')[2]

    const data = {
      id: activityToUpdate.id,
      title: activityToUpdate.title,
      pace: parseFloat(choosePace({ pace1, pace2 })),
      duration: parseFloat(chooseTotalTime({ durationHour, durationMin, durationSec })),
      altimetry: !activityToUpdate.altimetry ? 0 : parseFloat(toNumberString(activityToUpdate.altimetry).split('.')[0]),
      average_cadence: !activityToUpdate.average_cadence ? 0 : parseFloat(toNumberString(activityToUpdate.average_cadence).split('.')[0]),
      average_heartrate: !activityToUpdate.average_heartrate ? 0 : parseFloat(toNumberString(activityToUpdate.average_heartrate).split('.')[0]),
      average_speed: !activityToUpdate.average_speed ? 0 : parseFloat(activityToUpdate.average_speed),
      calorie: !activityToUpdate.calorie ? 0 : parseFloat(toNumberString(activityToUpdate.calorie).split('.')[0]),
      distance: !activityToUpdate.distance ? 0 : parseFloat(activityToUpdate.distance),
      max_heartrate: !activityToUpdate.max_heartrate ? 0 : parseFloat(toNumberString(activityToUpdate.max_heartrate).split('.')[0]),
      heart_rate: !activityToUpdate.heart_rate ? 0 : parseFloat(toNumberString(activityToUpdate.average_heartrate).split('.')[0]),
      comments: activityToUpdate.comments
    }

    dispatch(updateUserActivityRequest(data))
  }

  function handleSaveNewActivity() {
    let durationToSave = parseFloat(chooseTotalTime({
      durationHour: duration.split(':')[0],
      durationMin: duration.split(':')[1],
      durationSec: duration.split(':')[2]
    }))

    let paceToSave = parseFloat(choosePace({
      pace1: pace.split(':')[0],
      pace2: pace.split(':')[1]
    }))

    if (!date || !hour) {
      toast.error('Preencha a data e hora que realizou a atividade!')
      return
    }

    if (!type) {
      toast.error('Selecione o tipo da atividade!')
      return
    }

    const data = {
      user_id: userId,
      type,
      title,
      pace: paceToSave,
      duration: durationToSave,
      start_date: `${date} ${hour}`,
      altimetry: parseFloat(toNumberString(altimetry).split('.')[0]),
      average_cadence: parseFloat(toNumberString(averageCadence).split('.')[0]),
      average_heartrate: parseFloat(toNumberString(averageHeartrate).split('.')[0]),
      average_speed: parseFloat(averageSpeed),
      max_speed: maxSpeed,
      calorie: parseFloat(toNumberString(calorie).split('.')[0]),
      distance: parseFloat(distance),
      max_heartrate: parseFloat(toNumberString(maxHeartrate).split('.')[0]),
      heart_rate: parseFloat(toNumberString(averageHeartrate).split('.')[0]),
      comments
    }

    dispatch(saveUserActivityRequest(data))

    if (prevDuration) {
      prevHandleFinish()
    }
  }

  const handleDeleteUserActivity = () => {
    const actToDelete = { id: activityToUpdate.id, visible: false };

    Promise.all([
      api.put(`user_activities/${activityToUpdate.id}`, actToDelete),
    ]).then(async ([syncResp]) => {
      if (refreshActivities) {
        refreshActivities()
      }

      toggle()
    });
  }

  return (
    <>
      <div>
        <Modal isOpen={modalDeleteActivity} toggle={toggleModalDeleteActivity}>
          <ModalHeader toggle={toggleModalDeleteActivity} close={closeBtn}>Confirmação</ModalHeader>
          <ModalBody>
            Tem certeza que deseja remover essa atividade?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleModalDeleteActivity}>Cancelar</Button>&nbsp;
            <Button color="danger" onClick={handleDeleteUserActivity}>Sim</Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          {prevDuration ?
            <ModalHeader toggle={toggle} close={closeBtn}>Salvar Atividade</ModalHeader>
            :
            <ModalHeader toggle={toggle} close={closeBtn}>{activity ? 'Editar Atividade' : 'Criar Atividade'}</ModalHeader>}
          <ModalBody>
            {loading && <Loader />}
            <Form>
              {prevDuration ? '' : <FormGroup>
                <Label for="type">Tipo</Label>
                <Input
                  disabled={activity ? true : false}
                  defaultValue={activity ? activityToUpdate.type : ''}
                  onChange={
                    activity ? e => setActivityToUpdate({ ...activityToUpdate, type: e.target.value })
                      : e => setType(e.target.value)
                  }
                  type="select"
                  name="type"
                  id="type"
                  placeholder={defaultTypes.label}>
                  <option value="0">Selecione...</option>
                  {defaultTypes.values.map(type => (
                    <option key={type.id} value={type.id}>{type.name}</option>
                  ))}
                </Input>
              </FormGroup>}
              <FormGroup>
                <Label for="title">Título</Label>
                <Input
                  disabled={prevDuration ? true : false}
                  defaultValue={activity ? activityToUpdate.title : title}
                  onChange={
                    activity ? e => setActivityToUpdate({ ...activityToUpdate, title: e.target.value })
                      : e => setTitle(e.target.value)
                  }
                  type="text" name="title" id="title" placeholder="Dê um título para a atividade" />
              </FormGroup>
              {activity || prevDuration ? '' :
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="date">Data</Label>
                      <Input defaultValue={date} onChange={e => setDate(e.target.value)} type="date" name="date" id="date" />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="hour">Hora</Label>
                      <Input defaultValue={hour} onChange={e => setHour(e.target.value)} type="time" name="hour" id="hour" />
                    </FormGroup>
                  </Col>
                </Row>
              }
              {prevDuration ? '' :
                <>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="duration">Tempo</Label>
                        <TimeInput
                          defaultValue={activity ? activityToUpdate.duration : ''}
                          onChange={
                            activity ? e => setActivityToUpdate({ ...activityToUpdate, duration: e.target.value })
                              : e => setDuration(e.target.value)
                          }
                          name="duration"
                          id="duration" />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="distance">Distância</Label>
                        <Input
                          defaultValue={activity ? activityToUpdate.distance : ''}
                          onChange={
                            activity ? e => setActivityToUpdate({ ...activityToUpdate, distance: e.target.value })
                              : e => setDistance(e.target.value)
                          }
                          type="number"
                          name="distance"
                          id="distance"
                          placeholder="km" />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="pace">Ritmo</Label>
                        <PaceInput
                          defaultValue={activity ? activityToUpdate.pace : ''}
                          onChange={
                            activity ? e => setActivityToUpdate({ ...activityToUpdate, pace: e.target.value })
                              : e => setPace(e.target.value)
                          }
                          name="pace"
                          id="pace" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="average_speed">Vel. Média</Label>
                    <Input
                      defaultValue={activity ? activityToUpdate.average_speed : ''}
                      onChange={
                        activity ? e => setActivityToUpdate({ ...activityToUpdate, average_speed: e.target.value })
                          : e => setAverageSpeed(e.target.value)
                      }
                      type="number"
                      name="average_speed"
                      id="average_speed"
                      placeholder="km/h" />
                  </FormGroup>
                </>}
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="average_heartrate">FC Média</Label>
                    <Input
                      defaultValue={activity ? activityToUpdate.average_heartrate : ''}
                      onChange={
                        activity ? e => setActivityToUpdate({ ...activityToUpdate, average_heartrate: e.target.value })
                          : e => setAverageHeartrate(e.target.value)
                      }
                      type="number" name="average_heartrate" id="average_heartrate" placeholder="bpm" />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="max_heartrate">FC Máxima</Label>
                    <Input
                      defaultValue={activity ? activityToUpdate.max_heartrate : ''}
                      onChange={
                        activity ? e => setActivityToUpdate({ ...activityToUpdate, max_heartrate: e.target.value })
                          : e => setMaxHeartrate(e.target.value)
                      }
                      type="number" name="max_heartrate" id="max_heartrate" placeholder="bpm" />
                  </FormGroup>
                </Col>
              </Row>
              {prevDuration ? '' :
                <>
                  <FormGroup>
                    <Label for="altimetry">Ganho de Elevação</Label>
                    <Input
                      defaultValue={activity ? activityToUpdate.altimetry : ''}
                      onChange={
                        activity ? e => setActivityToUpdate({ ...activityToUpdate, altimetry: e.target.value })
                          : e => setAltimetry(e.target.value)
                      }
                      type="number" name="altimetry" id="altimetry" placeholder="metros" />
                  </FormGroup>
                  <FormGroup>
                    <Label for="average_cadence">Cadência Média</Label>
                    <Input
                      defaultValue={activity ? activityToUpdate.average_cadence : ''}
                      onChange={
                        activity ? e => setActivityToUpdate({ ...activityToUpdate, average_cadence: e.target.value })
                          : e => setAverageCadence(e.target.value)
                      }
                      type="number" name="average_cadence" id="average_cadence" placeholder="ppm" />
                  </FormGroup>
                </>}
              <FormGroup>
                <Label for="calorie">Calorias</Label>
                <Input
                  defaultValue={activity ? activityToUpdate.calorie : ''}
                  onChange={
                    activity ? e => setActivityToUpdate({ ...activityToUpdate, calorie: e.target.value })
                      : e => setCalorie(e.target.value)
                  }
                  type="number" name="calorie" id="calorie" placeholder="kcal" />
              </FormGroup>
              <FormGroup>
                <Label for="comments">Observações</Label>
                <Input
                  defaultValue={activity ? activityToUpdate.comments : ''}
                  onChange={
                    activity ? e => setActivityToUpdate({ ...activityToUpdate, comments: e.target.value })
                      : e => setComments(e.target.value)
                  }
                  type="textarea" name="comments" id="comments" />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>
                {activity &&
                  <button className="btn btn-bordered-danger" onClick={toggleModalDeleteActivity}>
                    <FaTrash />
                  </button>}
              </div>
              <div>
                <button className="btn btn-bordered-primary" onClick={toggle}>Cancelar</button>&nbsp;
                <button className="btn btn-bordered-success" onClick={activity ? handleActivityUpdate : handleSaveNewActivity}>Salvar</button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}

export default ModalActivity;
