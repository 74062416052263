import styled from 'styled-components';

export const Container = styled.section`
    table {
        padding: 10px;

        th {
            padding: 10px;
        }

        td {
            padding: 10px;
        }
    }

    @media (max-width: 767px) {
        table {
            display: block;
            position: relative;

            thead,
            tbody,
            th,
            td,
            tr {
                display: block;
            }

            td,
            th {
                height: 35px;
                margin-bottom: 10px;
            }

            thead {
                float: left;
            }

            tbody {
                width: auto;
                position: relative;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                white-space: nowrap;
            }

            tbody tr {
                display: inline-block;
            }
        }
    }
`;
