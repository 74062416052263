import React, {useState, useEffect} from 'react';

import { Row, Col,  Modal, ModalHeader, ModalBody, ModalFooter, Button, 
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Table, 
    ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText,
    Card, CardHeader, CardBody, FormGroup  } from 'reactstrap';

import { toast } from 'react-toastify';

import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';

import { MdDirectionsRun } from 'react-icons/md';

import api from '~/services/api';

const ACTIVITY_TYPE_OPTIONS = {
  DURATION: {id: 'DURATION', name: "T", labelType: 'Min', fieldName: 'duration'},
  DISTANCE: {id: 'DISTANCE', name: "Distância", labelType: 'Km', fieldName: 'distance'},
}

export default function TrainingNewModal(props) {
  const [show, setShow] = useState(props.open);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [training, setTraining] = useState(props.training);
  const [isTrainingEdit, setIsTrainingEdit] = useState(props.isTrainingEdit);
  const [folders, setFolders] = useState([]);
  const [trainingGroupTemplates, setTrainingGroupTemplates] = useState([]);
  const [showDDFolder, setShowDDFolder] = useState(false);
  const [activityTypes, setActivityTypes] = useState([]);
  const [currentFolder, setCurrentFolder] = useState({name: 'Selecionar', id: -1});
  const [currentActivityType, setCurrentActivityType] = useState({name: 'Selecionar', id: -1});
  const [showDDActivityType, setShowDDActivityType] = useState(false);
  
  const [trainingTypes, setTrainingTypes] = useState([{ id: 'Run', name: 'Corrida'}, { id: 'WeightTraining', name: 'Treino de Força'}, { id: 'Ride', name: 'Bicicleta'}, { id: 'Swim', name: 'Natação'}, { id: 'Drills', name: 'Educativos'}, { id: 'Cardio', name: 'Cardio'}, { id: 'Others', name: 'Outros'}, { id: 'Workout', name: 'Workout'}]);
  const [showDDType, setShowDDType] = useState(false);
  const [currentType, setCurrentType] = useState({ id: -1, name: 'Selecionar'});

  const [trainingType, setTrainingType] = useState('');

  const [trainingIntencity, setTrainingIntencity] = useState([{ id: 1, name: 'Iniciante'}, { id: 2, name: 'Intermediário'}, { id: '3', name: 'Avançado'}]);
  const [showDDIntencity, setShowDDIntencity] = useState(false);
  const [currentTrainingIntencity, setCurrentTrainingIntencity] = useState({ id: -1, name: 'Selecionar'});

  const [trainingCoverLink, setTrainingCoverLink] = useState(''); 


  useEffect(() => {
    const activityTypeOptions = [ACTIVITY_TYPE_OPTIONS.DISTANCE, ACTIVITY_TYPE_OPTIONS.DURATION]

    setActivityTypes(activityTypeOptions)

    Promise.all([api.get('folders'), api.get('training_groups?type=TEMPLATE')])
    .then(([folderResp, trainingGroupsResp])  => {
      setFolders(folderResp.data);
      setTrainingGroupTemplates(trainingGroupsResp.data)
    });

    if (training) {
      if (training.folder) {
        setCurrentFolder(training.folder);
      }

      if (training.activity_type === ACTIVITY_TYPE_OPTIONS.DURATION.id) {
        setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DURATION);
      } else {
        setCurrentActivityType(ACTIVITY_TYPE_OPTIONS.DISTANCE);

      }

      switch (training.intencity) {
        case 1:
          setCurrentTrainingIntencity({ id: 1, name: 'Iniciante'}) 
          break;
        case 2:
          setCurrentTrainingIntencity({ id: 2, name: 'Intermediário'}) 
          break;
        case 3:
          setCurrentTrainingIntencity({ id: 3, name: 'Avançado'}) 
          break;
        default:
          setCurrentTrainingIntencity({ id: 1, name: 'Iniciante'}) 
          break;
      }

      switch (training.type) {
        case 'Run':
          setCurrentType({ id: 'Run', name: 'Corrida'});
          break;
        case 'WeightTraining':
          setCurrentType({ id: 'WeightTraining', name: 'Treino de Força'});
          break;
        case 'Ride':
          setCurrentType({ id: 'Ride', name: 'Bicicleta'});
          break;
        case 'Swim':
          setCurrentType({ id: 'Swim', name: 'Natação'});
          break;
        case 'Drills':
          setCurrentType({ id: 'Drills', name: 'Educativos'});
          break;
        case 'Cardio':
          setCurrentType({ id: 'Cardio', name: 'Cardio'});
          break;
        case 'Others':
          setCurrentType({ id: 'Others', name: 'Outros'});
          break;
        case 'Workout':
          setCurrentType({ id: 'Workout', name: 'Workout'});
          break;
        default:
          setCurrentType({ id: 'Others', name: 'Outros'});
          break;
      }

      setTrainingType(training.type === 'Run' ? 'RUN' : 'EXPLORE')
      
    }
    // loadData();
  }, [training])

  const handleTrainingSubmit = async (event, errors, values) => {

    if (isTrainingEdit) {
      toast.error('Ocorreu um erro ao editar o treino, tente novamente mais tarde ou contate o suporte.')
      return;
    }

    if (currentType.id < 1) {
      toast.error('Selecione um tipo para o treino.')
      return
    }

    if (currentActivityType.id < 1) {
      toast.error('Selecione um tipo de atividade para prosseguir (Duração ou Distância)')
      return
    }

    if (!values.name) {
      toast.error('Atribua um nome para o seu treino')
      return
    }

    if (currentFolder.id > 0){
      values.folder_id = currentFolder.id
    }

    if (trainingType === 'EXPLORE' && currentTrainingIntencity.id < 1) {
      toast.error('Selecione a intencidade do treino')
      return
    }

    values.activity_type = currentActivityType.id
    
    if (!values.trainingGroups || values.trainingGroups.length < 1) {
      toast.error('Selecione algumas atividades para compor seu treino')
      return
    }

    let trainingTotal = 0;

    values.trainingGroups.forEach((tgroup, tgIdx) => {
      tgroup.activity_type = currentActivityType.id
      tgroup.group_order = (tgIdx +1)

      let trainingGroupTotal = 0;

      tgroup.trainingActivities.forEach((tactivity, taidx) => {
        tactivity.group_order = (taidx + 1)
        
        trainingGroupTotal += tactivity.distance

        if (tactivity.activity_type === 'Tempo') {
          if (!tactivity.duration || tactivity.duration === '') {
            toast.error("Preencha o campo de 'Tempo' da atividade " + tactivity.name)
            return;
          }
          tactivity.repetition = parseFloat(tactivity.duration)
          delete tactivity.distance
          delete tactivity.repetition
          tactivity.activity_type = 'DURATION'
        } else if (tactivity.activity_type === 'Deslocamento') {
          if (!tactivity.distance || tactivity.distance === '') {
            toast.error("Preencha o campo de 'Deslocamento' da atividade " + tactivity.name)
            return;
          }
          tactivity.distance = parseInt(tactivity.distance)
          delete tactivity.duration
          delete tactivity.repetition
          tactivity.activity_type = 'DISTANCE'
        } else {
          if (!tactivity.repetition || tactivity.repetition === '') {
            toast.error("Preencha o campo de 'Repetições' da atividade " + tactivity.name)
            return;
          }
          tactivity.repetition = parseInt(tactivity.repetition)
          delete tactivity.duration
          delete tactivity.distance
          tactivity.activity_type = 'REPETITION'
        }

        switch (tactivity.execution_speed) {
          case 'Rápido':
            tactivity.execution_speed = 3
            break;
          case 'Moderado':
            tactivity.execution_speed = 2
            break;
          case 'Lento':
            tactivity.execution_speed = 1
            break;
          default:
            tactivity.execution_speed = 1
        }
      })

      const totalGroup = (trainingGroupTotal  * tgroup.repetition)
      trainingTotal += totalGroup;

      if (currentActivityType.id === 'DURATION') {
        tgroup.duration_total = totalGroup;
      } else {
        tgroup.distance_total = totalGroup;
      }
    });

    values.type = currentType.id
    values.intensity = currentTrainingIntencity.id

    try {
      const resp = await api.post('trainings', values)

      if (resp.status === 200) {
        toast.success('Treino Criado com sucesso')
        setShow(!show)
      } else {
        toast.warn('Erro ao criar treino')
      }
    } catch (error) {
      toast.error('Aconteceu um erro ao salvar seu treino')
    }
  }

  const chooseTrainingTemplate = (trainingGroup) => {
    let groups = [...training.trainingGroups];

    groups.push(trainingGroup);

    training.trainingGroups = groups;

    setTraining(training);
    setShowGroupsModal(!showGroupsModal);
  }

  const chooseTrainingType = (ttype) => {
    if (ttype.id === 'WeightTraining' || ttype.id === 'Drills' || ttype.id === 'Cardio' || ttype.id === 'Others') {
      setTrainingType('EXPLORE')
    } else if (ttype.id === 'Run') {
      setTrainingType('RUN')
    }

    setCurrentType(ttype)
  }

  const _handleLinkImageChange = (link) => {
    setTrainingCoverLink(link)
  }

  const _handleAddExploreTrainingGroup = () => {
    const taToAdd = { name: '', description: '',  activity_type: '', clone_type: 'SCHEMA', group_order: 1, repetition: '', execution_speed: '', media_link: '', thumb_link: '' }

    const tgToAdd = { name: '',  activity_type: '', clone_type: 'SCHEMA', trainingActivities: [taToAdd] }

    let groups = [...training.trainingGroups];

    groups.push(tgToAdd);


    training.trainingGroups = groups;
    setTraining(training);
  }

  const _handleAddActivity = (tgIdx) => {
    let groups = [...training.trainingGroups];

    const currTG = groups[tgIdx];

    const taToAdd = { name: '', description: '',  activity_type: '', clone_type: 'SCHEMA', group_order: 1, repetition: '', execution_speed: '', media_link: '', thumb_link: '' }
    currTG.trainingActivities.push(taToAdd);
  }

  const _handleRemoveActivity = (tgIdx, actIdx) => {
    const currTraining = {...training}

    const currTg = currTraining.trainingGroups[tgIdx];

    if (currTg && currTg.trainingActivities && currTg.trainingActivities.length > 1 ) {
      currTg.trainingActivities.splice(actIdx, 1)
    } else if (currTg && currTg.trainingActivities) {
      training.trainingGroups.splice(tgIdx, 1)
    }

    setTraining(currTraining);
  }

  return (
    <Modal style={{maxWidth: '2600px', width: '95%', maxHeight: '1600px', height: '95%'}} isOpen={show} toggle={() => props.toggle(!show)}>
      <Modal style={{maxWidth: '500', height: '95%'}} isOpen={showGroupsModal}>
        <ModalHeader toggle={() => setShowGroupsModal(!showGroupsModal)}>Selecione um template de treino</ModalHeader>
        <ModalBody>
          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center'}}>
            {trainingGroupTemplates.map((elem, index) => {
              return (
                <Card className="shadow" onClick={() => chooseTrainingTemplate(elem)} key={index} style={{width: 150, height: 40, margin: 5 }}>
                  <CardBody style={{ padding: 5}}>
                    {elem.name}
                  </CardBody>
                </Card>
              )
            })}

          </div>
        </ModalBody>

        <ModalFooter>
            <Button color="primary" onClick={() => setShowGroupsModal(!showGroupsModal)}>Cancelar</Button>
        </ModalFooter>
      </Modal>

      <AvForm onSubmit={handleTrainingSubmit} model={training}>
        <ModalBody>
            <Row>
              <Col lg={5}>
                  <Row>
                      <Col lg={7}>
                        <Row>
                        <Col lg={4}>
                          <span style={{fontWeight: '500', fontSize: 15}}>Tipo do Treino</span><br />
                          <Dropdown isOpen={showDDType} toggle={() => setShowDDType(!showDDType)} style={{marginTop: 10, marginBottom: 15}} >
                              <DropdownToggle style={{width: 150, marginRight: 10}}> {currentType.name} </DropdownToggle>
                              <DropdownMenu
                                  modifiers={{
                                  setMaxHeight: { enabled: true, order: 890, fn: (data) => {
                                      return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, }}}}}} >
                                        {trainingTypes.map((elem, index) => {
                                          return (
                                            <DropdownItem onClick={() => chooseTrainingType(elem)}>{elem.name}</DropdownItem>
                                          )
                                        })}
                              </DropdownMenu>
                          </Dropdown>
                          </Col>
                          <Col lg={4}>
                            <span style={{fontWeight: '500', fontSize: 15}}>Pasta</span><br />
                            <Dropdown isOpen={showDDFolder} toggle={() => setShowDDFolder(!showDDFolder)} style={{marginTop: 10, marginBottom: 15, paddingLeft: 10, paddingRight: 10}} >
                                <DropdownToggle style={{width: 150, marginRight: 10}}> {currentFolder.name} </DropdownToggle>
                                <DropdownMenu
                                    modifiers={{
                                    setMaxHeight: { enabled: true, order: 890, fn: (data) => {
                                        return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, }}}}}} >
                                          {folders.map((elem, index) => {
                                            return (
                                              <DropdownItem onClick={() => setCurrentFolder(elem)}>{elem.name}</DropdownItem>
                                            )
                                          })}
                                </DropdownMenu>
                            </Dropdown>
                          </Col>
                          {trainingType === 'RUN' && 
                            <Col lg={4}>
                              <span style={{fontWeight: '500', fontSize: 15}}>Modelo do Treino</span><br />
                              <Dropdown isOpen={showDDActivityType} toggle={() => setShowDDActivityType(!showDDActivityType)} style={{marginTop: 10, marginBottom: 15, paddingLeft: 10}} >
                                  <DropdownToggle style={{width: 150}}> {currentActivityType.name} </DropdownToggle>
                                  <DropdownMenu
                                      modifiers={{
                                      setMaxHeight: { enabled: true, order: 890, fn: (data) => {
                                          return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, }}}}}} >
                                            {activityTypes.map((elem, index) => {
                                              return (
                                                <DropdownItem onClick={() => { setCurrentActivityType(elem)}}>{elem.name}</DropdownItem>
                                              )
                                            })}
                                  </DropdownMenu>
                              </Dropdown>
                            </Col>}
                        </Row>
                        <AvField style={{ height: 44, fontSize: 20, fontWeight: 'bold', marginTop: 15}} placeholder="Nome do treino" name="name" />
                        <AvField style={{ height: 24, fontSize: 14}} placeholder="Nome interno" name="internal_name" />
                        {trainingType === 'RUN' && currentActivityType.id === 'DURATION' && 
                          <Row>
                              <Col lg={3}>Duração (min)</Col>
                              <Col lg={5}><AvField style={{width: '95%', height: 20}} name="duration" /></Col>
                          </Row>}
                        {trainingType === 'RUN' && currentActivityType.id === 'DISTANCE' && 
                          <Row>
                            <Col lg={3}>Distância (km)</Col>
                            <Col lg={5}><AvField style={{width: '95%', height: 20}} name="distance" /></Col>
                          </Row>}
                        {trainingType === 'RUN' &&
                          <Row>
                            <Col lg={2}><MdDirectionsRun color="gray" size={18} style={{marginRight:10}} /></Col>
                            <Col lg={3}><strong>--:--:--</strong></Col>
                            <Col lg={3}><strong>17.09 km</strong></Col>
                          </Row>}

                        {trainingType === 'EXPLORE' &&
                          <Row>
                            <Col lg={12}>
                              <Row>
                                <Col lg={12}>
                                  <span style={{fontWeight: '500', fontSize: 15}}>Intencidade</span><br />
                                  <Dropdown isOpen={showDDIntencity} toggle={() => setShowDDIntencity(!showDDIntencity)} style={{marginTop: 10, marginBottom: 15}} >
                                    <DropdownToggle style={{width: 150, marginRight: 10}}> {currentTrainingIntencity.name} </DropdownToggle>
                                      <DropdownMenu
                                          modifiers={{
                                          setMaxHeight: { enabled: true, order: 890, fn: (data) => {
                                              return { ...data, styles: { ...data.styles, overflow: 'auto', maxHeight: 400, }}}}}} >
                                                {trainingIntencity.map((elem, index) => {
                                                  return (
                                                    <DropdownItem onClick={() => setCurrentTrainingIntencity(elem)}>{elem.name}</DropdownItem>
                                                  )
                                                })}
                                      </DropdownMenu>
                                  </Dropdown>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={6}>
                                  <AvField label="Estimativa início" style={{ height: 25}} name="estimated_init" />
                                </Col>
                                <Col lg={6}>
                                  <AvField label="Estimativa fim" style={{ height: 25}} name="estimated_end" />
                                </Col>
                              </Row>
                            
                              <Row>
                                <Col lg={12}>
                                  <AvField label="Equipamentos (por extenso)" style={{ height: 25}} name="equipments" />
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={12}>
                                  <AvField label="Imagem (link)" onChange={(e) => _handleLinkImageChange(e.target.value)} style={{ height: 25}} name="cover_link" />
                                </Col>
                              </Row>

                              <Row>
                                <Col lg={12}>
                                  <span>Image Preview</span> <br /><br />
                                  <img src={trainingCoverLink} alt="" height="100" />
                                </Col>
                              </Row>

                            </Col>
                          </Row>}
                      </Col>
                      <Col lg={5}>
                        <h4>Descrição</h4>
                        <AvField type="textarea" style={{height: 150}} name="description" />
                      </Col>
                  </Row>
                  {trainingType === 'RUN' && 
                    <Row>
                        <Col lg={7} style={{backgroundColor: 'white'}}>
                        <Table responsive style={{marginTop: 20}}>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th style={{textAlign: 'center'}}>Planejado</th>
                                    <th style={{textAlign: 'center'}}>Executado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Duração</td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="duration" /> </td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="exec_duration" /> </td>
                                    <td> <span>min</span> </td>
                                </tr>
                                <tr>
                                    <td>Distancia</td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="distance" /> </td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="exec_distance" /> </td>
                                    <td> <span>Kms</span> </td>
                                </tr>
                                <tr>
                                    <td>Pace</td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="pace" /> </td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="exec_pace" /> </td>
                                    <td> <span>min/km</span> </td>
                                </tr>
                                <tr>
                                    <td>Caloria</td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="calorie" /> </td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="exec_calorie" /> </td>
                                    <td> <span>kcal</span> </td>
                                </tr>
                                <tr>
                                    <td>Elevação</td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="altimetry" /> </td>
                                    <td> <AvField style={{width: '95%', height: 20}} name="exec_altimetry" /> </td>
                                    <td> <span>m</span> </td>
                                </tr>
                            </tbody>
                            </Table>
                        </Col>

                        <Col lg={5} style={{backgroundColor: 'white'}}>
                            <h4 style={{marginTop: 20 }}>Detalhes</h4>
                            <hr style={{ backgroundColor: 'gray', height: 1 }} />

                            { training.trainingGroups.map((tgroup, gidx) => {
                              
                              if (tgroup.trainingActivities.length > 1) {
                                return (
                                  <>
                                    <span style={{color: 'black'}}>{gidx + 1}. <strong>{tgroup.name} | Repetir {tgroup.repetition} vezes</strong> </span> <br />

                                    {tgroup.trainingActivities.map((tactivity, aidx) => {

                                      const metric = training.activity_type === ACTIVITY_TYPE_OPTIONS.DURATION.id ? tactivity.duration : tactivity.distance

                                      return (
                                        <>
                                          <span style={{color: 'black', marginLeft:10}}>{aidx+1} <strong>{tactivity.name}</strong> </span> <br />
                                          <span style={{marginLeft: 30}}> {metric} {currentActivityType.labelType} @  {tactivity.zone}</span><br />
                                        </>
                                      )
                                    })}
                                  </>
                                )
                              } else {
                                const metric = training.activity_type === ACTIVITY_TYPE_OPTIONS.DURATION.id ? tgroup.trainingActivities[0].duration : tgroup.trainingActivities[0].distance

                                return (
                                  <>
                                    <span style={{color: 'black'}}>{gidx + 1}. <strong>{tgroup.name} | Repetir {tgroup.repetition} vezes</strong> </span> <br />
                                    <p style={{marginLeft: 20}}> {metric} {currentActivityType.labelType} @  {tgroup.trainingActivities[0].zone}</p>
                                  </>
                                )
                              }

                            })}
                        </Col>
                    </Row>}

              </Col>
              
              <Col lg={7} style={{backgroundColor: 'white', padding: 5}}>
                  <Row style={{marginBottom: 15}}>
                    <Col lg={3}>
                      <h4>Fases do Treino</h4>
                    </Col>
                    <Col lg={9}>
                      <Button color="info" onClick={() => {
                        if (trainingType === 'RUN') {
                          setShowGroupsModal(!showGroupsModal)
                        } else if (trainingType === 'EXPLORE') {
                          _handleAddExploreTrainingGroup()
                        }
                      }}>Adicionar Fase</Button>{' '}
                    </Col>
                  </Row>
                 
                  <div style={{overflowY: 'scroll', borderLeft: '1px solid gray', paddingLeft: '10px', paddingRight: '5px', width:'100%', float: 'left', height:'600px', position:'relative'}}>
                      

                    {training.trainingGroups.map((tgroup, gidx) => {
                      const trainingGroupName = "trainingGroups["+gidx+"].name";
                      const trainingGroupDesc = "trainingGroups["+gidx+"].description";
                      const trainingGroupRepetition = "trainingGroups["+gidx+"].repetition";
                      

                      return (
                        <Card className="shadow" key={gidx}>
                          <CardHeader style={{padding: 5}}> 
                            <div style={{'display': '-webkit-box'}}>
                              {trainingType === 'EXPLORE' ? 
                                <>
                                  <span>Nome da Série</span> 
                                  <AvInput placeholder="Nome da Série" style={{ height: 25, width: '15%', marginLeft: 10, marginRight:10 }} name={trainingGroupName} /> 
                                  <span>Descrição da Série</span> 
                                  <AvInput placeholder="Descrição da Série" style={{ height: 25, width: '50%', marginLeft: 10, marginRight:10 }} name={trainingGroupDesc} /> 
                                </>
                                :
                                <>
                                  <span style={{minWidth: 355, width: 200, fontSize: 18, fontWeight: 'bold', marginRight: 50 }}>{tgroup.name}</span><span>Repetir</span> 
                                  <AvInput style={{ height: 20, width: 100, marginLeft: 10, marginRight:10 }} name={trainingGroupRepetition} /> 
                                  <AvInput style={{ display: 'none' }} name={trainingGroupName} /> 
                                  <span>vezes</span>
                                </>
                              }
                              
                            </div>
                          </CardHeader>
                          <CardBody style={{ padding: 0 }}>
                            <ListGroup style={{padding: 0, margin: 0 }}>
                              {training.trainingGroups[gidx].trainingActivities.map((input, aidx) => {
                                const trainingActivityIdLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].id";
                                const trainingActivityNameLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].name";
                                const trainingActivityDescriptionLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].description";
                                const trainingActivitySpeedLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].execution_speed";
                                const trainingActivityActivityTypeLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].activity_type";
                                const trainingActivityRepetitionLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].repetition";
                                const trainingActivityDurationLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].duration";
                                const trainingActivityDistanceLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].distance";
                                const trainingActivityGifLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].media_link";
                                const trainingActivityThumbLabel = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].thumb_link";
                                const trainingActivityDistance = `trainingGroups[${gidx}].trainingActivities[${aidx}].${currentActivityType.fieldName}`;
                                const trainingActivityZone = "trainingGroups["+gidx+"].trainingActivities["+aidx+"].zone";
                                const trainingActivityName = training.trainingGroups[gidx].trainingActivities[aidx].name;

                                let trainingActivityType = training.trainingGroups[gidx].trainingActivities[aidx].activity_type;
                                let trainingActivityExecutionSpeed = training.trainingGroups[gidx].trainingActivities[aidx].execution_speed;

                                trainingActivityType = trainingActivityType === 'REPETITION' ? 'Repetição' : 'Tempo'

                                trainingActivityExecutionSpeed = trainingActivityExecutionSpeed === 1 ? 'Lento' : (trainingActivityExecutionSpeed === 2 ? 'Moderado' : 'Rápido');

                                if (trainingType === 'EXPLORE') {
                                  return (
                                    <ListGroupItem style={{padding: 2, paddingBottom: 10, backgroundColor: (aidx % 2 === 0 ? 'rgba(100, 100, 100, 0.2)' : 'rgba(200, 200, 200, 0.2)')}}>
                                      <ListGroupItemHeading style={{padding: 5, margin: 0}}>{trainingActivityName}</ListGroupItemHeading>
                                      <ListGroupItemText style={{padding: 5, margin: 0}}>
                                        
                                        <AvInput placeholder="ID" style={{height: 30, width: '100%', display: 'none'}} name={trainingActivityIdLabel} /> 
                                        
                                        <div style={{}}>
                                        {/* { name: '', description: 'REPETITION',  activity_type: 'REPETITION', clone_type: 'SCHEMA', group_order: 1, repetition: 10, execution_speed: '2', media_link: '', thumb_link: '' } */}
                                          
                                          <Row>
                                            <div  style={{display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', height: 22}}>
                                              <button  type="button" style={{width: 20, height: 20, marginRight: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                                onClick={() => _handleRemoveActivity(gidx, aidx)}>x</button>
                                            </div>
                                          </Row>
                                          <Row>
                                            <Col lg={4}>
                                              <AvInput style={{height: 40, width: '100%', marginRight: 10 }} placeholder="Nome do Exercício" name={trainingActivityNameLabel} />
                                            </Col>
                                            <Col lg={2}>
                                              <AvField type="select" value={trainingActivityType} name={trainingActivityActivityTypeLabel} style={{height: 40, width: 120, marginLeft: 0, marginRight: 10}}>
                                                <option>Repetição</option>
                                                <option>Tempo</option>
                                                <option>Deslocamento</option>
                                              </AvField> 
                                            </Col>
                                            <Col lg={2}>
                                              <AvField type="select" value={trainingActivityExecutionSpeed} name={trainingActivitySpeedLabel} style={{height: 40, width: 120, marginLeft: 0, marginRight: 10}}>
                                                <option>Lento</option>
                                                <option>Moderado</option>
                                                <option>Rápido</option>
                                              </AvField> 
                                            </Col>
                                            <Col lg={4}>
                                              <AvInput style={{height: 40, width: '100%' }} placeholder="Descrição do Exercício" name={trainingActivityDescriptionLabel} /> 
                                            </Col>
                                          </Row>

                                          <Row>
                                            <Col lg={2}>
                                              <AvInput placeholder="Repetições" style={{height: 30, width: '100%'}} name={trainingActivityRepetitionLabel} /> 
                                            </Col>
                                            <Col lg={2}>
                                              <AvInput placeholder="Tempo" style={{height: 30, width: '100%' }} name={trainingActivityDurationLabel} />
                                            </Col>
                                            <Col lg={2}>
                                              <AvInput placeholder="Deslocamento" style={{height: 30, width: '100%' }} name={trainingActivityDistanceLabel} /> 
                                            </Col>
                                            <Col lg={3}>
                                              <AvInput placeholder="Gif (link)" style={{height: 30, width: '100%' }} name={trainingActivityGifLabel} /> 
                                            </Col>
                                            <Col lg={3}>
                                              <AvInput placeholder="Thumb (link)" style={{height: 30, width: '100%' }} name={trainingActivityThumbLabel} /> 
                                            </Col>
                                          </Row>

                                          {/* <AvInput style={{height: 25, width: 100, marginRight: 10 }} name={trainingActivityDistance} /> {currentActivityType.labelType}  */}
                                          {/* <AvField type="select" name={trainingActivityZone} style={{height: 25, width: 120, marginLeft: 40, marginRight: 10}}>
                                            <option>ZONA-1</option>
                                            <option>ZONA-2</option>
                                            <option>ZONA-3</option>
                                            <option>ZONA-4</option>
                                            <option>ZONA-5a</option>
                                            <option>ZONA-5b</option>
                                            <option>ZONA-5c</option>
                                          </AvField>Zona */}
                                        </div>
                                      </ListGroupItemText>
                                    </ListGroupItem>
                                  )
                                } else if (trainingType === 'RUN') {
                                  return (
                                    <ListGroupItem style={{padding: 3}}>
                                      <ListGroupItemHeading style={{padding: 5, margin: 0}}>{trainingActivityName}</ListGroupItemHeading>
                                      <ListGroupItemText style={{padding: 5, margin: 0}}>
                                        <div style={{'display': '-webkit-box'}}>
                                          <AvInput style={{ display: 'none' }} name={trainingActivityNameLabel} /> 
                                          <AvInput style={{height: 35, width: 100, marginRight: 10 }} name={trainingActivityDistance} /> {currentActivityType.labelType} 
                                          <AvField type="select" name={trainingActivityZone} style={{height: 35, width: 120, marginLeft: 40, marginRight: 10}}>
                                            <option>ZONA-1</option>
                                            <option>ZONA-2</option>
                                            <option>ZONA-3</option>
                                            <option>ZONA-4</option>
                                            <option>ZONA-5a</option>
                                            <option>ZONA-5b</option>
                                            <option>ZONA-5c</option>
                                          </AvField>Zona
                                        </div>
                                      </ListGroupItemText>
                                    </ListGroupItem>
                                  )
                                }}
                              )}
                                
                              <Button color="info" style={{width: 200, height: 40, margin: 5, alignSelf: 'flex-end'}} onClick={() => _handleAddActivity(gidx)}>Adicionar Exercício</Button>
                            </ListGroup>

                            </CardBody>
                        </Card>
                      )
                    })}

                  </div>
              </Col>


          </Row>
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={() => props.toggle(!show)}>Cancelar</Button>
            <FormGroup>
              <Button  type="submit" color="success" >Salvar e Fechar</Button>
          </FormGroup>
        </ModalFooter>
        </AvForm>
    </Modal>
  );
}
