import React from 'react';

import { Row, Col, Card, CardBody } from 'reactstrap';

import { Link } from 'react-router-dom'

function EmailContactScreen() {
    return (
        <>
            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Enviar E-mail</h4>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg={7}>
                                    <p>Para falar conosco por e-mail, veja as opções abaixo (todas com prazo de resposta de até 1 dia útil):</p>

                                    <ul style={{ listStyleType: 'square', marginLeft: 25 }}>
                                        <li>Falar com treinadores: <a href="mailto:atendimento@corridaperfeita.com">atendimento@corridaperfeita.com</a></li>
                                        <li>Falar com o suporte técnico (senha, acesso, sistema, etc.): <a href="mailto:suporte@corridaperfeita.com">suporte@corridaperfeita.com</a></li>
                                        <li>Para tratar de sua assinatura (cancelamento, alterações, etc.), <Link to="/perfil#assinatura">clique aqui.</Link></li>
                                    </ul>

                                    <p>
                                        <b>Obs</b>.: Caso desejar uma resposta mais rápida para dúvidas de seu treinamento,
                                    acione nossos treinadores por WhatsApp Menu Ajuda &gt; WhatsApp)
                                    </p>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default EmailContactScreen;
