import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { setCourseFlow } from '~/store/modules/auth/actions';
import history from '~/services/history';

import { isMobile } from 'react-device-detect'

import api from '~/services/api';

const TAX_CHILD = 0.38;

export default function CourseItem({ course, navigation, profile }) {
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(750)

  async function handleCourseDetail() {
    if (course.clone_type === 'COURSE') {
      const response = await api.post(`/courses/${course.id}/init`);

      if (response.status === 200) {
        await dispatch(setCourseFlow(true));

        const redirectURL = `/curso/${response.data.id}`
        history.push(redirectURL)
      } else {
        alert('Ops!', 'Tivemos um problema ao iniciar o curso, tente novamente mais tarde.');
      }
    } else {
      await dispatch(setCourseFlow(true));

      const redirectURL = `/curso/${course.id}`
      history.push(redirectURL)
    }
  }

  const marginTopChid = (screenWidth * TAX_CHILD);
  const executedPercent = `${course.executed_percent}% concluído`
  const courseStatus = (course.executed_status === 'EXECUTED_LOW' || course.executed_status === 'EXECUTED_MEDIUM') ? executedPercent : (course.executed_status === 'EXECUTED_HIGH' ? 'Concluído' : 'Não iniciado' )
  const courseButtonLabel = (course.executed_status === 'EXECUTED_LOW' || course.executed_status === 'EXECUTED_MEDIUM') ? 'Continuar' : (course.executed_status === 'EXECUTED_HIGH' ? 'Abrir' : 'Iniciar' )

  return (

    <div key={`cKey_${course.id}`} style={{ cursor: 'pointer', display: 'flex', marginTop: 0, justifyContent: 'space-between', alignItems: 'space-between', marginBottom: 10, width: (isMobile ? '100%' : '40%'), flexDirection: 'row', backgroundColor: 'gray'}}
      onClick={handleCourseDetail}>

      {course.cover &&
          <img src={course.cover} style={{ margin: 0, width: (isMobile ? '100%' : '40%'), height: marginTopChid, position: 'absolute' }} alt="" />}

      <div  style={{display: 'flex', flexDirection: 'column', width: '70%', height: marginTopChid }}>
        <div style={{width: 8, position: 'absolute', zIndex: 10}} />

        <div style={{margin: 15, zIndex: 10}}>
          <p style={{ fontWeight: '500', fontSize: 20,  color: 'white', width: '60%' }}>{course.name}</p>

          <div style={{height: 0.5, width: '40%', backgroundColor: 'white', marginVertical: 7}} />

          {/* <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
            <FaClock name="clock-outline" size={15} color="#e1e1e1" />
            <p style={{ color: '#e1e1e1', margin: 10 }}> {course.duration}min</p>
          </div> */}

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
            <p style={{ color: '#e1e1e1' }}>{course.description}</p>
          </div>
        </div>
      </div>

      <div  style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '30%',  padding: 10, zIndex: 0 }}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <div style={{alignItems: 'center', backgroundColor: 'rgba(9,9,9, 0.3)', borderRadius: 10, padding: 5}}>
            <p style={{fontWeight: '500', color: 'white', marginBottom: 2}}>{courseStatus}</p>
            <div style={{height: 5, width: '100%', backgroundColor: 'white', marginBottom: 5}}>
              <div style={{height: 5, width: `${course.executed_percent || 0}%`, backgroundColor: 'green'}} />
            </div>

            <Button color="primary" onClick={() => handleCourseDetail()} style={{display: 'flex', flex:1, width: 150, height: 50, justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
              <p style={{fontWeight: '500', color: 'white', alignSelf: 'center', padding: 0, margin: 0}}>{courseButtonLabel}</p>
            </Button>
            {/* <div onPress={handleCourseDetail} style={{display: 'flex', height: 30, width: 100, backgroundColor: 'blue', justifyContent: 'center', alignItems: 'center'}}>

            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
