import React, {useState, useEffect} from 'react';

import { Row, Col,  Modal, Input, ModalBody, ModalFooter, Button, FormGroup, ModalHeader, Form, Label} from 'reactstrap';

export default function InfoZoneModal(props) {
  const [show, setShow] = useState(props.open);
  const [zoneInfo, setZoneInfo] = useState(props.zoneInfo);
  
  return (
    <Modal style={{maxWidth: '800px', width: 800, maxHeight: '600', height: 600}} isOpen={show} toggle={() => props.toggle(!show)}>
      <Form>
        <ModalBody>
            <Row>
              <Col lg={12}>
                <h4>{zoneInfo?.name}</h4>
              </Col>
            </Row>
            <Row>

              <Col lg={12} style={{height: 500, width: '100%'}}>
                <iframe
                style={{height: '100%', width: '100%'}}
                  className="embed-responsive-item"
                  title="video"
                  src={zoneInfo?.video}
                  allow="fullscreen"></iframe>
              </Col>
              
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={() => props.toggle(!show)}>Fechar</Button>
        </ModalFooter>
        </Form>
    </Modal>
  );
}
