import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter } from 'reactstrap';
import Switch from 'react-switch';
import moment from 'moment'
import { FaHeart, FaBars, FaArrowLeft, FaArrowRight, FaPlay, FaPause, FaCheck, FaSave, FaTimes, FaInfo, FaArrowAltCircleRight, FaWindowClose } from 'react-icons/fa';
import { setCourseFlow } from '~/store/modules/auth/actions';

import Timer from 'react-compound-timer'

import { toast } from 'react-toastify'
import ReactTimer from '@xendora/react-timer'


import {Animated} from "react-animated-css";
import history from '~/services/history';

import audioBeepEnd from '~/assets/audios/beep-end.mp3'
import audioBeepStart from '~/assets/audios/beep-start.mp3'
import audioBeepCount from '~/assets/audios/number-count.mp3'


import api from '~/services/api';

import { formatTimer, formatDateToServer, formatActivityTypeIcon } from '~/services/util';

const ms = require('pretty-ms')


export default class TrainingExecMain extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      trainingToSave: null,
      trainingStated: false,
      trainingPaused: false,
      trainingFinished: false,
      userActivityModalVisib: false,
      trainingActivityInfosModal: false,
      currentTraining: props.training,
      currentTraininGroup: props.currTraininGroup,
      currentTrainingGroupIdx: props.currTrainingGroupIdx,
      currentTraininActivity: props.currTraininActivity,
      trainingToChangeID: props.trainingToChangeID,
      currentTrainingActivityIdx: 0,
      nextTraininActivity: null,

      trainingPauseTimer: 0,
      trainingTimer: 0,
      activityTimer: 0,
      prepareTimer: 0,
      durationTimer: 0,
      lastIntervalTimer: 0,

      prepereTimerVisible: false,

      isGiveUp: false,
      toSaveUserActivity: true,
      userActivityToSave: null,
      nextTrainingGroup: null,

      pauseTrainingFunction: null,
      startTrainingFunction: null,
      actDurationTimer: false,

      //----
      time: {}, decreaseTime: 0,
      durationTime: {}, durationSegTime: 0,
      trainingTime: {}, trainingTotalTime: 0,
      audioStart: new Audio(audioBeepStart),
      audioEnd: new Audio(audioBeepEnd),
      audioCound: new Audio(audioBeepCount),

      prepareBeforeAct: false
    }

    // timer de exercicios de tempo, descanso, duracao
    this.timer = 0;

    this.prepareTimer = 0;

    // timer da atividade
    this.durationTimer = 0;

    // timer do treino
    this.trainingTimer = 0;

    // this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.durationTimerCountDown = this.durationTimerCountDown.bind(this);
  }

  async componentDidMount() {
    this._loadData()

    const userAct = {
      title: this.state.currentTraining.name,
      type: this.state.currentTraining.type,
      user_id: this.props.profile.id
    }

    await this.setState({ ...this.state, userActivityToSave: userAct });
  }

  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      // "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  _loadData = () => {
    try {
      if (this.state.currentTraininGroup) {
        let trainingGroup = { ...this.state.currentTraininGroup }
        const newActs = [...trainingGroup.trainingActivities, {id: 66666, name: 'Finalizar Série', activity_type: 'FINISH'}]
        trainingGroup = { ...trainingGroup, trainingActivities: newActs }

        const currActiv = this.state.currentTraininGroup?.trainingActivities[0];
        const nextActiv = this.state.currentTraininGroup?.trainingActivities[1];

        let nextTG = null;
        let currTGIdx = 0
        
        this.state.currentTraining.trainingGroups.forEach((tg, tgIdx ) => {
          if (tg.id === this.state.currentTraininGroup.id) {
            nextTG = this.state.currentTraining.trainingGroups[tgIdx+1];
            currTGIdx = tgIdx
            return
          }
        })

        this.setState({ ...this.state, currNextTrainingGroup: trainingGroup, currentTrainingGroupIdx: currTGIdx, currentTraininActivity: currActiv, nextTraininActivity: nextActiv, nextTrainingGroup: nextTG })
      }
    } catch (error) {
      //
    }
  }

  startTimer() {
    if (this.timer == 0 && this.state.decreaseTime > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  _playAudioEnd = async () => {
    try {
      await this.state.audioEnd.play();
      return;
    } catch (error) {
      //
    }
  }

  _playAudioStart = async () => {
    try {
      const audioInterval = setInterval(() => {
        this.state.audioStart.play();
        clearInterval(audioInterval);
      }, 1000);
      return;
    } catch (error) {
      //
    }
  }

  _playAudioNumCount = async () => {
    try {
      await this.state.audioCound.play();
      return;
    } catch (error) {
      //
    }
  }

  async countDown(newActIdx) {
    // Remove one second, set state so a re-render happens.

    let seconds = this.state.decreaseTime - 1;
    if (seconds < 0) {    
      clearInterval(this.timer);

      await this.setState({...this.state, showModalTimer: true, currInitInterval: null, currInitTimer: 0, decreaseTime: 0 })

      this._changeActivity(newActIdx+1)
      await this._playAudioEnd()
    } else {
      this.setState({
        time: this.secondsToTime(seconds),
        decreaseTime: seconds,
      });
    }
  }

  _durationContext = async (newActIdx) => {
    const currAct = this.state.currentTraininGroup.trainingActivities[newActIdx]
    const nextAct = this.state.currentTraininGroup.trainingActivities[newActIdx+1]

    await this.setState({...this.state, durationTimer: 0, prepareTimer: 0, prepereTimerVisible: true, currentTraininActivity: currAct, nextTraininActivity: nextAct, decreaseTime: (currAct.duration*60) })

    await this.prepareBeforeStartActivity(newActIdx)
  }

  _restContext = async (newActIdx) => {
    const currAct = this.state.currentTraininGroup.trainingActivities[newActIdx]
    const nextAct = this.state.currentTraininGroup.trainingActivities[newActIdx+1]

    await this.setState({...this.state, durationTimer: 0, prepareTimer: 0, prepereTimerVisible: true, currentTraininActivity: currAct, nextTraininActivity: nextAct, decreaseTime: (currAct.duration*60) })

    await clearInterval(this.timer);
    this.timer = setInterval(() => this.countDown(newActIdx), 1000);
    this._startCurrLocalInterval()
  }

  _populateRuntime = async (newActIdx) => {
    const trainingToSav = { ...this.state.trainingToSave };
    let currNextTrainingGroup = null;

    trainingToSav.runtime = this.state.trainingTimer;
    trainingToSav.executed_status = 'EXECUTING'

    const taRuntime = this.state.durationSegTime;

    const currAct = this.state.currentTraininGroup.trainingActivities[newActIdx-1]

    await trainingToSav.trainingGroups?.forEach((tg, tgIdx ) => {
      if (tg.id === this.state.currentTraininGroup.id) {
        tg.trainingActivities.forEach((ta, taIdx) => {
          if (ta.id === currAct.id) {
            ta.runtime = taRuntime;
            ta.executed_status = 'EXECUTED_HIGH'
          }
        })
      }
    })

    await this.state.currentTraining.trainingGroups?.forEach((tg, tgIdx ) => {
      if (tg.id === this.state.currentTraininGroup.id) {
        tg.trainingActivities.forEach((ta, taIdx) => {
          if (ta.id === currAct.id) {
            ta.runtime = taRuntime;
            ta.executed_status = 'EXECUTED_HIGH'
          }
        })
      }
    })

    return { trainingToSave: trainingToSav };
  }

  _handleNextActivity = async () => {
    if (this.state.currentTraininActivity?.activity_type === 'DURATION') {
      return;
    }

    const nextIdx = this.state.currentTrainingActivityIdx + 1;

    this._changeActivity(nextIdx)
  }

  _changeActivity = async (newActIdx, isLast = false, isSkipSerie = false) => {
    const currAct = this.state.currentTraininGroup.trainingActivities[newActIdx]
    const nextAct = this.state.currentTraininGroup.trainingActivities[newActIdx+1]
    
    isLast = currAct?.activity_type === 'FINISH';

    const { trainingToSave  } = await this._populateRuntime(newActIdx)

    if (currAct?.activity_type === 'DURATION' && currAct?.name === 'Descanso') {
      this._restContext(newActIdx);
    } else if (currAct?.activity_type === 'DURATION') {
      this._durationContext(newActIdx);
    } else {
      await this.setState({...this.state, showModalTimer: false, currentTraininActivity: currAct, nextTraininActivity: nextAct })
      this._startCurrLocalInterval()
    }

    if (isLast || isSkipSerie) {
      let nextTrainingGroup = null;
      let nextTrainingGroupIdx = null;
      if (this.state.currentTraining.trainingGroups) {
        for (let i = 0; i < this.state.currentTraining.trainingGroups.length; i++) {

          const currTG = this.state.currentTraining.trainingGroups[i];

          if (currTG.id === this.state.currentTraininGroup.id) {
            nextTrainingGroupIdx = i+1
            nextTrainingGroup = this.state.currentTraining.trainingGroups[nextTrainingGroupIdx];

            if (!nextTrainingGroup) {
              clearInterval(this.trainingInterval)
              clearInterval(this.durationInterval)
              
              await this.setState({ ...this.state, trainingFinished: true })
            }
            break;
          }
        }
      }
    } else {
      if (this.props.goToActivity) {
        this.props.goToActivity(this.state.currentTraininGroup, this.state.currentTrainingGroupIdx, nextAct, (newActIdx+1))
      }
      this.setState({ ...this.state, currentTrainingActivityIdx: newActIdx, trainingTimer: 0, trainingToSave: trainingToSave })
    }
  }

  _finishSerie = async () => {
    let { trainingToSave, currNextTrainingGroup, currNextTrainingActivity, currNextTrainingGroupIdx } = await this._populateRuntimeInFinish('EXECUTING');

    if (this.props.goToActivity && currNextTrainingGroup) {
      const trainingToUpdate = {
        training: trainingToSave,
        trainingStatus: 'EXECUTING' };

      this._updateTrainingMetrics(trainingToUpdate)
    
      const newCurrAct = currNextTrainingGroup.trainingActivities[0]
      const newNextAct = currNextTrainingGroup.trainingActivities[1]

      const newActs = [...currNextTrainingGroup.trainingActivities, {id: 66666, name: 'Finalizar Série', activity_type: 'FINISH'}]
      currNextTrainingGroup = { ...currNextTrainingGroup, trainingActivities: newActs }

      this.props.goToActivity(currNextTrainingGroup, currNextTrainingGroupIdx, newNextAct, 0)

      this.setState({ ...this.state, currentTraininGroup: currNextTrainingGroup, currentTrainingGroupIdx: currNextTrainingGroupIdx, currentTrainingActivityIdx: 0, currentTraininActivity: newCurrAct, nextTraininActivity: newNextAct, trainingToSave: trainingToSave })
    }  else {
      //
    }
  }

  async trainingTimerCountDown() {
    let seconds = this.state.trainingTotalTime + 1;

    this.setState({
      trainingTime: this.secondsToTime(seconds),
      trainingTotalTime: seconds,
    });
  }

  prepareBeforeStartActivity = async (newActIdx) => {
    //setar time especifico

    this.setState({
      ...this.state,
      prepareBeforeAct: true,
      prepareTimer: 3
    });

    //start audio countdown
    await this._playAudioNumCount()
    
    const interval = setInterval(async () => {
      if (this.state.prepareTimer <= 1) {
        // start audio act beep
        await this._playAudioStart()

        clearInterval(interval)

        this.setState({
          ...this.state,
          prepareBeforeAct: false,
          prepareTimer: this.state.prepareTimer - 1
        });
        
        //start principal
        clearInterval(this.timer);
        this.timer = setInterval(() => this.countDown(newActIdx), 1000);
        this._startCurrLocalInterval()
      } else {
        this.setState({ ...this.state, prepareTimer: this.state.prepareTimer - 1 });
      }
    }, 1000);
  }

  _startInterval = async (toRefresh=true) => {

    if (toRefresh) {
      this.setState({
        trainingTime: this.secondsToTime(0),
        trainingTotalTime: 0,
        trainingStated: true,
      });
      if (this.props.startTrainingFunc) {
        this.props.startTrainingFunc(true)
      }
    }

    await clearInterval(this.trainingTimer);
    this.trainingTimer = setInterval(() => this.trainingTimerCountDown(), 1000);
  }  

  async durationTimerCountDown() {
    let seconds = this.state.durationSegTime + 1;

    this.setState({
      durationTime: this.secondsToTime(seconds),
      durationSegTime: seconds,
    });
  }

  _startCurrLocalInterval = async (refreshData=true) => {
    if (refreshData) {
      this.setState({
        durationTime: this.secondsToTime(0),
        durationSegTime: 0,
      });
    }

    await clearInterval(this.durationTimer);
    this.durationTimer = setInterval(() => this.durationTimerCountDown(), 1000);
  }

  _handleInitActivityNow = async () => {
    await this._startInterval();

    const currAct = this.state.currentTraininGroup?.trainingActivities[0]
    const nextAct = this.state.currentTraininGroup?.trainingActivities[1]

    if (currAct && currAct.activity_type === 'DURATION') {
      this._durationContext(0)
    } else {
      await this.setState({...this.state, currentTraininActivity: currAct, nextTraininActivity: nextAct })
      await this._startCurrLocalInterval();
      // playAudioStart()
    }

    const trainingToSave = {id : this.state.currentTraining.id, runtime: this.state.currentTraining.runtime, executed_status: this.state.currentTraining.executed_status, trainingGroups: []}

    await this.state.currentTraining.trainingGroups.forEach(tg => {
      const currTG = { id: tg.id, runtime: tg.runtime, executed_status: tg.executed_status, trainingActivities: [] }

      tg.trainingActivities.forEach( ta => {
        const currTA = { id: ta.id, runtime: ta.runtime, executed_status: ta.executed_status }
        currTG.trainingActivities.push(currTA);
      });

      trainingToSave.trainingGroups.push(currTG)
    });

    this.setState({ ...this.state, trainingToSave })
  }

  _formatTimer = (timerObj) => {
    if (timerObj && timerObj.timer) {
      const { timer } = timerObj;

      const { minutes, seconds } = formatTimer(timer.toFixed(0))

      return `${minutes}:${seconds}`;
    }
  }

  _populateRuntimeInFinish = async (trainingExecutedStatus) => {
    const trainingToSave = { ...this.state.trainingToSave };

    let currNextTrainingGroup = null;
    let currNextTrainingGroupIdx = null;
    let currTrainingActivity = null;
    let currNextTrainingActivity = null;

    const totalRuntime = (this.state.trainingTime.m * 60) + this.state.trainingTime.s

    trainingToSave.runtime = totalRuntime;
    trainingToSave.executed_status = trainingExecutedStatus

    await trainingToSave.trainingGroups.forEach((tg, tgIdx ) => {
      if (tg.id === this.state.currentTraininGroup.id) {
        tg.executed_status = 'EXECUTED_HIGH'
        const runtimeTotal = this.state.trainingTimer - this.state.lastIntervalTimer
        tg.runtime = runtimeTotal;
      }
    })

    this.state.currentTraining.executed_status = trainingExecutedStatus

    const currentTrainingLocal = { ...this.state.currentTraining }

    await currentTrainingLocal.trainingGroups.forEach((tg, tgIdx ) => {
      if (tg.id === this.state.currentTraininGroup.id) {
        tg.executed_status = 'EXECUTED_HIGH'
        const runtimeTotal = this.state.trainingTimer - this.state.lastIntervalTimer
        tg.runtime = runtimeTotal;

        currNextTrainingGroupIdx = tgIdx+1

        currNextTrainingGroup = this.state.currentTraining.trainingGroups[currNextTrainingGroupIdx];
        currTrainingActivity = currNextTrainingGroup?.trainingActivities[0]
        currNextTrainingActivity = currNextTrainingGroup?.trainingActivities[1]
      }
    })

    this.setState({ ...this.state, currentTraining: currentTrainingLocal })

    return { trainingToSave, currNextTrainingGroup, currNextTrainingActivity,  currTrainingActivity, currNextTrainingGroupIdx };
  }

  _updateTrainingMetrics = async (trainingToSave) => {
    if (trainingToSave) {
      const resp = await api.post(`/trainings/${trainingToSave.training.id}/update-metrics`, trainingToSave);

      if (resp.status === 200) {
        if (trainingToSave.training?.executed_status === 'EXECUTING') {
          toast.success('Série finalizada com sucesso!')
        } else {
          toast.success('Treino finalizado com sucesso!')
        }
      } else {
        toast.warn('Problema ao salvar atividade')
      }

    }
  }

  _handleFinishTraining = async (giveUpDestination = false, goOut = false) => {
    if (this.state.isGiveUp) {
      //
    } else {
      const { trainingToSave } = await this._populateRuntimeInFinish('EXECUTED_HIGH');
      this.finishTraining(trainingToSave, 'EXECUTED_LOW');
    }
  }

  finishTraining = async (trainingToSave, trainingStatus='EXECUTED_HIGH') => {
    const trainingToUpdate = {
      training: trainingToSave,
      from: 'EXPLORER',
      trainingStatus
    };
    
    if (this.state.trainingToChangeID && this.state.trainingToChangeID > 0) {
      trainingToUpdate.defaultTrainingID = this.state.trainingToChangeID
    }

    if (this.state.toSaveUserActivity) {
      const newStatDate = formatDateToServer(moment(trainingToSave.due_date).toDate())

      const actObj = {
        title: this.state.userActivityToSave.title || trainingToSave.name,
        type: trainingToSave.type,
        start_date: moment(newStatDate).format('YYYY-MM-DD HH:mm:ss'),
        user_id: this.props.profile.id,
        calorie: this.state.userActivityToSave.calorie,
        average_heartrate: this.state.userActivityToSave.average_heartrate,
        heart_rate: this.state.userActivityToSave.average_heartrate,
        max_heartrate: this.state.userActivityToSave.max_heartrate,
        comments: this.state.userActivityToSave.comments
      }

      trainingToUpdate.userActivity = actObj
    }

    this._updateTrainingMetrics(trainingToUpdate)

    if (this.props.exitTraining){
      this.props.exitTraining()
    }
  }

  _handleContinueSerie = async () => {
    //
  }

  _formatTrainingTimer = (timer) => {
    try {
      if (this.state.trainingTimer) {
        const { minutes, seconds } = formatTimer(this.state.trainingTimer)
        return `${minutes}:${seconds}`
      } else {
        return '00:00'
      }
    } catch (error) {
      return '00:00'
    }
  }

  _handleActivityInfoModal = (activity) => {
    //
  }

  _handleSkipSerie = async () => {
    if (this.props.training.trainingGroups) {
      for (let i = 0; i < this.props.training.trainingGroups.length; i++) {

        const currTG = this.props.training.trainingGroups[i];

        if (currTG && currTG.id === this.state.currentTraininGroup?.id) {
          const nextTgIdx = i+1;
          let nextTrainingGroup = this.props.training.trainingGroups[nextTgIdx];

          if (nextTrainingGroup) {
            const newCurrAct = nextTrainingGroup.trainingActivities[0]
            const newNextAct = nextTrainingGroup.trainingActivities[1]
      
            const newActs = [...nextTrainingGroup.trainingActivities, {id: 66666, name: 'Finalizar Série', activity_type: 'FINISH'}]
            nextTrainingGroup = { ...nextTrainingGroup, trainingActivities: newActs }
      
            this.props.goToActivity(nextTrainingGroup, nextTgIdx, newNextAct, 0)
      
            this.setState({ ...this.state, currentTraininGroup: nextTrainingGroup, currentTrainingGroupIdx: nextTgIdx, currentTrainingActivityIdx: 0, currentTraininActivity: newCurrAct, nextTraininActivity: newNextAct })
          }
          break;
        }
      }
    }
  }

  _handleTrainingInfos = () => {
    this.setState({ ...this.state, trainingActivityInfosModal: !this.state.trainingActivityInfosModal})
  }

  _handleLeaveTraining = () => {
    if (this.props.exitTraining) {
      this.props.exitTraining()
    }
  }

  _handleOpenUActModal = () => {
    this.setState({ ...this.state,  userActivityModalVisib: !this.state.userActivityModalVisib })
  }

  _handlePauseActivity = async () => {
    await clearInterval(this.timer)
    await clearInterval(this.trainingTimer)
    await clearInterval(this.durationTimer)

    this.setState({ ...this.state,  optionsContainerVisible: !this.state.optionsContainerVisible, trainingPaused: true })
  }
  

  _handleContinueActivity = async () => {
    if (this.state.currentTraininActivity?.activity_type === 'DURATION') {
      await clearInterval(this.timer);
      this.timer = setInterval(() => this.countDown(this.state.currentTrainingActivityIdx), 1000);
    }

    this._startCurrLocalInterval(false)
    this._startInterval(false)

    this.setState({ ...this.state,  optionsContainerVisible: false, trainingPaused: false })
  }

  render() {
    let speedLabel = ''

    if (this.state.currentTraininActivity?.activity_type !== 'DURATION' && this.state.currentTraininActivity?.execution_speed) {
      speedLabel = 'Rápido'
      if (this.state.currentTraininActivity?.execution_speed === 2) {
        speedLabel = 'Moderado'
      } else if (this.state.currentTraininActivity?.execution_speed === 1) {
        speedLabel = 'Lento'
      }
    }

    
    return (
      <>
        {!this.state.trainingStated &&
          <main style={{ width: '100%', padding: 10}}>
              <div className="btn-toggle" onClick={() => this.props.handleCollapsedChange(!this.props.collapsed)} style={{width: 60}}>
                <FaBars />
              </div>
              <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                  <Card style={{marginTop: 5, borderRadius: 30}}>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <div style={{display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', paddingVertical: 30}}>
                              <p style={{fontSize: 15, marginTop: 20}}>Tudo pronto para começar?</p>
                              <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Button onClick={this._handleInitActivityNow} style={{height: 55, marginBottom: 10, width: '20%',  backgroundColor: 'green', justifyContent: 'center', alignItems: 'center'}}>
                                  <p style={{color: 'white', fontWeight: '600', fontSize: 15}}>INICIAR</p>
                                </Button>
                              </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={1}></Col>
              </Row>
            </main>
        } 
        {(this.state.trainingFinished || this.state.isGiveUp) && 
            <main style={{ width: '100%', padding: 10}}>
              <div className="btn-toggle" onClick={() => this.props.handleCollapsedChange(!this.props.collapsed)} style={{width: 60}}>
                <FaBars />
              </div>
              <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                  <Card style={{marginTop: 5, borderRadius: 30}}>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <div style={{display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', paddingVertical: 30}}>
                              {!this.state.isGiveUp &&
                                <p style={{fontSize: 15, marginBottom: 10, fontWeight: '500'}}>PARABÉNS!!</p>}
      
                              <p style={{fontSize: 25, fontWeight: '500'}}>{this.state.currentTraininGroup?.name}</p>
                              <p style={{fontSize: 15, marginTop: 20}}>FINALIZADO</p>
      
                              <p style={{fontSize: 50, fontWeight: '500', alignSelf: 'center'}}>{this._formatTimer({timer: 0})}</p>
      
                              <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {this.state.isGiveUp &&
                                  <Button onClick={this._handleContinueSerie} color="success" style={{height: 55, width: 200, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span style={{color: 'white', fontWeight: '600', fontSize: 15}}>CONTINUAR TREINO</span>
                                  </Button>}

                                {!this.state.isGiveUp &&
                                  <Card
                                    style={{ minWidth: 500, padding: 10, cursor: 'pointer' }}
                                  onClick={() => {
                                    this._handleOpenUActModal()
                                  }}>
                                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <p style={{fontSize: 18, color: '#003399', textAlign: 'center', height: 'auto' }}>Salvar Atividade</p>
                                  </div>
                                  {this.state.toSaveUserActivity &&
                                    <>
                                      <div style={{ display: 'flex',  marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <p style={{fontSize: 15, color: '#010101'}}>{this.state.userActivityToSave?.title}</p>
                                        {this.state.userActivityToSave && <img alt=""
                                          style={{ width: 18, marginHorizontal: 10 }}
                                          source={formatActivityTypeIcon(this.state.userActivityToSave?.type)} />}
                                      </div>
                    
                                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginBottom: 10}}>
                                        <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center'}}>
                                          <p style={{fontSize: 15, marginTop: 10, color: '#a9a9a9'}}>FC Média</p>
                                          <p style={{fontSize: 15, marginTop: 5, color: '#010101'}}>{this.state.userActivityToSave?.average_heartrate ? this.state.userActivityToSave.average_heartrate : '--'}</p>
                                        </div>
                                        
                                        <div style={{height: 60, width: 1, backgroundColor: '#a9a9a9', borderRadius:1}}></div>

                                        <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center'}}>
                                          <p style={{fontSize: 15, marginTop: 10, color: '#a9a9a9'}}>FC Máxima</p>
                                          <p style={{fontSize: 15, marginTop: 5, color: '#010101'}}>{this.state.userActivityToSave?.max_heartrate ? this.state.userActivityToSave.max_heartrate : '--'}</p>
                                        </div>
                                        
                                        <div style={{height: 60, width: 1, backgroundColor: '#a9a9a9', borderRadius:1}}></div>

                                        <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center'}}>
                                          <p style={{fontSize: 15, marginTop: 10, color: '#a9a9a9'}}>Calorias</p>
                                          <p style={{fontSize: 15, marginTop: 5, color: '#010101'}}>{this.state.userActivityToSave?.calorie ? this.state.userActivityToSave.calorie : '--'}</p>
                                        </div>
                                      </div>
                    
                                      <div style={{  display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                          <p style={{fontSize: 18, color: '#003399', textAlign: 'center', height: 'auto' }}>Preencher métricas</p>
                                      </div>
                                    </>
                                    }
                                </Card>}
      
                                {this.state.isGiveUp ?
                                  <Button outline color="danger" onClick={async () =>
                                  {
                                    //
                                  }} style={{height: 55, width: 200, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                      <p style={{}}>ABANDONAR TREINO</p>
                                  </Button>
                                :
                                  <Button color="success" onClick={ () => this._handleFinishTraining() } style={{height: 55, marginVertical: 10, width: '40%', justifyContent: 'center', alignItems: 'center'}}>
                                    <span style={{fontWeight: '600', fontSize: 15}}>SALVAR TREINO</span>
                                  </Button> }
      
                              </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={1}></Col>
              </Row>
            </main>}

          {!this.state.trainingFinished && this.state.trainingStated && this.state.currentTraininActivity?.activity_type === 'FINISH' &&
              <main style={{ width: '100%', padding: 10}}>
              <div className="btn-toggle" onClick={() => this.props.handleCollapsedChange(!this.props.collapsed)} style={{width: 60}}>
                <FaBars />
              </div>
              <Row>
                <Col lg={1}></Col>
                <Col lg={10}>
                  <Card style={{marginTop: 5, borderRadius: 30}}>
                    <CardBody>
                      <Row>
                        <Col lg={12}>
                          <div style={{display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', paddingVertical: 30}}>
                              {!this.state.isGiveUp &&
                                <p style={{fontSize: 15, marginBottom: 10, fontWeight: '500'}}>PARABÉNS!</p>}
      
                              <p style={{fontSize: 25, fontWeight: '500'}}>{this.state.currentTraininGroup?.name}</p>
                              <p style={{fontSize: 15, marginTop: 20}}>FINALIZADO</p>
      
                              <p style={{fontSize: 50, fontWeight: '500', alignSelf: 'center'}}>{this._formatTimer({timer: 0})}</p>
      
                              <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {this.state.isGiveUp &&
                                  <Button onClick={this._handleContinueSerie} color="success" style={{height: 55, width: 200, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <span style={{color: 'white', fontWeight: '600', fontSize: 15}}>CONTINUAR TREINO</span>
                                  </Button>}
      
      

                                  <Button onClick={this._finishSerie} color="success" style={{height: 55, width: 200, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <p style={{color: 'white', fontWeight: '600', fontSize: 15}}>CONTINUAR TREINO</p>
                                  </Button>
                              </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={1}></Col>
              </Row>
            </main>
          }

          {this.state.trainingStated && !this.state.trainingFinished && this.state.currentTraininActivity?.activity_type !== 'FINISH' && 
              <main style={{ width: '100%', padding: 10}}>
                <div className="btn-toggle" onClick={() => this.props.handleCollapsedChange(!this.props.collapsed)} style={{width: 60}}>
                  <FaBars />
                </div>
                <Row>
                  <Col lg={1}></Col>
                  <Col lg={10}>
                    <Card style={{marginTop: 5, borderRadius: 30}}>
                      <CardBody>
                        <Row>
                          <Col lg={7}>
                            <h1>
                              {this.state.currentTraininActivity?.name}
                            </h1>

                            {/* {this.state.currentTraininActivity?.activity_type !== 'DURATION' && 
                              <Row>
                                <Col lg={6}>
                                  <p>Repetições: <span style={{fontWeight: '500', fontSize: 20}}>10</span></p>
                                </Col>
                                <Col lg={6}>
                                  <p>Velocidade: <span style={{fontWeight: '500', fontSize: 20}}>Moderado</span></p>
                                </Col>
                              </Row> } */}

                              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#e1e1e1'}}>
                                <img style={{maxWidth:'100%', height: 'auto'}} src={this.state.currentTraininActivity?.media_link} alt="Mídia Exemplificando" />
                              </div>
                          </Col>
                          <Col lg={5} style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'row'}}>
                            <div style={{width: 2, height: '100%', backgroundColor: '#a1a1a1', borderRadius:1}}></div>
                            
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column', height: '100%', flex: 1}}>
                              
                              
                              
                                <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', height: 'auto' }}>
                                  <h4>Tempo total do treino</h4>
                                  <div style={{height: 1, width: '80%', backgroundColor: '#e1e1e1', borderRadius:1, marginTop: 10 }}></div>

                                  {(this.state.trainingTime && this.state.trainingTime.s) ?
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                      <h1 style={{fontWeight: '600', fontSize: 55 }}>
                                        {`${this.state.trainingTime?.m < 10 ? `0${this.state.trainingTime?.m}` : this.state.trainingTime?.m}:${this.state.trainingTime?.s < 10 ? `0${this.state.trainingTime?.s}` : this.state.trainingTime?.s}` }
                                      </h1>

                                      <Button 
                                        color={this.state.trainingPaused ? 'success' : 'secondary'}
                                        onClick={() => {
                                          if (this.state.trainingPaused) {
                                            this._handleContinueActivity()
                                          } else {
                                            this._handlePauseActivity()
                                          }
                                          }} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', height: 45, width: 45, marginLeft: 20 }}>
                                            {this.state.trainingPaused ?
                                            <FaPlay size={15} />
                                            :
                                            <FaPause size={15} />}
                                        
                                        
                                      </Button>
                                    </div>
                                    :
                                      <h1 style={{fontWeight: '600', fontSize: 55 }}>00:00</h1>
                                    }
                                  <div style={{height: 1, width: '80%', backgroundColor: '#e1e1e1', borderRadius:1, marginBottom: 15}}></div>
                                </div>


                              
                              {this.state.trainingPaused &&
                                <div style={{ display: 'flex', flex:1, width: '80%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                  {this.state.currentTraininActivity?.tuto_link && 
                                    <Button size="sm" color="primary" style={{width: '100%', height: 45, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} onClick={this._handleTrainingInfos}>
                                      <FaInfo color="white" size={16} />&nbsp;&nbsp;
                                      <span style={{fontWeight: '500', fontSize: 18}}>Explicações</span>
                                    </Button>}

                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginTop: 5}}>
                                      <Button outline size="sm" color="danger" onClick={this._handleLeaveTraining} style={{width: '45%', height: 45, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} >
                                        <FaWindowClose color="white" size={16} />&nbsp;&nbsp;
                                        <span style={{fontWeight: '500', fontSize: 15}}>Abandonar Treino</span>
                                      </Button>
                                      <Button outline size="sm" color="secondary" onClick={this._handleSkipSerie} style={{width: '45%', height: 45, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}} >
                                      <span style={{fontWeight: '500', fontSize: 15}}>Pular Série</span>&nbsp;&nbsp;
                                        <FaArrowAltCircleRight color="white" size={16} />
                                      </Button>
                                    </div>
                                </div>}


                              <div style={{ display: 'flex', flex:2, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                {this.state.currentTraininActivity?.activity_type === 'DURATION' ? 
                                    <>
                                      {this.state.prepareBeforeAct &&
                                            <>
                                              <h3>Preparar!</h3>
                                              <span>
                                                <span style={{fontWeight: '700', fontSize: 70, color: 'black'}}>{this.state.prepareTimer}</span>seg
                                              </span>
                                            </>
                                      }

                                      {!this.state.prepareBeforeAct && this.state.time && this.state.time.s && this.state.time.s !== 0 &&
                                        <span>
                                          <span style={{fontWeight: '700', fontSize: 70, color: 'black'}}>{this.state.time.s}</span>seg
                                        </span>}
                                    </>
                                  :
                                  <div>
                                    <p>
                                      <span style={{fontWeight: '700', fontSize: 70, color: 'black' }}>{this.state.currentTraininActivity?.repetition}</span> x
                                    </p>
                                    <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                      <span>Velocidade</span>
                                      <span style={{fontWeight: '500', fontSize: 18, color: 'black' }}>{speedLabel}</span>
                                    </span>
                                  </div>
                                  }
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={1}></Col>
                </Row>
                
                <Row>
                  <Col lg={12} style={{ display: 'flex', justifyContent:  'flex-end'}}>
                    <Card onClick={() => this._handleNextActivity()} style={{marginTop: 5, borderRadius: 30, cursor: 'pointer', width: '25%', marginRight: '8%' }} className="shadow">
                      <CardBody>
                        <Row>
                          <Col style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 5 }}>
                            <span style={{fontWeight: '500', fontSize: 18, marginRight: 5 }}>Próximo</span>
                            <FaArrowRight size={20} />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={8}>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                              <p style={{fontWeight: '500', fontSize: 15, width: '100%' }}>{this.state.nextTraininActivity?.name}</p>
                              <p style={{fontWeight: '400', fontSize: 15 }}>
                                {/* {this.state.currentTraininActivity?.activity_type !== 'FINISH' ? (this.state.currentTraininActivity?.activity_type === 'DURATION' ? `${(this.state.nextTraininActivity?.duration*60).toFixed(0)}seg` : `${this.state.nextTraininActivity?.repetition}x`) : ''} */}
                                {this.state.nextTraininActivity?.activity_type !== 'FINISH' ? (this.state.nextTraininActivity?.activity_type === 'DURATION' ? `${(this.state.nextTraininActivity?.duration*60).toFixed(0)}seg` : `${this.state.nextTraininActivity?.repetition}x`) : ''}
                              </p>

                            </div>
                          </Col>

                          <Col lg={4} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            {this.state.currentTraininActivity?.activity_type !== 'FINISH' && this.state.nextTraininActivity?.thumb_link ?
                              <div style={{ margin: 6, width: 100, height: 100 }}>
                                <img style={{maxWidth: '100%', height: 'auto'}} src={this.state.nextTraininActivity?.thumb_link} alt="Mídia Exemplificando" />
                              </div>
                              :
                              <div style={{height: 80, width: 80, backgroundColor: '#a1a1a1' }} /> }
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={1}></Col>
                </Row>
              </main>}

          <Modal isOpen={this.state.userActivityModalVisib} toggle={this._handleOpenUActModal}>
            <ModalHeader toggle={this._handleOpenUActModal} close={<button className="close" onClick={this._handleOpenUActModal}>&times;</button>}>Salvar Atividade</ModalHeader>
            <ModalBody>
                {/* {loading && <Loader />} */}
                <Form>
                    <FormGroup>
                        <Label for="title">Título</Label>
                        <Input
                            defaultValue={this.state.userActivityToSave?.title}
                            onChange={e => this.setState({ ...this.state, userActivityToSave: { ...this.state.userActivityToSave, title: e.target.value } })}
                            type="text" name="title" id="title" placeholder="Dê um título para a atividade" />
                    </FormGroup>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="average_heartrate">FC Média</Label>
                                <Input
                                  onChange={e => this.setState({ ...this.state, userActivityToSave: { ...this.state.userActivityToSave, average_heartrate: e.target.value } })}
                                  type="number" name="average_heartrate" id="average_heartrate" placeholder="bpm" />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="max_heartrate">FC Máxima</Label>
                                <Input
                                  onChange={e => this.setState({ ...this.state, userActivityToSave: { ...this.state.userActivityToSave, max_heartrate: e.target.value } })}
                                  type="number" name="max_heartrate" id="max_heartrate" placeholder="bpm" />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="calorie">Calorias</Label>
                        <Input
                          onChange={e => this.setState({ ...this.state, userActivityToSave: { ...this.state.userActivityToSave, calorie: e.target.value } })}
                          type="number" name="calorie" id="calorie" placeholder="kcal" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="comments">Observações</Label>
                        <Input
                          onChange={e => this.setState({ ...this.state, userActivityToSave: { ...this.state.userActivityToSave, comments: e.target.value } })}
                          type="textarea" name="comments" id="comments" />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-bordered-primary" onClick={this._handleOpenUActModal}>Cancelar</button>&nbsp;
                <button className="btn btn-bordered-success" onClick={() => {
                  this._handleOpenUActModal()
                }}>OK</button>
            </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.trainingActivityInfosModal} className="modal-dialog modal-lg" toggle={() => this.setState({ ...this.state, trainingActivityInfosModal: !this.state.trainingActivityInfosModal})}>
            <ModalHeader 
                toggle={() => this.setState({ ...this.state, trainingActivityInfosModal: !this.state.trainingActivityInfosModal})}
                close={<button className="close" onClick={() => this.setState({ ...this.state, trainingActivityInfosModal: !this.state.trainingActivityInfosModal})}>&times;</button>}>Explicações do exercício</ModalHeader>
            <ModalBody>
              <Row>
                <Col sm="12">
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', flex: 1, padding: 20}}>
                  
                    <h4 style={{ color: '#a1a1a1' }}>{this.state.currentTraininActivity?.name}</h4>

                    <p style={{ color: '#a1a1a1' }}>{this.state.currentTraininActivity?.description}</p>
                    
                    {this.state.currentTraininActivity?.tuto_link ?
                      <div className="embed-responsive embed-responsive-16by9">
                          <iframe className="embed-responsive-item" title="video" src={this.state.currentTraininActivity?.tuto_link} allow="fullscreen"></iframe>
                      </div>
                    :
                    <p>Este exercício não possui vídeo de explicações cadastrado.</p>}
                  </div>
                </Col>
              </Row>  
            </ModalBody>
            <ModalFooter>
                {/* <button className="btn btn-bordered-primary" onClick={() => this.setState({ ...this.state, trainingActivityInfosModal: !this.state.trainingActivityInfosModal})}>Cancelar</button>&nbsp; */}
                <button className="btn btn-bordered-success" onClick={() => {
                  this.setState({ ...this.state, trainingActivityInfosModal: !this.state.trainingActivityInfosModal})
                }}>Fechar</button>
            </ModalFooter>
        </Modal>
      </>
    )
  }

};
