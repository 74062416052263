import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Card, CardBody, Button } from 'reactstrap';

import { signInRequest } from '~/store/modules/auth/actions';
import Loader from '~/components/Loader/Loader';

import TrainingListItem from '../TrainingListItem/TrainingListItem'
import TrainingsOnboardModal from '../../onBoards/TrainingsOnboardModal'

import { FaInfo } from 'react-icons/fa';

import api from '~/services/api';
import queryString from 'query-string';

class WeightTrainingScreen extends Component {
    constructor(props) {
        super(props);

        const urlParsed = queryString.parse(this.props.location.search);

        this.state = {
            loading: true,
            allTrainings: null,
            trainings: null,
            filterType: urlParsed.type,
            trainingToChange: urlParsed.rt,
            trainingOnBoardFlow: false,
        }
    }

    loadtrainings = async () => {
        const urlParsed = queryString.parse(this.props.location.search);

        if (this.state.allTrainings) {
            const trainings = this.state.allTrainings.filter((item) => item.type === this.state.filterType)
    
            this.setState({...this.state, loading: false, trainings: trainings })
        } else {
            await Promise.all([ api.get(`/trainings?training_type=2&limit=25`) ]).then(async ([exploreTrainings]) => {
        
            if (exploreTrainings.status === 200) {
                const trainings = exploreTrainings.data.filter((item) => item.type === this.state.filterType)
    
                this.setState({...this.state, loading: false, trainings: trainings, allTrainings: exploreTrainings.data })
            } else {
                this.setState({...this.state, loading: false  })
            }
    
            this.verifyTrainingOnboard();
            });
        }
    }

    async componentDidMount() {
        this.loadtrainings()
    }

    verifyTrainingOnboard = () => {
        if (this.state.filterType === 'WeightTraining') {
            this.setState({...this.state, trainingOnBoardFlow: !this.props.onboardStatuses?.weightTraining  })
        } else if (this.state.filterType === 'Drills') {
            this.setState({...this.state, trainingOnBoardFlow: !this.props.onboardStatuses?.drills  })
        } else if (this.state.filterType === 'Cardio') {
            this.setState({...this.state, trainingOnBoardFlow: !this.props.onboardStatuses?.cardio  })
        }
    }


    render() {
        return (
          <React.Fragment>
                  { /* preloader */}
                  {false && <Loader />}
      
                  <Row>
                      <Col>
                          <div className="page-title-box">
                              <Row>
                                  <Col lg={7}>
                                      <h4 className="page-title">Treinos</h4>
                                  </Col>
                                  <Col lg={5} className="mt-lg-3 mt-md-0">
                                      
                                  </Col>
                              </Row>
                          </div>
                      </Col>
                  </Row>
                  <Row>
                      <Col lg={12}>
                          <Card>
                              <CardBody>
                                    <div style={{width: '100%', height: 'auto', paddingBottom: 20, display: 'flex', alignItems: 'space-between', justifyContent: 'space-between' }}>
                                        <p style={{ fontWeight: '400', color: 'gray'}}>Séries para você ter músculos bem preparados para a corrida.</p>
                                        <Button color="primary" style={{borderRadius: '50%', width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        onClick={() => {
                                            this.setState({ ...this.state, trainingOnBoardFlow: !this.state.trainingOnBoardFlow })
                                            }}>
                                                <FaInfo />
                                        </Button>
                                    </div>
                                    

                                    {this.state.loading && <Loader size="large" color={'gray'} style={{marginTop: 20}} />}
                                    {!this.state.loading && (!this.state.trainings || this.state.trainings.length <= 0) &&
                                        (<div style={{ backgroundColor: '#f9f9f9', boxShadow: '0 5px 15px rgba(0, 0, 0, 0.1)', paddingBottom: 10, marginTop: 10 }}>
                                        <p style={{ margin: 10, alignSelf: 'center', fontWeight: '400', fontSize: 15, color: 'blue', }} >
                                            Nenhum treino encontrado.
                                        </p>
                                        <p style={{ marginHorizontal: 10, marginTop: 5, alignSelf: 'center', fontSize: 12, color: 'blue', }} >
                                            Aguarde, em breve novos treinos disponíveis aqui!
                                        </p>
                                        </div>)
                                    }

                                    {!this.state.loading && this.state.trainings && this.state.trainings.length > 0 &&
                                    this.state.trainings.map((training, tIdx) => {
                                        return (
                                            <TrainingListItem key={`t${tIdx}`} training={training} profile={this.props.profile} trainingToChange={this.state.trainingToChange} />
                                        )
                                    })}
                              </CardBody>
                          </Card>
                      </Col>
                  </Row>

                  {this.state.trainingOnBoardFlow &&
                    <TrainingsOnboardModal open={this.state.trainingOnBoardFlow} trainingType={this.state.filterType} toggle={() => this.setState({ ...this.state, trainingOnBoardFlow: !this.state.trainingOnBoardFlow })} />}
          </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.user.profile,
        onboardStatuses: state.user.onboardStatuses
    }
};
export default connect(mapStateToProps, { signInRequest })(WeightTrainingScreen);