import React from 'react'

import { FormGroup, Label, Input } from 'reactstrap'

import DatePicker from 'react-datepicker'

import moment from 'moment'

import { formatDuration } from '~/services/util'

export default function Distance({
    selectedSchema,
    step,
    setValueForEnableNextButton,
    handleSetDistance,
    distance,
    setDistanceHistory,
    distanceHistory,
    setKmTime,
    setKmDate,
    currentPractice,
    setCurrentPractice,
    handleTargetDate,
    startAt,
    handleStartAt,
    setDaysWeeklyWorkout,
    daysWeeklyWorkout,
    setHeight,
    height,
    setWeight,
    weight,
    setMHistoryNotes,
    setGeneralNotes,
    TimeInput,
    minStartAt,
    minTargetDate,
    targetDate,
    maxStartAt,
    maxTargetDate,
    maxWeek,
    minWeek,
    mHistoryNotes,
    generalNotes,
    profile
}) {
    return (
        <>
            {selectedSchema.type === 'DISTANCE' && step.order === 1 &&
                <div className="mt-5">
                    <h4>{step.title}:</h4>

                    <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'auto' }}>
                        {step.data.length > 0 &&
                            step.data.map(opt => (
                                <div
                                    key={opt.label}
                                    onClick={() => setValueForEnableNextButton(() => handleSetDistance(opt.value))}
                                    style={{ border: `${distance === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 8, borderRadius: 8, marginLeft: 8 }}>
                                    <input
                                        type="radio"
                                        name={opt.label}
                                        value={opt.value}
                                        checked={distance === opt.value}
                                        onChange={e => handleSetDistance(parseInt(e.target.value))} />

                                    <h5 className="ml-2">{opt.label}</h5>
                                </div>
                            ))}
                    </div>
                </div>
            }

            {selectedSchema.type === 'DISTANCE' && step.order === 2 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {step.data.length > 0 &&
                            step.data.map(opt => (
                                <div
                                    className="mt-2"
                                    key={opt.label}
                                    onClick={() => setValueForEnableNextButton(() => setDistanceHistory(opt.value))}
                                    style={{ border: `${distanceHistory === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                                    <input
                                        type="radio"
                                        name={opt.label}
                                        value={opt.value}
                                        checked={distanceHistory === opt.value}
                                        onChange={e => setDistanceHistory(e.target.value)} />

                                    <h5 className="ml-2">{opt.label}</h5>
                                </div>
                            ))}
                    </div>
                </div>
            }

            {selectedSchema.type === 'DISTANCE' && step.order === 3 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {step.data.length > 0 &&
                            step.data.map(opt => {
                                if (opt.distance === distance) {
                                    return (
                                        <div
                                            className="mt-2"
                                            key={opt.label}
                                            onClick={() => setValueForEnableNextButton(() => setCurrentPractice(opt.value))}
                                            style={{ border: `${currentPractice === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                                            <input
                                                type="radio"
                                                name={opt.label}
                                                value={opt.value}
                                                checked={currentPractice === opt.value}
                                                onChange={e => setCurrentPractice(e.target.value)} />

                                            <h5 className="ml-2">{opt.label}</h5>
                                        </div>
                                    )
                                }

                                return ''
                            })}
                    </div>
                </div>
            }

            {selectedSchema.type === 'DISTANCE' && step.order === 4 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {step.data.length > 0 &&
                            step.data.map(opt => (
                                <div
                                    className="mt-2"
                                    key={opt.label}
                                    style={{ border: '1px solid #EEEEEE', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>

                                    <div style={{ width: 50 }}>
                                        <h5>{opt.label}</h5>
                                    </div>

                                    <div className="ml-2">
                                        <FormGroup>
                                            <Label for="km_time">Tempo obtido</Label>
                                            <TimeInput
                                                defaultValue={profile[`km${opt.value}_time`] ? formatDuration(profile[`km${opt.value}_time`], 'time') : null}
                                                onChange={e => setValueForEnableNextButton(() => setKmTime(opt.value, e.target.value))}
                                                name="km_time"
                                                id="km_time" />
                                        </FormGroup>
                                    </div>

                                    <div className="ml-2">
                                        <FormGroup>
                                            <Label for="km_date">Quando foi obtido</Label>
                                            <Input
                                                defaultValue={profile[`km${opt.value}_date`] ? moment(profile[`km${opt.value}_date`]).format('YYYY-MM-DD') : null}
                                                onChange={e => setValueForEnableNextButton(() => setKmDate(opt.value, e.target.value))}
                                                type="date"
                                                name="km_date"
                                                id="km_date"
                                                bsSize="sm" />
                                        </FormGroup>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            }

            {selectedSchema.type === 'DISTANCE' && step.order === 5 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="mt-2" style={{ maxWidth: 400 }}>
                            <FormGroup>
                                <Label for="startAt">{step.selectText}</Label>
                                {/* <Input
                                    aria-disabled={true}
                                    value={startAt}
                                    min={minStartAt}
                                    max={maxStartAt}
                                    onChange={e => setValueForEnableNextButton(() => handleStartAt(e.target.value))}
                                    type="date"
                                    name="startAt"
                                    id="startAt" /> */}

                                <DatePicker
                                    selected={startAt ? new Date(startAt) : null}
                                    onChange={date => setValueForEnableNextButton(() => handleStartAt(date))}
                                    minDate={new Date(minStartAt)}
                                    maxDate={new Date(maxStartAt)}
                                    placeholderText="Data de início"
                                    locale="pt-BR"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    withPortal
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <h5>{step.description}</h5>
                </div>
            }

            {selectedSchema.type === 'DISTANCE' && step.order === 6 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="mt-2" style={{ maxWidth: 400 }}>
                            <FormGroup>
                                <Label for="targetDate">{step.selectText}</Label>
                                {/* <Input
                                    aria-disabled={true}
                                    value={targetDate}
                                    min={minTargetDate}
                                    max={maxTargetDate}
                                    onChange={e => setValueForEnableNextButton(() => handleTargetDate(e.target.value))}
                                    type="date"
                                    name="targetDate"
                                    id="targetDate" /> */}

                                <DatePicker
                                    selected={targetDate ? new Date(targetDate) : null}
                                    onChange={date => setValueForEnableNextButton(() => handleTargetDate(date))}
                                    minDate={minTargetDate ? new Date(minTargetDate) : null}
                                    maxDate={maxTargetDate ? new Date(maxTargetDate) : null}
                                    placeholderText="Data do objetivo"
                                    locale="pt-BR"
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control"
                                    withPortal
                                />
                            </FormGroup>
                        </div>
                    </div>

                    <h5>{step.description}</h5>

                    <h5>No mínimo {minWeek ? minWeek : 0} semanas</h5>
                    <h5>No máximo {maxWeek ? maxWeek : 0} semanas</h5>

                    <p className="mt-3">{step.description2}</p>
                </div>
            }



            {selectedSchema.type === 'DISTANCE' && step.order === 7 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {step.data.length > 0 &&
                            step.data.map(opt => (
                                <div
                                    className="mt-2"
                                    key={opt.label}
                                    onClick={() => setValueForEnableNextButton(() => setDaysWeeklyWorkout(opt.value))}
                                    style={{ border: `${daysWeeklyWorkout === opt.value ? '1px solid #4C70B7' : '1px solid #EEEEEE'}`, cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', background: '#EEEEEE', padding: 10, borderRadius: 8, maxWidth: 400 }}>
                                    <input
                                        type="radio"
                                        name={opt.label}
                                        value={opt.value}
                                        checked={daysWeeklyWorkout === opt.value}
                                        onChange={e => setDaysWeeklyWorkout(e.target.value)} />

                                    <h5 className="ml-2">{opt.label}</h5>
                                </div>
                            ))}
                    </div>
                </div>
            }

            {selectedSchema.type === 'DISTANCE' && step.order === 8 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <h5>{step.description}</h5>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="mt-2" style={{ maxWidth: 400 }}>
                            <FormGroup>
                                <Label for="height">Altura(cm)</Label>
                                <Input
                                    value={height}
                                    onChange={e => setValueForEnableNextButton(() => setHeight(e.target.value))}
                                    type="number"
                                    name="height"
                                    id="height"
                                    placeholder="Altura" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="weight">Peso(kg)</Label>
                                <Input
                                    value={weight}
                                    onChange={e => setValueForEnableNextButton(() => setWeight(e.target.value))}
                                    type="number"
                                    name="weight"
                                    id="weight"
                                    placeholder="Peso" />
                            </FormGroup>
                        </div>
                    </div>
                </div>
            }

            {selectedSchema.type === 'DISTANCE' && step.order === 9 &&
                <div className="mt-5">
                    <h4>{step.title}</h4>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className="mt-2" style={{ maxWidth: 400 }}>
                            <FormGroup>
                                <Label for="mHistoryNotes">{step.description}</Label>
                                <Input
                                    onChange={e => setValueForEnableNextButton(() => setMHistoryNotes(e.target.value))}
                                    type="textarea"
                                    maxLength="300"
                                    name="mHistoryNotes"
                                    id="mHistoryNotes"
                                    placeholder="Opcional" />
                                {mHistoryNotes && mHistoryNotes.length === 300 && <span style={{ color: 'red' }}>Limite máximo de 300 caracteres atingido</span>}
                            </FormGroup>
                            <FormGroup>
                                <Label for="generalNotes">{step.description2}</Label>
                                <Input
                                    onChange={e => setValueForEnableNextButton(() => setGeneralNotes(e.target.value))}
                                    type="textarea"
                                    maxLength="300"
                                    name="generalNotes"
                                    id="generalNotes"
                                    placeholder="Opcional" />
                                {generalNotes && generalNotes.length === 300 && <span style={{ color: 'red' }}>Limite máximo de 300 caracteres atingido</span>}
                            </FormGroup>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}