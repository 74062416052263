import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody
} from "reactstrap";
import DirectionsRun from "@material-ui/icons/DirectionsRun";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { FaCheckCircle } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";

import {
  formatActivityTypeIcon,
  formatDuration,
  formatDistance
} from "~/services/util";

export default function TrainingDetailContainer({
  training,
  handleTrainingDetail,
  currentDate,
  showHeader
}) {
  const profile = useSelector(state => state.user.profile);
  const [modal, setModal] = useState(false);
  const [trainingName, setTrainingName] = useState(false);
  const [trainingDescription, setTrainingDescription] = useState(false);
  const toggle = (name, description) => {
    console.log(name, description);
    setTrainingName(name);
    setTrainingDescription(description);
    setModal(!modal);
  };
  const zones = {
    "ZONA-1": 1,
    "ZONA-2": 2,
    "ZONA-3": 3,
    "ZONA-4": 4,
    "ZONA-5a": 5,
    "ZONA-5b": 6,
    "ZONA-5c": 7
  };

  const formatUserZone = zone => {
    if (profile && profile.userZone && zone) {
      const zoneId = zones[zone];

      let currZone = profile.userZone.zones.filter(
        item => item.order === zoneId
      );

      if (currZone && currZone[0]) {
        currZone = currZone[0];

        const isBpm = currZone.start_pace || currZone.end_pace;
        if (currZone.order === 1) {
          return !isBpm
            ? `(Abaixo de ${currZone.end_bpm} bpm)`
            : `(Acima de ${currZone.end_pace} min/km)`;
        }

        if (currZone.order === 7) {
          return !isBpm
            ? `(Acima de ${currZone.start_bpm} bpm)`
            : `(Abaixo de ${currZone.start_pace} min/km)`;
        }

        return !isBpm
          ? `(${currZone.start_bpm} a ${currZone.end_bpm} bpm)`
          : `(${currZone.start_pace} a ${currZone.end_pace} min/km)`;
      }
    }

    return "";
  };

  const formatZone = zone => {
    if (zone) {
      switch (zone) {
        case "ZONA-1":
          return `Zona 1 (Leve)`;
        case "ZONA-2":
          return `Zona 2 (Moderado)`;
        case "ZONA-3":
          return `Zona 3 (Pouco Difícil)`;
        case "ZONA-4":
          return `Zona 4 (Difícil)`;
        case "ZONA-5a":
          return `Zona 5a (Muito Difícil)`;
        case "ZONA-5b":
          return `Zona 5b (Extremamente Difícil)`;
        case "ZONA-5c":
          return `Zona 5c (Limite Máximo)`;

        default:
          return `Zona`;
      }
    }
  };

  function drawActivityInfos(act, groupItem, actIdx) {
    const zoneFormated = formatZone(act.zone);

    let description = "";

    if (groupItem.repetition && groupItem.repetition > 1) {
      if (act.activity_type === "DISTANCE") {
        description = `${act.distance} km @ ${zoneFormated}`;
      }
      if (act.activity_type === "DURATION") {
        description = `${act.duration} min @ ${zoneFormated}`;
      }
    } else {
      if (act.activity_type === "DISTANCE") {
        description = `${act.distance} km @ ${zoneFormated}`;
      }
      if (act.activity_type === "DURATION") {
        description = `${act.duration} min @ ${zoneFormated}`;
      }
    }

    return (
      <div key={`act_${actIdx}`}>
        {groupItem.repetition && groupItem.repetition > 1 ? (
          <div>
            <div
              style={{
                marginLeft: 20,
                marginTop: 0,
                alignSelf: "flex-start",
                flexDirection: "row"
              }}
            >
              <p style={{ fontWeight: "500", margin: 0, padding: 0 }}>
                {actIdx + 1}.{" "}
              </p>
              <p style={{ fontWeight: "500", margin: 0, padding: 0 }}>
                {act.name}
              </p>
            </div>
            <div>
              <p
                style={{ color: "gray", margin: 0, padding: 0, marginLeft: 40 }}
              >
                {description}
              </p>
              <p
                style={{
                  color: "gray",
                  fontSize: 12,
                  margin: 0,
                  padding: 0,
                  marginLeft: 40
                }}
              >{`${formatUserZone(act.zone)}`}</p>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                margin: 0,
                padding: 0,
                marginLeft: 20,
                marginTop: 0,
                alignSelf: "flex-start",
                flexDirection: "row"
              }}
            >
              <p style={{ fontWeight: "500", margin: 0, padding: 0 }}>
                {actIdx + 1}.{" "}
              </p>
              <div>
                <p
                  style={{
                    color: "gray",
                    margin: 0,
                    padding: 0,
                    marginLeft: 5
                  }}
                >
                  {description}
                </p>
                <p
                  style={{
                    color: "gray",
                    margin: 0,
                    padding: 0,
                    fontSize: 12,
                    marginLeft: 5
                  }}
                >{`${formatUserZone(act.zone)}`}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function modalDescription() {
    return (
      <div>
        <Modal isOpen={modal} toggle={toggle} backdrop="static">
          <ModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <h5>{trainingName}</h5>
            <p>{trainingDescription}</p>
            <Button color="secondary" onClick={() => toggle()}>
              OK
            </Button>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  return (
    <>
      <Card
        onClick={e => {
          console.log("QUE PORRA", e);
          if (handleTrainingDetail) {
            handleTrainingDetail(training);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        {showHeader && (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <CardTitle
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "first-baseline"
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={formatActivityTypeIcon(training.type)}
                    height="20"
                    style={{
                      margin: 10,
                      objectFit: "cover",
                      backgroundSize: "cover"
                    }}
                    alt="user-img"
                    title="Categoria"
                  />
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      marginTop: 10,
                      color: "#4c70b7"
                    }}
                  >
                    {training.name}
                  </p>
                  {training.executed_status === "EXECUTED_HIGH" ? (
                    <FaCheckCircle
                      style={{ margin: 10 }}
                      color="green"
                      size={20}
                    />
                  ) : (
                    <FaCheckCircle
                      style={{ margin: 10 }}
                      color="#c1c1c1"
                      size={20}
                    />
                  )}
                </div>
                {training.description && (
                  <div
                    onClick={e => {
                      e.stopPropagation();
                      toggle(training.name, training.description);
                    }}
                  >
                    <FiInfo style={{ margin: 10 }} color="black" size={20} />
                  </div>
                )}
              </CardTitle>
            </div>

            {training.type !== "Run" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10
                }}
              >
                <DirectionsRun name="human" size={20} />
                <p style={{ fontSize: 14, marginTop: 10 }}>
                  {" "}
                  {training && !!training.equipments
                    ? training.equipments
                    : "Apenas peso do corpo"}{" "}
                </p>
              </div>
            )}

            {training.type !== "Run" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginHorizontal: 10
                }}
              >
                <div style={{ flexDirection: "row" }}></div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginHorizontal: 10
                }}
              >
                {training.activity_type === "DURATION" ? (
                  <div style={{ flexDirection: "row" }}>
                    <AssignmentIcon
                      name="clock-outline"
                      size={20}
                      color={"gray"}
                    />
                    <p style={{}}>
                      {" "}
                      {training && !!training.duration
                        ? `Tempo: ${formatDuration(training.duration)}`
                        : "--:--"}{" "}
                    </p>
                  </div>
                ) : (
                  <div style={{ flexDirection: "row" }}>
                    <p style={{ marginLeft: 10 }}>
                      {" "}
                      {training && !!training.distance
                        ? `Distância: ${formatDistance(training.distance)}`
                        : ""}{" "}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <CardBody>
          {training &&
            training.type === "Run" &&
            training.trainingGroups.map((item, tgIDX) => {
              return (
                <div style={{ alignItems: "center" }} key={`tgIDX_${tgIDX}`}>
                  <div style={{ alignItems: "flex-start", width: "80%" }}>
                    <div>
                      <div style={{ flexDirection: "row" }}>
                        <span style={{ margin: 0, padding: 0 }}>
                          {tgIDX + 1}.{" "}
                        </span>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: 15,
                            margin: 0,
                            padding: 0
                          }}
                        >
                          {item.repetition > 1
                            ? `${item.name} | Repetir ${item.repetition} vezes`
                            : `${item.name}`}
                        </span>
                      </div>
                      {item.trainingActivities.map((act, aIdx) => {
                        return drawActivityInfos(act, item, aIdx);
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </CardBody>
      </Card>
      {modalDescription()}
    </>
  );
}
