import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import userActivities from './userActivity/sagas'
import userTrainingPlan from './userTrainingPlan/sagas'
import liveLectures from './liveLectures/sagas'
import trainingPlanRequests from './trainingPlanRequests/sagas'

export default function* rootSaga() {
  return yield all([auth, user, userActivities, userTrainingPlan, liveLectures, trainingPlanRequests]);
}