import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Container } from './styles'
import { FaFileDownload } from 'react-icons/fa'

export default class ExtraNutritionScreen extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col>
            <div className="page-title-box">
              <Row>
                <Col lg={7}>
                  <h4 className="page-title">Guia essencial de nutrição</h4>
                </Col>
                <Col lg={5} className="mt-lg-3 mt-md-0"></Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <p>
                  Oferecemos um guia com orientações essenciais sobre nutrição para praticantes de corrida,
                  principalmente para o pré, durante e pós corrida. São informações básicas para uma melhor conduta alimentar,
                  que não substituem o apoio de um profissional de Nutrição.
                </p>

                <div className="extra-card">
                  <p>Conteúdo do guia:</p>

                  <p className="ml-3">
                    <ol>
                      <li>Diferença de alimentação entre amadores e profissionais.</li>
                      <li>A alimentação no dia-a-dia.</li>
                      <li>A alimentação antes, durante e após provas e treinos.</li>
                    </ol>
                  </p>

                  <a
                    href="https://cp-static-files.s3-sa-east-1.amazonaws.com/eBookCorridaeAlimentacao.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-primary">
                    Clique aqui para fazer o download do guia&nbsp;&nbsp;
                    <FaFileDownload color="white" size={20} />
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container >
    );
  }
}
