import produce from 'immer';

const INITIAL_STATE = {
    loading: false,
    data: {},
    modalShowLecture: false,
    modalLoading: false,
    startedLectureId: null
};

export default function liveLectures(state = INITIAL_STATE, action) {

    return produce(state, draft => {

        switch (action.type) {

            case '@liveLecture/LOAD_LIVE_LECTURES_REQUEST': {
                draft.loading = true
                break;
            }

            case '@liveLecture/LOAD_LIVE_LECTURES_SUCCESS': {
                draft.data = action.payload.data;
                draft.loading = false;
                break;
            }

            case '@liveLecture/LOAD_LIVE_LECTURES_FAILURE': {
                draft.loading = false;
                break;
            }

            case '@liveLecture/TOGGLE_SHOW_MODAL_LECTURE': {
                draft.modalShowLecture = !draft.modalShowLecture
                break
            }

            case '@liveLecture/START_LIVE_LECTURE_SUCCESS': {
                draft.startedLectureId = action.payload.data.id
                break
            }

            case '@liveLecture/FINISH_LIVE_LECTURE_REQUEST': {
                draft.modalLoading = true
                break
            }

            case '@liveLecture/FINISH_LIVE_LECTURE_SUCCESS': {
                draft.modalLoading = false
                break
            }

            case '@liveLecture/FINISH_LIVE_LECTURE_FAILURE': {
                draft.modalLoading = false
                break
            }

            default:
        }
    })
}