import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Row, Col, Card, CardBody,
  Modal, ModalBody, Spinner
} from 'reactstrap';

import { toast } from 'react-toastify';
import moment from 'moment';

import * as  mobiscroll from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

import './calendar.css'

import { toggleShowModalTrainingPlanRequestTmp } from '~/store/modules/trainingPlanRequests/actions';

import api, { BASE_API_URL } from '~/services/api';
import { useDispatch, useSelector } from 'react-redux';

import TrainingShowModal from '~/pages/Training/show/TrainingShowModal';
import TrainingPlanRequestModal from '~/pages/TrainingPlanRequestModal'

import { formatDuration, formatActivityTypeIcon } from '~/services/util'

import { isMobile } from 'react-device-detect'

const ROUTES_TRAINING = {
  'WeightTraining': 'treinos-forca',
  'Drills': 'treinos-educativos',
  'Cardio': 'treinos-cardio',
  'Others': 'treinos-outros',
}

let colors = ['#FFFFFF', '#E9FFE9', '#9bbbcc']

export default function TrainingPlan() {
  const dispatch = useDispatch();

  const profile = useSelector(state => state.user.profile);
  const userTrainingPlan = useSelector(state => state.userTrainingPlan);
  const trainingPlanRequestShowModal = useSelector(state => state.trainingPlanRequests.trainingPlanRequestShowModalTMPButton);
  const [lastRequest, setLastRequest] = useState(null)

  const [dataLoaded, setDataLoaded] = useState(false);

  const [trainingActivityShowModal, setTrainingActivityShowModal] = useState(false);
  const [trainingActivityToShow, setTrainingActivityToShow] = useState();

  const [myEvents, setEvents] = useState([]);

  const onPageChange = useCallback((event, inst) => {
    let data = myEvents.slice(0);

    const initOfMonth = moment().set('date', 1).set('h', 0).set('minutes', 0).set('seconds', 0)

    if (!moment(event.month).isBefore(initOfMonth)) return

    const date = moment(event.month).format('YYYY-MM-DD')

    if (moment(date).get('month') === moment().get('month')) return

    Promise.all([api.get(`${BASE_API_URL}/getTrainingsByMonthAndUser?userId=${profile.id}&date=${date}`)])
      .then(([userTrainingPlanDays]) => {
        let days = userTrainingPlanDays.data

        if (days.length > 0) {
          days = days.map(day => {
            if (day.trainings && day.trainings.length > 0) {
              day.trainings = day.trainings.filter(training => training.executed_status && training.executed_status === 'EXECUTED_HIGH')

              day.trainings = day.trainings.map(training => {
                return { ...training, allDay: false, title: training.name, start: moment(day.date), color: training?.executed_status === 'EXECUTED_HIGH' ? colors[1] : colors[0], end: moment(day.date), obj_type: 'TRAINING' }
              })

              for (let index = 0; index < day.trainings.length; index++) {
                const element = day.trainings[index];

                if (element.obj_type === 'TRAINING') {
                  Promise.all([api.get(`/user_activitie/by-training?trainingId=${element.id}`)])
                    .then(([actResp]) => {
                      if (actResp && actResp.data) {
                        element.userActivity = actResp.data
                      }
                    })
                }

                if (!element.executed_status || element.executed_status === 'EXECUTED_LOW') {
                  day.trainings.splice(index, 1)
                }
              }
            }

            return { id: day.id, trainings: day.trainings }
          })

          days.forEach(day => {
            day.trainings.forEach(training => {
              for (let index = 0; index < data.length; index++) {
                const element = data[index]

                if (element.obj_type === 'TRAINING' && element.id === training.id) {
                  data.splice(index, 1)
                }
              }
            })

            data = [...data, ...day.trainings]
          })

          if (data.length > 0) {
            for (let index = 0; index < data.length; index++) {
              const element = data[index];

              if (element.obj_type === 'TRAINING' &&
                element.training_plan_day_id &&
                element.executed_status === 'EXECUTED_HIGH') {

                for (let index2 = 0; index2 < data.length; index2++) {
                  const element2 = data[index2];

                  if (element2 && element2.training_id && element2.training_id === element.id) {
                    data.splice(index2, 1)
                  }
                }
              }

              if (element.name && element.start && element.end && element.name === 'Educativos de corrida') {
                data[index].start = moment(data[index].start).set('h', 1).set('minutes', 0).set('seconds', 0)
                data[index].end = moment(data[index].end).set('h', 1).set('minutes', 10).set('seconds', 0)
              }
            }
          }
        }

        setEvents(data);
      })
  }, [myEvents, profile.id]);

  const onEventClick = useCallback((event) => {
    handleOpenActivityDetail(event.event)
  }, []);

  const view = useMemo(() => {
    return {
      calendar: { type: 'month', labels: true }
    };
  }, []);

  const renderCustomLabel = useCallback((data) => {
    const event = data.original;

    return (
      isMobile ?
        <div className="custom-event-mobile" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: event?.executed_status === 'EXECUTED_HIGH' ? colors[1] : colors[0] }}>
          <img src={formatActivityTypeIcon(event?.type)} alt="icon" height="25px" />
        </div>
        :
        <div className="custom-event" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: event?.executed_status === 'EXECUTED_HIGH' ? colors[1] : colors[0] }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ whiteSpace: 'normal' }}>{event?.title}</div>
            <div><img src={formatActivityTypeIcon(event?.type)} alt="icon" height="20px" /></div>
          </div>
          {event.obj_type === 'TRAINING' && event.userActivity ?
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>{event.userActivity.duration ? formatDuration(event.userActivity.duration, 'time') : '--:--:--'}</div>
              <div>{event.userActivity.distance || '--'}&nbsp;km</div>
            </div>
            :
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div>{event.duration ? formatDuration(event.duration, 'time') : '--:--:--'}</div>
              <div>{event.distance || '--'}&nbsp;km</div>
            </div>
          }
        </div>
    );
  }, []);

  const renderEvent = useCallback((data) => {
    const event = data.original;

    return (
      <div className="custom-event-popup" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: event?.executed_status === 'EXECUTED_HIGH' ? colors[1] : colors[0] }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ whiteSpace: 'normal', fontSize: 12 }}>{event?.title}</div>
          <div><img src={formatActivityTypeIcon(event?.type)} alt="icon" height="20px" /></div>
        </div>
        {event.obj_type === 'TRAINING' && event.userActivity ?
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>{event.userActivity.duration ? formatDuration(event.userActivity.duration, 'time') : '--:--:--'}</div>
            <div>{event.userActivity.distance || '--'}&nbsp;km</div>
          </div>
          :
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>{event.duration ? formatDuration(event.duration, 'time') : '--:--:--'}</div>
            <div>{event.distance || '--'}&nbsp;km</div>
          </div>
        }
      </div>
    );
  }, []);

  const loadData = useCallback(() => {
    setDataLoaded(false);

    Promise.all([api.get(`/training_plans?userId=${profile.id}&calendar=true`), api.get(`/user_activities?user_id=${profile.id}`)])
      .then(([currTrainingPlanResp, userActsResp]) => {
        let data = []

        const endOfCurrentWeek = moment(moment().endOf('week')).add(1, 'd').set('h', 23).set('minutes', 59).set('seconds', 59)

        const weeksFoward = moment(endOfCurrentWeek).add(50, 'w').set('h', 23).set('minutes', 59).set('seconds', 59)

        if (currTrainingPlanResp.data && currTrainingPlanResp.data.length > 0) {
          let days = []

          if (currTrainingPlanResp.data[0].trainingPlanWeeks && currTrainingPlanResp.data[0].trainingPlanWeeks.length > 0) {
            currTrainingPlanResp.data[0].trainingPlanWeeks.forEach(week => {
              if (week.trainingPlanDays && week.trainingPlanDays.length > 0) {
                week.trainingPlanDays.forEach(day => {
                  if (moment(day.date).isBefore(weeksFoward)) {
                    days.push(day)
                  }
                });
              }
            });
          }

          if (days.length > 0) {
            days = days.map(day => {
              if (day.trainings && day.trainings.length > 0) {
                day.trainings = day.trainings.map(training => {
                  return { ...training, allDay: false, title: training.name, start: moment(day.date), color: training.executed_status === 'EXECUTED_HIGH' ? colors[1] : colors[0], end: moment(day.date), obj_type: 'TRAINING' }
                })
              }

              return { id: day.id, trainings: day.trainings }
            })

            days.forEach(day => {
              data = [...data, ...day.trainings]
            })
          }
        }

        if (userActsResp.data && userActsResp.data.length > 0) {
          let activities = []

          activities = userActsResp.data.map(activity => {
            return { ...activity, allDay: false, start: moment(activity.start_date), color: colors[1], end: moment(activity.start_date), obj_type: 'USR_ACT', executed_status: 'EXECUTED_HIGH' }
          })

          data = [...data, ...activities]
        }

        if (data.length > 0) {
          for (let index = 0; index < data.length; index++) {
            const element = data[index];

            if (element.obj_type === 'TRAINING' &&
              element.training_plan_day_id &&
              element.executed_status === 'EXECUTED_HIGH') {

              for (let index2 = 0; index2 < data.length; index2++) {
                const element2 = data[index2];

                if (element2 && element2.training_id && element2.training_id === element.id) {
                  data.splice(index2, 1)
                }
              }
            }

            if (element.obj_type === 'TRAINING') {
              Promise.all([api.get(`/user_activitie/by-training?trainingId=${element.id}`)])
                .then(([actResp]) => {
                  if (actResp && actResp.data) {
                    element.userActivity = actResp.data
                  }
                })
            }

            if (element.name && element.start && element.end && element.name === 'Educativos de corrida') {
              data[index].start = moment(data[index].start).set('h', 1).set('minutes', 0).set('seconds', 0)
              data[index].end = moment(data[index].end).set('h', 1).set('minutes', 10).set('seconds', 0)
            }
          }
        }

        setEvents(data)
        setDataLoaded(true);
      }).catch(err => {
        toast.error('Erro ao carregar o treino!')
        setDataLoaded(true)
      });
  }, [profile.id]);

  useEffect(() => {
    loadData();

    api.get(`get_user_requests?userId=${profile.id}`)
      .then(response => {
        if (response.data && response.data.data.length > 0) {
          setLastRequest(response.data.data[0])
        }
      })
  }, [loadData, profile.id])

  function handleOpenActivityDetail(trainingToShow) {
    if ((trainingToShow && (trainingToShow.type === 'WeightTraining' || trainingToShow.type === 'Drills' || trainingToShow.type === 'Cardio' || trainingToShow.type === 'Others') && (!trainingToShow.executed_status || trainingToShow.executed_status !== 'EXECUTED_HIGH'))) {
      window.location.href = `/${ROUTES_TRAINING[trainingToShow.type]}?type=${trainingToShow.type}&rt=${trainingToShow.id}`
    } else if (trainingToShow && trainingToShow.obj_type && trainingToShow.obj_type === 'USR_ACT') {
      trainingToShow.trainingGroups = [];

      setTrainingActivityToShow(trainingToShow)
      setTrainingActivityShowModal(true);
    } else if (trainingToShow) {
      trainingToShow.trainingGroups = [];

      setTrainingActivityToShow(trainingToShow)
      setTrainingActivityShowModal(true);
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={!dataLoaded}>
        <ModalBody >
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner color="primary" /><span style={{ marginLeft: 20 }}>Aguarde...</span>
          </div>
        </ModalBody>
      </Modal>

      {trainingActivityShowModal && <TrainingShowModal open={trainingActivityShowModal} training={trainingActivityToShow} toggle={() => setTrainingActivityShowModal(false)} />}

      {trainingPlanRequestShowModal &&
        <TrainingPlanRequestModal />}

      {dataLoaded && <>
        <Row>
          <Col>
            <div className="page-title-box">
              <Row>
                <Col lg={7}> <h4 className="page-title">Treino</h4> </Col>
                <Col lg={5} className="mt-lg-3 mt-md-0"></Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} >
            <Card>
              <CardBody style={{ height: '100%', minHeight: 700 }}>
                <Row >
                  {userTrainingPlan && userTrainingPlan.data && userTrainingPlan.data.deactivated_at === null ?
                    <Col lg={12}>
                      <h4>Calendário de atividades</h4>
                      <p>Plano de treino ativo: <span style={{ fontWeight: '500' }}>{userTrainingPlan.data.name}</span></p>
                      <p>Data final do plano: <span style={{ fontWeight: '500' }}>{userTrainingPlan.data.end_date ? moment(userTrainingPlan.data.end_date).utc(true).format('DD/MM/YYYY') : '-'}</span></p>
                      {lastRequest && (lastRequest.status === 'OPEN' || lastRequest.status === 'CREATED' || lastRequest.status === 'PENDING') && <p>Solicitação de plano de treino em andamento</p>}
                      <button type="button" className="btn btn-secondary waves-effect" onClick={() => {
                        dispatch(toggleShowModalTrainingPlanRequestTmp());
                      }}>{lastRequest && (lastRequest.status === 'OPEN' || lastRequest.status === 'CREATED' || lastRequest.status === 'PENDING') && 'Ver dados da solicitação'}
                        {!lastRequest && 'Solicitar novo plano'}
                        {lastRequest && (lastRequest.status === 'FINISHED' || lastRequest.status === 'CANCELED') && 'Solicitar novo plano'}</button>
                    </Col>
                    :
                    <Col lg={12}>
                      <p className="sub-header">Voce não possui um plano de treinos ativo.</p>
                      <p className="sub-header">Solicite agora o seu próximo plano de treino aos nossos treinadores.</p>
                      {lastRequest && (lastRequest.status === 'OPEN' || lastRequest.status === 'CREATED' || lastRequest.status === 'PENDING') && <p>Solicitação de plano de treino em andamento</p>}
                      <button type="button" className="btn btn-secondary waves-effect" onClick={() => {
                        dispatch(toggleShowModalTrainingPlanRequestTmp());
                      }}>{lastRequest && (lastRequest.status === 'OPEN' || lastRequest.status === 'CREATED' || lastRequest.status === 'PENDING') && 'Ver dados da solicitação'}
                        {!lastRequest && 'Solicitar novo plano'}
                        {lastRequest && (lastRequest.status === 'FINISHED' || lastRequest.status === 'CANCELED') && 'Solicitar novo plano'}</button>
                    </Col>
                  }

                  <Col lg={12} style={{ background: 'green', margin: 0, padding: 0, marginTop: 20 }}>
                    <Row style={{ backgroundColor: '#ffffff', padding: 8 }}>
                      <mobiscroll.Eventcalendar
                        theme="material"
                        themeVariant="light"
                        locale={mobiscroll.localePtBR}
                        dragToCreate={false}
                        dragToMove={false}
                        dragToResize={false}
                        height={1500}
                        renderLabel={renderCustomLabel}
                        renderEvent={renderEvent}
                        data={myEvents}
                        view={view}
                        onEventClick={onEventClick}
                        onPageChange={onPageChange}
                        colors={[
                          {
                            date: moment(),
                            background: colors[2]
                          }
                        ]}
                      />
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>}
    </React.Fragment>
  );
}
