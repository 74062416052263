import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  updateProfileSuccess,
  updateProfileFailure,
  updateStravaIntegrationSuccess,
  updateStravaIntegrationFailure,
  setStravaIntegrated,
  setGarminIntegrated
} from './actions';

export function* updateProfile({ payload }) {
  try {
    const { id, ...data } = payload.data;
    const showToast = payload.showToast

    let response

    if (data.garmin_access_token === '' && data.garmin_token_secret === '') {
      response = yield call(api.put, `users/${id}?removeGarminIntegration=true`, data);
    } else if (data.s_id === '') {
      response = yield call(api.put, `users/${id}?removeStravaIntegration=true`, data);
    } else {
      response = yield call(api.put, `users/${id}`, data);
    }

    if (response.data.code) {
      toast.error(response.data.msg)
      yield put(updateProfileFailure())
      return
    }

    if (data.s_id === '') {
      toast.success('Integração removida com sucesso!')
      yield put(setStravaIntegrated(false))
    } else if (data.garmin_access_token === '') {
      toast.success('Integração removida com sucesso!')
      yield put(setGarminIntegrated(false))
    } else if (showToast) {
      toast.success('Perfil atualizado com sucesso!');
    }

    yield put(updateProfileSuccess(response.data))
  } catch (error) {
    toast.error('Erro ao atualizar perfil!');
    yield put(updateProfileFailure())
  }
}

export function* updateStrava({ payload }) {
  try {
    const { profileId } = payload

    const response = yield call(api.put, `integrations/${profileId}/strava`, {})

    if (response.data.msg === 'strava_update_success') {
      yield put(updateStravaIntegrationSuccess())
      toast.success('Sucesso! As atividades do Strava foram atualizadas!');
    }
  } catch (error) {
    toast.error('Erro ao atualizar integração com o Strava!');
    yield put(updateStravaIntegrationFailure())
  }
}

export function* integrateStrava({ payload }) {
  try {
    const { profileId, code } = payload.data

    yield call(api.post, `s/${profileId}?code=${code}`, {})

    toast.success('Integração realizada com sucesso!')
  } catch (error) {
    toast.error('Erro ao integrar com o Strava!');
  }
}

export function* integrateGarmin({ payload }) {
  try {
    const { profileId, token, secret } = payload.data

    yield call(api.post, `integrations/${profileId}/garmin`, { token, secret })

    toast.success('Integração realizada com sucesso!')
  } catch (error) {
    toast.error('Erro ao integrar com a Garmin!');
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/INTEGRATE_STRAVA', integrateStrava),
  takeLatest('@user/UPDATE_STRAVA_INTEGRATION', updateStrava),
  takeLatest('@user/INTEGRATE_GARMIN', integrateGarmin)
]);
