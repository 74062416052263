import React from 'react';

import { Modal, ModalBody } from 'reactstrap'

function ModalInfoRecordedLectures({ toggle, modal }) {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalBody>
          <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
            Acesse a seção de gravações
          </p>

          <p style={{ textAlign: 'center' }}>
            Alguns minutos após o fim da aula, a gravação vai ficar disponível em nosso site,
            na seção de gravações, que pode ser acessada no menu de treinos ao vivo, em
            "Gravações anteriores".
          </p>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <button style={{ paddingLeft: 50, paddingRight: 50 }} onClick={toggle} className="btn btn-outline-secondary">
              OK
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ModalInfoRecordedLectures;
