import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Card, CardBody, Collapse, Button } from 'reactstrap';

import { signInRequest } from '~/store/modules/auth/actions';
import Loader from '~/components/Loader/Loader';
import { Container } from './styles'

class ExtraGiftsScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isOpen: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      isOpen5: false
    }
  }

  render() {
    return (
      <Container>
        { /* preloader */}
        {false && <Loader />}

        <Row>
          <Col>
            <div className="page-title-box">
              <Row>
                <Col lg={7}>
                  <h4 className="page-title">Benefícios e Descontos</h4>
                </Col>
                <Col lg={5} className="mt-lg-3 mt-md-0">

                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="extra-card">
                  <Button color="primary" block onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                    <i className="fas fa-chevron-down"></i>&nbsp;
                    Produtos exclusivos do Corrida Perfeita
                  </Button>
                  <Collapse isOpen={this.state.isOpen}>
                    <Card>
                      <CardBody>
                        <p>Os produtos Corrida Perfeita são vendidos
                        exclusivamente para você nosso assinante,
                        e no momento atual, com descontos a partir
                        de 20%.</p>

                        <p>Para comprar na nossa loja virtual você precisa
                        fazer um cadastro prévio, que será aprovado por
                        nós em até 1(um) dia útil. Caso você já tenha cadastro
                        e não conseguir entrar, nos envie uma mensagem em
                        loja@corridaperfeita.com para liberação de seu cadastro.</p>

                        <a className="btn btn-primary" href="https://www.lojacorridaperfeita.com.br/?utm_source=plataforma-cp-web&amp;utm_medium=menu-extras-beneficios&amp;utm_campaign=menu-extras-beneficios-plataforma-cp-web" target="_blank" rel="noreferrer">
                          Acessar Loja Virtual&nbsp;
                          <span className="fas fa-external-link-alt"></span>
                        </a>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="extra-card">
                  <Button color="primary" block onClick={() => this.setState({ isOpen2: !this.state.isOpen2 })}>
                    <i className="fas fa-chevron-down"></i>&nbsp;
                    Desconto em Tênis - Loja Velocitá
                  </Button>
                  <Collapse isOpen={this.state.isOpen2}>
                    <Card>
                      <CardBody>
                        <img src="https://appweb-static.s3.amazonaws.com/imgs/beneficios-velocita.png" className="img-fluid" alt="velocita" />

                        <p>A loja Velocitá, originada em São Paulo,
                        é especializada em tênis de corredores e
                        oferece para todos os atletas do Corrida Perfeita
                        <b> 10% de desconto</b> na sua loja online.</p>

                        <p>O desconto só não é válido para
                        produtos em promoção e para relógios Garmin
                        (a marca tabela os preços para lojistas).</p>

                        <p>
                          <a href="https://velocita.run/CorridaPerfeita" target="_blank" rel="noopener noreferrer">
                            Clique aqui para comprar com 10% off na Velocitá
                          </a>
                        </p>
                        <p>*(o desconto é aplicado no carrinho)</p>

                        <p>
                          Obs.: Se você está em SP, pode obter o desconto comprando nas
                          lojas físicas. Mas para isso precisa se&nbsp;
                          <a href="https://corridaperfeita.typeform.com/to/K8orSE" target="_blank" rel="noopener noreferrer">
                            cadastrar neste link
                          </a>
                          &nbsp;antes de ir até uma das lojas.
                        </p>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="extra-card">
                  <Button color="primary" block onClick={() => this.setState({ isOpen4: !this.state.isOpen4 })}>
                    <i className="fas fa-chevron-down"></i>&nbsp;
                    Produtos Hupi - Desconto de 15%
                  </Button>
                  <Collapse isOpen={this.state.isOpen4}>
                    <Card>
                      <CardBody>
                        <img src="https://appweb-static.s3.amazonaws.com/imgs/beneficios-hupi.png" className="img-fluid" alt="hupi" />

                        <br />
                        <br />

                        <p>A marca brasileira Hupi, especializada em acessórios esportivos,
                        como meias, viseiras, óculos, mochilas de hidratação, etc., oferece aos clientes
                        Corrida Perfeita um ótimo desconto de 15% nos produtos da sua marca.
                        </p>

                        <p>Nossa equipe já testou e aprovou os seguintes produtos: meias, bonés de corrida, viseiras e óculos.</p>

                        <p><b>Cupom:</b> CORRIDAPERFEITA15</p>
                        <p><b>Regras:</b> válido somente para produtos da própria marca Hupi.</p>

                        <a href="https://www.hupishop.com.br/running"
                          target="_blank"
                          rel="noopener noreferrer">
                          Clique aqui para conferir os produtos e comprar
                        </a>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
                <div className="extra-card">
                  <Button color="primary" block onClick={() => this.setState({ isOpen5: !this.state.isOpen5 })}>
                    <i className="fas fa-chevron-down"></i>&nbsp;
                    2 meses grátis no STRAVA Premium
                  </Button>
                  <Collapse isOpen={this.state.isOpen5}>
                    <Card>
                      <CardBody>
                        <img src="https://appweb-static.s3.amazonaws.com/imgs/beneficios-strava.png" className="img-fluid" alt="strava" />

                        <br />
                        <br />

                        <p>
                          O aplicativo Strava oferece 2 meses grátis da sua versão Premium para os membros do Clube Corrida Perfeita.
                        </p>

                        <p>Dentre outros benefícios, no premium você pode:</p>

                        <p>- Comparar seu desempenho e sua evolução nos locais que costuma correr.</p>
                        <p>- Pode analisar métricas avançadas de seu condicionamento a partir dos treinos realizados conosco.</p>
                        <p>- E muito mais...</p>

                        <br />

                        <p>Vale a pena experimentar!</p>

                        <a href="https://www.strava.com/subscribe/CorridaPerfeita_60"
                          target="_blank"
                          rel="noopener noreferrer">
                          Clique aqui para obter o benefício
                        </a>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return;
};
export default connect(mapStateToProps, { signInRequest })(ExtraGiftsScreen);
