
import styled from "styled-components";

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 4.5rem;
    margin-top: 1rem;
`
