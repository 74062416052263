import axios from "axios"

export const BASE_FRONT_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_BASE_FRONT_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_FRONT_URL_PRODUCTION

export const BASE_APP_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_BASE_APP_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_APP_URL_PRODUCTION

export const BASE_API_URL =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_BASE_API_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_API_URL_PRODUCTION

const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
  },
})

export default api
