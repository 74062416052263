import React from 'react';
import {
  ProSidebar,
  Menu,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

import { formatTimer } from '~/services/util'


import { FaCheck } from 'react-icons/fa';

const CourseSidebar = ({ image, course, collapsed, rtl, toggled, handleToggleSidebar, goToLecture, exitCourse, updateModule }) => {
  const formatRuntime = (runtime) => {
    if (runtime) {
      const { minutes, seconds } = formatTimer(runtime.toFixed(0))

      return `${minutes}:${seconds}`;
    }
  }

  return (
    <ProSidebar
      image={false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '0 24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          Módulos
        </div>
      </SidebarHeader>

      <SidebarContent>
        {course && course.courseModules && course.courseModules.map( (cMod, cmIdx) => {

          const bgColor = cMod.executed_status && cMod.executed_status !== 'EXECUTED_LOW' ? 'green' : '#a1a1a1';
          const icColor = cMod.executed_status && cMod.executed_status !== 'EXECUTED_LOW' ? 'green' : '#e1e1e1';

          return (
            <Menu iconShape="circle" key={`cMod_${cmIdx}`}>
              <SubMenu suffix={<span className="badge yellow">{cMod.courseLectures ? cMod.courseLectures.length : ''}</span>} key={`cm_${cmIdx}`} title={cMod.name} icon={<FaCheck color={icColor} />}>

                {cMod.courseLectures && cMod.courseLectures.map( (cLec, lIdx) => {
                  const bgColor = cLec.executed_status === 'EXECUTED_HIGH' ? 'green' : '#a1a1a1';
                  const icColor = cLec.executed_status === 'EXECUTED_HIGH' ? 'green' : '#e1e1e1';
                  if (cLec.activity_type !== 'FINISH')
                  return (
                    <div key={`clc_${lIdx}`} style={{display: 'flex', marginBottom: 15, cursor: 'pointer'}} onClick={() => updateModule(cLec, lIdx, cMod, cmIdx)}>
                      <div style={{width: 25, height: 25, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#a1a1a1'}}>
                        <FaCheck color={icColor} />
                      </div>
                      <div style={{display: 'flex', flexDirection: 'column', marginLeft: 7, marginRight: 5, width: '100%'}}>
                        <span style={{}}>{cLec.name}</span>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                          <span>{formatRuntime(cLec?.duration)}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </SubMenu>
            </Menu>
          )
        })}
        
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper">
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default CourseSidebar;