import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ProSidebar, Menu, MenuItem, SubMenu, SidebarContent, SidebarHeader, SidebarFooter } from 'react-pro-sidebar';
import { FaClock } from 'react-icons/fa'
import 'react-pro-sidebar/dist/css/styles.css';
import { setCourseFlow } from '~/store/modules/auth/actions';
import history from '~/services/history';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';

import Loader from '~/components/Loader/Loader';

import Highcharts from 'highcharts/highstock'
import { MdHelp, MdClose } from 'react-icons/md'
import CourseExecMain from './components/CourseExecMain'
import CourseSidebar from './components/CourseSidebar'

import api from '~/services/api';

class CourseExecScreen extends Component {
    constructor(props) {
        super(props);

        const courseID = props.match.params.id

        this.state = {
            loading: true,
            rtl: false,
            collapsed: false,
            image: true,
            toggled: false,
            locale: 'en',
            currentCourse: {},
            currentLecture: {},
            currentLectureIndex: 0,
            currentModuleIndex: 0,
            currentModule: {}
        }

        this._loadCourse(props.match.params.id)
    }

    _loadCourse = async (courseID) => {
        await Promise.all([ api.get(`/courses/${courseID}`) ]).then(async ([coursesResp]) => {
            if (coursesResp.status === 200) {
            let { data } = coursesResp
            
            let currentModule = null;
            let currentLecture = null;
            let currentLectureIndex = 0;
            let currentModuleIndex = 0;

            if (data) {
                for (let i = 0; i < data.courseModules.length; i++) {
                    const newActs = [...data.courseModules[i].courseLectures, {id: 66666, name: 'Finalizar Módulo', activity_type: 'FINISH'}]
                    data.courseModules[i] = { ...data.courseModules[i], courseLectures: newActs }

                    if (!data.courseModules[i].executed_status) {
                        currentModule = data.courseModules[i];
                        currentModuleIndex = i;
                        break;
                    }
                }

                currentModule = currentModule || data.courseModules[0]

                if (currentModule) {
                    currentLecture = currentModule.courseLectures[0]
                    currentLectureIndex = 0;
                  }
            }


            this.setState({...this.state, currentCourse: data, currentModule, currentLecture, currentLectureIndex, currentModuleIndex })
            } else {
                alert('Problema ao carregar curso, tente novamente mais tarde.')
            }
        });
    }
    
    componentWillUnmount() {
        this.props.setCourseFlow(false);
    }

    _exitCourse = async () => {
        await this.props.setCourseFlow(false);
        history.push('/video-aulas/')
    }

    _handleCollapsedChange = (collapsed) => {
        this.setState({ ...this.state, collapsed })
    };

    _handleRtlChange = (rtl) => {
        this.setState({ ...this.state, rtl, locale: (rtl ? 'ar' : 'en') })
    };
    _handleImageChange = (image) => {
        this.setState({ ...this.state, image })
    };

    _handleToggleSidebar = (toggled) => {
        this.setState({ ...this.state, toggled })
    };

    _handleInitCourse = () => {
    }

    _updateCurrentCourse = (newCourse) => {
        this.setState({ ...this.state, currentCourse: newCourse })
    }

    _goToLecture = (newModule, newModuleIdx, newLecture, newLectureIdx) => {

        this.setState({...this.state, 
            currentLecture: newLecture,
            currentLectureIndex: newLectureIdx,
            currentModuleIndex: newModuleIdx,
            currentModule: newModule })
    }

    render() {
        const { currentCourse, currentLecture, currentModule, currentLectureIndex, currentModuleIndex } = this.state;

        const executedPercent = !!currentCourse ? `${currentCourse.executed_percent}% concluído` : ''
        const courseStatus = !!currentCourse && (currentCourse.executed_status === 'EXECUTED_LOW' || currentCourse.executed_status === 'EXECUTED_MEDIUM') ? executedPercent : (currentCourse.executed_status === 'EXECUTED_HIGH' ? 'Concluído' : 'Não iniciado' )
        const courseButtonLabel = !!currentCourse && (currentCourse.executed_status === 'EXECUTED_LOW' || currentCourse.executed_status === 'EXECUTED_MEDIUM') ? 'Continuar' : (currentCourse.executed_status === 'EXECUTED_HIGH' ? 'Abrir' : 'Iniciar' )

        return (
          <React.Fragment>
                  { /* preloader */}
                  {false && <Loader />}

                <div style={{backgroundColor: 'white', margin: 0, padding: 10, height: 60, width: '100%'}}>
                    {currentCourse && (
                        <Row>
                            <Col lg={4}><h4 style={{   }}>{currentCourse.name}</h4></Col>
                            <Col lg={7}>
                                <p style={{fontWeight: '500', color: '#0e0e0e', fontSize: 15, marginBottom: 2}}>{courseStatus}</p>
                                <div style={{height: 5, width: '20%', backgroundColor: '#a1a1a1', marginBottom: 5}}>
                                    <div style={{height: 5, width: `${currentCourse.executed_percent || 0}%`, backgroundColor: 'green'}} />
                                </div>
                            </Col>
                            
                            <Col lg={1} style={{display: 'flex', justifyContent: 'center'}}>
                                <Button  onClick={this._exitCourse} style={{width: 44, height: 44, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <MdClose size={25} style={{fontWeight: '600', fontSize: 25}} />
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>

                <div className={`app ${this.state.rtl ? 'rtl' : ''} ${this.state.toggled ? 'toggled' : ''}`} style={{display: 'flex', flex: 1, height: '100%'}}>
                    <CourseSidebar
                        image={this.state.image}
                        course={currentCourse}
                        module={currentModule}
                        goToLecture={this._goToLecture}
                        updateModule={(lec, lecIdx, mod, modIdx) => { this.setState({ ...this.state, currentLecture: lec, currentLectureIndex: lecIdx, currentModule: mod, currentModuleIndex: modIdx })}}
                        updateCourse={this._updateCurrentCourse}
                        exitCourse={this._exitCourse}
                        collapsed={this.state.collapsed}
                        rtl={this.state.rtl}
                        toggled={this.state.toggled}
                        handleToggleSidebar={this._handleToggleSidebar}
                    />
                    <CourseExecMain
                        image={this.state.image}
                        course={currentCourse}
                        module={currentModule}
                        lecture={currentLecture}
                        lectureIndex={currentLectureIndex}
                        moduleIndex={currentModuleIndex}
                        updateCourse={(course) => { this.setState({ ...this.state, currentCourse: course })}}
                        updateCurrents={(lec, lecIdx) => { this.setState({ ...this.state, currentLecture: lec, currentLectureIndex: lecIdx })}}
                        updateModule={(lec, lecIdx, mod, modIdx) => { this.setState({ ...this.state, currentLecture: lec, currentLectureIndex: lecIdx, currentModule: mod, currentModuleIndex: modIdx })}}
                        toggled={this.state.toggled}
                        collapsed={this.state.collapsed}
                        rtl={this.state.rtl}
                        exitCourse={this._exitCourse}
                        handleToggleSidebar={this._handleToggleSidebar}
                        handleCollapsedChange={this._handleCollapsedChange}
                        handleRtlChange={this._handleRtlChange}
                        handleImageChange={this._handleImageChange}
                    />
                    </div>
      
          </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.user.profile
    }
};
export default connect(mapStateToProps, { setCourseFlow })(CourseExecScreen);