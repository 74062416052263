import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Container, Row, Col, Card, CardBody } from 'reactstrap';

import { resetPasswordRequest } from '~/store/modules/auth/actions';
import logo from '~/assets/img/logo_corridaperfeita_azul.png';
import history from '~/services/history'

class SuccessResetPasswordScreen extends Component {
    render() {
        return (
            <>
                <div className="account-pages mt-5 mb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Card>
                                    <CardBody className="p-4 position-relative">
                                        <div className="text-center w-75 m-auto">
                                            <a href="/">
                                                <span><img src={logo} alt="" height="48" /></span>
                                            </a>
                                            <p className="text-muted mb-4 mt-3">Nova senha definida com sucesso!</p>

                                            <br/>

                                            <button className="btn btn-bordered-primary" onClick={() => history.push('/')}>
                                              {this.props.signed ? 'OK' : 'Ir para o Login'}
                                            </button>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { signed, loading } = state.auth;

    return { signed, loading }
};

export default connect(mapStateToProps, { resetPasswordRequest })(SuccessResetPasswordScreen);
