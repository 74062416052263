import React from 'react';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

//import './config/ReactotronConfig';

import Routes from './routes';
import history from './services/history';

import GlobalStyle from './styles/global';

import '~/assets/scss/DefaultTheme.scss'

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import pt_BR from 'date-fns/locale/pt-BR';

import { store, persistor } from './store';

registerLocale('pt-BR', pt_BR)

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>

        <Router history={history}>
          <Routes />
          <GlobalStyle />
          <ToastContainer autoClose={3000} />
        </Router>

      </PersistGate>
    </Provider>
  );
}

export default App;
