import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import {
    saveTrainingPlanRequestSuccess,
    saveTrainingPlanRequestFailure
} from './actions';

import { updateProfileSuccess } from '../user/actions'

export function* saveTrainingPlanRequest({ payload }) {
    try {
        yield call(api.post, 'training_plan_requests', payload.data);

        if (payload.userProfileToUpdate) {
            const response = yield call(api.put, `users/${payload.data.user_id}`, payload.userProfileToUpdate)

            yield put(updateProfileSuccess(response.data))
        }

        yield put(saveTrainingPlanRequestSuccess())
    } catch (error) {
        yield put(saveTrainingPlanRequestFailure())

        if (error.response.data?.message && error.response.data?.message === 'user_has_request_already_open') {
            toast.warn('Você já possui uma solicitação em aberto.')
        } else {
            toast.error('Erro ao realizar solicitação! Tente novamente.')
        }
    }
}

export default all([
    takeLatest('@trainingPlanRequests/SAVE_TRAINING_PLAN_REQUEST_REQUEST', saveTrainingPlanRequest)
]); 