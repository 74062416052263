import React from 'react';

import moment from 'moment'

import iconBike from '~/assets/img/training_types/icone-bike.png';
import iconRun from '~/assets/img/training_types/icone-corrida.png';
import iconDrills from '~/assets/img/training_types/icone-educativo.png';
import iconWeight from '~/assets/img/training_types/icone-exercicio.png';
import iconSwim from '~/assets/img/training_types/icone-natacao.png';
import iconOthers from '~/assets/img/training_types/icone-outros.png';

const TRAINING_CATEGORIES = {
  Run: 'Corrida',
  WeightTraining: 'Treino de Força',
  Workout: 'Treino',
  Walk: 'Caminhada',
  Ride: 'Bicicleta',
  Swim: 'Natação',
  Drills: 'Educativo'
};

export function formatTrainingCategory(categoryStr) {
  const resp = TRAINING_CATEGORIES[categoryStr]
    ? TRAINING_CATEGORIES[categoryStr]
    : 'Outros';

  return resp;
}

export function formatZone(zone) {
  if (zone) {
    switch (zone) {
      case 'ZONA-1':
        return `Zona 1 (Leve)`
      case 'ZONA-2':
        return `Zona 2 (Moderado)`
      case 'ZONA-3':
        return `Zona 3 (Pouco Difícil)`
      case 'ZONA-4':
        return `Zona 4 (Difícil)`
      case 'ZONA-5a':
        return `Zona 5a (Muito Difícil)`
      case 'ZONA-5b':
        return `Zona 5b (Extremamente Difícil)`
      case 'ZONA-5c':
        return `Zona 5c (Limite Máximo)`

      default:
        return `Zona`
    }
  }
}

export function formatTrainingIcon(type) {
  let currIcon = null;

  switch (type) {
    case 'Run':
      currIcon = iconRun;
      break;
    case 'WeightTraining':
      currIcon = iconWeight;
      break;
    case 'Workout':
      currIcon = iconWeight;
      break;
    case 'Ride':
      currIcon = iconBike;
      break;
    case 'Swim':
      currIcon = iconSwim;
      break;
    case 'Drills':
      currIcon = iconDrills;
      break;
    default:
      currIcon = iconOthers;
      break;
  }

  return (<img src={currIcon} alt="Treino de Corrida" style={{ width: 20, height: 20 }} />)
}

export function isAdm(userID) {
  return (
    userID === 1 ||
    userID === 2 ||
    userID === 3 ||
    userID === 4 ||
    userID === 5 ||
    userID === 8 ||
    userID === 10 ||
    userID === 2966 ||
    userID === 4283
  )
}

export function formatDuration(duration, type = null) {
  let hoursStr = '';
  let minutesStr = '';
  let secondsStr = '';

  let hours = 0;
  let minutes = 0;
  let sec = 0;

  try {
    if (duration && duration > 0) {
      if (duration >= 60) {
        hours = duration / 60;
        minutes = (hours % 1) * 60;
      } else {
        minutes = duration;
      }
      sec = (minutes % 1) * 60;

      hoursStr = hours < 10 ? `0${Math.floor(hours)}` : `${Math.floor(hours)}`;
      minutesStr = minutes < 10 ? `0${Math.floor(minutes)}` : `${Math.floor(minutes)}`;
      secondsStr = sec < 10 ? `0${Math.floor(sec)}` : `${Math.floor(sec)}`;

      switch (type) {
        case 'time':
          return `${hoursStr}:${minutesStr}:${secondsStr}`;
        case 'pace':
          return `${minutesStr}:${secondsStr}`;
        default: {
          if (hours > 0) {
            return `${hoursStr}h ${minutesStr}m`;
          }
          return `${minutesStr}m ${secondsStr}s`;
        }
      }
    }
  } catch (error) {
    //
  }

  return '--';
}

export function formatFixedNum(num, label = '', defaultVal = '-') {

  try {

    if (num) {
      return `${num.toFixed(0)} ${label}`;
    }
  } catch (error) {
  }

  return defaultVal;
}

export function formatTimer(intTimer) {
  const sec_num = parseInt(intTimer, 10); // don't forget the second param
  let minutes = Math.floor((sec_num) / 60);
  let seconds = sec_num - (minutes * 60);

  if (minutes < 10) { minutes = `0${minutes}`; }
  if (seconds < 10) { seconds = `0${seconds}`; }

  return { minutes, seconds }
}

export function formatDateToServer(date, hour = null, min = null) {

  try {
    date = moment(new Date(date)).toDate();

    if (hour) {
      date.setHours(hour || 0);
    }

    if (min) {
      date.setMinutes(min || 0);
    }

    date.setSeconds(0);
    date.setHours(date.getHours() + 3);

    return date;
  } catch (error) {
    date.setHours(date.getHours() + 3);

    return date;
  }
}

export function formatDistance(distance) {
  if (distance) {
    const distanceTotal = distance * 1000

    const result = distanceTotal < 1000 ? `${distanceTotal}m` : `${distance.toFixed(1)}km`

    return result;
  }

  return '-';
}

export function formatActivityTypeIcon(type) {
  switch (type) {
    case 'Run':
      return iconRun;

    case 'Ride':
      return iconBike;

    case 'Swim':
      return iconSwim;

    case 'WeightTraining':
      return iconWeight;
    case 'Walk':
      return iconRun;
    case 'Drills':
      return iconDrills;

    default:
      return iconOthers;
  }
}

export function formatSubscriptionStatus(status) {
  switch (status) {
    case 'active':
      return 'ATIVA';

    case 'canceled':
      return 'CANCELADA';

    case 'finished':
      return 'FINALIZADA';

    case 'pending':
      return 'PENDENTE';
    default:
      return '--';
  }
}

export const formatBillStatus = {
  'paid': {
    title: 'PAGA',
    className: 'badge badge-success'
  },
  'pending': {
    title: 'PENDENTE',
    className: 'badge badge-warning'
  },
  'review': {
    title: 'PENDENTE',
    className: 'badge badge-warning'
  },
  'scheduled': {
    title: 'PENDENTE',
    className: 'badge badge-warning'
  },
  'processing': {
    title: 'PENDENTE',
    className: 'badge badge-warning'
  },
  'canceled': {
    title: 'CANCELADA',
    className: 'badge badge-danger'
  }
}

export function formatTrainingIntensity(intensity) {
  switch (intensity) {
    case 1:
      return 'Iniciante'

    case 2:
      return 'Intermediário'

    case 3:
      return 'Avançado'
    default:
      return '-'
  }
}

export const shuffle = (array) => {
  var m = array.length, t, i;

  // While there remain elements to shuffle…
  while (m) {

    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

export function formatRequestKeepFitType(value) {
  switch (value) {
    case 'Quero apenas manter minha forma':
      return 'manutenção nos'
    case 'Tenho um objetivo próximo com data marcada':
      return 'manutenção com objetivo nos'
    default:
      return ''
  }
}

export function formatTrainingPlanRequestObjective(value) {
  switch (value) {
    case 'quero melhorar':
      return 'melhorar nos'
    case 'primeira vez':
      return 'completar os'
    default:
      return ''
  }
}
