import produce from 'immer';

import moment from 'moment'

const INITIAL_STATE = {
    loading: false,
    expirationDate: null,
    data: null
};

export default function userTrainingPlan(state = INITIAL_STATE, action) {

    return produce(state, draft => {

        switch (action.type) {

            case '@userTrainingPlan/LOAD_USER_TRAINING_PLAN_REQUEST': {
                draft.loading = true
                break;
            }

            case '@userTrainingPlan/LOAD_USER_TRAINING_PLAN_SUCCESS': {
                draft.data = action.payload.data
                draft.loading = false;
                draft.expirationDate = moment().add(30, 'seconds').format('YYYY-MM-DD HH:mm');
                break;
            }

            case '@userTrainingPlan/LOAD_USER_TRAINING_PLAN_FAILURE': {
                draft.loading = false;
                break;
            }

            default:
        }
    })
}
