import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';

import api from '~/services/api';

import Loader from '~/components/Loader/Loader';

import { Container } from './styles';

export default class TrainingCategory extends Component {
    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);

        this.state = {
            pageData: [],
            loading: true
        }

        this.loadData();
    }

    loadData = async () => {
        const { data } = await api.get('training_categories');

        this.setState({...this.state, pageData: data, loading: false });
    }

    render() {

        return (
            <React.Fragment>
                {this.state.loading && <Loader />}

                <Row>
                    <Col>
                        <div className="page-title-box">
                            <Row>
                                <Col lg={7}>
                                    <h4 className="page-title">Categorias de treino</h4>
                                </Col>
                                <Col lg={5} className="mt-lg-3 mt-md-0">
                                    
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <p>TrainingCategory content</p>
                                <>
                                <table className="table table-centered mb-0" id="btn-editable">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Criação</th>
                                        <th className="tabledit-toolbar-column"></th></tr>
                                    </thead>

                                    <tbody>
                                        { this.state.pageData.map( data => (
                                            <tr id="1">
                                                <td><span className="tabledit-span tabledit-identifier">{data.id}</span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">{data.name}</span></td>
                                                <td className="tabledit-view-mode"><span className="tabledit-span">{data.created_at}</span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
        </React.Fragment>

        );
        
    };
}
