import React from "react"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"

export default function PauseModal({ show, toggle }) {
  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
    >
      <ModalBody style={{ margin: "auto" }}>
        <p>
          Para solicitar a pausa nos serviços, envie um e-mail para{" "}
          <b>
            <u>assinatura@corridaperfeita.com</u>
          </b>{" "}
          informando quantos meses (de 1 a 6) você pretende se ausentar.
        </p>
        <p>Lembrando que a pausa não interrompe eventuais parcelas do seu pagamento feitas por cartão.</p>
      </ModalBody>
      <ModalFooter>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto ",
            width: "50%",
          }}
        >
          <a href="mailto:assinatura@corridaperfeita.com" className="btn btn-primary" style={{ marginTop: 45, marginBottom: 10, height: 45 }}>
            ENVIAR EMAIL
          </a>

          <Button color="danger" style={{ marginBottom: 45, height: 45 }} onClick={toggle}>
            SAIR
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
