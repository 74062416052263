import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import Switch from 'react-switch';
import { FaHeart, FaBars, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { setCourseFlow } from '~/store/modules/auth/actions';

import { Animated } from "react-animated-css";
import history from '~/services/history';

import api from '~/services/api';

const CourseExecMain = ({
  collapsed,
  rtl,
  image,
  course,
  module,
  lecture,
  lectureIndex,
  moduleIndex,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  updateCourse,
  updateCurrents,
  updateModule,
  exitCourse
}) => {
  const dispatch = useDispatch();

  const [currentCourse, setCurrentCourse] = useState()
  const [currentModule, setCurrentModule] = useState()
  const [currentModuleIndex, setCurrentModuleIndex] = useState(0)
  const [currentLecture, setCurrentLecture] = useState()
  const [currentLectureIndex, setCurrentLectureIndex] = useState(0)

  const [animationVisible, setAnimationVisible] = useState(true)

  useEffect(() => {
    setCurrentCourse(course)
    setCurrentModule(module)
    setCurrentLecture(lecture)
    setCurrentLectureIndex(lectureIndex)
    setCurrentModuleIndex(moduleIndex)
  }, [course, lecture, lectureIndex, module, moduleIndex])

  const handlePrevLecture = async () => {
    const nextIdx = currentLectureIndex - 1;

    const cuuLec = currentModule.courseLectures[nextIdx];

    setCurrentLecture(cuuLec)
    setCurrentLectureIndex(nextIdx)

    if (updateCurrents) {
      updateCurrents(cuuLec, nextIdx)
    }
  }

  const handleNextLecture = async () => {
    await setAnimationVisible(false)
    const nextIdx = currentLectureIndex + 1;

    const cuuLec = currentModule.courseLectures[nextIdx];

    await setAnimationVisible(true)
    setCurrentLecture(cuuLec)
    setCurrentLectureIndex(nextIdx)

    if (updateCurrents) {
      updateCurrents(cuuLec, nextIdx)
    }
  }

  const handleDoneLecture = async () => {
    const response = await api.post(`/courses/${currentCourse.id}/finish-lecture?course_lecture_id=${currentLecture.id}`);

    if (response.status === 200) {
      if (updateCourse) {
        currentLecture.executed_status = 'EXECUTED_HIGH'
        setCurrentLecture(currentLecture)
        updateCourse(response.data)
      }
    } else {
      //
    }
  }

  const handleFinishModule = async () => {
    const nextModuleIdx = currentModuleIndex + 1;

    let newCModules = currentCourse.courseModules[nextModuleIdx];
    let cuuLec = null;
    let currentLectureIndex = 0;

    if (newCModules) {
      const newActs = [...newCModules.courseLectures, { id: 66666, name: 'Finalizar Módulo', activity_type: 'FINISH' }]
      newCModules = { ...newCModules, courseLectures: newActs }

      for (let i = 0; i < newCModules.courseLectures.length; i++) {
        const currCL = newCModules.courseLectures[i];

        if (currCL && (!currCL.executed_status || currCL.executed_status !== 'EXECUTED_HIGH')) {
          currentLectureIndex = i;
          cuuLec = newCModules.courseLectures[i]
          break;
        }
      }

      setCurrentModule(newCModules)
      setCurrentLecture(cuuLec)
      setCurrentLectureIndex(currentLectureIndex)
      setCurrentModuleIndex(nextModuleIdx)

      if (updateModule) {
        updateModule(cuuLec, currentLectureIndex, newCModules, nextModuleIdx)
      }
    } else {
      if (exitCourse) {
        exitCourse()
      }
    }
  }

  return (
    <main style={{ width: '100%', padding: 10 }}>
      <div className="btn-toggle" onClick={() => handleCollapsedChange(!collapsed)}  style={{width: 60}}>
        {/* <Switch height={16} width={30} checkedIcon={false} uncheckedIcon={false} onChange={handleCollapsedChange} checked={collapsed} onColor="#219de9" offColor="#bbbbbb" /> */}
        <FaBars />
      </div>
      <Card style={{marginTop: 5, borderRadius: 30}}>
        <CardBody>

          <header style={{ width: '100%' }}>
            {currentLectureIndex !== (currentModule?.courseLectures?.length - 1) &&
              <Row style={{  }}>
                <Col lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {currentLectureIndex > 0 &&
                    <Button color="primary" outline onClick={() => handlePrevLecture()} style={{ display: 'flex', flex: 1, width: 200, justifyContent: 'center', alignItems: 'center' }}>
                      <FaArrowLeft style={{ marginRight: 10 }} />
                      Anterior
                    </Button>}
                </Col>
                <Col lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button color="primary" outline onClick={() => handleDoneLecture()} style={{ display: 'flex', flex: 1, width: 200, justifyContent: 'center', alignItems: 'center'}}>
                    <Switch height={20} width={34} checkedIcon={false} uncheckedIcon={false} onChange={handleDoneLecture} checked={(currentLecture?.executed_status && currentLecture?.executed_status !== 'EXECUTED_LOW') ? true : false} onColor="#21e99d" offColor="#bbbbbb" />
                    <span style={{ marginLeft: 10 }}>Concluído</span>
                  </Button>
                </Col>
                <Col lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button color="primary" outline onClick={() => handleNextLecture()} style={{ display: 'flex', flex: 1, width: 200, justifyContent: 'center', alignItems: 'center'}}>
                    Próximo
                    <FaArrowRight style={{ marginLeft: 10 }} />
                  </Button>
                </Col>
              </Row>}
          </header>

          <div style={{marginTop: 15}}>
            {currentLecture?.activity_type !== 'FINISH' &&
              <div className="block " style={{ marginLeft: 100, marginRight: 100 }}>
                
                {currentLecture?.media ? 
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={currentLecture.media} height="600" alt="Mídia Exemplificando" />
                  </div>
                  :
                  <div className="embed-responsive embed-responsive-16by9" style={{ height: 600, marginTop: 0}}>
                    <iframe className="embed-responsive-item" title="video" src={currentLecture?.video} height="600" style={{height: 600}} allow="fullscreen"></iframe>
                  </div>}

                <h3>
                  {currentLecture?.name}
                </h3>

                <p style={{ marginTop: 20 }}>
                  {currentLecture?.content}
                </p>

                <div>
                
                {currentLecture?.external_link && currentLecture?.external_link_title && 
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10}}>
                    <a href={currentLecture.external_link} target="_blank" rel="noreferrer" className="btn btn-bordered-primary" style={{width: 400}}>
                        {currentLecture.external_link_title}
                    </a>
                  </div>}

                </div>
              </div>}
            {currentLecture?.activity_type === 'FINISH' &&
              <div className="block ">
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <h4>Módulo</h4>
                  <h3>{currentModule.name}</h3>
                  <h4>Finalizado</h4>
                  <Button color="success" onClick={() => handleFinishModule()} style={{ marginTop: 40, marginBottom: 20 }}>
                    <p>Próximo Módulo</p>
                  </Button>
                </div>
              </div>}
          </div>
        </CardBody>
      </Card>

    </main>
  );
};

export default CourseExecMain;