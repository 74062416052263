import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  loadUserActivitiesRequest,
  loadUserActivitiesSuccess,
  loadUserActivitiesFailure,
  saveUserActivitySuccess,
  saveUserActivityFailure,
  updateUserActivitySuccess,
  updateUserActivityFailure,
  toggleModalActivity,
  setActivityToUpdate
} from './actions';

import {
  updateProfileSuccess
} from '../user/actions'

export function* loadUserActivities({ payload }) {
  try {
    const { userId, fromCalendarRefresh } = payload;

    const response = yield call(api.get, `user_activities?user_id=${userId}&page=1`);

    yield put(loadUserActivitiesSuccess(response.data))

    const user = yield call(api.get, `users/${userId}`)

    yield put(updateProfileSuccess(user.data))

    if (fromCalendarRefresh) window.location.reload()
  } catch (error) {
    toast.error('Erro ao carregar atividades!');
    yield put(loadUserActivitiesFailure())
  }
}

export function* saveUserActivity({ payload }) {
  try {
    const { data, usedExternal } = payload

    const response = yield call(api.post, `user_activities`, data);

    toast.success('Atividade salva com sucesso!');

    yield put(saveUserActivitySuccess(response.data));

    if (!usedExternal) yield put(toggleModalActivity())
    if (usedExternal) {
      const userId = yield select(state => state.user.profile.id)
      yield put(loadUserActivitiesRequest(userId, true))
    }
  } catch (err) {
    toast.error('Falha na criação. Houve um erro na criação da sua atividade, verifique seus dados');
    yield put(saveUserActivityFailure());
  }
}

export function* updateUserActivity({ payload }) {
  try {
    const { id, ...data } = payload.data
    const { usedExternal } = payload

    const response = yield call(api.put, `user_activities/${id}`, data);

    toast.success('Atividade atualizada com sucesso!')

    yield put(updateUserActivitySuccess(response.data))

    if (!usedExternal) yield put(toggleModalActivity())

    if (usedExternal) {
      const userId = yield select(state => state.user.profile.id)
      yield put(loadUserActivitiesRequest(userId, true))
    }

    yield put(setActivityToUpdate(null))
  } catch (error) {
    toast.error('Falha na alteração. Houve um erro na alteração da sua atividade, verifique seus dados');
    yield put(updateUserActivityFailure());
    if (!payload.usedExternal) yield put(toggleModalActivity())
    yield put(setActivityToUpdate(null))
  }
}

export default all([
  takeLatest('@userActivity/LOAD_USER_ACTIVITIES_REQUEST', loadUserActivities),
  takeLatest('@userActivity/SAVE_USER_ACTIVITY_REQUEST', saveUserActivity),
  takeLatest('@userActivity/UPDATE_USER_ACTIVITY_REQUEST', updateUserActivity)
]);
