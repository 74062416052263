export function loadLiveLecturesRequest() {
    return {
        type: '@liveLecture/LOAD_LIVE_LECTURES_REQUEST'
    };
}

export function loadLiveLecturesSuccess(data) {
    return {
        type: '@liveLecture/LOAD_LIVE_LECTURES_SUCCESS',
        payload: { data },
    };
}

export function loadLiveLecturesFailure() {
    return {
        type: '@liveLecture/LOAD_LIVE_LECTURES_FAILURE'
    };
}

export function toggleShowModalLecture() {
    return {
        type: '@liveLecture/TOGGLE_SHOW_MODAL_LECTURE'
    };
}

export function startLiveLectureRequest(lectureId) {
    return {
        type: '@liveLecture/START_LIVE_LECTURE_REQUEST',
        payload: { lectureId },
    };
}

export function startLiveLectureSuccess(data) {
    return {
        type: '@liveLecture/START_LIVE_LECTURE_SUCCESS',
        payload: { data }
    };
}

export function startLiveLectureFailure() {
    return {
        type: '@liveLecture/START_LIVE_LECTURE_FAILURE'
    };
}

export function finishLiveLectureRequest(data) {
    return {
        type: '@liveLecture/FINISH_LIVE_LECTURE_REQUEST',
        payload: { data }
    };
}

export function finishLiveLectureSuccess() {
    return {
        type: '@liveLecture/FINISH_LIVE_LECTURE_SUCCESS'
    };
}

export function finishLiveLectureFailure() {
    return {
        type: '@liveLecture/FINISH_LIVE_LECTURE_FAILURE'
    };
}