import React, { useState } from 'react';
import { Row, Col } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { updateProfileRequest } from '~/store/modules/user/actions'
import moment from 'moment'
import defaultUserImage from '~/assets/img/default_user_image.png'
import api from '~/services/api';
import Loader from '~/components/Loader/Loader';

function Profile({ profile }) {
  const dispatch = useDispatch()
  const [sUsername, setSUsername] = useState(profile.name)
  const [bio, setBio] = useState(profile.bio || '')
  const [genre, setGenre] = useState(profile.genre || '')
  const [weight, setWeight] = useState(profile.weight || '')
  const [height, setHeight] = useState(profile.height || '')
  const [birth, setBirth] = useState(moment(profile.birth).format('YYYY-MM-DD'))
  const [sPhoto, setSPhoto] = useState(profile.s_photo || '')
  const [photoToUpload, setPhotoToUpload] = useState(null)
  const [updatePictureLoading, setUpdatePictureLoading] = useState(false)

  async function uploadProfilePicture() {
    if (photoToUpload) {
      const photoPayload = new FormData()

      photoPayload.append('file', photoToUpload)

      setUpdatePictureLoading(true)

      await api.post(`/files/${profile.id}/cloud`, photoPayload)

      setUpdatePictureLoading(false)
    }
  }

  async function handleUpdateProfile(event) {
    event.preventDefault()

    const data = {
      id: profile.id,
      name: sUsername,
      bio,
      genre,
      weight,
      height,
      birth
    }

    await uploadProfilePicture()

    dispatch(updateProfileRequest(data))
  }

  function handleUpdateProfilePicture(event) {
    if (event.target.files.length) {
      const file = event.target.files[0]
      setPhotoToUpload(file)
      const path = URL.createObjectURL(file)
      setSPhoto(path)
    }
  }

  return (
    <Row>
      <Col>
        <div className="p-2">
          <form onSubmit={handleUpdateProfile} className="form-horizontal">
            <Row>
              <Col sm={3} lg={2}>
                {
                  updatePictureLoading ?
                    <Loader />
                    :
                    <img src={sPhoto ? sPhoto : defaultUserImage} className="rounded-circle img-thumbnail" alt="profile" />
                }
              </Col>
              <Col sm={9}>
                <div className="form-group row">
                  <Col sm={12}>
                    <label className="col-sm-12 col-form-label" htmlFor="avatar">Foto de perfil</label>

                    <div className="col-sm-6">
                      <input
                        type="file"
                        id="avatar"
                        name="avatar"
                        className="form-control-file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleUpdateProfilePicture}
                      />
                    </div>
                  </Col>
                </div>

                <div className="form-group row">
                  <Col sm={12}>
                    <label className="col-sm-12 col-form-label" htmlFor="s_username">Nome de exibição</label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="s_username"
                        name="s_username"
                        className="form-control"
                        value={sUsername}
                        onChange={e => setSUsername(e.target.value)} />
                    </div>
                  </Col>
                </div>

                <div className="form-group row">
                  <Col sm={12}>
                    <label className="col-sm-12 col-form-label" htmlFor="bio">Sobre mim</label>
                    <div className="col-sm-6">
                      <input
                        type="text"
                        id="bio"
                        name="bio"
                        className="form-control"
                        value={bio}
                        onChange={e => setBio(e.target.value)} />
                    </div>
                  </Col>
                </div>

                <div className="form-group row">
                  <Col sm={12}>
                    <label className="col-sm-12 col-form-label" htmlFor="birth">Data de nascimento</label>
                    <div className="col-sm-6">
                      <input
                        type="date"
                        id="birth"
                        name="birth"
                        className="form-control"
                        value={birth}
                        onChange={e => setBirth(e.target.value)} />
                    </div>
                  </Col>
                </div>

                <div className="form-group row">
                  <Col sm={4}>
                    <label className="col-sm-12 col-form-label" htmlFor="genre">Gênero</label>
                    <div className="col-sm-12">
                      <select
                        id="genre"
                        name="genre"
                        className="form-control"
                        value={genre}
                        onChange={e => setGenre(e.target.value)}>
                        <option value="0">Selecione</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                      </select>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <label className="col-sm-12 col-form-label" htmlFor="weight">Peso(kg)</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="weight"
                        name="weight"
                        className="form-control"
                        value={weight}
                        onChange={e => setWeight(e.target.value)} />
                    </div>
                  </Col>
                  <Col sm={4}>
                    <label className="col-sm-12 col-form-label" htmlFor="height">Altura(cm)</label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        id="height"
                        name="height"
                        className="form-control"
                        value={height}
                        onChange={e => setHeight(e.target.value)} />
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="mt-4">
                <button
                  type="submit"
                  className="btn btn-bordered-primary waves-effect width-md waves-light btn-xs-block">Salvar</button>
              </Col>
            </Row>
          </form>
        </div>
      </Col>
    </Row>
  );
}

export default Profile;
