export function loadUserActivitiesRequest(userId, fromCalendarRefresh) {
    return {
        type: '@userActivity/LOAD_USER_ACTIVITIES_REQUEST',
        payload: { userId, fromCalendarRefresh },
    };
}

export function loadUserActivitiesSuccess(data) {
    return {
        type: '@userActivity/LOAD_USER_ACTIVITIES_SUCCESS',
        payload: { data },
    };
}

export function loadUserActivitiesFailure() {
    return {
        type: '@userActivity/LOAD_USER_ACTIVITIES_FAILURE'
    };
}

export function saveUserActivityRequest(data, usedExternal) {
    return {
        type: '@userActivity/SAVE_USER_ACTIVITY_REQUEST',
        payload: { data, usedExternal },
    };
}

export function saveUserActivitySuccess(data) {
    return {
        type: '@userActivity/SAVE_USER_ACTIVITY_SUCCESS',
        payload: { data },
    };
}

export function saveUserActivityFailure() {
    return {
        type: '@userActivity/SAVE_USER_ACTIVITY_FAILURE',
    };
}

export function updateUserActivityRequest(data, usedExternal) {
    return {
        type: '@userActivity/UPDATE_USER_ACTIVITY_REQUEST',
        payload: { data, usedExternal },
    };
}

export function updateUserActivitySuccess(data) {
    return {
        type: '@userActivity/UPDATE_USER_ACTIVITY_SUCCESS',
        payload: { data },
    };
}

export function updateUserActivityFailure() {
    return {
        type: '@userActivity/UPDATE_USER_ACTIVITY_FAILURE',
    };
}

export function toggleModalActivity() {
    return {
        type: '@userActivity/TOGGLE_MODAL_ACTIVITY'
    }
}

export function setActivityToUpdate(data) {
    return {
        type: '@userActivity/SET_ACTIVITY_TO_UPDATE',
        payload: { data }
    }
}