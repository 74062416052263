import React, { useState } from 'react';

import {
  Modal, ModalBody, ModalFooter
} from 'reactstrap';

import Loader from '~/components/Loader/Loader';

import { useDispatch, useSelector } from 'react-redux'

import { toggleShowModalLecture } from '~/store/modules/liveLectures/actions'
import { FaSignInAlt, FaExclamationCircle } from 'react-icons/fa';

import ModalInfo from '../ModalInfo'
import ModalInfoRecordedLectures from './ModalInfoRecordedLectures'

import { Title } from './styles'

function ModalLecture({ lecture }) {
  const dispatch = useDispatch()

  const loading = useSelector(state => state.liveLectures.modalLoading)

  const modal = useSelector(state => state.liveLectures.modalShowLecture)

  const toggle = () => dispatch(toggleShowModalLecture())

  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

  const [modalHowWorks, setModalHowWorks] = useState(false)
  const [modalInfoRecordedLectures, setModalInfoRecordedLectures] = useState(false)

  return (
    <div>
      <ModalInfo modal={modalHowWorks} toggle={() => setModalHowWorks(false)} />
      <ModalInfoRecordedLectures modal={modalInfoRecordedLectures} toggle={() => setModalInfoRecordedLectures(false)} />

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <Title>
          <h5>{lecture.title}</h5>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <h5 onClick={() => setModalHowWorks(true)} style={{ marginRight: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <FaExclamationCircle />&nbsp;
              Como funciona
            </h5>
            {closeBtn}
          </div>
        </Title>
        <ModalBody>
          {loading && <Loader />}

          <p><b>Nível:</b> {lecture.level}</p>

          <p><b>Quando fazer:</b> {lecture.when_to_do}</p>

          <p><b>Equipamentos:</b> {lecture.equipments}</p>

          <p><b>Transmissão ao vivo:</b> {lecture.day}</p>

          <br />

          <a href={lecture.zoom_link} target="_blank" rel="noopener noreferrer" className="btn btn-bordered-primary btn-block mb-2">
            Acesso à sala do Zoom com o professor&nbsp;&nbsp;
            <FaSignInAlt color="white" size={20} />
          </a>

          <p onClick={() => setModalInfoRecordedLectures(true)} style={{ fontWeight: 'bold', cursor: 'pointer', color: '#081C3A', textDecoration: 'underline #081C3A', textAlign: 'center' }}>
            Não poderá estar ao vivo no horário?
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-bordered-primary" onClick={toggle}>Fechar</button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ModalLecture;
