import React, {useState} from 'react';
import { Row, Col, Button, Card, CardBody, } from 'reactstrap';
import { useSelector  } from 'react-redux';

import Loader from '~/components/Loader/Loader';
import TainingNewModal from '../Training/new/TainingNewModal';

export default function Calendar() {

  const loading = useSelector(state => state.auth.loading);
  const [training, setTraining] = useState({trainingGroups: []});
  const [show, setShow] = useState(false);

  function handleModal() {
    setShow(!show);
  }

  return (
    <React.Fragment>
            {loading && <Loader />}

            <Row>
                <Col>
                    <div className="page-title-box">
                        <Row>
                            <Col lg={7}>
                                <h4 className="page-title">Treinos</h4>
                            </Col>
                            <Col lg={5} className="mt-lg-3 mt-md-0">
                                
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card className="shadow">
                        <CardBody>
                            
                            <Button color="info" onClick={handleModal}>Cadastrar Treino</Button>

                            {show && <TainingNewModal open={show} training={training} toggle={handleModal} /> }

                        </CardBody>
                    </Card>
                </Col>
            </Row>
    </React.Fragment>
  );
}
