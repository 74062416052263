import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert } from 'reactstrap';
import api from '~/services/api'
import logo from '~/assets/img/logo_corridaperfeita_azul.png';
import queryString from 'query-string'
import history from '~/services/history';


const ConfirmEmail = (props) => {

  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  const confirmEmail = async () => {
    if(token){
      await api.put(`/confirm_email?token=${token}`);
      history.push('/confirmar-email-sucesso')
    }else{
      setError(true);
    }
  }

  const getUser = async (token) => {
    if(token){
      const response = await api.get(`get_user_by_token?token=${token}`)
      setUser(response.data)
    }
  }

  useEffect(() => {
    const query = queryString.parse(props.location.search)
    
    if(query.token){
      setToken(query.token);
      getUser(query.token);
    }

  }, [])

  return (
    <>
      <div className="account-pages mt-5 mb-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <Card>
              {error && <Alert color="danger" isOpen={error ? true : false}>
                <div>{'Token inválido!'}</div>
                </Alert>}
                <CardBody className="p-4 justify-content-center">
                  <div className="text-center w-90 m-auto">
                    <a href="/">
                      <span><img src={logo} alt="" height="48" /></span>
                    </a>
                    { user ? <p className="text-muted mb-4 mt-3">{user.name}, clique no botão abaixo para confirmar o email <b>{user.email}.</b></p>
                    : <p className="text-muted mb-4 mt-3">Clique no botão para confirmar o seu email.</p>}
                  </div>

                  <Button style={{ background: '#081C39' }} className="btn-block" onClick={() => confirmEmail()} >Confirmar Email</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ConfirmEmail;