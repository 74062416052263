import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom'
import { TabContent, TabPane, Row, Col } from 'reactstrap';
import Loader from '~/components/Loader/Loader';
import { updateStravaIntegrated, integrateStrava, updateGarminIntegrated, integrateGarmin } from '~/store/modules/user/actions'
import { Container } from './styles'
import queryString from 'query-string'
import AccountData from './components/AccountData'
import Profile from './components/Profile'
import Integrations from './components/Integrations'
import Subscription from './components/Subscription'
import api from '~/services/api'
import ZoneTab from './ZoneTab'
import { toast } from 'react-toastify';
import history from '~/services/history';
import { isAdm } from '~/services/util'

export default function ProfileScreen() {
  const dispatch = useDispatch()
  const profile = useSelector(state => state.user.profile);
  const loading = useSelector(state => state.user.loading);
  const [subscription, setSubscription] = useState()
  const [subscriptions, setSubscriptions] = useState()
  const [loadSubscriptionErrorMessage, setLoadSubscriptionErrorMessage] = useState(null)
  const location = useLocation()
  const tab = location.hash.split('#')[1]
  const [activeTab, setActiveTab] = useState(tab);
  const parsedParams = queryString.parse(location.search)
  const code = parsedParams.code
  const oauth_token = parsedParams.oauth_token
  const oauth_token_secret = parsedParams.oauth_token_secret
  const oauth_verifier = parsedParams.oauth_verifier

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {
    setActiveTab(tab)
  }, [tab])

  const getGarminAccessToken = useCallback(async () => {
    try {
      const response = await api.post('integrations/garmin/getGarminAccessToken', { oauth_verifier, oauth_token, oauth_token_secret })

      if (response.data && response.data.token) {
        let splitedToken = response.data.token.split('&')
        const token = splitedToken[0].split('=')[1]
        const secret = splitedToken[1].split('=')[1]

        dispatch(updateGarminIntegrated())

        dispatch(integrateGarmin({ profileId: profile.id, token, secret }))

        history.push('/perfil#integracoes')
      } else {
        toast.error('Ocorreu um erro ao integrar com a Garmin, tente novamente.')
      }
    } catch (error) {
      toast.error('Ocorreu um erro ao integrar com a Garmin, tente novamente.')
    }
  }, [dispatch, profile.id, oauth_verifier, oauth_token, oauth_token_secret]);

  useEffect(() => {
    const parsedParams = queryString.parse(location.search)

    if (parsedParams.oauth_token && parsedParams.oauth_token_secret && parsedParams.oauth_verifier !== 'null') {
      setActiveTab('integracoes')

      getGarminAccessToken()
    }

    if (parsedParams.code) {
      dispatch(updateStravaIntegrated())

      setActiveTab('integracoes')

      dispatch(integrateStrava({ profileId: profile.id, code }))

      history.push('/perfil#integracoes')
    }
  }, [profile.id, code, dispatch, location.search, oauth_token, oauth_token_secret, oauth_verifier, getGarminAccessToken])

  useEffect(() => {
    api.get(`subscriptions?userId=${profile.id}`)
      .then((response) => {
        if (response.data.length > 0) {
          setSubscriptions(response.data)
          setSubscription(response.data[0])
        } else {
          setLoadSubscriptionErrorMessage('Sem assinatura corrente')
        }
      })
      .catch(() => {
        setLoadSubscriptionErrorMessage('Erro ao carregar sua assinatura')
      })
  }, [profile]);

  return (
    <Container>
      {loading && <Loader />}

      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={7}>
                <h4 className="page-title">Configurações</h4>
              </Col>
              <Col lg={5} className="mt-lg-3 mt-md-0">

              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="card-box">
            <ul className="nav nav-pills navtab-bg nav-justified">
              <li className="nav-item">
                <a href="#dados-da-conta" data-toggle="tab" aria-expanded="false" onClick={() => { toggle('dados-da-conta'); }} className={`nav-link ${classnames({ active: activeTab === 'dados-da-conta' })}`}>
                  <span className="d-inline-block d-sm-none"><i className="fas fa-user-lock"></i></span>
                  <span className="d-none d-sm-inline-block">Dados da Conta</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#meu-perfil" data-toggle="tab" aria-expanded="false" onClick={() => { toggle('meu-perfil'); }} className={`nav-link ${classnames({ active: activeTab === 'meu-perfil' })}`}>
                  <span className="d-inline-block d-sm-none"><i className="fas fa-user"></i></span>
                  <span className="d-none d-sm-inline-block">Meu Perfil</span>
                </a>
              </li>
              {(profile?.subscription_type === 'ESSENTIAL' || isAdm(profile?.id)) &&
                <li className="nav-item">
                  <a href="#zonas-de-treino" data-toggle="tab" aria-expanded="false" onClick={() => { toggle('zonas-de-treino'); }} className={`nav-link ${classnames({ active: activeTab === 'zonas-de-treino' })}`}>
                    <span className="d-inline-block d-sm-none"><i className="fas fa-list-ol"></i></span>
                    <span className="d-none d-sm-inline-block">Zonas de Treino</span>
                  </a>
                </li>}
              <li className="nav-item">
                <a href="#integracoes" data-toggle="tab" aria-expanded="false" onClick={() => { toggle('integracoes'); }} className={`nav-link ${classnames({ active: activeTab === 'integracoes' })}`}>
                  <span className="d-inline-block d-sm-none"><i className="fas fa-cog"></i></span>
                  <span className="d-none d-sm-inline-block">Integrações</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="#assinatura" data-toggle="tab" aria-expanded="false" onClick={() => { toggle('assinatura'); }} className={`nav-link ${classnames({ active: activeTab === 'assinatura' })}`}>
                  <span className="d-inline-block d-sm-none"><i className="fas fa-money-check-alt"></i></span>
                  <span className="d-none d-sm-inline-block">Assinatura</span>
                </a>
              </li>
            </ul>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="dados-da-conta">
                {profile && <AccountData profile={profile} />}
              </TabPane>

              <TabPane tabId="meu-perfil">
                {profile && <Profile profile={profile} />}
              </TabPane>

              {(profile?.subscription_type === 'ESSENTIAL' || isAdm(profile?.id)) &&
                <TabPane tabId="zonas-de-treino">
                  <ZoneTab />
                </TabPane>}

              <TabPane tabId="integracoes">
                {profile && <Integrations profile={profile} />}
              </TabPane>

              <TabPane tabId="assinatura" className="subscription">
                {profile && <Subscription profile={profile} subscription={subscription} loadSubscriptionErrorMessage={loadSubscriptionErrorMessage} />}
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
