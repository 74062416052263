import React, { useState, useMemo } from 'react';
import dateFns, { format, addSeconds, addMinutes, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { Card, Row, Col } from 'reactstrap'

import {
  formatTrainingCategory,
  formatDuration,
  formatActivityTypeIcon,
} from '~/services/util';


import {
  ContainerCard,
  Avatar,
  Separator,
  ItemTitle,
  ItemValue,
  ItemBlock,
  ItemsContainer,
  ActivityTitle,
  ActivityDate,
  ActivityUserName,
} from './styles';

import defaultUserImage from '~/assets/img/default_user_image.png'
import { FaPen } from 'react-icons/fa'

export default function UserActivityItem({ item, navigation, profile, saveActivityCallback, updateActivityCallback }) {
  const dateFormatted = useMemo(() => {
    try {
      const newDate = item.start_date.split('T');
      const date = new Date(`${newDate[0]}T${newDate[1]}`);
      return format(date, "dd MMM, yyyy 'às' HH:mm", { locale: pt });
    } catch (error) {
      return '';
    }
  }, [item]);

  function buildFirstBlock(item) {
    if (item.type === 'Run' || item.type === 'Ride' || item.type === 'Swim') {
      return (
        <ItemBlock>
          <ItemTitle>Distancia</ItemTitle>
          <ItemValue>
            {item.distance && item.distance > 0 ? `${item.distance.toFixed(1)} km` : '--'}
          </ItemValue>
        </ItemBlock>
      );
    }
    return (
      <ItemBlock>
        <ItemTitle>Tempo</ItemTitle>
        <ItemValue>{formatDuration(item.duration)}</ItemValue>
      </ItemBlock>
    );
  }

  function buildSecondBlock(item) {
    if (item.type === 'Run') {
      return (
        <ItemBlock>
          <ItemTitle>Ritmo</ItemTitle>
          <ItemValue>
            {item.pace && item.pace > 0 ? `${formatDuration(item.pace, 'pace')} /km` : '--'}
          </ItemValue>
        </ItemBlock>
      );
    }
    if (item.type === 'Swim') {
      return (
        <ItemBlock>
          <ItemTitle>Ritmo</ItemTitle>
          <ItemValue>
            {item.pace && item.pace > 0 ? `${formatDuration(item.pace, 'pace')} /100m` : '--'}
          </ItemValue>
        </ItemBlock>
      );
    }
    if (item.type === 'Ride') {
      return (
        <ItemBlock>
          <ItemTitle>Velocidade</ItemTitle>
          <ItemValue>
            {item.average_speed && item.average_speed > 0 ? `${item.average_speed.toFixed(1)} km/h` : '--'}
          </ItemValue>
        </ItemBlock>
      );
    }
    return (
      <ItemBlock>
        <ItemTitle>FC Média</ItemTitle>
        <ItemValue>
          {item.average_heartrate && item.average_heartrate > 0 ? `${item.average_heartrate.toFixed(0)} bpm` : '--'}
        </ItemValue>
      </ItemBlock>
    );
  }

  function buildThirdBlock(item) {
    if (item.type === 'Run' || item.type === 'Ride' || item.type === 'Swim') {
      return (
        <ItemBlock>
          <ItemTitle>Tempo</ItemTitle>
          <ItemValue>{formatDuration(item.duration)}</ItemValue>
        </ItemBlock>
      );
    }
    return (
      <ItemBlock>
        <ItemTitle>Caloria</ItemTitle>
        <ItemValue>
          {item.calorie && item.calorie > 0 ? `${item.calorie.toFixed(0)} kcal` : '--'}
        </ItemValue>
      </ItemBlock>
    );
  }

  return (
        <Card
          style={{ padding: 10 }}
          key={`${item.id}`}
          onClick={() => {}}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <img className="img-fluid" src={profile.s_photo ? profile.s_photo : defaultUserImage} style={{ borderRadius: 200, marginRight: 10, maxHeight: 80 }} />

            <div style={{ display: 'flex', marginTop: 10, flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
              <div style={{ flex: 1 }}>
                <ActivityUserName>{profile.name}</ActivityUserName>
                <ActivityDate>{dateFormatted}</ActivityDate>
              </div>
              <img style={{ width: 20, marginHorizontal: 10 }} source={formatActivityTypeIcon(item.type)}
              />
            </div>

            <button className="btn" onClick={() => {
              if (updateActivityCallback) {
                updateActivityCallback(item)
              }
            }}>
              <FaPen color="gray" size={18} />
            </button>
          </div>
          <ActivityTitle>{item.title ? item.title : formatTrainingCategory(item.type)}</ActivityTitle>

          <ItemsContainer>
            {buildFirstBlock(item)}
            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', width: 1, height: 'auto', }} />
            {buildSecondBlock(item)}
            <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', width: 1, height: 'auto', }} />
            {buildThirdBlock(item)}
          </ItemsContainer>
        </Card>
  );
}
