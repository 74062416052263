import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Card, CardBody, CardText, CardTitle, Input, InputGroupAddon, InputGroup } from 'reactstrap';
import moment from 'moment'
import api from '~/services/api'
import Loader from '~/components/Loader/Loader';
import Pagination from "react-reactstrap-pagination";
import { FaVideo, FaStar, FaRegStar } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom';
import { Container } from './styles'

function VideoAoVivoScreen() {
  const [loading, setLoading] = useState(false)
  const [liveLectures, setLiveLectures] = useState([])
  const [selectedPage, setSelectedPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [lastPage, setLastPage] = useState(1)
  const [searchString, setSearchString] = useState('0')
  const [showFavorites, setShowFavorites] = useState(false)

  const loadLiveLectures = useCallback(async (page) => {
    try {
      setLoading(true)

      let url = `live_lectures?page=${page}&limit=${perPage}&clone_type=SCHEMA&order=DESC&isRecord=true&obj_type=LECTURE`

      if (searchString !== '0') {
        url = url + `&title=${searchString}`
      }

      if (showFavorites) {
        url = url + '&onlyFavorites=true'
      }

      const { data } = await api.get(url)

      setLiveLectures(data.data)
      setTotalItems(data.total)
      setLastPage(data.lastPage)
      setSelectedPage(data.page)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error('Erro ao carregar gravações!')
    }
  }, [perPage, searchString, showFavorites])

  useEffect(() => {
    loadLiveLectures(1)
  }, [loadLiveLectures])

  return (
    <Container>
      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={7}>
                <h4 className="page-title">Gravações das aulas funcionais ao vivo</h4>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {loading && <Loader />}
              <Row className="mb-2">
                <Col xl={3}>
                  <InputGroup>
                    <Input type="select" placeholder="Filtrar" onChange={(e) => setSearchString(e.target.value)}>
                      <option value="0">Filtrar</option>
                      <option value="Alongamento">Alongamento</option>
                      <option value="Mobilidade">Mobilidade</option>
                      <option value="Alongamento e Mobilidade">Alongamento e Mobilidade</option>
                      <option value="Fortalecimento">Fortalecimento</option>
                      <option value="Fortalecimento e Cardio">Fortalecimento + Cardio</option>
                      <option value="Funcional Cardio">Funcional Cardio</option>
                    </Input>
                    <InputGroupAddon addonType="append">
                      <button
                        style={{ border: 'none', padding: 5, backgroundColor: '#fff' }}
                        onClick={() => setShowFavorites(!showFavorites)}>
                        {showFavorites ? <FaStar className="mr-1" size={25} color="#DEC610" /> : <FaRegStar className="mr-1" size={25} color="#081B39" />}
                        {showFavorites ? 'Favoritas' : 'Todas'}
                      </button>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                {
                  liveLectures.length > 0 &&
                  liveLectures.map(lec => (
                    <Col xl={3} lg={4} key={lec.id}>
                      <Card body>
                        <CardTitle>{lec.name}{lec.favorite && lec.favorite.length > 0 && <FaStar className="ml-1" size={18} color="#DEC610" />}</CardTitle>
                        <CardText>Data: {moment(lec.date).format('DD/MM/YYYY')}</CardText>
                        <Link
                          to={`aula-coletiva/${lec.id}?referrer=gravacoes`}
                          className="btn btn-bordered-primary btn-block">
                          Assistir&nbsp;&nbsp;
                          <FaVideo color="white" size={20} />
                        </Link>
                      </Card>
                    </Col>
                  ))
                }
              </Row>
              <Row>
                <Col style={{ overflow: 'auto' }}>
                  <Pagination
                    firstPageText="Início"
                    lastPageText="Fim"
                    previousPageText="Anterior"
                    nextPageText="Próximo"
                    totalItems={totalItems}
                    pageSize={perPage}
                    maxPaginationNumbers={lastPage}
                    defaultActivePage={selectedPage}
                    onSelect={loadLiveLectures}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container >
  );
}

export default VideoAoVivoScreen;
