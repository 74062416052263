import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify'

import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Alert } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvFeedback } from 'availity-reactstrap-validation';

import { resetPasswordRequest } from '~/store/modules/auth/actions';
import Loader from '~/components/Loader/Loader';
import logo from '~/assets/img/logo_corridaperfeita_azul.png';
import queryString from 'query-string'

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            token: ''
        }
    }

    componentDidMount() {
        const query = queryString.parse(this.props.location.search)
        this.setState({ token: query.token })
    }

    /**
     * Handles the submit
     */
    handleValidSubmit = (event, values) => {
        if (values.password.length < 6) {
            toast.error('A senha deve possuir no mínimo 6 caracteres.')
            return
        }

        if (values.password_confirmation !== values.password) {
            toast.error('As senhas devem ser iguais, verifique-as.')
            return
        }

        this.props.resetPasswordRequest(this.state.token, values.password, values.password_confirmation);
    }

    render() {
        return (
            <>
                <div className="account-pages mt-5 mb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={5}>
                                <Card>
                                    <CardBody className="p-4 position-relative">
                                        <div className="text-center w-75 m-auto">
                                            <a href="/">
                                                <span><img src={logo} alt="" height="48" /></span>
                                            </a>
                                            <p className="text-muted mb-4 mt-3">Recuperar senha</p>
                                        </div>


                                        { /* preloader */}
                                        {this.props.loading && <Loader />}

                                        {this.props.error && <Alert color="danger" isOpen={this.props.error ? true : false}>
                                            <div>{this.props.error}</div>
                                        </Alert>}

                                        <AvForm onValidSubmit={this.handleValidSubmit}>

                                            <AvGroup>
                                                <Label for="password">Senha</Label>
                                                <AvField type="password" name="password" id="password" placeholder="Sua nova senha" value={this.state.password} required />
                                                <AvFeedback>Campo inválido</AvFeedback>
                                            </AvGroup>

                                            <AvGroup>
                                                <Label for="password_confirmation">Confirmação de senha</Label>
                                                <AvField type="password" name="password_confirmation" id="password_confirmation" placeholder="Repita sua nova senha" value={this.state.password_confirmation} required />
                                                <AvFeedback>Campo inválido</AvFeedback>
                                            </AvGroup>

                                            <FormGroup>
                                                <Button style={{ background: '#081C39' }} className="btn-block">Salvar</Button>
                                            </FormGroup>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { signed, loading } = state.auth;

    return { signed, loading }
};

export default connect(mapStateToProps, { resetPasswordRequest })(ResetPassword);
