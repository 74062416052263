import React, { useState } from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Nav, NavLink, NavItem, TabPane, TabContent, FormGroup, Button, Input,  Card, CardTitle, CardText } from 'reactstrap';

import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

import { formatTimer } from '~/services/util'


import { FaPlay } from 'react-icons/fa';

import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart, FaCheck } from 'react-icons/fa';

const TrainingSidebar = ({ image, collapsed, toggled, handleToggleSidebar, training, currTrainingGroup, currTrainingGroupIdx, currTrainingActivity, currTrainingActivityIdx, updateTraining, trainingStarted  }) => {

  const [clickedActivity, setClickedActivity]= useState();
  const [actDetailModal, setActDetailModal]= useState(false);

  const formatRuntime = (runtime) => {
    if (runtime) {
      const { minutes, seconds } = formatTimer(runtime.toFixed(0))

      return `${minutes}:${seconds}`;
    }
  }

  return (
    <ProSidebar
      image={false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '0 24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          TREINO DETALHADO
        </div>
      </SidebarHeader>

      <SidebarContent>
        {training && training.trainingGroups && training.trainingGroups.map( (cMod, cmIdx) => {
          const bgColor = cMod.executed_status && cMod.executed_status !== 'EXECUTED_LOW' ? 'green' : '#a1a1a1';
          const icColor = cMod.executed_status && cMod.executed_status !== 'EXECUTED_LOW' ? 'green' : '#a1a1a1';

          return (
            <Menu iconShape="circle" key={`cMod_${cmIdx}`}>
              {/* <SubMenu suffix={<span className="badge yellow">{cMod.courseLectures ? cMod.courseLectures.length : ''}</span>} key={`cm_${cmIdx}`} title={cMod.name} icon={<FaCheck color={icColor} />}></SubMenu> */}

              <SubMenu open={!trainingStarted || (currTrainingGroup && currTrainingGroup.id === cMod.id)} suffix={<span className="badge yellow">{cMod.trainingActivities ? cMod.trainingActivities.length : ''}</span>} key={`cm_${cmIdx}`} title={cMod.name} icon={<FaCheck color={icColor} />}>
                {cMod.trainingActivities && cMod.trainingActivities.map( (tAct, lIdx) => {
                  const bgColor = tAct.executed_status === 'EXECUTED_HIGH' ? 'green' : '#a1a1a1';
                  const icColor = tAct.executed_status === 'EXECUTED_HIGH' ? 'green' : '#e1e1e1';
                  
                  

                  if (tAct.activity_type !== 'FINISH')
                    return (
                      <div key={`clc_${lIdx}`} style={{display: 'flex', marginBottom: 15}}>
                        <div style={{width: 65, height: 49, position: 'relative', backgroundColor: icColor, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          
                          {tAct.tuto_link &&
                          <div style={{ position: 'absolute', top: 2, left: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }} 
                            onClick={() => {
                              setClickedActivity(tAct);
                              setActDetailModal(true);
                            }}>
                            <div style={{width: 45, height: 45, backgroundColor: 'rgba(0, 0, 0, 0.1)', cursor: 'pointer' }} />
                            <FaPlay size={15}  style={{ position: 'absolute', color: 'rgba(240, 240, 240, 0.9)', cursor: 'pointer' }} />
                          </div>}

                          <img src={tAct?.thumb_link} alt="" height={45} style={{width: 45, height: 45}} />
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 5, marginRight: 5, width: '100%'}}>
                          <span style={{}}>{tAct.name}</span>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>{tAct?.activity_type === 'DURATION' ? `${(tAct?.duration*60).toFixed(0)}seg` : `${tAct?.repetition}x`}</span>
                            <span>{formatRuntime(tAct?.runtime)}</span>
                          </div>
                        </div>
                      </div>
                    )
                })}
              </SubMenu>
            </Menu>
          )
        })}
        
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper">
        </div>
      </SidebarFooter>

      <Modal isOpen={actDetailModal} className="modal-dialog modal-lg" toggle={() => setActDetailModal(!actDetailModal)}>
        <ModalHeader 
            toggle={() => setActDetailModal(!actDetailModal)}
            close={<button className="close" onClick={() => setActDetailModal(!actDetailModal)}>&times;</button>}>Explicações do exercício</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', flex: 1, padding: 20}}>
              
                <h4 style={{ color: '#a1a1a1' }}>{clickedActivity?.name}</h4>

                <p style={{ color: '#a1a1a1' }}>{clickedActivity?.description}</p>
                
                {clickedActivity?.tuto_link ?
                  <div className="embed-responsive embed-responsive-16by9">
                      <iframe className="embed-responsive-item" title="video" src={clickedActivity?.tuto_link} allow="fullscreen"></iframe>
                  </div>
                :
                <p>Este exercício não possui vídeo de explicações cadastrado.</p>}
              </div>
            </Col>
          </Row>  
        </ModalBody>
        <ModalFooter>
            <button className="btn btn-bordered-success" onClick={() => {
              setActDetailModal(!actDetailModal)
            }}>Fechar</button>
        </ModalFooter>
      </Modal>
    </ProSidebar>
  );
};

export default TrainingSidebar;