import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { MdInfoOutline, MdDelete } from 'react-icons/md';
import { TabContent, TabPane, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FaInfo, FaInfoCircle } from 'react-icons/fa';
import Loader from '~/components/Loader/Loader';
import { toast } from 'react-toastify'
import { updateProfileSuccess } from '~/store/modules/user/actions'
import { Container } from './styles'
import api from '../../../services/api'
import history from '~/services/history';
import { setCourseFlow } from '~/store/modules/auth/actions';
import queryString from 'query-string'

import NewZoneModal from '../components/NewZoneModal'
import InfoZoneModal from '../components/InfoZoneModal'

const ZONES_INFOS = [
  { order: 1, name: 'Zona 1 - Esforço Leve', video: 'https://player.vimeo.com/video/390965364', description: 'Zona de treino 1, para esforço leve' },
  { order: 2, name: 'Zona 2 - Esforço Moderado', video: 'https://player.vimeo.com/video/390966046', description: 'Zona de treino 1, para esforço leve' },
  { order: 3, name: 'Zona 3 - Um Pouco Difícil', video: 'https://player.vimeo.com/video/390966447', description: 'Zona de treino 1, para esforço leve' },
  { order: 4, name: 'Zona 4 - Difícil', video: 'https://player.vimeo.com/video/390966994', description: 'Zona de treino 1, para esforço leve' },
  { order: 5, name: 'Zona 5a - Muito Difícil', video: 'https://player.vimeo.com/video/390967333', description: 'Zona de treino 1, para esforço leve' },
  { order: 6, name: 'Zona 5b - Extremamente Difícil', video: 'https://player.vimeo.com/video/390967333', description: 'Zona de treino 1, para esforço leve' },
  { order: 7, name: 'Zona 5c - Limite Máximo', video: 'https://player.vimeo.com/video/390967333', description: 'Zona de treino 1, para esforço leve' }
]

const ZONE_HOW_WORKS = { name: 'Como Funciona', video: 'https://player.vimeo.com/video/390962131' }
const ZONE_HOW_MAKE_TEST = { name: 'Veja como fazer o teste', video: 'https://player.vimeo.com/video/390968183' }

export default function ZoneTab() {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.user.profile);

  const [userZones, setUserZones] = useState();
  const [currUserZones, setCurrUserZones] = useState();
  const [bpmVisible, setBpmVisible] = useState(false);
  const [newZoneModalVisible, setNewZoneModalVisible] = useState(false);

  const [currentZoneInfoData, setCurrentZoneInfoData] = useState();
  const [zoneInfoModalVisible, setZoneInfoModalVisible] = useState(false);
  const [zoneHowWorksModalVisible, setZoneHowWorksModalVisible] = useState(false);
  const [zoneHowWorksData, setZoneHowWorksData] = useState(ZONE_HOW_WORKS);

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

  const loadUserZones = useCallback(async () => {
    const response = await api.get(`/user_zones?user_id=${profile.id}`);

    const { data } = response;

    if (data) {
      setCurrUserZones(data.slice(0, 1)[0])
      setUserZones(data.slice(1));
    }
  }, [profile.id]);

  useEffect(() => {

    loadUserZones();
  }, [profile.id, loadUserZones]);


  function _formatStartZone(zone) {
    if (!bpmVisible) {
      if (zone.order === 1 || zone.order === 7) {
        return zone.order === 1 ? 'Acima' : 'Abaixo'
      }
      return zone.start_pace;
    } else {
      if (zone.order === 1 || zone.order === 7) {
        return zone.order === 1 ? 'Abaixo' : 'Acima'
      }

      return zone.start_bpm;
    }
  }

  function _formatEndZone(zone) {
    if (!bpmVisible) {
      return zone.order === 7 ? zone.start_pace : zone.end_pace;
    } else {
      return zone.order === 7 ? zone.start_bpm : zone.end_bpm;
    }
  }

  function _formatZoneColor(zone) {
    switch (zone.order) {
      case 1:
        return '#74D7ED'
      case 2:
        return '#13CE66'
      case 3:
        return '#FFFF7C'
      case 4:
        return '#FFBB6A'
      case 5:
        return '#EB9C3D'
      case 6:
        return '#F95F62'
      case 7:
        return '#DB3B3E'
      default:
        return 'gray'
    }
  }

  const _handleDeleteCurrent = async () => {
    if (currUserZones) {
      const resp = await api.delete(`/user_zones/${currUserZones.id}`);

      if (resp.status === 204) {
        await _refreshUser()
        toggle()
      }
    }
  }

  const _refreshUser = async () => {
    loadUserZones();

    const resp = await api.get(`/users/${profile.id}`);

    if (resp && resp.status === 200) {
      dispatch(updateProfileSuccess(resp.data));
    }
  }

  const _handleSeeCourse = async () => {
    const coursesToShowTmp = []

    try {
      Promise.all([api.get(`/courses`), api.get(`/courses?user_id=${profile.id}`)
      ]).then(async ([allCoursesResp, userCoursesResp]) => {
        if (userCoursesResp.status === 200) {
          const { data } = userCoursesResp

          allCoursesResp.data.forEach(crse => {
            const hasMy = data.find(({ cloned_id }) => { return cloned_id === crse.id });

            if (hasMy) {
              coursesToShowTmp.push(hasMy);
            } else {
              coursesToShowTmp.push(crse);
            }
          })

          let currCourse = coursesToShowTmp.find(({ cloned_id }) => { return cloned_id === 11 });

          if (currCourse) {
            await goToCourse(currCourse)
          } else {
            currCourse = coursesToShowTmp.find(({ id }) => { return id === 11 });
            if (currCourse) {
              await goToCourse(currCourse)
            }
          }
        }
      }).catch(error => {
        //
      })
    } catch (error) {
      //
    }
  }

  const goToCourse = async (course) => {
    if (course.clone_type === 'COURSE') {
      const response = await api.post(`/courses/${course.id}/init`);

      if (response.status === 200) {
        await dispatch(setCourseFlow(true));

        const redirectURL = `/curso/${response.data.id}`
        history.push(redirectURL)
      } else {
        alert('Ops!', 'Tivemos um problema ao iniciar o curso, tente novamente mais tarde.');
      }
    } else {
      await dispatch(setCourseFlow(true));

      const redirectURL = `/curso/${course.id}`
      history.push(redirectURL)
    }

  }

  return (
    <>
      <div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle} close={closeBtn}>Confirmação</ModalHeader>
          <ModalBody>
            Tem certeza que deseja remover esse registro?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>Cancelar</Button>&nbsp;
            <Button color="danger" onClick={_handleDeleteCurrent}>OK</Button>
          </ModalFooter>
        </Modal>
      </div>
      <Row style={{ marginTop: 20 }}>
        <Col sm="6">
          <Row>
            <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4 style={{ marginBottom: 20 }}>Zonas de treino de corrida</h4>
              <button
                onClick={() => _handleSeeCourse()}
                style={{ justifyContent: 'center', alignItems: 'center' }} className="btn btn-link nav-link right-bar-toggle waves-effect waves-light">
                <span style={{ fontSize: 15 }}>Como funciona</span>
                <MdInfoOutline color="primary" size={15} style={{ marginLeft: 10 }} />
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="list-group list-group-flush">
                {profile.userZone && profile.userZone.zones.map((zone, zIdx) => {
                  return (
                    <li key={`zz_${zIdx}`} onClick={() => {
                      setCurrentZoneInfoData(zone)
                      setZoneInfoModalVisible(true)
                    }} className="list-group-item" style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', alignItems: 'center', paddingLeft: 0, justifyContent: 'space-between', height: 50, paddingVertical: 10, paddingRight: 10, backgroundColor: (zIdx % 2 === 0 ? '#eeeeee' : 'white') }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: 15, height: 50, backgroundColor: _formatZoneColor(zone), marginLeft: 0, paddingLeft: 0, marginRight: 20 }} />
                        <a>{zone.name} <FaInfoCircle style={{ marginLeft: 5 }} size={13} /></a>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span style={{ color: 'gray', fontWeight: '500', fontSize: 14, width: 50, textAlign: 'center' }}>{_formatStartZone(zone) || '-'}</span>
                        <span style={{ color: 'gray', fontWeight: '500', fontSize: 14, width: 25, textAlign: 'center' }}>{zone.order === 1 || zone.order === 7 ? 'de' : 'a'}</span>
                        <span style={{ color: 'gray', fontWeight: '500', fontSize: 14, width: 50, textAlign: 'center' }}>{_formatEndZone(zone) || '-'}</span>
                      </div>
                    </li>
                  )
                })}

                {!profile.userZone && ZONES_INFOS.map((zone, zIdx) => {
                  return (
                    <li key={`zz_${zIdx}`} onClick={() => {
                      setCurrentZoneInfoData(zone)
                      setZoneInfoModalVisible(true)
                    }} className="list-group-item" style={{ display: 'flex', cursor: 'pointer', flexDirection: 'row', alignItems: 'center', paddingLeft: 0, justifyContent: 'space-between', height: 50, paddingVertical: 10, paddingRight: 10, backgroundColor: (zIdx % 2 === 0 ? '#eeeeee' : 'white') }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ width: 15, height: 50, backgroundColor: _formatZoneColor(zone), marginLeft: 0, paddingLeft: 0, marginRight: 20 }} />
                        <a>{zone.name} <FaInfoCircle style={{ marginLeft: 5 }} size={13} /></a>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <span style={{ color: 'gray', fontWeight: '500', fontSize: 14, width: 50, textAlign: 'center' }}>{_formatStartZone(zone) || '-'}</span>
                        <span style={{ color: 'gray', fontWeight: '500', fontSize: 14, width: 25, textAlign: 'center' }}>{zone.order === 1 || zone.order === 7 ? 'de' : 'a'}</span>
                        <span style={{ color: 'gray', fontWeight: '500', fontSize: 14, width: 50, textAlign: 'center' }}>{_formatEndZone(zone) || '-'}</span>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
              <Button color="primary" outline={!bpmVisible} size="sm" style={{}} onClick={() => setBpmVisible(true)}>BPM</Button>&nbsp;
              <Button color="primary" outline={bpmVisible} size="sm" style={{}} onClick={() => setBpmVisible(false)}>Pace</Button>

            </Col>
          </Row>
        </Col>
        <Col sm="6">
          <Row>
            <Col>
              <p style={{}}>
                Suas zonas de treino podem ser analizadas pela sua frequência cardíaca (BPM) e pelo seu rítmo (min/km), desde que você faça nosso teste de corrida para cálculo das zonas.
                <button
                  onClick={async () => {
                    await setZoneHowWorksData(ZONE_HOW_MAKE_TEST)
                    setZoneHowWorksModalVisible(true)
                  }}
                  className="btn btn-link nav-link right-bar-toggle waves-effect waves-light">
                  <span style={{ fontSize: 15 }}>Veja como fazer o teste</span>
                </button>
              </p>
            </Col>
          </Row>
          <Row style={{}}>
            <Col>
              <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <h5 style={{ marginBottom: 20 }}>Resultado do teste vigente</h5>
                <button disabled={!currUserZones} style={{ justifyContent: 'center', alignItems: 'center' }} className="btn btn-link nav-link right-bar-toggle waves-effect waves-light" onClick={toggle}>
                  <span style={{ fontSize: 15 }}>Apagar ultimo teste</span>
                  <MdDelete color="primary" size={15} style={{ marginLeft: 10 }} />
                </button>
              </span>
              {!currUserZones ?
                <div style={{ justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                  <p>Nenhuma Zona encontrada</p>
                </div>
                :
                (
                  <table className="table mb-0" >
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>BPM médio</th>
                        <th>Ritmo médio</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <th scope="row">{moment(currUserZones.execution_date).format('DD/MM/YY')}</th>
                        <td>{currUserZones.bpm_avg}</td>
                        <td>{currUserZones.pace}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              <Button color="primary" onClick={() => setNewZoneModalVisible(!newZoneModalVisible)} style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>Registrar novo teste</Button>
            </Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col>
              <h5 style={{ marginBottom: 20 }}>Histórico de testes</h5>

              {!userZones ?
                <div style={{ justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                  <p>Nenhuma Zona encontrada</p>
                </div>
                :
                (
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>BPM médio</th>
                        <th>Ritmo médio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userZones.map((zone, zIdx) => {
                        return (
                          <tr key={`zzz_${zIdx}`}>
                            <th scope="row">{moment(zone.execution_date).format('DD/MM/YY')}</th>
                            <td>{zone.bpm_avg}</td>
                            <td>{zone.pace}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                )}
            </Col>
          </Row>
        </Col>
        {newZoneModalVisible && <NewZoneModal open={newZoneModalVisible} userID={profile.id} refreshUser={_refreshUser} toggle={setNewZoneModalVisible} />}
        {zoneInfoModalVisible && <InfoZoneModal open={zoneInfoModalVisible} toggle={setZoneInfoModalVisible} zoneInfo={currentZoneInfoData} />}
        {zoneHowWorksModalVisible && <InfoZoneModal open={zoneHowWorksModalVisible} toggle={setZoneHowWorksModalVisible} zoneInfo={zoneHowWorksData} />}
      </Row>
    </>
  );
}
