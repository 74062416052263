import React, { useState, useEffect } from "react"
import moment from "moment"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
} from "reactstrap"
import api from "~/services/api"
import { formatSubscriptionStatus, isAdm, formatBillStatus } from "~/services/util"
import { FaFileInvoice } from "react-icons/fa"
import Loader from "~/components/Loader/Loader"
import RefundModal from "./ModalRefund"

function Subscription({ subscription, loadSubscriptionErrorMessage = null, profile }) {
  const [modalCancellation, setModalCancellation] = useState(false)
  const [cancellationReason, setCancellationReason] = useState()
  const [comments, setComments] = useState()
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [nextBillingValue, setNextBillingValue] = useState(0)
  const [originalValue, setOriginalValue] = useState(0)
  const [billAmount, setBillAmount] = useState('')
  const [showModalRefund, setShowModalRefund] = useState(false)

  const toggleModalRefund = () => setShowModalRefund(!showModalRefund)
  const toggleModalCancellation = () => setModalCancellation(!modalCancellation)

  const closeBtn = <button className="close" onClick={toggleModalCancellation}>&times;</button>;

  const handleCancelSubscription = async () => {
    const data = {
      status: "CREATED",
      cancellation_reason: cancellationReason,
      comments,
      subscription_id: subscription.id,
    }

    setLoading(true)

    try {
      await api.post(`/subscription_cancellations`, data);

      setLoading(false)
      setStep(step + 1)
    } catch (e) {
      setLoading(false)
      setStep(6)
    }
  }

  useEffect(() => {
    if (subscription?.product_items?.length > 0) {
      let totalPrice = subscription.product_items.reduce((acc, productItem) => acc + parseFloat(productItem.pricing_schema?.price), 0)

      let totalDiscount = 0

      if (subscription.bills?.length) {
        setBillAmount(new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(subscription.bills[0]?.amount))
      }

      const diffOfTodayFromStartAt = subscription.bills?.reduce((acc, bill) => {
        if (bill.charges?.length > 0) {
          if (bill.charges[0].last_transaction?.status === 'success') {
            return acc += 1
          }
        }
      }, 0)

      for (let index = 0; index < subscription.product_items.length; index++) {
        const product_item = subscription.product_items[index];

        if (product_item.discounts?.length > 0) {
          for (let index2 = 0; index2 < product_item.discounts.length; index2++) {
            const discount = product_item.discounts[index2];

            if (discount.cycles > 1 && diffOfTodayFromStartAt < discount.cycles) {
              if (discount.discount_type === 'amount') {
                totalDiscount += parseFloat(discount.amount)
              } else if (discount.discount_type === 'percentage') {
                const totalProductItemPercentageDiscount = (parseFloat(product_item.pricing_schema?.price) / 100) * parseFloat(discount.percentage)

                totalDiscount += totalProductItemPercentageDiscount
              }
            }
          }
        }
      }

      setNextBillingValue(totalPrice - totalDiscount)
      setOriginalValue(totalPrice)
    }
  }, [subscription])

  return (
    <>
      {
        subscription ?
          <Row>
            <Col>
              <div className="p-2">
                <Row>
                  <Col sm={12}>
                    <h5>Plano vigente:</h5>

                    <h3>{subscription.plan?.name}</h3>

                    <h5>Status:</h5>

                    <h4 className={subscription.status === 'active' ? 'text-success' : 'text-danger'}>{formatSubscriptionStatus(subscription.status)}</h4>

                    <h5>Início da assinatura:</h5>

                    <h4>{moment(subscription.start_at).format('DD/MM/YYYY')}</h4>

                    {subscription.status !== 'active' &&
                      <>
                        <h5>Data de encerramento:</h5>

                        <h4>{moment(subscription.current_period?.end_at).format('DD/MM/YYYY')}</h4>
                      </>
                    }

                    {subscription.status === 'active' &&
                      <>
                        <h5>Previsão de renovação:</h5>

                        <h4>{moment(subscription.next_billing_at).format('DD/MM/YYYY')}</h4>

                        <h5>Meio de pagamento previsto:</h5>

                        <h4>{subscription.payment_method?.public_name}</h4>

                        <h5>Valor total previsto na renovação:</h5>

                        <h4>
                          {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(nextBillingValue)}
                          &nbsp;{subscription.interval_count > 1 ? `em ${subscription.installments}x` : ''}
                        </h4>
                      </>
                    }
                  </Col>
                </Row>

                <h4 className="table-title">Faturas mais recentes</h4>

                <div className="table-responsive">
                  <table className="table table-borderless mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Data</th>
                        <th>Nome</th>
                        <th>Valor Original</th>
                        <th>Valor Pago</th>
                        <th>Forma</th>
                        <th>Status</th>
                        <th>Opções</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscription.bills?.map((bill) => {
                        return (
                          <tr key={bill.id}>
                            <th scope="row">{bill.id}</th>
                            <td>{bill.created_at ? moment(bill.created_at).format('DD/MM/YYYY') : '--'}</td>
                            <td>{subscription.plan?.name}</td>
                            <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(originalValue)}</td>
                            <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(bill.amount)}</td>
                            <td>
                              {bill.charges[0]?.payment_method?.public_name}

                              {bill.charges[0]?.payment_method?.code === 'bank_slip' &&
                                bill.charges[0]?.print_url &&
                                <a href={bill.charges[0].print_url} target="_blank" rel="noreferrer" style={{ marginLeft: 5 }}>
                                  <FaFileInvoice color="secondary" size={16} />
                                </a>}
                            </td>
                            <td>
                              {
                                formatBillStatus[bill.status] ?
                                  <span className={formatBillStatus[bill.status].className}>{formatBillStatus[bill.status].title}</span>
                                  :
                                  <span className="badge badge-secondary">--</span>
                              }
                            </td>
                            <td>
                              {
                                bill.status !== 'canceled' &&
                                bill.url &&
                                (
                                  <a href={bill.url} target="_blank" rel="noreferrer">Ver/Pagar</a>
                                )
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <br />
                </div>

                {subscription.status === 'active' &&
                  (profile?.subscription_type === 'ESSENTIAL' || isAdm(profile?.id)) &&
                  <>
                    <h5>CANCELAR FUTURAS RENOVAÇÕES</h5>
                    <h5>Para solicitar o cancelamento da renovação/pagamento previsto para {moment(subscription.next_billing_at).format('DD/MM/YYYY')}, <span onClick={() => setModalCancellation(!modalCancellation)} className="text-danger" style={{ cursor: 'pointer' }}>clique aqui</span>.</h5>
                  </>
                }

                <h5>ALTERAÇÕES E OUTROS</h5>
                <p>Caso desejar modificar algo, como trocar o plano, ou tratar de outro assunto sobre sua assinatura, escreva para <a href="mailto:assinatura@corridaperfeita.com">assinatura@corridaperfeita.com</a>.</p>

                {
                  moment().diff(subscription.start_at, 'd', true) < 16 &&
                  (profile?.subscription_type === 'ESSENTIAL' || isAdm(profile?.id)) &&
                  <>
                    <h5>USO DA GARANTIA (REEMBOLSO)</h5>
                    <p>Nestes primeiros 15 dias de seu plano conosco, você pode fazer uso da garantia e ter 100% do seu investimento de volta,&nbsp;
                      <a href="#assinatura" onClick={toggleModalRefund} className="text-danger" role="button">
                        clicando aqui.
                      </a>
                    </p>
                  </>
                }
              </div>
            </Col>

            <RefundModal
              show={showModalRefund}
              toggle={toggleModalRefund}
              paymentMethod={subscription?.payment_method?.code || ''}
              subscriptionId={subscription?.id || ''}
              billAmount={billAmount}
            />

            <Modal isOpen={modalCancellation} toggle={toggleModalCancellation}>
              <ModalHeader toggle={toggleModalCancellation} close={closeBtn}>Cancelamento de renovação</ModalHeader>
              <ModalBody>
                {loading && <Loader />}

                {step === 1 &&
                  <div>
                    <p>Poxa! Ficamos tristes com isso, mas respeitamos sua escolha.</p>

                    <p>
                      Ao continuar, você vai cancelar apenas a renovação/pagamento futuro previstos para {moment(subscription.next_billing_at).format('DD/MM/YYYY')}.
                      Caso queira pedir devolução de valores da garantia de 15 dias,&nbsp;
                      <a
                        href="https://ajuda.corridaperfeita.com/support/solutions/articles/47001154428-como-pedir-o-reembolso-da-garantia-prevista-no-site"
                        target="_blank"
                        rel="noreferrer"
                      >
                        veja aqui como fazer
                      </a>
                      .
                    </p>
                  </div>}
                {
                  step === 2 &&
                  <Form>
                    <FormGroup>
                      <Label style={{ marginTop: 20 }} for="cancellationReason">
                        Antes de confirmar sua solicitação, poderia nos dizer o principal motivo do seu pedido ?
                      </Label>
                      <br />
                      <span>(A resposta é opcional)</span>
                      <br />
                      <br />

                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Financeiro"}
                            type="radio"
                            name="reason"
                            value="Financeiro"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Financeiro
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Falta de tempo para treinar"}
                            type="radio"
                            name="reason"
                            value="Falta de tempo para treinar"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Falta de tempo para treinar
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Falta de disciplina/motivação"}
                            type="radio"
                            name="reason"
                            value="Falta de disciplina/motivação"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Falta de disciplina/motivação
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Lesão"}
                            type="radio"
                            name="reason"
                            value="Lesão"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Lesão
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Outros problemas de saúde"}
                            type="radio"
                            name="reason"
                            value="Outros problemas de saúde"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Outros problemas de saúde
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Contratei outro serviço"}
                            type="radio"
                            name="reason"
                            value="Contratei outro serviço"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Contratei outro serviço
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Mudei de atividade física"}
                            type="radio"
                            name="reason"
                            value="Mudei de atividade física"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Mudei de atividade física
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Não gostei do serviço"}
                            type="radio"
                            name="reason"
                            value="Não gostei do serviço"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Não gostei do serviço
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Motivos pessoais"}
                            type="radio"
                            name="reason"
                            value="Motivos pessoais"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Motivos pessoais
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mb-2">
                        <Label check>
                          <Input
                            checked={cancellationReason === "Outros"}
                            type="radio"
                            name="reason"
                            value="Outros"
                            onChange={e => setCancellationReason(e.target.value)}
                          />{" "}
                          Outros
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </Form>
                }

                <Form>
                  {step === 3 &&
                    <FormGroup>
                      <Label for="comments">E antes de finalizar, gostaria de acrescentar algo? Pode ser um elogio, uma crítica, o que quiser.</Label><br />
                      <span>(A resposta é opcional)</span>
                      <Input
                        onChange={
                          e => setComments(e.target.value)
                        }
                        value={comments}
                        type="textarea" name="comments" id="comments" />
                    </FormGroup>}
                </Form>

                {step === 4 &&
                  <p>
                    Confirma o cancelamento da renovação/cobrança previsto para {moment(subscription.next_billing_at).format('DD/MM/YYYY')}?
                  </p>
                }

                {step === 5 &&
                  <p>A sua renovação foi cancelada com sucesso.</p>}

                {step === 6 &&
                  <div>
                    <h5>Falha!</h5>

                    <p>O cancelamento automático da renovação NÃO pode ser finalizado!</p>

                    <p>Isso pode ter acontecido por alguma falha técnica de sistema.</p>

                    <p>
                      Você pode tentar novamente, ou ainda, enviar um e-mail para assinatura@corridaperfeita.com
                      informando seu CPF e o ocorrido.
                    </p>
                  </div>}
              </ModalBody>
              <ModalFooter>
                {step !== 6 && step !== 5 && <Button color="danger" onClick={toggleModalCancellation}>Sair</Button>}&nbsp;
                {step === 5 && <Button color="primary" onClick={() => window.location.reload()}>Fechar</Button>}&nbsp;
                {step === 1 &&
                  <Button
                    color="primary"
                    onClick={() => {
                      setStep(step + 1)
                    }}>Solicitar cancelamento</Button>}
                {step > 1 && step < 4 &&
                  <Button
                    color="primary"
                    onClick={() => {
                      setStep(step + 1)
                    }}>Continuar</Button>}
                {step === 4 &&
                  <Button
                    color="primary"
                    onClick={handleCancelSubscription}>Confirmar Cancelamento</Button>}
                {step === 6 &&
                  <Button
                    color="primary"
                    onClick={() => setStep(1)}>Tentar Novamente</Button>}
                {step === 6 &&
                  <a
                    className="btn btn-success"
                    href="mailto:assinatura@corridaperfeita.com">Enviar E-mail</a>}

              </ModalFooter>
            </Modal>
          </Row>
          :
          <Row>
            <Col>
              {!subscription && !loadSubscriptionErrorMessage && <Loader />}
              <p>{loadSubscriptionErrorMessage ? loadSubscriptionErrorMessage : 'Sem assinatura corrente'}</p>
            </Col>
          </Row>
      }
    </>
  )
}

export default Subscription;
