import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { Row, Col, Card, CardBody } from 'reactstrap';

import { FaSignInAlt, FaExclamationCircle } from 'react-icons/fa'

import ModalLecture from '~/pages/VideoCalendarScreen/ModalLecture'
import ModalInfo from '~/pages/VideoCalendarScreen/ModalInfo'
import { toggleShowModalLecture, loadLiveLecturesRequest } from '~/store/modules/liveLectures/actions'
import Loader from '~/components/Loader/Loader';

import { Title } from './styles'

function VideoCalendarScreen() {
  const dispatch = useDispatch()

  const liveLectures = useSelector(state => state.liveLectures.data)

  const loading = useSelector(state => state.liveLectures.loading)

  const handleShowLecture = (lec) => {
    setLecture(lec)
    dispatch(toggleShowModalLecture())
  }

  const [lecture, setLecture] = useState(null)

  useEffect(() => {
    dispatch(loadLiveLecturesRequest())
  }, [])

  const [info, setInfo] = useState(false)

  return (
    <>
      {lecture && <ModalLecture lecture={lecture} />}

      <ModalInfo modal={info} toggle={() => setInfo(false)} />

      <Row>
        <Col>
          <div className="page-title-box">
            <Row>
              <Col lg={7}>
                <h4 className="page-title">Agenda de Lives</h4>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {loading && <Loader />}
              <Row>
                <Col lg={12}>
                  <Title>
                    <h5>Programação de aulas ao vivo:</h5>

                    <h5 onClick={() => setInfo(true)} style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                      <FaExclamationCircle />&nbsp;
                      Como funciona
                    </h5>
                  </Title>

                  <br />

                  <Row>
                    {liveLectures.data &&
                      liveLectures.data.length > 0 &&
                      liveLectures.data.map(lec => (
                        <Col key={lec.id} sm={12} md={4} className="mb-5">
                          <Row>
                            <Col xs={12}>
                              <h4>{lec.title}</h4>

                              <p><b>Descrição:</b> {lec.description}</p>
                            </Col>
                            <Col xs={12}>
                              <button
                                style={{ width: '80%' }}
                                onClick={() => handleShowLecture(lec)}
                                className="btn btn-bordered-primary">
                                Acesso à próxima aula&nbsp;&nbsp;
                                                            <FaSignInAlt color="white" size={20} />
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default VideoCalendarScreen;
