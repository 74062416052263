import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const ContainerCard = styled.div`
  background: 'white';
  width: 650px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-top: 10px;
`;

export const ItemTitle = styled.p`
  color: #a1a1a1;
  margin-bottom: 0px;
  padding-bottom: 0px;
`;

export const ActivityTitle = styled.p`
  font-size: 16px;
  margin: 10px;
  font-weight: 600;
  color: #515151;
`;

export const ActivityDate = styled.p`
  font-size: 13px;
  color: 'gray';
`;

export const ActivityUserName = styled.p`
  font-size: 15px;
  font-weight: bold;
  color: #717171;
`;

export const ItemValue = styled.p`
  margin-top: 0;
  padding-top: 0;
  font-size: 17px;
  color: #919191;
`;

export const ItemsContainer = styled.div`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  flex-direction: row;
  justify-content: space-between;
`;

export const ItemBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const Separator = styled.div`
  display: flex;
  width: 2px;
  height: 30px;
  background: rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Avatar = styled.img`
  display: flex;
  width: 45;
  height: 45;
  background: #e1e1e1;
  margin: 10px;
  border-radius: 30;
  border: 0.3px solid #818181;
`;

export const Title = styled.p`
  font-size: 16px;
  color: #212121;
  font-weight: 600;
  align-self: center;
  margin-top: 30px;
`;
