import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { loadUserTrainingPlanSuccess, loadUserTrainingPlanFailure } from './actions';

export function* loadUserTrainingPlan({ payload }) {
    try {
        const { userId } = payload;

        const response = yield call(api.get, `training_plans?userId=${userId}`);

        if (response && response.status === 200) {
            const currTP = response.data && response.data.length > 0 ? response.data[0] : null
            yield put(loadUserTrainingPlanSuccess(currTP))
        } else {
            yield put(loadUserTrainingPlanFailure())
        }
    } catch (error) {
        toast.error('Erro ao carregar plano de treino!');
        yield put(loadUserTrainingPlanFailure())
    }
}

export default all([
    takeLatest('@userTrainingPlan/LOAD_USER_TRAINING_PLAN_REQUEST', loadUserTrainingPlan)
]); 