import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, TabPane, TabContent, Input, ListGroup, ListGroupItem } from 'reactstrap';
import Loader from '~/components/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowModalTrainingPlanRequestTmp, saveTrainingPlanRequestRequest } from '~/store/modules/trainingPlanRequests/actions';
import { updateProfileSuccess } from '~/store/modules/user/actions';
import api from '~/services/api'
import { toast } from 'react-toastify'
import distanceImg from '~/assets/img/training_requests/distancia.png'
import keepFitImg from '~/assets/img/training_requests/manter-forma.png'
import returnInitiationImg from '~/assets/img/training_requests/iniciacao.png'
import slimmingImg from '~/assets/img/training_requests/emagrecimento.png'
import tafImg from '~/assets/img/training_requests/taf.png'
import alternativeImg from '~/assets/img/training_requests/alternativo.png'
import { FaAngleLeft, FaAngleRight, FaCheck } from 'react-icons/fa'
import InputMask from 'react-input-mask'
import moment from 'moment'
import DistanceRequest from './components/Distance'
import KeepFitRequest from './components/KeepFit'
import ReturnInitiationRequest from './components/ReturnInitiation'
import SlimmingRequest from './components/Slimming'
import AlternativeRequest from './components/Alternative'
import { formatRequestKeepFitType, formatTrainingPlanRequestObjective } from '~/services/util'
import momentBusinessDays from 'moment-business-days'

momentBusinessDays.updateLocale('pt-br', {
  holidays: [
    `${new Date().getFullYear()}-09-07`
  ],
  holidayFormat: 'YYYY-MM-DD',
  workingWeekdays: [1, 2, 3, 4, 5]
});

const TimeInput = (props) => (
  <InputMask mask="99:99:99" defaultValue={props.defaultValue} onChange={props.onChange} placeholder="--:--:--">
    {(inputProps) => <Input {...inputProps} type="text" disableunderline="true" bsSize="sm" />}
  </InputMask>
);

const statusEnums = { CREATED: 'CREATED', OPEN: 'OPEN', FINISHED: 'FINISHED', CANCELED: 'CANCELED', PENDING: 'PENDING' }

const formatTrainingRequestType = (type) => {
  switch (type) {
    case 'DISTANCE':
      return distanceImg
    case 'KEEP_FIT':
      return keepFitImg
    case 'RETURN_INITIATION':
      return returnInitiationImg
    case 'SLIMMING':
      return slimmingImg
    case 'TAF':
      return tafImg
    case 'ALTERNATIVE':
      return alternativeImg
    default:
      return ''
  }
}

const chooseTotalTime = (item) => {
  let durationHour = item.split(':')[0]
  let durationMin = item.split(':')[1]
  let durationSec = item.split(':')[2]

  durationHour = parseFloat(durationHour)
  durationMin = parseFloat(durationMin)
  durationSec = parseFloat(durationSec)

  const duration = ((durationHour * 60) + durationMin + (durationSec ? durationSec / 60 : 0)).toFixed(2);

  return duration
}

function TrainingPlanRequestModal() {
  const dispatch = useDispatch()

  const [schemas, setSchemas] = useState([])

  const [selectedSchema, setSelectedSchema] = useState(null)

  const [lastRequest, setLastRequest] = useState(null)

  const profile = useSelector(state => state.user.profile)

  const loading = useSelector(state => state.trainingPlanRequests.loading)

  const [loadingSchemas, setLoadingSchemas] = useState(false)

  const showModal = useSelector(state => state.trainingPlanRequests.trainingPlanRequestShowModalTMPButton)

  const toggle = () => dispatch(toggleShowModalTrainingPlanRequestTmp())

  const [modalCancel, setModalCancel] = useState(false)

  const toggleCancel = () => setModalCancel(!modalCancel)

  const closeBtn = <button className="close" onClick={toggle}>&times;</button>;

  const [activeTab, setActiveTab] = useState(1);

  //distance properties
  const [distance, setDistance] = useState(null)
  const [distanceHistory, setDistanceHistory] = useState(null)
  const [currentPractice, setCurrentPractice] = useState(null)
  const [km5Time, setKm5Time] = useState(null)
  const [km5Date, setKm5Date] = useState(null)
  const [km10Time, setKm10Time] = useState(null)
  const [km10Date, setKm10Date] = useState(null)
  const [km21Time, setKm21Time] = useState(null)
  const [km21Date, setKm21Date] = useState(null)
  const [km42Time, setKm42Time] = useState(null)
  const [km42Date, setKm42Date] = useState(null)
  const [startAt, setStartAt] = useState(null)
  const [minStartAt, setMinStartAt] = useState(null)
  const [maxStartAt, setMaxStartAt] = useState(null)
  const [targetDate, setTargetDate] = useState(null)
  const [minTargetDate, setMinTargetDate] = useState(null)
  const [maxTargetDate, setMaxTargetDate] = useState(null)
  const [daysWeeklyWorkout, setDaysWeeklyWorkout] = useState(profile.days_weekly_workout)
  const [height, setHeight] = useState(profile.height)
  const [weight, setWeight] = useState(profile.weight)
  const [mHistoryNotes, setMHistoryNotes] = useState(null)
  const [generalNotes, setGeneralNotes] = useState(null)
  //

  //keep fit properties
  const [trainingType, setTrainingType] = useState(null)
  const [keepFitCondition, setKeepFitCondition] = useState(null)
  const [selectedWeek, setSelectedWeek] = useState(null)
  const [oldSteps, setOldSteps] = useState(null)
  //

  //alternative properties
  const [alternativeType, setAlternativeType] = useState(null)
  const [trainingSessionTime, setTrainingSessionTime] = useState(null)
  //

  const requestSucceded = useSelector(state => state.trainingPlanRequests.requestSucceded)

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [backButtonSelected, setBackButtonSelected] = useState(false);

  const [minWeek, setMinWeek] = useState(0)
  const [maxWeek, setMaxWeek] = useState(0)

  const today = moment().weekday()

  useEffect(() => {
    const nextDateToEnable = moment()

    if (today === 4) setStartAt(nextDateToEnable.add(4, 'days'))
    else if (today === 5) setStartAt(nextDateToEnable.add(4, 'days'))
    else if (today === 6) setStartAt(nextDateToEnable.add(3, 'days'))
    else if (today === 0) setStartAt(nextDateToEnable.add(2, 'days'))
    else if (today === 3) setStartAt(nextDateToEnable.add(2, 'days'))
    else setStartAt(nextDateToEnable.add(2, 'days'))

    setMinStartAt(nextDateToEnable)

    const maxDate = moment(nextDateToEnable)

    setMaxStartAt(momentBusinessDays(maxDate).businessAdd(15))
  }, [today])

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const setKmTime = (type, value) => {
    switch (type) {
      case 5:
        setKm5Time(value)
        break
      case 10:
        setKm10Time(value)
        break
      case 21:
        setKm21Time(value)
        break
      case 42:
        setKm42Time(value)
        break
      default:
        break
    }
  }

  const setKmDate = (type, value) => {
    switch (type) {
      case 5:
        setKm5Date(value)
        break
      case 10:
        setKm10Date(value)
        break
      case 21:
        setKm21Date(value)
        break
      case 42:
        setKm42Date(value)
        break
      default:
        break
    }
  }

  useEffect(() => {
    setLoadingSchemas(true)

    api.get('training_plan_request_schemas')
      .then(response => {
        setSchemas(response.data)
        setLoadingSchemas(false)
      })
      .catch(error => {
        setLoadingSchemas(false)
        toast.error('Erro ao carregar opções! atualize a página e tente novamente.')
      })
  }, [])

  useEffect(() => {
    api.get(`get_user_requests?userId=${profile.id}`)
      .then(response => {
        if (response.data && response.data.data.length > 0) {
          setLastRequest(response.data.data[0])
        }
      })
  }, [profile.id])

  useEffect(() => {
    api.get(`/users/${profile.id}`)
      .then(response => {
        dispatch(updateProfileSuccess(response.data));
      })
  }, [dispatch, profile.id])

  function formatTitleAlternative(type) {
    switch (type) {
      case 'CARDIO':
        return 'Funcional cardio'
      case 'BIKE':
        return 'Bicicleta'
      case 'ROPE_JUMP':
        return 'Pulo de corda'
      default:
        return ''
    }
  }

  function handleFinishRequest() {
    const data = {
      title: selectedSchema.title,
      subtitle: selectedSchema.subtitle,
      description: selectedSchema.description,
      type: selectedSchema.type,
      user_id: profile.id,
      distance,
      distance_history: distanceHistory,
      currennt_practice: currentPractice,
      km5_time: km5Time ? parseFloat(chooseTotalTime(km5Time)) : null,
      km5_date: km5Date,
      km10_time: km10Time ? parseFloat(chooseTotalTime(km10Time)) : null,
      km10_date: km10Date,
      km21_time: km21Time ? parseFloat(chooseTotalTime(km21Time)) : null,
      km21_date: km21Date,
      km42_time: km42Time ? parseFloat(chooseTotalTime(km42Time)) : null,
      km42_date: km42Date,
      start_at: startAt,
      target_date: targetDate,
      days_weekly_workout: daysWeeklyWorkout,
      mhistory_notes: mHistoryNotes,
      general_notes: generalNotes,
      trainingType,
      training_session_time: trainingSessionTime
    }

    if (selectedSchema.type === 'ALTERNATIVE') {
      data.title = data.title + ' - ' + formatTitleAlternative(alternativeType)
    }

    if (!data.km5_time) data.km5_time = profile.km5_time
    if (!data.km5_date) data.km5_date = profile.km5_date
    if (!data.km10_time) data.km10_time = profile.km10_time
    if (!data.km10_date) data.km10_date = profile.km10_date
    if (!data.km21_time) data.km21_time = profile.km21_time
    if (!data.km21_date) data.km21_date = profile.km21_date
    if (!data.km42_time) data.km42_time = profile.km42_time
    if (!data.km42_date) data.km42_date = profile.km42_date

    const userProfileToUpdate = {
      height,
      weight,
      days_weekly_workout: daysWeeklyWorkout
    }

    dispatch(saveTrainingPlanRequestRequest(data, userProfileToUpdate))
  }

  function clearSchema() {
    setSelectedSchema(null)
    setActiveTab(1)
    setDistance(null)
    setDistanceHistory(null)
    setCurrentPractice(null)
    setKm5Time(null)
    setKm5Date(null)
    setKm10Time(null)
    setKm10Date(null)
    setKm21Time(null)
    setKm21Date(null)
    setKm42Time(null)
    setKm42Date(null)
    setTargetDate(null)
    setMHistoryNotes(null)
    setGeneralNotes(null)
    setTrainingType(null)
    setKeepFitCondition(null)
    setSelectedWeek(null)
    setNextButtonDisabled(true)
    setBackButtonSelected(false)
  }

  function setValueForEnableNextButton(fn) {
    fn()

    if (!backButtonSelected) setNextButtonDisabled(false)
  }

  function setNextStepTab() {
    const stepTab = activeTab + 1

    toggleTab(stepTab)

    if (selectedSchema.type === 'DISTANCE' &&
      (stepTab === 4 || stepTab === 5 || (stepTab === 7 && daysWeeklyWorkout) || stepTab === 8)) return

    if (selectedSchema.type === 'KEEP_FIT' &&
      trainingType === 'Quero apenas manter minha forma' &&
      (stepTab === 4 || stepTab === 5 || (stepTab === 7 && daysWeeklyWorkout) || stepTab === 8)) return

    if (selectedSchema.type === 'KEEP_FIT' &&
      trainingType === 'Tenho um objetivo próximo com data marcada' &&
      (stepTab === 4 || stepTab === 5 || (stepTab === 7 && daysWeeklyWorkout) || stepTab === 8)) return

    if (selectedSchema.type === 'RETURN_INITIATION' &&
      (stepTab === 2 || (stepTab === 4 && daysWeeklyWorkout) || stepTab === 5)) return

    if (selectedSchema.type === 'SLIMMING' &&
      (stepTab === 2 || (stepTab === 4 && daysWeeklyWorkout) || stepTab === 5)) return

    if (selectedSchema.type === 'ALTERNATIVE' &&
      stepTab === 2) return

    if (backButtonSelected) {
      setBackButtonSelected(false)
    }

    if (selectedSchema.type === 'DISTANCE' && stepTab === 6) {
      const weeksLimits = formatUserTrainingLimitDates()
      setMinWeek(weeksLimits.min)
      setMaxWeek(weeksLimits.max)
    }

    setNextButtonDisabled(true)
  }

  function setBackStepTab() {
    const stepTab = activeTab - 1

    toggleTab(stepTab)

    setBackButtonSelected(true)
  }

  function subtractMinTargetDate(date) {
    const dateToSubtract = date.clone()

    if (dateToSubtract.get('day') === 1) {
      dateToSubtract.subtract(2, 'd')
    } else if (dateToSubtract.get('day') === 2) {
      dateToSubtract.subtract(3, 'd')
    }

    return dateToSubtract
  }

  function sumMaxTargetDate(date) {
    const dateToSum = date.clone()

    if (dateToSum.get('day') === 3) {
      dateToSum.add(4, 'd')
    } else if (dateToSum.get('day') === 4) {
      dateToSum.add(3, 'd')
    } else if (dateToSum.get('day') === 5) {
      dateToSum.add(2, 'd')
    } else if (dateToSum.get('day') === 6) {
      dateToSum.add(1, 'd')
    }

    return dateToSum
  }

  function handleStartAt(value) {
    const minDate = moment(minStartAt)

    let dateToSetTarget

    if (moment(value).isBefore(minDate)) {
      setStartAt(minDate)
      dateToSetTarget = minDate
    } else {
      setStartAt(value)
      dateToSetTarget = value
    }

    if (dateToSetTarget && selectedSchema.type === 'KEEP_FIT' && trainingType === 'Tenho um objetivo próximo com data marcada') {
      setMinTargetDate(subtractMinTargetDate(momentBusinessDays(dateToSetTarget).businessAdd(20)))
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(dateToSetTarget).businessAdd(40)))
    }
  }

  function handleTargetDate(value) {
    const minDate = moment(minTargetDate)

    if (moment(value).isBefore(minDate)) {
      setTargetDate(minDate)
      return
    }

    setTargetDate(value)
  }

  function formatUserTrainingLevel(value) {
    if (value === '+ 1h por semana' || value === '+ 2h por semana' || value === '+ 3h por semana' || value === '+ 4h por semana' || value === '+ 3 atividades por semana') {
      return 3
    } else if (value === '30min a 1h total por semana' || value === '1h a 2h total por semana' || value === '1h30 a 3h total por semana' || value === '3h a 4h total por semana' || value === '2 atividades por semana') {
      return 2
    } else if (value === 'zero ou quase nada' || value === '- 1h30 ou estou sem correr' || value === '- 3h ou estou sem correr' || value === 'zero ou 1 atividade por semana') {
      return 1
    }
  }

  function formatUserTrainingLimitDates() {
    const complete = 'primeira vez'
    const improve = 'quero melhorar'
    const level = formatUserTrainingLevel(currentPractice)

    //5k completar
    if (distance === 5 && distanceHistory === complete) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(60)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 12 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 12 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 12 }
      }
    }

    //5k melhorar
    if (distance === 5 && distanceHistory === improve) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(60)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 12 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 12 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(20)))
        return { min: 4, max: 12 }
      }
    }

    //10k completar
    if (distance === 10 && distanceHistory === complete) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(60)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(50)))
        return { min: 10, max: 12 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 12 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 12 }
      }
    }

    //10k melhorar
    if (distance === 10 && distanceHistory === improve) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(60)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(50)))
        return { min: 10, max: 12 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 12 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(30)))
        return { min: 6, max: 12 }
      }
    }

    //15/16k completar
    if (distance === 15 && distanceHistory === complete) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(80)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(60)))
        return { min: 12, max: 16 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(50)))
        return { min: 10, max: 16 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(40)))
        return { min: 8, max: 16 }
      }
    }

    //15/16k melhorar
    if (distance === 15 && distanceHistory === improve) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(80)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(60)))
        return { min: 12, max: 16 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(50)))
        return { min: 10, max: 16 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(30)))
        return { min: 6, max: 16 }
      }
    }

    //21k completar
    if (distance === 21 && distanceHistory === complete) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(80)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(70)))
        return { min: 14, max: 16 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(70)))
        return { min: 14, max: 16 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(60)))
        return { min: 12, max: 16 }
      }
    }

    //21k melhorar
    if (distance === 21 && distanceHistory === improve) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(80)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(70)))
        return { min: 14, max: 16 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(60)))
        return { min: 12, max: 16 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(50)))
        return { min: 10, max: 16 }
      }
    }

    //42k completar
    if (distance === 42 && distanceHistory === complete) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(100)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(100)))
        return { min: 20, max: 20 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(80)))
        return { min: 16, max: 20 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(60)))
        return { min: 12, max: 20 }
      }
    }

    //42k melhorar
    if (distance === 42 && distanceHistory === improve) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(100)))

      if (level === 1) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(90)))
        return { min: 18, max: 20 }
      } else if (level === 2) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(60)))
        return { min: 12, max: 20 }
      } else if (level === 3) {
        setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(50)))
        return { min: 10, max: 20 }
      }
    }
  }

  function handleSetDistance(value) {
    setDistance(value)

    if (value === 5) {
      setMinTargetDate(subtractMinTargetDate(momentBusinessDays(minStartAt).businessAdd(20)))
    } else if (value === 10 || value === 15) {
      setMinTargetDate(subtractMinTargetDate(momentBusinessDays(minStartAt).businessAdd(30)))
    } else if (value === 21 || value === 42) {
      setMinTargetDate(subtractMinTargetDate(momentBusinessDays(minStartAt).businessAdd(50)))
    }

    if (value === 5 || value === 10) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(minStartAt).businessAdd(60)))
    } else if (value === 15 || value === 21) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(minStartAt).businessAdd(80)))
    } else if (value === 42) {
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(minStartAt).businessAdd(100)))
    }

    if (selectedSchema.type === 'KEEP_FIT') {
      setMinTargetDate(subtractMinTargetDate(momentBusinessDays(minStartAt).businessAdd(20)))
      setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(minStartAt).businessAdd(40)))
    }
  }

  function handleSetTrainingType(value) {
    switch (value) {
      case 'Quero apenas manter minha forma':
        setOldSteps(selectedSchema.steps)
        setSelectedSchema({
          ...selectedSchema,
          steps: selectedSchema.steps_without_target
        })
        break
      case 'Tenho um objetivo próximo com data marcada':
        if (oldSteps) {
          setSelectedSchema({
            ...selectedSchema,
            steps: oldSteps,
          })
          setOldSteps(null)
        }
        break
      default:
        break;
    }

    setTrainingType(value)
  }

  function handleSetSelectedWeek(value) {
    setTargetDate(momentBusinessDays(startAt).businessAdd(value * 5))

    setMinTargetDate(subtractMinTargetDate(momentBusinessDays(startAt).businessAdd(20)))
    setMaxTargetDate(sumMaxTargetDate(momentBusinessDays(startAt).businessAdd(40)))

    setSelectedWeek(value)
  }

  function formatTrainingObjectiveType(text) {
    switch (text) {
      case 'Quero apenas manter minha forma':
        return 'Sem objetivo definido'
      case 'Tenho um objetivo próximo com data marcada':
        return 'Com objetivo definido'
      default:
        return ''
    }
  }

  async function cancelRequest() {
    if (lastRequest) {
      try {
        await api.put(`training_plan_requests/${lastRequest.id}`, { status: statusEnums.CANCELED })

        window.location.reload()
      } catch (error) {
        toast.error('Não foi possível cancelar a solicitação, tente novamente.')
      }
    }
  }

  return (
    <>
      <div>
        <Modal isOpen={requestSucceded} toggle={() => {
          toggle()
          window.location.reload()
        }}>
          <ModalHeader toggle={() => {
            toggle()
            window.location.reload()
          }} close={closeBtn}>Confirmação de solicitação</ModalHeader>
          <ModalBody>
            <h4>Recebemos sua solicitação de plano de treino de corrida com sucesso.</h4>

            <p>Em até 2 (dois) dias úteis um de nossos treinadores vai preparar e liberar o seu treino.</p>
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-bordered-success btn-block" onClick={() => {
              toggle()
              window.location.reload()
            }}>OK</button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal isOpen={keepFitCondition === 'no' ? true : false}
          toggle={clearSchema}>
          <ModalBody>
            <h4>Considere começar por outra distância ou por outro objetivo. Em caso de dúvidas, procure nossas opções de atendimento.</h4>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-bordered-success btn-block"
              onClick={clearSchema}>OK</button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal isOpen={modalCancel} toggle={toggleCancel}>
          <ModalBody>
            <h4>Deseja realmente cancelar a solicitação? Esta ação não pode ser desfeita.</h4>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-bordered-primary"
              onClick={toggleCancel}>Não</button>
            <button
              className="btn btn-bordered-danger"
              onClick={cancelRequest}>Sim</button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal isOpen={showModal} toggle={toggle} size="md">
          <ModalHeader toggle={toggle} close={closeBtn}>
            {lastRequest &&
              (lastRequest.status === statusEnums.FINISHED || lastRequest.status === statusEnums.CANCELED) &&
              'Solicitar Plano de Treino de Corrida'}

            {!lastRequest && 'Solicitar Plano de Treino de Corrida'}

            {lastRequest &&
              (lastRequest.status === statusEnums.CREATED || lastRequest.status === statusEnums.OPEN || lastRequest.status === statusEnums.PENDING) &&
              'Solicitação de Plano de Treino em Andamento'}
          </ModalHeader>
          <ModalBody>
            {loading && <Loader />}
            {loadingSchemas && <Loader />}

            {!selectedSchema &&
              <div>
                {lastRequest &&
                  (lastRequest.status === statusEnums.FINISHED || lastRequest.status === statusEnums.CANCELED) &&
                  <>
                    <h4>Olá {profile.name}! Desejamos boas vindas ao nosso assistente de solicitação de treino.</h4>
                    <br />
                    <h4>Escolha o tipo de treino que deseja que os nossos treinadores preparem para você:</h4>

                    <Row className="mt-4" style={{ display: 'flex', flexDirection: 'column' }}>
                      {schemas.length > 0 &&
                        schemas.map(option => (
                          <Col key={option.id} style={{
                            padding: 10,
                            marginLeft: 10,
                            marginTop: 10,
                            borderRadius: 8,
                            background: '#EEEEEE',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            cursor: 'pointer',
                            maxWidth: 470,
                            minHeight: 150
                          }} onClick={() => setSelectedSchema(option)}>
                            <img
                              src={formatTrainingRequestType(option.type)}
                              alt="icon"
                              className="img-fluid"
                              style={{ minHeight: 110, maxHeight: 131, minWidth: 110, maxWidth: 131 }} />

                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <h3>{option.title}</h3>
                                {option.subtitle &&
                                  <h5 style={{ backgroundColor: '#ffffff', marginLeft: 10, padding: 5, borderRadius: 4 }}>
                                    {option.subtitle}
                                  </h5>}
                              </div>
                              <h5 className="text-muted">{option.description}</h5>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </>
                }

                {!lastRequest &&
                  <>
                    <h4>Olá {profile.name}! Desejamos boas vindas ao nosso assistente de solicitação de treino.</h4>
                    <br />
                    <h4>Escolha o tipo de treino que deseja que os nossos treinadores preparem para você:</h4>

                    <Row className="mt-4" style={{ display: 'flex', flexDirection: 'column' }}>
                      {schemas.length > 0 &&
                        schemas.map(option => (
                          <Col key={option.id} style={{
                            padding: 10,
                            marginLeft: 10,
                            marginTop: 10,
                            borderRadius: 8,
                            background: '#EEEEEE',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            cursor: 'pointer',
                            maxWidth: 470,
                            minHeight: 150
                          }} onClick={() => setSelectedSchema(option)}>
                            <img
                              src={formatTrainingRequestType(option.type)}
                              alt="icon"
                              className="img-fluid"
                              style={{ minHeight: 110, maxHeight: 131, minWidth: 110, maxWidth: 131 }} />

                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <h3>{option.title}</h3>
                                {option.subtitle &&
                                  <h5 style={{ backgroundColor: '#ffffff', marginLeft: 10, padding: 5, borderRadius: 4 }}>
                                    {option.subtitle}
                                  </h5>}
                              </div>
                              <h5 className="text-muted">{option.description}</h5>
                            </div>
                          </Col>
                        ))}
                    </Row>
                  </>
                }

                {lastRequest &&
                  (lastRequest.status === statusEnums.CREATED || lastRequest.status === statusEnums.OPEN || lastRequest.status === statusEnums.PENDING) &&
                  <>
                    {lastRequest.type === 'ALTERNATIVE' ?
                      <h4>Você possui uma solicitação de plano de treino alternativo em aberto.</h4>
                    : <h4>Você possui uma solicitação de plano de treino de corrida em aberto.</h4>}
                    <br />

                    <ListGroup>
                      <ListGroupItem>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          <p>Plano de treino solicitado</p>
                          {lastRequest &&
                            <p>
                              {lastRequest.type === 'KEEP_FIT' ? formatRequestKeepFitType(lastRequest.trainingType) : formatTrainingPlanRequestObjective(lastRequest.distance_history)}
                              {lastRequest.distance ? ` ${lastRequest.distance}km` : ''}
                              {lastRequest.type === 'SLIMMING' || lastRequest.type === 'RETURN_INITIATION' || lastRequest.type === 'ALTERNATIVE' ? lastRequest.title : ''}
                            </p>}
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          <p>Data da solicitação</p>
                          {lastRequest && <p>{moment(lastRequest.created_at).format('DD/MM/YYYY')}</p>}
                        </div>
                      </ListGroupItem>
                      <ListGroupItem>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                          <p>Prazo para retorno</p>
                          <p>2 dias úteis</p>
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                    <br />

                    {(lastRequest.status === statusEnums.OPEN || lastRequest.status === statusEnums.PENDING) &&
                      <p>Seu pedido já está em atendimento por um treinador.
                      Caso necessário, entre em contato conosco pela opção <b>Ajuda (?)</b> em seu Perfil.</p>}

                    {lastRequest.status === statusEnums.CREATED &&
                      <>
                        <p>Enquanto um de nossos treinadores não iniciar a preparação do seu treino, você pode
                      cancelar a solicitação e fazer outra, caso desejar.</p>

                        <button className="btn btn-block btn-outline-danger" onClick={toggleCancel}>Cancelar</button>
                      </>
                    }
                  </>
                }
              </div>
            }

            {selectedSchema &&
              <div>
                <TabContent activeTab={activeTab}>
                  {selectedSchema.steps.map(step => (
                    <TabPane key={step.order} tabId={step.order}>
                      <Row>
                        <Col sm="12">
                          <div style={{ maxWidth: 600 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <h4>Tipo de treino selecionado:</h4>
                              <button className="btn btn-bordered-secondary" onClick={clearSchema}>Trocar</button>
                            </div>

                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                              <img
                                src={formatTrainingRequestType(selectedSchema.type)}
                                alt="icon"
                                className="img-fluid"
                                style={{ minWidth: 80, maxHeight: 80 }} />

                              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                  <h3>{selectedSchema.title}</h3>
                                  {!distance && <h5 style={{ marginLeft: 10 }}>{selectedSchema.subtitle}</h5>}
                                </div>

                                <h6>{distance ? `Treino para ${distance}km` : selectedSchema.description}</h6>
                                {trainingType && <h6>{formatTrainingObjectiveType(trainingType)}</h6>}

                              </div>
                            </div>
                          </div>

                          <DistanceRequest
                            profile={profile}
                            selectedSchema={selectedSchema}
                            step={step}
                            TimeInput={TimeInput}
                            currentPractice={currentPractice}
                            setCurrentPractice={setCurrentPractice}
                            daysWeeklyWorkout={daysWeeklyWorkout}
                            distance={distance}
                            distanceHistory={distanceHistory}
                            height={height}
                            weight={weight}
                            setDaysWeeklyWorkout={setDaysWeeklyWorkout}
                            handleSetDistance={handleSetDistance}
                            setDistanceHistory={setDistanceHistory}
                            setGeneralNotes={setGeneralNotes}
                            mHistoryNotes={mHistoryNotes}
                            generalNotes={generalNotes}
                            setHeight={setHeight}
                            setKmDate={setKmDate}
                            setKmTime={setKmTime}
                            setMHistoryNotes={setMHistoryNotes}
                            handleStartAt={handleStartAt}
                            handleTargetDate={handleTargetDate}
                            setValueForEnableNextButton={setValueForEnableNextButton}
                            setWeight={setWeight}
                            startAt={startAt}
                            targetDate={targetDate}
                            minStartAt={minStartAt}
                            minTargetDate={minTargetDate}
                            maxTargetDate={maxTargetDate}
                            maxStartAt={maxStartAt}
                            maxWeek={maxWeek}
                            minWeek={minWeek}
                          />

                          <KeepFitRequest
                            profile={profile}
                            selectedSchema={selectedSchema}
                            step={step}
                            setValueForEnableNextButton={setValueForEnableNextButton}
                            setTrainingType={handleSetTrainingType}
                            trainingType={trainingType}
                            handleSetDistance={handleSetDistance}
                            distance={distance}
                            setKeepFitCondition={setKeepFitCondition}
                            keepFitCondition={keepFitCondition}
                            TimeInput={TimeInput}
                            setKmTime={setKmTime}
                            setKmDate={setKmDate}
                            handleSetSelectedWeek={handleSetSelectedWeek}
                            selectedWeek={selectedWeek}
                            targetDate={targetDate}
                            minTargetDate={minTargetDate}
                            maxTargetDate={maxTargetDate}
                            handleTargetDate={handleTargetDate}
                            minStartAt={minStartAt}
                            startAt={startAt}
                            handleStartAt={handleStartAt}
                            daysWeeklyWorkout={daysWeeklyWorkout}
                            setDaysWeeklyWorkout={setDaysWeeklyWorkout}
                            height={height}
                            setHeight={setHeight}
                            weight={weight}
                            setWeight={setWeight}
                            setGeneralNotes={setGeneralNotes}
                            setMHistoryNotes={setMHistoryNotes}
                            mHistoryNotes={mHistoryNotes}
                            generalNotes={generalNotes}
                            maxStartAt={maxStartAt}
                          />

                          <ReturnInitiationRequest
                            selectedSchema={selectedSchema}
                            step={step}
                            daysWeeklyWorkout={daysWeeklyWorkout}
                            setDaysWeeklyWorkout={setDaysWeeklyWorkout}
                            setValueForEnableNextButton={setValueForEnableNextButton}
                            handleSetSelectedWeek={handleSetSelectedWeek}
                            selectedWeek={selectedWeek}
                            startAt={startAt}
                            minStartAt={minStartAt}
                            handleStartAt={handleStartAt}
                            distanceHistory={distanceHistory}
                            setDistanceHistory={setDistanceHistory}
                            height={height}
                            setHeight={setHeight}
                            weight={weight}
                            setWeight={setWeight}
                            setGeneralNotes={setGeneralNotes}
                            setMHistoryNotes={setMHistoryNotes}
                            mHistoryNotes={mHistoryNotes}
                            generalNotes={generalNotes}
                            maxStartAt={maxStartAt}
                          />

                          <SlimmingRequest
                            selectedSchema={selectedSchema}
                            step={step}
                            selectedWeek={selectedWeek}
                            handleSetSelectedWeek={handleSetSelectedWeek}
                            setValueForEnableNextButton={setValueForEnableNextButton}
                            startAt={startAt}
                            minStartAt={minStartAt}
                            handleStartAt={handleStartAt}
                            distanceHistory={distanceHistory}
                            setDistanceHistory={setDistanceHistory}
                            daysWeeklyWorkout={daysWeeklyWorkout}
                            setDaysWeeklyWorkout={setDaysWeeklyWorkout}
                            height={height}
                            setHeight={setHeight}
                            weight={weight}
                            setWeight={setWeight}
                            setGeneralNotes={setGeneralNotes}
                            setMHistoryNotes={setMHistoryNotes}
                            mHistoryNotes={mHistoryNotes}
                            generalNotes={generalNotes}
                            maxStartAt={maxStartAt}
                          />

                          <AlternativeRequest
                            selectedSchema={selectedSchema}
                            step={step}
                            alternativeType={alternativeType}
                            handleStartAt={handleStartAt}
                            maxStartAt={maxStartAt}
                            minStartAt={minStartAt}
                            setAlternativeType={setAlternativeType}
                            setValueForEnableNextButton={setValueForEnableNextButton}
                            startAt={startAt}
                            currentPractice={currentPractice}
                            setCurrentPractice={setCurrentPractice}
                            setTrainingSessionTime={setTrainingSessionTime}
                            trainingSessionTime={trainingSessionTime}
                            generalNotes={generalNotes}
                            mHistoryNotes={mHistoryNotes}
                            setGeneralNotes={setGeneralNotes}
                            setMHistoryNotes={setMHistoryNotes}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  ))}
                </TabContent>
              </div>
            }
          </ModalBody>
          <ModalFooter>
            {selectedSchema &&
              activeTab <= selectedSchema.steps.length &&
              activeTab > 1 &&
              <button
                className="btn btn-bordered-success"
                onClick={setBackStepTab}>
                <FaAngleLeft color="white" size={22} />&nbsp;
                Voltar
              </button>}

            {selectedSchema &&
              activeTab < selectedSchema.steps.length &&
              !backButtonSelected &&
              <button
                disabled={nextButtonDisabled}
                className="btn btn-bordered-success"
                onClick={setNextStepTab}>
                Continuar&nbsp;
                <FaAngleRight color="white" size={22} />
              </button>}

            {selectedSchema &&
              activeTab < selectedSchema.steps.length &&
              backButtonSelected &&
              <button
                className="btn btn-bordered-success"
                onClick={() => {
                  toggleTab(activeTab + 1)
                  setBackButtonSelected(false)

                  if (selectedSchema.type === 'DISTANCE' && activeTab + 1 === 6) {
                    const weeksLimits = formatUserTrainingLimitDates()
                    setMinWeek(weeksLimits.min)
                    setMaxWeek(weeksLimits.max)
                  }
                }}>
                Continuar&nbsp;
                <FaAngleRight color="white" size={22} />
              </button>}

            {selectedSchema &&
              activeTab === selectedSchema.steps.length &&
              <button disabled={loading} className="btn btn-bordered-success" onClick={handleFinishRequest}>
                Finalizar&nbsp;
                <FaCheck color="white" size={22} />
              </button>}
          </ModalFooter>
        </Modal>
      </div>
    </>
  )
}

export default TrainingPlanRequestModal;
