export function loadUserTrainingPlanRequest(userId) {
    return {
        type: '@userTrainingPlan/LOAD_USER_TRAINING_PLAN_REQUEST',
        payload: { userId },
    };
}

export function loadUserTrainingPlanSuccess(data) {
    return {
        type: '@userTrainingPlan/LOAD_USER_TRAINING_PLAN_SUCCESS',
        payload: { data },
    };
}

export function loadUserTrainingPlanFailure() {
    return {
        type: '@userTrainingPlan/LOAD_USER_TRAINING_PLAN_FAILURE'
    };
}