import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Card, CardBody,
  Button, ButtonGroup, Tooltip,
  Modal, ModalHeader, ModalBody, ModalFooter, Spinner
} from 'reactstrap';
import { parseISO, addDays, format } from 'date-fns';
import api from '~/services/api'
import * as UserActivitiesActions from '~/store/modules/userActivity/actions';
import * as UserTrainingPlanActions from '~/store/modules/userTrainingPlan/actions';
import * as TrainingPlanRequests from '~/store/modules/trainingPlanRequests/actions';
import { FaCheckCircle } from 'react-icons/fa'
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { formatDuration, isAdm } from '~/services/util'
import moment from 'moment'
import ModalActivity from './components/Modal'
import TrainingShowModal from '../Training/show/TrainingShowModal'
import UserActivityItem from './components/UserActivityItem'
import TrainingDetailContainer from './components/TrainingDetailContainer'
import TrainingPlanRequestModal from '~/pages/TrainingPlanRequestModal'
import NewUserOnboardModal from '~/pages/onBoards/NewUserOnboardModal'
import {
  DayContainer,
  NewAppInfoBanner, Paragraph, NewAppInfoBannerContainer
} from './styles'
import StarIcon from '../../assets/star-icon.png'

const ROUTES_TRAINING = {
  'WeightTraining': 'treinos-forca',
  'Drills': 'treinos-educativos',
  'Cardio': 'treinos-cardio',
  'Others': 'treinos-outros',
}

function orderTrainingGroupsAndTrainingActivities(training) {
  let ordered = training

  if (ordered.trainingGroups && ordered.trainingGroups.length > 0) {
    ordered.trainingGroups = ordered.trainingGroups.sort((a, b) => {
      if (a.group_order && b.group_order && a.group_order < b.group_order) return -1

      return 0
    })

    for (let index = 0; index < ordered.trainingGroups.length; index++) {
      if (ordered.trainingGroups[index].trainingActivities && ordered.trainingGroups[index].trainingActivities.length > 0) {
        ordered.trainingGroups[index].trainingActivities = ordered.trainingGroups[index].trainingActivities.sort((a, b) => {
          if (a.group_order && b.group_order && a.group_order < b.group_order) return -1

          return 0
        })
      }
    }
  }

  return ordered
}

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
      tooltipOpen2: false,
      tooltipOpen3: false,
      trainingPlanWeek: null,
      trainingDays: null,
      currentTraings: null,
      currentDay: null,
      today: null,
      todayDate: null,
      daysCalendarScrollItens: null,
      daysWIndex: null,
      calendarDays: null,
      currentDayIndex: null,
      showTrainingModalVisible: false,
      trainingToShow: null,
      loading: true,
      lastRequest: null,
      typeformIframeModal: false,
      typeformIframeUrl: null,
      newUserOnBoardFlow: (this.props.profile.onboard_status === 'NEW_USER')
    }
  }

  componentDidMount() {
    if (this.props.userActivities.expirationDate && this.props.userTrainingPlan.expirationDate) {
      const userActivitiesExpirationCacheDate = moment(this.props.userActivities.expirationDate).format('YYYY-MM-DD HH:mm');
      const userTrainingPlanExpirationCacheDate = moment(this.props.userTrainingPlan.expirationDate).format('YYYY-MM-DD HH:mm');

      const now = moment().format('YYYY-MM-DD HH:mm');

      if (moment(now).isAfter(userActivitiesExpirationCacheDate)) {
        this.props.loadUserActivitiesRequest(this.props.profile.id)
      }

      if (moment(now).isAfter(userTrainingPlanExpirationCacheDate)) {
        this.props.loadUserTrainingPlanRequest(this.props.profile.id)
      }
    } else {
      this.props.loadUserTrainingPlanRequest(this.props.profile.id)
      this.props.loadUserActivitiesRequest(this.props.profile.id)
    }

    this._loadTrainingPlanRequest()

    setTimeout(() => {
      this._loadTrainingPlans()
    }, 2000)
  }

  _loadTrainingPlanRequest = async () => {
    api.get(`get_user_requests?userId=${this.props.profile.id}`)
      .then(response => {
        if (response.data && response.data.data.length > 0) {
          this.setState({ ...this.state, lastRequest: response.data.data[0] })
        }
      })
  }

  _loadTrainingPlans = async () => {
    if (this.props.userTrainingPlan && this.props.userTrainingPlan.data) {
      const currTrainingPlan = this.props.userTrainingPlan.data;

      let trainingPlanWeekResp = await api.get(
        `/training_plan_weeks?trainingPlanId=${currTrainingPlan.id}`
      );

      trainingPlanWeekResp = trainingPlanWeekResp.data;

      if (trainingPlanWeekResp && trainingPlanWeekResp.start_date) {

        const firstDayOfWeek = parseISO(trainingPlanWeekResp.start_date);
        const currTodayDate = format(new Date(), 'yyyy-MM-dd');

        const currTrainingDays = {};
        for (let index = 0; index < 7; index++) {

          const currDay = format(addDays(firstDayOfWeek, index), 'yyyy-MM-dd');

          currTrainingDays[currDay] = null;
        }

        let currDayTmp = null
        let currTraings = null
        let currToday = null
        let currCurrentDayIndex = null

        trainingPlanWeekResp.days.forEach(async (item, idx) => {
          const currDay = format(parseISO(item.date), 'yyyy-MM-dd');
          currTrainingDays[currDay] = item;

          if (currDay === currTodayDate) {
            currDayTmp = { date: `${currDay}`, trainingPlanDay: item }
            currTraings = item.trainings
            currToday = item
            currCurrentDayIndex = idx
          }
        });

        const trainingDaysArr = []
        await Object.keys(currTrainingDays).forEach((key, value) => {
          const obj = currTrainingDays[key];

          let statusCheck = false

          if (obj?.trainings?.length > 0) {
            obj.trainings.forEach(training => {
              if (training.executed_status === 'EXECUTED_HIGH') {
                statusCheck = true;
              }
            });
          }

          trainingDaysArr.push({ date: `${key}`, trainingPlanDay: obj, statusCheck })
        });

        this.setState({
          ...this.state,
          trainingPlan: currTrainingPlan,
          todayDate: currTodayDate,
          calendarDays: Object.keys(currTrainingDays),
          trainingPlanWeek: trainingPlanWeekResp,
          trainingDays: trainingDaysArr,
          currentDay: currDayTmp,
          currentTraings: currTraings,
          today: currToday,
          currentDayIndex: currCurrentDayIndex,
          loading: false,
        })
      } else {
        this.setState({ ...this.state, loading: false, })
      }
    } else {
      this.setState({ ...this.state, loading: false, })
    }
  }

  refreshActivities = () => {
    this.props.loadUserActivitiesRequest(this.props.profile.id)
  }

  tooltipToggle = () => this.setState({ ...this.state, tooltipOpen: !this.state.tooltipOpen });
  tooltipToggle2 = () => this.setState({ ...this.state, tooltipOpen2: !this.state.tooltipOpen2 });
  tooltipToggle3 = () => this.setState({ ...this.state, tooltipOpen3: !this.state.tooltipOpen3 });

  handleUpdateActivity = (activity) => {
    const activityToUpdate = {
      ...activity,
      pace: formatDuration(activity.pace, 'pace'),
      duration: formatDuration(activity.duration, 'time'),
    }

    this.props.setActivityToUpdate(activityToUpdate)

    this.props.toggleModalActivity()
  }

  formatDateSrt = (date) => {
    if (!date) return '';

    const newDate = date.split('T');
    date = new Date(`${newDate[0]}T11:00:00`);
    const month = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    return `${date.getDate()} de ${month[date.getMonth()]}`;
  }

  buildDayName = (date) => {
    if (date) {
      const newDate = date.split('T');
      date = new Date(`${newDate[0]}T11:00:00`);
      const days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
      return days[date.getDay()];
    }
  }

  formatDate = (date) => {
    const newDate = `${date}`.split('T');

    date = new Date(`${newDate[0]}T11:00:00`);

    return format(date, "dd/MM")
  }

  _handleChooseCurrentDay = async (currObj) => {
    const currTrainings = currObj.trainingPlanDay ? currObj.trainingPlanDay.trainings : []
    this.setState({ ...this.state, currentTraings: currTrainings, currentDay: currObj })
  }

  _handleTrainingDetail = async (training) => {
    if ((training.type === 'WeightTraining' || training.type === 'Drills' || training.type === 'Cardio' || training.type === 'Others') && (!training.executed_status || training.executed_status !== 'EXECUTED_HIGH')) {

      window.location.href = `/${ROUTES_TRAINING[training.type]}?type=${training.type}&rt=${training.id}`
    } else {
      this.setState({ ...this.state, showTrainingModalVisible: !this.state.showTrainingModalVisible, trainingToShow: training })
    }
  }

  _saveActivityCallback = async (newAct) => {
    let interval = {}
    interval = setInterval(async () => {
      await this.setState({ ...this.state, modalVisible: false });
      clearInterval(interval)
    }, 500);
  }

  _openTypeformModal = (typeformURL) => {

    this.setState({ ...this.state, typeformIframeModal: true, typeformIframeUrl: typeformURL })
  }

  _closeTypeformModal = () => {
    this.setState({ ...this.state, typeformIframeModal: false, typeformIframeUrl: null })
  }


  render() {
    const closeBtn = <button className="close" onClick={this._closeTypeformModal}>&times;</button>;

    const { data } = this.props.userTrainingPlan

    return (
      <>
        {this.props.userActivities.modalActivity &&
          <ModalActivity refreshActivities={this.refreshActivities} />}

        {this.props.trainingPlanRequestShowModalTMPButton &&
          <TrainingPlanRequestModal />}

        {this.state.newUserOnBoardFlow &&
          (this.props?.profile?.subscription_type === 'ESSENTIAL' || isAdm(this.props?.profile?.id)) &&
          <NewUserOnboardModal open={this.state.newUserOnBoardFlow} toggle={() => this.setState({ ...this.state, newUserOnBoardFlow: !this.state.newUserOnBoardFlow })} />}

        {this.state.typeformIframeModal &&
          <Modal isOpen={this.state.typeformIframeModal} toggle={() => this._closeTypeformModal()} className="modal-full" >
            <ModalHeader toggle={this._closeTypeformModal} close={closeBtn}>Solicitar Treino de Corrida</ModalHeader>
            <ModalBody>
              <iframe src={this.state.typeformIframeUrl} width="100%" height="100%" style={{ minHeight: 600 }} title="Solicitar Plano de Treino">
              </iframe>
            </ModalBody>
            <ModalFooter>
              <button className="btn btn-bordered-danger" onClick={this._closeTypeformModal}>Cancelar</button>
            </ModalFooter>
          </Modal>}

        <Row>
          <Col>
            <div className="page-title-box">
              <Row>
                <Col>
                  <h4 className="page-title">Principal</h4>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          {(this.props?.profile?.subscription_type === 'ESSENTIAL' || isAdm(this.props?.profile?.id)) &&
            <Col lg={6}>
              {/* <NewAppInfoBanner>
                <img src={StarIcon} width="50" height="50" alt="star-icon" />

                <NewAppInfoBannerContainer>
                  <Paragraph>
                    Nosso site está de cara nova, experimente a nova versão clicando no link abaixo.
                    <br />
                    <a style={{ color: '#fff', textDecoration: 'underline' }} href="https://app.corridaperfeita.com">app.corridaperfeita.com</a>
                  </Paragraph>
                </NewAppInfoBannerContainer>
              </NewAppInfoBanner> */}

              <h4>Próximos treinos programados</h4>
              <Card>
                <CardBody>
                  {this.props.userTrainingPlan.loading && <div style={{ display: 'flex', height: 100, flex: 1, justifyContent: 'center', alignItems: 'center' }}><Spinner color="primary" /></div>}

                  {this.props.userTrainingPlan.data && data.deactivated_at === null ?
                    <>
                      <p>Plano de treino ativo: <span style={{ fontWeight: '500' }}>{data.name}</span></p>
                      <p>Data final do plano: <span style={{ fontWeight: '500' }}>{data.end_date ? moment(data.end_date).utc(true).format('DD/MM/YYYY') : '-'}</span></p>
                      {this.state.lastRequest && (this.state.lastRequest.status === 'OPEN' || this.state.lastRequest.status === 'CREATED' || this.state.lastRequest.status === 'PENDING') && <p>Solicitação de plano de treino em andamento</p>}
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Link to="/meu-treino" className="btn btn-secondary" style={{}}>Ver calendário completo</Link>

                        {this.props.profile &&
                          <button type="button" className="btn btn-secondary waves-effect" onClick={
                            () => this.props.toggleShowModalTrainingPlanRequestTmp()
                          }>{this.state.lastRequest && (this.state.lastRequest.status === 'OPEN' || this.state.lastRequest.status === 'CREATED' || this.state.lastRequest.status === 'PENDING') && 'Ver dados da solicitação'}
                            {!this.state.lastRequest && 'Solicitar novo plano'}
                            {this.state.lastRequest && (this.state.lastRequest.status === 'FINISHED' || this.state.lastRequest.status === 'CANCELED') && 'Solicitar novo plano'}</button>}
                      </div>
                    </>
                    :
                    <>
                      <p>Você não possui plano de treino de corrida ativo.</p>
                      <p>Solicite agora o seu próximo plano de treino.</p>
                      {this.state.lastRequest && (this.state.lastRequest.status === 'OPEN' || this.state.lastRequest.status === 'CREATED' || this.state.lastRequest.status === 'PENDING') && <p>Solicitação de plano de treino em andamento</p>}
                      <br />
                      <br />
                      {this.props.profile &&
                        <button type="button" className="btn btn-secondary waves-effect" onClick={
                          () => this.props.toggleShowModalTrainingPlanRequestTmp()
                        }>{this.state.lastRequest && (this.state.lastRequest.status === 'OPEN' || this.state.lastRequest.status === 'CREATED' || this.state.lastRequest.status === 'PENDING') && 'Ver dados da solicitação'}
                          {!this.state.lastRequest && 'Solicitar novo plano'}
                          {this.state.lastRequest && (this.state.lastRequest.status === 'FINISHED' || this.state.lastRequest.status === 'CANCELED') && 'Solicitar novo plano'}</button>}
                    </>
                  }

                  <Row>
                    {this.state.loading && <div style={{ display: 'flex', height: 100, flex: 1, justifyContent: 'center', alignItems: 'center' }}><Spinner color="primary" /></div>}

                    {this.props.userTrainingPlan.data &&
                      this.state.trainingPlanWeek &&
                      <Col>
                        <Row style={{ marginTop: 15 }}>
                          {this.state.trainingDays &&
                            this.state.trainingDays.length > 0 &&
                            this.state.trainingDays.map((obj, didx) => {
                              if (obj !== null) {
                                return (
                                  <DayContainer key={didx}>
                                    <div key={`${didx}`}
                                      onClick={() => this._handleChooseCurrentDay(obj)} style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', paddingTop: 15 }} >

                                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0, maxHeight: 18 }}>
                                        <p style={{ fontWeight: 'bold', padding: 0, color: `${(this.state.currentDay && obj.date === this.state.currentDay.date) ? '#4c70b7' : 'gray'}`, }}>
                                          {obj.date === this.state.todayDate && <div style={{ backgroundColor: '#4c70b7', width: 7, height: 7, borderRadius: 5 }} />}
                                          {this.buildDayName(obj.date)}
                                          {obj.statusCheck ? <FaCheckCircle style={{ marginLeft: 5 }} color="green" size={13} /> : <FaCheckCircle style={{ marginLeft: 5 }} color="#c1c1c1" size={13} />}
                                        </p>
                                      </div>

                                      <p style={{ color: `${(this.state.currentDay && obj.date === this.state.currentDay.date) ? '#4c70b7' : 'gray'}`, marginTop: 0, padding: 0 }} > {this.formatDate(obj.date)} </p>
                                    </div>
                                    {this.state.trainingDays && (didx + 1) !== this.state.trainingDays.length && <div style={{ backgroundColor: '#e1e1e1', width: 1 }} />}
                                  </DayContainer>
                                );
                              }

                              return (<div></div>)
                            })}
                        </Row>

                        <Row style={{ marginTop: 20 }}>
                          <Col>
                            {this.state.currentTraings && this.state.currentTraings.length <= 0 && (
                              <div>
                                <p style={{ margin: 10, alignSelf: 'center', fontSize: 16, fontWeight: '400', color: 'gray', }} >
                                  Nenhum treino agendado para {this.formatDateSrt(this.state.currentDay.date)}
                                </p>
                              </div>
                            )}

                            {this.state.currentTraings && this.state.currentTraings.length > 0 && this.state.currentTraings.map((training, tidx) => {
                              return (
                                <TrainingDetailContainer
                                  key={`tra_${tidx}`}
                                  training={orderTrainingGroupsAndTrainingActivities(training)}
                                  trainingIDX={tidx}
                                  currentDate={training.due_date}
                                  handleTrainingDetail={this._handleTrainingDetail}
                                  showHeader
                                />
                              );
                            })}
                          </Col>
                        </Row>
                      </Col>}
                  </Row>
                </CardBody>
              </Card>
            </Col>}

          <Col lg={6}>
            {
              this.props?.profile?.subscription_type !== 'ESSENTIAL' && !isAdm(this.props?.profile?.id) &&
              <NewAppInfoBanner>
                <img src={StarIcon} width="50" height="50" alt="star-icon" />

                <NewAppInfoBannerContainer>
                  <Paragraph>
                    Este acesso via navegador está passando por reformulação, para se adequar ao mesmo padrão
                    do App de celular.
                    <br />Aguarde as novidades!
                  </Paragraph>
                </NewAppInfoBannerContainer>
              </NewAppInfoBanner>
            }

            <h4>Atividades recentes</h4>

            {this.props.userActivities.loading && <div style={{ display: 'flex', height: 100, flex: 1, justifyContent: 'center', alignItems: 'center' }}><Spinner color="primary" /></div>}

            {this.props.userActivities.data.data &&
              this.props.userActivities.data.data.length > 0 ? '' :
              <>
                <p className="sub-header">Você não possui atividades registradas.</p>
                <p className="sub-header">Adicione sua primeira atividade manualmente
                  ou use as nossas integrações para sincronizar com o
                  Strava e seu relógio esportivo.</p>
              </>
            }

            <Row style={{ marginBottom: 10 }}>
              <Col lg={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                {(this.props?.profile?.subscription_type === 'ESSENTIAL' || isAdm(this.props?.profile?.id)) &&
                  <Link to="/meu-treino" style={{}}>Ver calendário completo</Link>}
              </Col>
              <Col lg={6} className="d-flex justify-content-end">
                <ButtonGroup>
                  <Link to="/perfil#integracoes" className="btn btn-secondary" id="tooltip1"><i className="fas fa-link"></i></Link>
                  <Tooltip placement="top" isOpen={this.state.tooltipOpen} target="tooltip1" toggle={this.tooltipToggle}>
                    Integrações
                  </Tooltip>
                  <Button onClick={this.props.toggleModalActivity} id="tooltip2"><i className="fas fa-plus-circle"></i></Button>
                  <Tooltip placement="top" isOpen={this.state.tooltipOpen2} target="tooltip2" toggle={this.tooltipToggle2}>
                    Adicionar atividade
                  </Tooltip>
                  <Button onClick={this.refreshActivities} id="tooltip3"><i className="fas fa-redo-alt"></i></Button>
                  <Tooltip placement="top" isOpen={this.state.tooltipOpen3} target="tooltip3" toggle={this.tooltipToggle3}>
                    Atualizar lista
                  </Tooltip>
                </ButtonGroup>
              </Col>
            </Row>

            {this.props.userActivities.data.data &&
              this.props.userActivities.data.data.length > 0 &&
              this.props.userActivities.data.data.map((activity, actIdx) => (
                <UserActivityItem key={`kkey_${actIdx}`} item={activity} profile={this.props.profile} saveActivityCallback={this._saveActivityCallback} updateActivityCallback={this.handleUpdateActivity} />
              ))}
          </Col>

          {this.state.showTrainingModalVisible && <TrainingShowModal open={this.state.showTrainingModalVisible} training={this.state.trainingToShow} toggle={() => this.setState({ ...this.state, showTrainingModalVisible: !this.state.showTrainingModalVisible })} />}
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userActivities: state.userActivities,
  userTrainingPlan: state.userTrainingPlan,
  profile: state.user.profile,
  stravaIntegrated: state.user.stravaIntegrated,
  trainingPlanRequestShowModal: state.trainingPlanRequests.showModal,
  trainingPlanRequestShowModalTMPButton: state.trainingPlanRequests.trainingPlanRequestShowModalTMPButton
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...UserActivitiesActions, ...UserTrainingPlanActions, ...TrainingPlanRequests }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
