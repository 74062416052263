import React, { useState } from 'react';
import { Modal, Row, Col, TabPane, TabContent } from 'reactstrap';
import { MdClose } from 'react-icons/md'
import { ModalTopBar, StepTab, GenericButton, ButtonsFooter, StripedStepsIndicator, StripedIndicator, ImageContainer } from './styles'
import cpIcon from '../../../assets/img/logo-cp-y-sm.png'
import stepTwoImage from '../../../assets/onboarding/principal.png'
import stepThreeImage from '../../../assets/onboarding/calendario.png'
import stepFourImage from '../../../assets/onboarding/video-aulas.png'
import stepFiveImage from '../../../assets/onboarding/exercicios-e-funcionais.png'
import stepSixImage from '../../../assets/onboarding/extras.png'
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowModalTrainingPlanRequestTmp } from '~/store/modules/trainingPlanRequests/actions'
import { updateProfileRequest } from '~/store/modules/user/actions'
import { FaLifeRing } from 'react-icons/fa'
import { MdHelp } from 'react-icons/md'

function NewUserOnboardModal({ toggle, open }) {
  const dispatch = useDispatch()

  const profile = useSelector(state => state.user.profile)

  const [activeTab, setActiveTab] = useState(1);
  const steps = [1, 2, 3, 4, 5, 6, 7]

  function updateUserProfile() {
    const data = {
      id: profile.id,
      onboard_status: 'FINISH'
    }

    dispatch(updateProfileRequest(data, false))
  }

  function closeOnboarding() {
    updateUserProfile()

    toggle()
  }

  async function finishOnboarding() {
    updateUserProfile()

    dispatch(toggleShowModalTrainingPlanRequestTmp())

    toggle()
  }

  return (
    <div>
      <Modal isOpen={open} toggle={toggle} size="xl">
        <ModalTopBar>
          <button className="btn" onClick={closeOnboarding}>
            <MdClose size={30} />
          </button>
        </ModalTopBar>

        <Row style={{ backgroundColor: '#081B39', margin: 0, minHeight: '600px' }}>
          <Col lg={12} style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={1}>
                <Row>
                  <Col sm="12">
                    <StepTab>
                      <img src={cpIcon} alt="logo-corrida-perfeita" height="120" />

                      <h2 style={{ color: '#fff', marginTop: 20, textAlign: 'center' }}>Desejamos boas vindas ao<br /> Clube Corrida Perfeita</h2>

                      <p style={{ color: '#fff', marginTop: 20, fontSize: '18pt', textAlign: 'center' }}>
                        Vamos fazer um rápido tour, para que você<br /> tenha ótimos resultados treinando conosco.
                      </p>

                      <StripedStepsIndicator>
                        {
                          steps.map(item => (
                            <StripedIndicator key={item} backgroundColor={activeTab === item ? '#FBE308' : '#5A6978'} />
                          ))
                        }
                      </StripedStepsIndicator>

                      <ButtonsFooter>
                        <GenericButton style={{ marginRight: 20 }} backgroundColor="#081B39" borderColor="#fff" fontColor="#fff" onClick={() => setActiveTab(8)}>
                          Pular
                        </GenericButton>
                        <GenericButton style={{ marginLeft: 20 }} backgroundColor="#FBE308" borderColor="#FBE308" fontColor="#000" onClick={() => setActiveTab(prev => prev + 1)}>
                          Continuar
                        </GenericButton>
                      </ButtonsFooter>
                    </StepTab>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={2}>
                <Row>
                  <Col sm="12">
                    <StepTab>
                      <img src={cpIcon} alt="logo-corrida-perfeita" height="120" />

                      <h2 style={{ color: '#fff', marginTop: 20, textAlign: 'center' }}>Acompanhe sua evolução</h2>

                      <ImageContainer>
                        <img src={stepTwoImage} alt="corrida-perfeita" height="300" />

                        <p style={{ color: '#fff', fontSize: '18pt', textAlign: 'center' }}>
                          Acompanhe em PRINCIPAL<br /> o histórico dos seus treinos e<br /> veja a sua evolução.
                        </p>
                      </ImageContainer>

                      <StripedStepsIndicator>
                        {
                          steps.map(item => (
                            <StripedIndicator key={item} backgroundColor={activeTab === item ? '#FBE308' : '#5A6978'} />
                          ))
                        }
                      </StripedStepsIndicator>

                      <ButtonsFooter>
                        <GenericButton style={{ marginRight: 20 }} backgroundColor="#081B39" borderColor="#fff" fontColor="#fff" onClick={() => setActiveTab(8)}>
                          Pular
                        </GenericButton>
                        <GenericButton style={{ marginLeft: 20 }} backgroundColor="#FBE308" borderColor="#FBE308" fontColor="#000" onClick={() => setActiveTab(prev => prev + 1)}>
                          Continuar
                        </GenericButton>
                      </ButtonsFooter>
                    </StepTab>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={3}>
                <Row>
                  <Col sm="12">
                    <StepTab>
                      <img src={cpIcon} alt="logo-corrida-perfeita" height="120" />

                      <h2 style={{ color: '#fff', marginTop: 20, textAlign: 'center' }}>Plano de treino personalizado para você</h2>

                      <ImageContainer>
                        <img src={stepThreeImage} alt="corrida-perfeita" height="300" />

                        <p style={{ color: '#fff', fontSize: '18pt', textAlign: 'center' }}>
                          Peça e acompanhe seu<br /> treinamento a ser feito em<br /> TREINO.
                        </p>
                      </ImageContainer>

                      <StripedStepsIndicator>
                        {
                          steps.map(item => (
                            <StripedIndicator key={item} backgroundColor={activeTab === item ? '#FBE308' : '#5A6978'} />
                          ))
                        }
                      </StripedStepsIndicator>

                      <ButtonsFooter>
                        <GenericButton style={{ marginRight: 20 }} backgroundColor="#081B39" borderColor="#fff" fontColor="#fff" onClick={() => setActiveTab(8)}>
                          Pular
                        </GenericButton>
                        <GenericButton style={{ marginLeft: 20 }} backgroundColor="#FBE308" borderColor="#FBE308" fontColor="#000" onClick={() => setActiveTab(prev => prev + 1)}>
                          Continuar
                        </GenericButton>
                      </ButtonsFooter>
                    </StepTab>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={4}>
                <Row>
                  <Col sm="12">
                    <StepTab>
                      <img src={cpIcon} alt="logo-corrida-perfeita" height="120" />

                      <h2 style={{ color: '#fff', marginTop: 20, textAlign: 'center' }}>Atinja seus objetivos longe de lesões</h2>

                      <ImageContainer>
                        <img src={stepFourImage} alt="corrida-perfeita" height="300" />

                        <p style={{ color: '#fff', fontSize: '18pt', textAlign: 'center' }}>
                          Não deixe de conferir nossas aulas<br /> exclusivas que vão ajudar na<br /> evolução de sua corrida.
                        </p>
                      </ImageContainer>

                      <StripedStepsIndicator>
                        {
                          steps.map(item => (
                            <StripedIndicator key={item} backgroundColor={activeTab === item ? '#FBE308' : '#5A6978'} />
                          ))
                        }
                      </StripedStepsIndicator>

                      <ButtonsFooter>
                        <GenericButton style={{ marginRight: 20 }} backgroundColor="#081B39" borderColor="#fff" fontColor="#fff" onClick={() => setActiveTab(8)}>
                          Pular
                        </GenericButton>
                        <GenericButton style={{ marginLeft: 20 }} backgroundColor="#FBE308" borderColor="#FBE308" fontColor="#000" onClick={() => setActiveTab(prev => prev + 1)}>
                          Continuar
                        </GenericButton>
                      </ButtonsFooter>
                    </StepTab>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={5}>
                <Row>
                  <Col sm="12">
                    <StepTab>
                      <img src={cpIcon} alt="logo-corrida-perfeita" height="120" />

                      <h2 style={{ color: '#fff', marginTop: 20, textAlign: 'center' }}>Atinja seus objetivos longe de lesões</h2>

                      <ImageContainer>
                        <img src={stepFiveImage} alt="corrida-perfeita" height="300" />

                        <p style={{ color: '#fff', fontSize: '18pt', textAlign: 'center' }}>
                          Acesse nossas séries de exercícios,<br /> bem como aulas ao vivo, voltadas<br /> para o preparo adequado do seu<br /> corpo para a corrida.
                        </p>
                      </ImageContainer>

                      <StripedStepsIndicator>
                        {
                          steps.map(item => (
                            <StripedIndicator key={item} backgroundColor={activeTab === item ? '#FBE308' : '#5A6978'} />
                          ))
                        }
                      </StripedStepsIndicator>

                      <ButtonsFooter>
                        <GenericButton style={{ marginRight: 20 }} backgroundColor="#081B39" borderColor="#fff" fontColor="#fff" onClick={() => setActiveTab(8)}>
                          Pular
                        </GenericButton>
                        <GenericButton style={{ marginLeft: 20 }} backgroundColor="#FBE308" borderColor="#FBE308" fontColor="#000" onClick={() => setActiveTab(prev => prev + 1)}>
                          Continuar
                        </GenericButton>
                      </ButtonsFooter>
                    </StepTab>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={6}>
                <Row>
                  <Col sm="12">
                    <StepTab>
                      <img src={cpIcon} alt="logo-corrida-perfeita" height="120" />

                      <h2 style={{ color: '#fff', marginTop: 20, textAlign: 'center' }}>Benefícios exclusivos</h2>

                      <ImageContainer>
                        <img src={stepSixImage} alt="corrida-perfeita" height="300" />

                        <p style={{ color: '#fff', fontSize: '18pt', textAlign: 'center' }}>
                          Confira em EXTRAS seus<br /> benefícios, grupos de<br /> membros e muito mais...
                        </p>
                      </ImageContainer>

                      <StripedStepsIndicator>
                        {
                          steps.map(item => (
                            <StripedIndicator key={item} backgroundColor={activeTab === item ? '#FBE308' : '#5A6978'} />
                          ))
                        }
                      </StripedStepsIndicator>

                      <ButtonsFooter>
                        <GenericButton style={{ marginRight: 20 }} backgroundColor="#081B39" borderColor="#fff" fontColor="#fff" onClick={() => setActiveTab(8)}>
                          Pular
                        </GenericButton>
                        <GenericButton style={{ marginLeft: 20 }} backgroundColor="#FBE308" borderColor="#FBE308" fontColor="#000" onClick={() => setActiveTab(prev => prev + 1)}>
                          Continuar
                        </GenericButton>
                      </ButtonsFooter>
                    </StepTab>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={7}>
                <Row>
                  <Col sm="12">
                    <StepTab>
                      <img src={cpIcon} alt="logo-corrida-perfeita" height="120" />

                      <h2 style={{ color: '#fff', marginTop: 20, textAlign: 'center' }}>Use nosso aplicativo de celular</h2>

                      <p style={{ color: '#fff', marginTop: 20, fontSize: '18pt', textAlign: 'center' }}>
                        Esse acesso via navegador é melhor utilizado em<br /> computadores e tablets.<br /> Por isso, recomendamos que baixe nosso aplicativo<br /> de celular para
                        Android ou iPhone disponível nas<br /> lojas. Basta procurar pelo nome "Corrida Perfeita".<br /> Na nossa <b>Central Tira Dúvidas</b> <FaLifeRing />,<br /> na opção de <b>Ajuda</b> <MdHelp />, você
                        também localiza os<br /> links para baixar.
                      </p>

                      <StripedStepsIndicator>
                        {
                          steps.map(item => (
                            <StripedIndicator key={item} backgroundColor={activeTab === item ? '#FBE308' : '#5A6978'} />
                          ))
                        }
                      </StripedStepsIndicator>

                      <ButtonsFooter>
                        <GenericButton style={{ marginRight: 20 }} backgroundColor="#081B39" borderColor="#fff" fontColor="#fff" onClick={() => setActiveTab(8)}>
                          Pular
                        </GenericButton>
                        <GenericButton style={{ marginLeft: 20 }} backgroundColor="#FBE308" borderColor="#FBE308" fontColor="#000" onClick={() => setActiveTab(prev => prev + 1)}>
                          Continuar
                        </GenericButton>
                      </ButtonsFooter>
                    </StepTab>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={8}>
                <Row>
                  <Col sm="12">
                    <StepTab>
                      <img src={cpIcon} alt="logo-corrida-perfeita" height="120" />

                      <h2 style={{ color: '#fff', marginTop: 20, textAlign: 'center' }}>Comece agora!</h2>

                      <p style={{ color: '#fff', marginTop: 20, fontSize: '18pt', textAlign: 'center' }}>
                        Solicite já o seu primeiro plano de treino de<br /> corrida. É rápido e fácil.
                        Ele será criado por<br /> nossa equipe de treinadores especializados<br /> em até 2 dias úteis.
                      </p>

                      <ButtonsFooter>
                        <GenericButton style={{ marginRight: 20 }} backgroundColor="#081B39" borderColor="#fff" fontColor="#fff" onClick={closeOnboarding}>
                          Agora não
                        </GenericButton>
                        <GenericButton style={{ marginLeft: 20 }} backgroundColor="#FBE308" borderColor="#FBE308" fontColor="#000" onClick={finishOnboarding}>
                          Solicitar treino
                        </GenericButton>
                      </ButtonsFooter>
                    </StepTab>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

export default NewUserOnboardModal;
