import styled from 'styled-components';
import { darken } from 'polished';

export const DayContainer = styled.div`
  display: flex;
  justify-content: spance-around;
  cursor: pointer;
  flex: 1;
  flex-direction: row;
  margin: 0px;
  padding: 0px;
  margin-top: 20px;

  &:hover {
    background: ${darken(0.01, '#f1f1f1')};
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`

export const NewAppInfoBanner = styled.div`
  height: 100px;
  width: 100%;
  background-color: #091B39;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  @media (max-width: 667px) {
    height: 150px;
  }
`

export const NewAppInfoBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const Paragraph = styled.p`
  color: #fff;
`
